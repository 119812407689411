import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAyM5R9vH7hSL8KGetfU3NS_4P9UYS8umE",
  authDomain: "jesssu-8721d.firebaseapp.com",
  databaseURL: "https://jesssu-8721d-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "jesssu-8721d",
  storageBucket: "jesssu-8721d.appspot.com",
  messagingSenderId: "868252052016",
  appId: "1:868252052016:web:88d726f0495842b03f5bf5",
  measurementId: "G-E7R1NP22CF"
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: "BCxmaAbLskfbTdD1mrlmVIRy-KZCD-LzMqLh1KevXpNFWRRiIrCnvaFbR_EP1-_LcTMJTL9DsdQRtLE8WBE-qqM" });
    if (currentToken) {
      return currentToken;
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });