/* eslint-disable */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { delete_single_look } from "../../../store/stash/api"
import { useNavigate } from 'react-router-dom';
import MyLookShare from "components/mylookshare";
const MyLookCard = ({ i }) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    // const [mylook, setMyLook] = useState([]);
    // const [isloading, setIsLoading] = useState(true);
    const state_account = useSelector(state => state.account);

    // const redirecttoproductdetails = (i) => {
    //     history(
    //         `/product/${i.productid}`);
    // }

    const edit_look_route = (i) => {
        history('/editlook/' + i.bundleid, { state: {bundledetails:i} });
    }
    const delete_look = (i) => {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "bundleid": i.bundleid
        }
        dispatch(delete_single_look(userpayload, (res) => {
            if (res.status === 200) {
                // setMyLook(res.data);
            }
        }
        )
        );
    }
    return (
        <>
            {i.layout === "2-2-1" ? <div className="classic_formate" style={{ backgroundImage: i.backgroundimage === "" || i.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${i.backgroundimage})` }}>
                <p className="formal_btn"><a >{i.bundlename}</a></p>
                <div className="classic_right_icon">
                    <ul className="list-unstyled" style={{ "zIndex": "5" }}>
                        <li><a href="#" data-toggle="modal" data-target={`#${i._id}sharelook`}><i className="fa-solid fa-share-nodes"></i></a></li>
                        <li><a onClick={() => edit_look_route(i)}><i className="fa-solid fa-pen"></i></a></li>
                        <li><a href="#"><i className="fa-solid fa-images"></i></a></li>
                        <li><a href="#" data-toggle="modal" data-target={`#${i._id}`} ><i className="fa-solid fa-trash"></i></a></li>
                    </ul>
                </div>
                <div className="classic_formate_scroll1_padding classic_formate_scroll1 no_scroll">
                    <div className="row-section">
                        <div className="new-look-row-for-selected-look">
                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                <img src={`${i.productdetails[0].productimage}`} alt=""
                                    className="classic_img" />
                            </div>
                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                <img src={`${i.productdetails[1].productimage}`} alt=""
                                    className="classic_img" />
                            </div>
                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                <img src={`${i.productdetails[2].productimage}`} alt=""
                                    className="classic_img" />
                            </div>
                        </div>
                    </div>
                    <div className="space_evenly display_flex">
                        <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                            <img src={`${i.productdetails[3].productimage}`} alt=""
                                className="classic_img" />
                        </div>
                        <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                            <img src={`${i.productdetails[4].productimage}`} alt=""
                                className="classic_img" />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-5 col-xs-5">
                            <div className="classic_img_area">
                                {i.productdetails.length > 0 &&
                                    <img src={`${i.productdetails[0].productimage}`} alt=""
                                        className="classic_img" />
                                }
                            </div>
                            <div className="classic_img_area">
                                {i.productdetails.length > 1 &&
                                    <img src={`${i.productdetails[1].productimage}`} alt=""
                                        className="classic_img" />
                                }
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-3">
                            <div className="classic-img-area2">
                                {i.productdetails.length > 2 &&
                                    <img src={`${i.productdetails[2].productimage}`} alt=""
                                        className="classic_img" />
                                }
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-3">
                            <div className="classic-img-area2">
                                {i.productdetails.length > 3 &&
                                    <img src={`${i.productdetails[3].productimage}`} alt=""
                                        className="classic_img" />
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-6">
                            <div className="classic-img-area3">
                                {i.productdetails.length > 4 &&
                                    <img src={`${i.productdetails[4].productimage}`} alt=""
                                        className="classic_img" />
                                }
                            </div>
                        </div>
                    </div> */}

                </div>
            </div> :
                i.layout === "3-2" ? <div className="classic_formate" style={{ backgroundImage: i.backgroundimage === "" || i.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${i.backgroundimage})` }}>
                    <p className="formal_btn"><a >{i.bundlename}</a></p>
                    <div className="classic_right_icon">
                        <ul className="list-unstyled">
                            <li><a href="#" data-toggle="modal" data-target={`#${i._id}sharelook`}><i className="fa-solid fa-share-nodes"></i></a></li>
                            <li><a href="#" onClick={() => edit_look_route(i)}><i className="fa-solid fa-pen"></i></a></li>
                            <li><a href="#"><i className="fa-solid fa-images"></i></a></li>
                            <li><a href="#" data-toggle="modal" data-target={`#${i._id}`} ><i className="fa-solid fa-trash"></i></a></li>
                        </ul>
                    </div>
                    <div className="classic_formate_scroll1 no_scroll">
                        <div className="align_baseline row_section_layout2 mylook_img_layout classic_img1 new-look-box-section">
                            <div>
                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                    <img src={`${i.productdetails[3].productimage}`} alt=""
                                        className="classic_img" />
                                </div>
                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                    <img src={`${i.productdetails[4].productimage}`} alt=""
                                        className="classic_img" />
                                </div>
                            </div>
                            <div className="row-section">
                                <div className="classic_section_box2">
                                    <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                        <img src={`${i.productdetails[0].productimage}`} alt=""
                                            className="classic_img" />
                                    </div>
                                    <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                        <img src={`${i.productdetails[1].productimage}`} alt=""
                                            className="classic_img" />
                                    </div>
                                    <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                        <img src={`${i.productdetails[2].productimage}`} alt=""
                                            className="classic_img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-4 col-xs-4">
                                <div className="classic-img-area4">
                                    {i.productdetails.length > 0 &&
                                        <img src={`${i.productdetails[0].productimage}`} alt=""
                                            className="classic_img" />
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-4">
                                <div className="classic-img-area4">
                                    {i.productdetails.length > 1 &&
                                        <img src={`${i.productdetails[1].productimage}`} alt=""
                                            className="classic_img" />
                                    }
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-4">
                                <div className="classic-img-area4">
                                    {i.productdetails.length > 2 &&
                                        <img src={`${i.productdetails[2].productimage}`} alt=""
                                            className="classic_img" />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-6">
                                <div className="classic-img-area5">
                                    {i.productdetails.length > 3 &&
                                        <img src={`${i.productdetails[3].productimage}`} alt=""
                                            className="classic_img" />
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-6">
                                <div className="classic-img-area5">
                                    {i.productdetails.length > 4 &&
                                        <img src={`${i.productdetails[4].productimage}`} alt=""
                                            className="classic_img" />
                                    }
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div> :
                    i.layout === "4-4" || i.layout === "" ?
                        <div className="classic_formate" style={{ backgroundImage: i.backgroundimage === "" || i.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${i.backgroundimage})` }} >
                            <p className="formal_btn"><a>{i.bundlename}</a></p>
                            <div className="classic_right_icon">
                                <ul className="list-unstyled">
                                    <li><a href="#" data-toggle="modal" data-target={`#${i._id}sharelook`}><i className="fa-solid fa-share-nodes"></i></a></li>
                                    <li><a href="#" onClick={() => edit_look_route(i)}><i className="fa-solid fa-pen"></i></a></li>
                                    <li><a href="#"><i className="fa-solid fa-images"></i></a></li>
                                    <li><a href="#" data-toggle="modal" data-target={`#${i._id}`} ><i className="fa-solid fa-trash"></i></a></li>
                                </ul>
                            </div>
                            <div className="classic_formate_padding no_scroll">
                                <div className="classic_img1 new-look-row-for-selected-look">
                                    {i.productdetails.length === 0 ? <></> :
                                        <div className="classic_img1 new-look-row-for-selected-look">
                                            <div className="select_layout_img1 classic-img-area6">
                                                <img src={`${i.productdetails[0].productimage}`} alt=""
                                                    className="classic_img1 classic_img" />
                                            </div>
                                            <div className="select_layout_img1 classic-img-area6">
                                                <img src={`${i.productdetails[1].productimage}`} alt=""
                                                    className="classic_img1 classic_img" />
                                            </div>
                                            <div className="select_layout_img1 classic-img-area6">
                                                <img src={`${i.productdetails[2].productimage}`} alt=""
                                                    className="classic_img1 classic_img" />
                                            </div>
                                            <div className="select_layout_img1 classic-img-area6">
                                                <img src={`${i.productdetails[3].productimage}`} alt=""
                                                    className="classic_img1 classic_img" />
                                            </div>
                                        </div>
                                        // i.productdetails.map((data, key) => (
                                        //     <div className="col-md-5" key={key}>
                                        // {/* // <div className="classic_section_box2 section" key={key}> */}

                                        // <div className="mylook_img_layout classic_img_layout2 classic-img-area6" >
                                        //     <img src={`${data.productimage}`} onClick={() => redirecttoproductdetails(data)} alt="" className="classic_img" />
                                        // </div>
                                        // {/* </div> */}
                                        // {/* </div>
                                        // )
                                        // ) */}
                                    }
                                </div>
                            </div>
                            <div className="modal fade" id={`${i._id}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="feed_details modal-dialog offer_dialog modal-md">
                                    <div className="modal-body">
                                        <div className="friends-timeline-pop-up">
                                            <div className="stash-pop-up-section">
                                                <div className="stash-pop-up-box">
                                                    <div className="wishlist-Just-checking-section">
                                                        <h6>DELETE </h6>
                                                    </div>
                                                    <div className="delete-section">
                                                        <h6>Are you sure you want to delete?</h6>
                                                    </div>
                                                    <div className="checking-row">
                                                        <button className="checkingforyes-btn-default" onClick={() => delete_look(i)} data-dismiss="modal">YES</button>
                                                        <button className="checking-btn-default" data-dismiss="modal">NO</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : <></>}
            <MyLookShare lookdata={i} modalid={`${i._id}sharelook`} />
        </>
    );
}
export default MyLookCard;

