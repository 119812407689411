import axios from "axios"
import { jesssuApi } from '../../config';


export const contact_us =
  (payload, callback) => async (dispatch) => {
    axios.post(`${jesssuApi}/api/jesssu/contactus/adddetails`, payload)
      .then(res => {
        callback({ success: true, data: res.data, status: res.status });
      })
      .catch(err => {
        callback({ success: false, status: err.response.status });
      })
  };