/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { notification_message_status } from "../../store/account/accountActions"
import { useDispatch, useSelector } from "react-redux";
import { Footer, Header, Mylook, ProfileSideBar, UserProductCard, Wishlist } from 'components';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AIAgent } from 'genai-product-comparison-agent';
const Stash = () => {
    const history = useNavigate();
    const state_account = useSelector(state => state.account);
    // const state_social = useSelector(state => state.social);
    const dispatch = useDispatch();
    const closeNotification = () => {
        dispatch(notification_message_status(false))
    }
    useEffect(() => {
        if(state_account.profile.email===""){
            history('/login')
        }
    }, []);

    return (
        <>
            <div className="thetop"></div>

            {/* <!-- Navigation Menu --> */}
            <Header />
            <Helmet>
                <title>Your Saved Stash : Organize & Access Favorite Products</title>
                <meta name="description" content="Keep track of your favorite products and posts all in one place. Easily organize and revisit your saved items anytime. Manage your stash and never miss out on great finds!" />
                <meta name="keywords" content="Stash, products" />
            </Helmet>
            {/* <!-- Navigation Menu closed Here -->
            <!-- Stash My Look Area Starts Here--> */}

            <div className="stash_my_look_area">
                <div className="container">
                    <div className="row">
                        <ProfileSideBar />
                        <div className="col-md-6">
                            <div className="wishlist_area">
                                <div>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="wishlist_box">
                                                {/* <!-- Nav tabs --> */}
                                                <ul className="nav nav-pills nav-pills-justified display_flex space_between2">
                                                    <li className="active"><a href="#wishlist" data-toggle="tab">My Wishlist</a></li>
                                                    <li><a href="#mylook" data-toggle="tab">My Look</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="col-md-4">
                                            <div className="wish_button">
                                                <a href={"/createnewlook"} target={"_self"} className="buy_now_btn buy_now_btn_bg">
                                                    <i className="fa-solid fa-plus"></i> Create</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="wishlist">
                                            <Wishlist />
                                        </div>
                                        <div role="tabpanel" className="tab-pane" id="mylook">
                                            <Mylook />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <UserProductCard />
                    </div>
                </div>
                <div>
                    <AIAgent email={state_account.profile.email} token={state_account.profile.token}/>
                </div>
                {state_account.showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <LazyLoadImage src="images/Icon metro-cancel.svg" alt="Cancel icon" onClick={closeNotification} />
                        <h5>{state_account.showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )}
            </div>

            {/* <!-- Stash My Look Area Closed Here -->
            <!-- Footer Section Start --> */}
            <Footer />
        </>
    );
}
export default Stash;
