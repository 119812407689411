/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_product_list_pagination } from "store/products/api";
import { add_delete_wishlist_product, get_wishlist } from "store/stash/api";
const UserProductCard = () => {
    const state_account = useSelector(state => state.account);
    const [isloading, setIsloading] = useState(true);
    const [userproductlist, setUserProductList] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    const dispatch = useDispatch();
    const pattern = /\.(jpeg|jpg|gif|png|svg)$/i;
    let pageno = 0;
    const count = 20;
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            get_wishlists();
            getProductsByUserDetails();
        }
        return () => { isMounted = false };
    }, []);

    const getProductsByUserDetails = () => {
        let payload = {
            "token": state_account.profile.token,
            "email": state_account.profile.email,
            'brands': state_account.profile.brand,
            'categories': state_account.profile.category,
            'minprice': "",
            'maxprice': "",
            'sortby': "",
            'subcategories': [],
            'gender': state_account.profile.gender,
            "COO": state_account.profile.COO,
        }
        dispatch(get_product_list_pagination(payload, pageno, count, (res) => {
            if (res.status === 200) {
                setUserProductList(res.data);
                setIsloading(false);
            }
        }))
    }

    const get_wishlists = () => {
        var userpayload =
        {
            "token": state_account.profile.token,
            "email": state_account.profile.email,
        }
        dispatch(get_wishlist(userpayload, (res) => {
            if (res.status === 200) {
                // res.data.map((i) => {
                //     setWishlist(current => [...current, i.item_id[0]])
                // });
                const updatedWishlist = res.data.map((item) => item.item_id[0]);
                setWishlist((current) => [...current, ...updatedWishlist]);
            }
        }));
    }


    async function addproductinwishlist(data) {
        let payload = {
            "token": state_account.profile.token,
            "email": state_account.profile.email,
            "productid": data.SKU,
            "url": data.ProductImage
        }
        let status = pattern.test(data.ProductImage)
        if(status === true){dispatch(add_delete_wishlist_product(payload, (res) => {
            if (res.status === 200) {
                if (res.data === "Product added to wishist") {
                    setWishlist(current => [...current, data.SKU])
                }
                else {
                    setWishlist((prevState) =>
                        prevState.filter((prevItem) => prevItem !== data.SKU)
                    );
                }
                // get_wishlists();
            }
        }
        )
        );}

        
    }

    return (<>
        <div className="col-md-3">
            <div className="related_product">
                <p className="related_product_heading">Related Product</p>
                <div className="classic_container">
                    <div className="classic_height">
                        <div className="row">
                            {isloading === true ? <div className="loader"></div> :
                                userproductlist.length === 0 ? <></> :
                                    userproductlist.map((i) => (
                                        <div className="col-md-6">
                                            <div className="home_page_dialog">
                                                <div className="related_product select_for_img">
                                                    {i.ProductImage !== "" ? <img src={`${i.ProductImage}`} alt="" className="img-responsive" />: <img src="/images/defultimg.svg" alt="" className="img-responsive" /> }
                                                    <div className="related_product_share like_share_product">
                                                        {wishlist.includes(i.SKU) === true &&
                                                            <a href="#" onClick={() => addproductinwishlist(i)}><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                        {wishlist.includes(i.SKU) === false &&
                                                            <a href="#" onClick={() => addproductinwishlist(i)}><i className="fa-regular fa-heart" ></i></a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div></>);
}
export default UserProductCard;