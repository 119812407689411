import * as types from "./stashTypes";

let stashobj = {
    loaded: false,
    loading: false,
    wishlist: [],
};


export default function stashReducer(state = stashobj, { type, payload }) {
    switch (type) {
        case types.ADD_WISHLIST:
            return {
                ...state,
                wishlist: (payload !== undefined && payload.length > 0) ? payload : state.wishlist,
                loaded: true,
                loading: false,
            };

        default:
            return state;
    }
}
