/* eslint-disable */
import { Header, Footer, Productshare } from 'components';
import React, { useEffect,  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_product_details,  get_product_list_pagination } from 'store/products/api';
// import ReactTooltip from "react-tooltip";
import { get_single_share_details, get_single_share_like_details, get_single_share_comment_details, get_single_share_dislike_details, get_single_share_shareby_details } from 'store/social/api';
import { get_single_share } from 'store/social/socialActions';
import { add_delete_wishlist_product, get_wishlist } from "store/stash/api";
import { Link } from "react-router-dom";
import "./singlepost.css"
import { notification_message_status } from "../../store/account/accountActions"
import { useNavigate } from 'react-router';
import { get_friend_list_success } from '../../store/account/accountActions'
import { get_friend_list } from "../../store/friend/api"


const Singlepost = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const state_account = useSelector(state => state.account);
    const social_account = useSelector(state => state.social);
    // const [sharedetails, setsharedetails] = useState([])
    const [sharelikedetails, setsharelikedetails] = useState([])
    const [sharedislikedetails, setsharedislikedetails] = useState([])
    const [sharecommentdetails, setsharecommentdetails] = useState([])
    const [sharesharedbydetails, setsharesharedbydetails] = useState([])
    const [recommandedProductList, setRecommandedProductList] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    const [shareddata, setshareddata] = useState(typeof any)
    const [reshare, setreshare] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const showNotificationMessage = false;



    useEffect(() => {
        getsharedetails()
        get_wishlists()
        getfriendlist()

    }, []);
    const closeNotification = () => {
        setShowNotification(false);
        dispatch(notification_message_status(false))
    }
    function getproductdataforshare(data) {
        setshareddata(data)
        setreshare(true)
    }
    async function addproductinwishlist(data) {
        let payload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "productid": data.SKU,
            "url": data.MainImage
        }

        dispatch(add_delete_wishlist_product(payload, (res) => {
            if (res.status === 200) {
                if (res.data === "Product added to wishist") {
                    setWishlist(current => [...current, data.SKU])
                }
                else {
                    setWishlist((prevState) =>
                        prevState.filter((prevItem) => prevItem !== data.SKU)
                    );
                }
            }
        }));
    }
    function getsharedetails() {
        ;
        let urldata = (window.location.pathname)
        let urls = urldata.split("/")
        let searchparams = `${urls[2]}`
        // console.log(searchparams)
        const payload = {
            "shareid": searchparams,
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }

        dispatch(get_single_share_details(payload, (res) => {
            if (res.status === 200) {
                dispatch(get_single_share(res.data))
                if (res.data[0].type === 1) {
                    getProductDetails(res.data[0].productid)
                }

            }
        }));
        dispatch(get_single_share_like_details(payload, (res) => {
            if (res.status === 200) {
                setsharelikedetails(res.data)
                // console.log(res.data)
            }
        }));
        dispatch(get_single_share_dislike_details(payload, (res) => {
            if (res.status === 200) {
                setsharedislikedetails(res.data)
                // console.log(res.data)
            }
        }));
        dispatch(get_single_share_comment_details(payload, (res) => {
            if (res.status === 200) {
                setsharecommentdetails(res.data)
                // console.log(res.data)
            }
        }));
        dispatch(get_single_share_shareby_details(payload, (res) => {
            if (res.status === 200) {
                setsharesharedbydetails(res.data)
                // console.log(res.data)
            }
        }));


        // console.log(sharedetails)


    }
    function get_wishlists() {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }
        dispatch(get_wishlist(userpayload, (res) => {

            if (res.status === 200) {
                for (let i = 0; i < res.data.length; i++) {
                    setWishlist(current => [...current, res.data[i].item_id[0]]);
                  }
            }
        }));

    }
    const getfriendlist = () => {
        let userpayload =
        {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_friend_list(userpayload, (res) => {
            if (res.status === 200) {
                // setFriendList(res.data);
                dispatch(get_friend_list_success(res.data))
            }
        }));
    }

    function getProductDetails(id) {
        var payload =
        {
            'token': state_account.profile.token,
            'userid': state_account.profile.email,
            "COO": state_account.profile.COO,
        }
        dispatch(get_product_details(payload, id, (res) => {

            if (res.status === 200) {
                getRecommandedProduct(res.data[0].Brand, res.data[0].Primary)
            }
        }));
    }
    // function gotosuggestedproductlist() {
    //     let category = ""
    //     state_account.profile.category.forEach(i => {
    //         const data = changevalueforurl(i)
    //         category = category + "&category=" + data
    //     })
    //     // console.log(category)
    //     let brand = ''
    //     state_account.profile.brand.forEach(i => {
    //         const data = changevalueforurl(i)
    //         brand = brand + "&brand=" + data
    //     })
    //     // console.log(brand)
    //     history(`products/any${category}${brand}`)
    // }

    function getRecommandedProduct(brand, category) {

        var payload =
        {
            'brands': [brand],
            'categories': [category],
            'minprice': "",
            'maxprice': "",
            'sortby': "",
            'subcategories': [],
            'token': null,
            'userid': null,
            'gender': "",
            "COO": [],
        }
        dispatch(get_product_list_pagination(payload, 0, 4, (res) => {
            if (res.status === 200) {
                setRecommandedProductList([...recommandedProductList, ...res.data]);
            }
        }));
    }
    // function nothing() { }
    // function changevalueforurl(data) {
    //     let step1 = data.replace("&", "-")
    //     let step2 = step1.replace("/", ".")
    //     let step3 = step2.replace("/", ".")
    //     return step3
    // }
    function routetotimeline(i) {
        history('/timeline', { state: { friendemail: i } });
    }
    // console.log(social_account.singleshare)
    return (
        <>
            <div className="thetop"></div>
            <Header />
            <img className='cursor-pointer' onClick={() => history.goBack()} src="/images/Back.png" alt="" />
            <div className="post-details-box">




                <div className="postdetails_area">

                    <div className="container">

                        <div className="row">
                            <div className="col-md-5">
                                <div className="details-box">
                                    {social_account.singleshare.length != ['undefined'] &&
                                        <div className="post-customer-row">
                                            <div class="col-md-2">
                                                <div className="post-customer-information-row">
                                                    <div className="customer-img-section">
                                                        {social_account.singleshare[0].imageofsharedby !== undefined &&
                                                            <img src={`${social_account.imageofsharedby[0] && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(social_account.imageofsharedby[0])
                                                                ? social_account.imageofsharedby[0]
                                                                : social_account.genderofsharedby === undefined ? "images/onboarding/man.png" : social_account.genderofsharedby[0] === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                }`} alt="" />
                                                            // <img src={social_account.imageofsharedby[0]} onError={({ currentTarget }) => {
                                                            //     currentTarget.onerror = null; // prevents looping
                                                            //     currentTarget.src = `${social_account.genderofsharedby[0] == "" || undefined || null ? "images/onboarding/man.png" : "images/onboarding/woman.png"}`;
                                                            // }} alt="" title="" />
                                                            // <img src={social_account.singleshare[0].imageofsharedby[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${social_account.singleshare[0].imageofsharedby[0]}`} alt="" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div className="information-section">
                                                    {social_account.singleshare[0].note !== undefined &&
                                                        <h6>{social_account.singleshare[0].note[0].note}</h6>}

                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div className="customer-comment-details">
                                                    {social_account.singleshare[0].createdAt !== undefined &&
                                                        <p>{new Intl.DateTimeFormat('en-GB', {
                                                            month: 'long',
                                                            day: '2-digit',
                                                            year: 'numeric',
                                                        }).format(new Date(social_account.singleshare[0].createdAt))}</p>}
                                                </div>
                                            </div>
                                        </div>



                                    }
                                    <div className="post-padding">
                                        {/* <div className="img-post-section"> */}
                                        {/* <div className="row">
                                                <div className="col-md-6">
                                                    <div className="post-detail-area">
                                                        <img src="images/onboarding anirban/demo2.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="post-detail-area">
                                                        <img src="images/onboarding anirban/demo2.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="post-detail-area">
                                                        <img src="images/onboarding anirban/demo2.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="post-detail-area">
                                                        <img src="images/onboarding anirban/demo2.png" alt="" />
                                                    </div>
                                                </div>
                                            </div> */}

                                        {social_account.singleshare[0].type === 1 &&
                                            <div className="social_box_img">
                                                <Link to={`/product/${social_account.singleshare[0].productid}`}><img src={social_account.singleshare[0].postimage} alt="" className="img-responsive" />
                                                </Link>
                                            </div>}

                                        {social_account.singleshare[0].type === 3 &&
                                            <div className="social_box_img">
                                                <a href='#'>
                                                    <img src={social_account.singleshare[0].postimage} alt="" className="img-responsive" /></a>
                                            </div>}


                                        {social_account.singleshare[0].type === 6 &&

                                            <div>
                                                {
                                                    social_account.singleshare[0].layout === "2-2-1" ?
                                                        <div className="classic_formate" style={{ backgroundImage: social_account.singleshare[0].backgroundimage === "" || social_account.singleshare[0].backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${social_account.singleshare[0].backgroundimage})` }}>

                                                            <div className="classic_formate_scroll1 no_scroll">
                                                                <div className="row">
                                                                    <div className="col-md-5 col-xs-5">
                                                                        <div className="classic_img_area">
                                                                            {social_account.singleshare[0].bundleproduct.length > 0 &&
                                                                                <img src={`${social_account.singleshare[0].bundleproduct[0].productimage}`} alt=""
                                                                                    className="classic_img" />
                                                                            }
                                                                        </div>
                                                                        <div className="classic_img_area">
                                                                            {social_account.singleshare[0].bundleproduct.length > 1 &&
                                                                                <img src={`${social_account.singleshare[0].bundleproduct[1].productimage}`} alt=""
                                                                                    className="classic_img" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-xs-3">
                                                                        <div className="classic-img-area2">
                                                                            {social_account.singleshare[0].bundleproduct.length > 2 &&
                                                                                <img src={`${social_account.singleshare[0].bundleproduct[2].productimage}`} alt=""
                                                                                    className="classic_img" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3 col-xs-3">
                                                                        <div className="classic-img-area2">
                                                                            {social_account.singleshare[0].bundleproduct.length > 3 &&
                                                                                <img src={`${social_account.singleshare[0].bundleproduct[3].productimage}`} alt=""
                                                                                    className="classic_img" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-xs-6">
                                                                        <div className="classic-img-area3">
                                                                            {social_account.singleshare[0].bundleproduct.length > 4 &&
                                                                                <img src={`${social_account.singleshare[0].bundleproduct[4].productimage}`} alt=""
                                                                                    className="classic_img" />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        :
                                                        social_account.singleshare[0].layout === "3-2" ?
                                                            <div className="classic_formate" style={{ backgroundImage: social_account.singleshare[0].backgroundimage === "" || social_account.singleshare[0].backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${social_account.singleshare[0].backgroundimage})` }}>
                                                                <div className="classic_formate_scroll1 no_scroll">
                                                                    <div className="row">
                                                                        <div className="col-md-4 col-xs-4">
                                                                            <div className="classic-img-area4">
                                                                                {social_account.singleshare[0].bundleproduct.length > 0 &&
                                                                                    <img src={`${social_account.singleshare[0].bundleproduct[0].productimage}`} alt=""
                                                                                        className="classic_img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-xs-4">
                                                                            <div className="classic-img-area4">
                                                                                {social_account.singleshare[0].bundleproduct.length > 1 &&
                                                                                    <img src={`${social_account.singleshare[0].bundleproduct[1].productimage}`} alt=""
                                                                                        className="classic_img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-xs-4">
                                                                            <div className="classic-img-area4">
                                                                                {social_account.singleshare[0].bundleproduct.length > 2 &&
                                                                                    <img src={`${social_account.singleshare[0].bundleproduct[2].productimage}`} alt=""
                                                                                        className="classic_img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xs-6">
                                                                            <div className="classic-img-area5">
                                                                                {social_account.singleshare[0].bundleproduct.length > 3 &&
                                                                                    <img src={`${social_account.singleshare[0].bundleproduct[3].productimage}`} alt=""
                                                                                        className="classic_img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xs-6">
                                                                            <div className="classic-img-area5">
                                                                                {social_account.singleshare[0].bundleproduct.length > 4 &&
                                                                                    <img src={`${social_account.singleshare[0].bundleproduct[4].productimage}`} alt=""
                                                                                        className="classic_img" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : social_account.singleshare[0].layout === "4-4" || social_account.singleshare[0].layout === "" || social_account.singleshare[0].layout === null ?

                                                                <div className="classic_formate" style={{ backgroundImage: social_account.singleshare[0].backgroundimage === "" || social_account.singleshare[0].backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${social_account.singleshare[0].backgroundimage})` }} >
                                                                    <div className="classic_formate_scroll no_scroll">
                                                                        <div className="row">
                                                                            {social_account.singleshare[0].bundleproduct.length === 0
                                                                                ?
                                                                                <></>
                                                                                :
                                                                                social_account.singleshare[0].bundleproduct.map((data, key) => (
                                                                                    <div className="col-md-6 col-xs-6" key={key}>
                                                                                        <div className="classic_img_area">
                                                                                            <img src={`${data.productimage}`} alt="" className="classic_img" />
                                                                                        </div>
                                                                                    </div>)
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>

                                                                </div> : <div className="classic_formate" style={{ backgroundImage: social_account.singleshare[0].backgroundimage === "" || social_account.singleshare[0].backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${social_account.singleshare[0].backgroundimage})` }} >
                                                                    <div className="classic_formate_scroll no_scroll">
                                                                        <div className="row">
                                                                            {social_account.singleshare[0].bundleproduct.length === 0
                                                                                ?
                                                                                <></>
                                                                                :
                                                                                social_account.singleshare[0].bundleproduct.map((data, key) => (
                                                                                    <div className="col-md-6 col-xs-6" key={key}>
                                                                                        <div className="classic_img_area">
                                                                                            <img src={`${data.productimage}`} alt="" className="classic_img" />
                                                                                        </div>
                                                                                    </div>)
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                }
                                            </div>
                                        }
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1">

                            </div>
                            <div className="col-md-6">
                                <div className="post-details-information-section-box">
                                    <div className="like-dislike-section">
                                        <div className="wrapper">
                                            <div className="title">


                                            </div>
                                            <div className="container1">
                                                <div className="card tabs">
                                                    <input id="tab-1" type="radio" className="tab tab-selector"
                                                        name="tab" />
                                                    <label htmlFor="tab-1" className="tab tab-primary">
                                                        <a href='#'><i className="fa-solid fa-thumbs-up"></i> {sharelikedetails.length}</a></label>
                                                    <input id="tab-2" type="radio" className="tab tab-selector" name="tab" />
                                                    <label htmlFor="tab-2" className="tab tab-success"> <a href='#'><i
                                                        className="fa-solid fa-thumbs-down"></i>{sharedislikedetails.length}</a></label>
                                                    <input id="tab-3" type="radio" className="tab tab-selector" name="tab" />
                                                    <label htmlFor="tab-3" className="tab tab-default"> <a href='#'><i
                                                        className="fa-solid fa-comment"></i> {sharecommentdetails.length}</a></label>
                                                    <input id="tab-4" type="radio" className="tab tab-selector" name="tab" />
                                                    <label htmlFor="tab-4" className="tab tab-warning"> <a href='#'><i
                                                        className="fa-solid fa-share"></i> {sharesharedbydetails.length}</a></label>
                                                    <div className="tabsShadow"></div>
                                                    <div className="glider"></div>
                                                    <section className="content">
                                                        <div className="item" id="content-1">

                                                            <div className="post-details-section-wrap">
                                                                <div className="post-timeline-brand-content">

                                                                    {sharelikedetails.map((data, index) => (
                                                                        <div className="post-comment-img-row" key={index}>

                                                                            <img onClick={() => { routetotimeline(data.email[0]) }} src={data.profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`}
                                                                                alt="" />
                                                                            <p onClick={() => { routetotimeline(data.email[0]) }}>{data.userfirstname[0]} {data.userlastname[0]}</p>

                                                                            <p>Likes this post</p>
                                                                        </div>))}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item" id="content-2">
                                                            <div className="post-details-section-wrap">
                                                                <div className="post-timeline-brand-content">

                                                                    {sharedislikedetails.map((data, index) => (

                                                                        <div className="post-comment-img-row" key={index}>
                                                                            <img src={`${data.profileimage[0] && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0])
                                                                                ? data.profileimage[0]
                                                                                : data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                                                }`} alt="" onClick={() => { routetotimeline(data.email[0]) }} />
                                                                            {/* <img src={data.profileimage[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`}
                                                                                alt="" onClick={() => { routetotimeline(data.email[0]) }} /> */}
                                                                            <p onClick={() => { routetotimeline(data.email[0]) }}>{data.userfirstname[0]} {data.userlastname[0]}</p>

                                                                            <p>Disikes this post</p>
                                                                        </div>))}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="item" id="content-3">
                                                            <div className="post-details-section-wrap">
                                                                <div className="post-timeline-brand-content">

                                                                    {sharecommentdetails.map((data, index) => (

                                                                        <div className="post-comment-img-row" key={index}>

                                                                            <img onClick={() => { routetotimeline(data.userid[0]) }} src={data.profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`}
                                                                                alt="" />
                                                                            <p onClick={() => { routetotimeline(data.userid[0]) }}>{data.userfirstname[0]} {data.userlastname[0]}</p>
                                                                            <p>{data.comment}</p>

                                                                        </div>))}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="item" id="content-4">
                                                            <div className="post-details-section-wrap">
                                                                <div className="post-timeline-brand-content">

                                                                    {sharesharedbydetails.map((data, index) => (

                                                                        <div className="post-comment-img-row" key={index}>

                                                                            <img onClick={() => { routetotimeline(data._id[0]) }} src={data.profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`}
                                                                                alt="" />
                                                                            <p onClick={() => { routetotimeline(data._id[0]) }}>Shared by: {data.userfirstname[0]} {data.userlastname[0]}</p>

                                                                            {/* <p>{data.comment}</p> */}
                                                                        </div>))}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </section>

                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="product_today">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10">
                                    {social_account.singleshare[0].type === 6 &&
                                        <h3>Products in this post</h3>}
                                    {social_account.singleshare[0].type === 1 &&
                                        <h3>Share related products</h3>}
                                </div>
                                {/* {social_account.singleshare[0].type === 1 &&
                                <div className="col-md-2">
                                    <p><a  onClick={() => gotosuggestedproductlist()} className="see_more">See More</a></p>
                                </div>} */}
                            </div>


                            <div className="row">
                                {social_account.singleshare[0].type === 6 &&
                                    <div>
                                        <div className="col-md-3">
                                            <div className="select_for-box home_page_dialog">
                                                <div className="post-select_for_img"> <img src={social_account.singleshare[0].bundleproduct[0].productimage}
                                                    alt="" className="img-responsive" />

                                                    {/* <div className="post-like_share_product">
                                                    {wishlist.includes(social_account.singleshare[0].bundleproduct[0].productid) == true &&
                                                                    <a onClick={() => addproductinwishlist(social_account.singleshare[0].bundleproduct[0].productid)}><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                                {wishlist.includes(social_account.singleshare[0].bundleproduct[0].productid) == false &&
                                                                    <a onClick={() => addproductinwishlist(social_account.singleshare[0].bundleproduct[0].productid)}><i className="fa-regular fa-heart" ></i></a>}
                                                                <a data-toggle="modal" data-target={`#example-sharemodel${social_account.singleshare[0].bundleproduct[0].productid}`} onClick={() => { getproductdataforshare(social_account.singleshare[0].bundleproduct[0].productid) }}><i className="fa-solid fa-share"></i></a>
                                                    </div> */}
                                                </div>
                                                <div className="post-select_for_text">
                                                    <p><a href={`/product/${social_account.singleshare[0].bundleproduct[0].productid}`}>{social_account.singleshare[0].bundleproduct[1].productname}</a></p>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`/product/${social_account.singleshare[0].bundleproduct[0].productid}`} className="post-real_price">$89.99</a></li>
                                                        <li><span className="post-old_price">$111.99</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="select_for-box home_page_dialog">
                                                <div className="post-select_for_img"> <img src={social_account.singleshare[0].bundleproduct[1].productimage}
                                                    alt="" className="img-responsive" />

                                                    {/* <div className="post-like_share_product">
                                                    {wishlist.includes(social_account.singleshare[0].bundleproduct[1].productid) == true &&
                                                                    <a onClick={() => addproductinwishlist(social_account.singleshare[0].bundleproduct[1].productid)}><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                                {wishlist.includes(social_account.singleshare[0].bundleproduct[1].productid) == false &&
                                                                    <a onClick={() => addproductinwishlist(social_account.singleshare[0].bundleproduct[1].productid)}><i className="fa-regular fa-heart" ></i></a>}
                                                                <a data-toggle="modal" data-target={`#example-sharemodel${social_account.singleshare[0].bundleproduct[1].productid}`} onClick={() => { getproductdataforshare(social_account.singleshare[0].bundleproduct[0].productid) }}><i className="fa-solid fa-share"></i></a>
                                                    </div> */}
                                                </div>
                                                <div className="post-select_for_text">
                                                    <p><a href={`/product/${social_account.singleshare[0].bundleproduct[1].productid}`} >{social_account.singleshare[0].bundleproduct[1].productname}</a></p>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`/product/${social_account.singleshare[0].bundleproduct[1].productid}`} className="post-real_price">$89.99</a></li>
                                                        <li><span className="post-old_price">$111.99</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="select_for-box home_page_dialog">
                                                <div className="post-select_for_img"> <img src={social_account.singleshare[0].bundleproduct[2].productimage}
                                                    alt="" className="img-responsive" />

                                                    {/* <div className="post-like_share_product">
                                                    {wishlist.includes(social_account.singleshare[0].bundleproduct[2].productid) == true &&
                                                                    <a onClick={() => addproductinwishlist(social_account.singleshare[0].bundleproduct[2].productid)}><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                                {wishlist.includes(social_account.singleshare[0].bundleproduct[2].productid) == false &&
                                                                    <a onClick={() => addproductinwishlist(social_account.singleshare[0].bundleproduct[2].productid)}><i className="fa-regular fa-heart" ></i></a>}
                                                                <a data-toggle="modal" data-target={`#example-sharemodel${social_account.singleshare[0].bundleproduct[2].productid}`} onClick={() => { getproductdataforshare(social_account.singleshare[0].bundleproduct[2].productid) }}><i className="fa-solid fa-share"></i></a>
                                                    </div> */}
                                                </div>
                                                <div className="post-select_for_text">
                                                    <p><a href={`/product/${social_account.singleshare[0].bundleproduct[2].productid}`}>{social_account.singleshare[0].bundleproduct[2].productname}</a></p>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`/product/${social_account.singleshare[0].bundleproduct[2].productid}`} className="post-real_price">$89.99</a></li>
                                                        <li><span className="post-old_price">$111.99</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="select_for-box home_page_dialog">
                                                <div className="post-select_for_img"> <img src={social_account.singleshare[0].bundleproduct[3].productimage}
                                                    alt="" className="img-responsive" />

                                                    {/* <div className="post-like_share_product">
                                                    {wishlist.includes(social_account.singleshare[0].bundleproduct[3].productid) == true &&
                                                                    <a onClick={() => addproductinwishlist(social_account.singleshare[0].bundleproduct[3].productid)}><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                                {wishlist.includes(social_account.singleshare[0].bundleproduct[1].productid) == false &&
                                                                    <a onClick={() => addproductinwishlist(social_account.singleshare[0].bundleproduct[3].productid)}><i className="fa-regular fa-heart" ></i></a>}
                                                                <a data-toggle="modal" data-target={`#example-sharemodel${social_account.singleshare[0].bundleproduct[3].productid}`} onClick={() => { getproductdataforshare(social_account.singleshare[0].bundleproduct[3].productid) }}><i className="fa-solid fa-share"></i></a>
                                                    </div> */}
                                                </div>
                                                <div className="post-select_for_text">
                                                    <p><a href={`/product/${social_account.singleshare[0].bundleproduct[3].productid}`}>{social_account.singleshare[0].bundleproduct[3].productname}</a></p>
                                                    <ul className="list-unstyled display_flex space_between2">
                                                        <li><a href={`/product/${social_account.singleshare[0].bundleproduct[3].productid}`} className="post-real_price">$89.99</a></li>
                                                        <li><span className="post-old_price">$111.99</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {social_account.singleshare[0].type === 1 &&
                                    <>
                                        {recommandedProductList.length === 0 ? <></> :
                                            recommandedProductList.map((product, i) =>
                                            (
                                                <div className="col-md-3">
                                                    <div className="select_for-box home_page_dialog">
                                                        <div className="post-select_for_img"> <img src={product.MainImage}
                                                            alt="" className="img-responsive" />

                                                            <div className="post-like_share_product">
                                                                <div className="social_section_show"></div>
                                                                <div className="social_section">
                                                                    {wishlist.includes(product.SKU) === true &&
                                                                        <a href='#' onClick={() => addproductinwishlist(product)}><i className="fa-regular fa-heart product-details-wish-icon-background"></i></a>}
                                                                    {wishlist.includes(product.SKU) === false &&
                                                                        <a href='#' onClick={() => addproductinwishlist(product)}><i className="fa-regular fa-heart" ></i></a>}
                                                                    <a data-toggle="modal" data-target={`#example-sharemodel${product.SKU}`} onClick={() => { getproductdataforshare(product) }}><i className="fa-solid fa-share"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="post-select_for_text">
                                                            <p><a href={`/product/${product.SKU}`}>{product.Name}</a></p>
                                                            <ul className="list-unstyled display_flex space_between2">
                                                                <li><a href={`/product/${product.SKU}`} className="post-real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                                <li><span className="post-old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            )
                                        }</>
                                }

                            </div>
                        </div>
                    </div>
                </div >
            </div >
            {reshare === true ? (
                <Productshare productdata={shareddata} pkey={"productdetails"} />
            ) : <></>}

            {state_account.showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
                        <h5>{state_account.showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
                        <h5>{showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <Footer />
        </>
    )
}

export default Singlepost;