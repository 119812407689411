/* eslint-disable */
import React, { useEffect, useState } from "react";
// import { useDispatch} from "react-redux";
import $ from "jquery";
import { Footer, Header } from "components";
// import { async } from "@firebase/util";
import { getallbrands } from "../../store/products/api"
import { Link } from "react-router-dom";
// import { bootstrapwitnesses_add } from "cardano/serialization-lib/@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib_bg.wasm";

const Brand = () => {
    // const dispatch = useDispatch();
    // const brand = {
    //     _id: "",
    //     image: ""
    // }
    const [brands, setbrands] = useState([])
    let pageno = 0;
    const count = 10;


    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getbrands();
            // $('body').addclassName('loaded');
            $(document).ready(function () {
                $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    $(this).parent().siblings().removeClass('open');
                    $(this).parent().toggleClass('open');
                });
            });
        }
        return () => { isMounted = false };
    }, []);

    const getmorebrands = () => {
        pageno = pageno + 1
        const payload = {};
        getallbrands(payload, pageno, count, (res) => {
            for (let i = 0; i < res.data.length; i++) {
                setbrands(current => [...current, res.data[i]])
            }
        })
    }

    const getbrands = async () => {
        const payload = {}
        await getallbrands(payload, pageno, count, (res) => {
            // setbrands([...brands, `Entry ${res.data.length}`]);
            // res.forEach(i => {
            for (let i = 0; i < res.data.length; i++) {
                setbrands(current => [...current, res.data[i]])
            }
        })
    }
    window.onscroll = function () {
        if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)) {
            getmorebrands()
        }
    }

    return (<>
        <div className="thetop" ></div>
        {/* <!-- Navigation Menu --> */}
        <Header />
        {/* <!-- Navigation Menu closed Here -->

	<!-- Brands Area Starts Here--> */}

        <div className="brands_area" >
            <div className="container" >
                <div className="brand_header">Brands</div>
                <div className="brand_area_content">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10" >
                            <div className="row" style={{ "overflowY": "hidden" }} onScroll={getmorebrands}>
                                {brands.map((data) => (
                                    <div className="col-md-3 col-xs-6" >
                                        <div className="brand_img_area">
                                            <div className="brand_img-section brand_img">
                                                <img src={data.image} alt="" className="img-responsive" />
                                            </div>
                                            <p className="brand_title"><Link to={`products/brand=${data._id}`}>{data._id}</Link></p>
                                        </div>
                                    </div>))}
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Brands Area Closed Here -->

	<!-- Footer Section Start --> */}
        <Footer />
        <script src="js/jquery.min.js"></script>
        <script src="js/popper.min.js"></script>
        <script src="owlcarousel/owl.carousel.js"></script>
        <script src="js/bootstrap.min.js"></script>
        <script src="js/script.js"></script>



    </>);
}

export default Brand;