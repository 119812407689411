/* eslint-disable */
import React from 'react'

function Friend1() {
	return (
		<>
			<div className="thetop"></div>

			<div className="navbar margin_none home_position navigation_menu navbar_area" role="navigation">
				<div className="container">
					<div className="navbar-header">
						<button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
						<a className="navbar-brand" ><img src="images/logo.png" alt="" className="navbar_brand_img" /></a>
					</div>
					<div className="navbar-collapse collapse">
						<ul className="nav navbar-nav">
							<li className="active"><a href='#'>Discover</a></li>
							<li className="active"><a href="#about">Activity</a></li>
							<li className="active"><a href="#portfolio">Social </a></li>
							<li className="active"><a href="#contact">Stash</a></li>
						</ul>
						<ul className="nav navbar-nav navbar-right mobile_flex">
							<li className="active"><a href='#'><i className="fa-solid fa-magnifying-glass"></i></a></li>
							<li className="active"><a href="#about"><i className="fa-solid fa-cart-arrow-down"></i></a></li>
							<li className="active"><a href="#portfolio"><i className="fa-solid fa-user-large"></i></a></li>
							<li className="active"><a href="#contact"><i className="fa-solid fa-bell"></i></a></li>
						</ul>
					</div>
				</div>
			</div>

			<div className="stash_my_look_area">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="name_area">
								<div className="display_flex align_item_center">
									<div className="name_img_area"><img src="images/home/cate_for1.jpg" alt="" className="name_img" /> </div>
									<div className="name_content">
										<p className="name_content_heading">Roger Fox</p>
										<p><i className="fa-solid fa-circle-dollar-to-slot"></i> 100 Jesssu coins</p>
									</div>
									<div className="button_toggle"><a data-toggle="collapse" href="#collapse2"><i className="fa-solid fa-list-ul"></i></a></div>
								</div>
							</div>
							<div id="collapse2" className="desktop_show panel-collapse collapse">
								<div className="name_area">
									<div className="display_flex profile_list"> <i className="fa-regular fa-circle-user"></i>
										<p><a href='#'>Profile Details</a></p>
									</div>
									<div className="display_flex profile_list"> <i className="fa-solid fa-users"></i>
										<p><a href='#'>Friends</a></p>
									</div>
									<div className="display_flex profile_list"> <i className="fa-regular fa-star"></i>
										<p><a href='#'>Jesssu Coins</a></p>
									</div>
									<div className="display_flex profile_list"> <i className="fa-solid fa-shirt"></i>
										<p><a href='#'>Categories</a></p>
									</div>
									<div className="display_flex profile_list"> <i className="fa-solid fa-wand-magic"></i>
										<p><a href='#'>Brands</a></p>
									</div>
									<div className="display_flex profile_list"> <i className="fa-solid fa-heart"></i>
										<p><a href='#'>Personalization</a></p>
									</div>
									<div className="display_flex profile_list"> <i className="fa-solid fa-bell"></i>
										<p><a href='#'>Notification</a></p>
									</div>
									<div className="display_flex profile_list"> <i className="fa-solid fa-people-arrows-left-right"></i>
										<p><a href='#'>Refer & Earn</a></p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="profile_area">
								<div className="text-right">
									<form method="post">
										<div className="search_friends">
											<i className="fa-solid fa-magnifying-glass"></i>
											<input type="text" Value="Ambani" placeholder="Search friends.." />
										</div>
									</form>
								</div>
								<div className="friend_box">
									<div className="withdrawl_modal">
										<p className="text_bold_600 withdraw_heading">NO MATCH FOUND</p>
										<p className="friend_1">
											Invite <span className="text_bold_600">"Ambani"</span> to <span className="display_block">Jesssu and earn 100 Jesssu Coins</span>
										</p>
										<p><a href='#' className="coin_btn" data-toggle="modal" data-target="#exampleModal2">Invite Now</a>
										</p>

									</div>
								</div>
							</div>

							<div className="modal fade" id="exampleModal2" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
								<div className="feed_details modal-dialog offer_dialog modal-sm">
									<div className="modal-content feed_modal_content">

										<button type="button" className="close modal_close" data-dismiss="modal">&times;</button>

										<div className="modal-body">
											<div className="country_modal">
												<p className="text_bold_600">Enter Recipient's Phone number</p>
												<form>
													<div className="mobile_number_list">
														<div className="input-group">
															<input type="tel" id="phone" />
														</div>
														<p><input type="submit" className="coin_btn" value="Send" />
														</p>
													</div>
												</form>
											</div>


										</div>
									</div>
								</div>
							</div>


						</div>
						<div className="col-md-3">
							<div className="related_product">
								<p className="related_product_heading request_heading">Requests</p>
								<div className="classNameic_height">
									<div className="name_area">
										<div className="display_flex">
											<div className="name_img_area"><img src="images/home/cate_for1.jpg" alt="" className="name_img" />
											</div>
											<div className="name_content">
												<p><span className="text_bold_600">Roger Fox</span> wants to add you in friends</p>
											</div>
										</div>
										<ul className="request_area list-unstyled display_flex space_between2">
											<li><a href='#' className="btn btn-color">Accept</a></li>
											<li><a href='#' className="btn btn-default">Decline</a></li>
										</ul>
									</div>

									<div className="name_area">
										<div className="display_flex">
											<div className="name_img_area"><img src="images/home/cate_for1.jpg" alt="" className="name_img" />
											</div>
											<div className="name_content">
												<p><span className="text_bold_600">Roger Fox</span> wants to add you in friends</p>
											</div>
										</div>
										<ul className="request_area list-unstyled display_flex space_between2">
											<li><a href='#' className="btn btn-color">Accept</a></li>
											<li><a href='#' className="btn btn-default">Decline</a></li>
										</ul>
									</div>

									<div className="name_area">
										<div className="display_flex">
											<div className="name_img_area"><img src="images/home/cate_for1.jpg" alt="" className="name_img" />
											</div>
											<div className="name_content">
												<p><span className="text_bold_600">Roger Fox</span> wants to add you in friends</p>
											</div>
										</div>
										<ul className="request_area list-unstyled display_flex space_between2">
											<li><a href='#' className="btn btn-color">Accept</a></li>
											<li><a href='#' className="btn btn-default">Decline</a></li>
										</ul>
									</div>

									<div className="name_area">
										<div className="display_flex">
											<div className="name_img_area"><img src="images/home/cate_for1.jpg" alt="" className="name_img" />
											</div>
											<div className="name_content">
												<p><span className="text_bold_600">Roger Fox</span> wants to add you in friends</p>
											</div>
										</div>
										<ul className="request_area list-unstyled display_flex space_between2">
											<li><a href='#' className="btn btn-color">Accept</a></li>
											<li><a href='#' className="btn btn-default">Decline</a></li>
										</ul>
									</div>

									<div className="name_area">
										<div className="display_flex">
											<div className="name_img_area"><img src="images/home/cate_for1.jpg" alt="" className="name_img" />
											</div>
											<div className="name_content">
												<p><span className="text_bold_600">Roger Fox</span> wants to add you in friends</p>
											</div>
										</div>
										<ul className="request_area list-unstyled display_flex space_between2">
											<li><a href='#' className="btn btn-color">Accept</a></li>
											<li><a href='#' className="btn btn-default">Decline</a></li>
										</ul>
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
			</div>

			<div className="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="footer_first"> <img src="images/logo.png" alt="" className="img-responsive footer-logo" />
								<p className="footer_sub_heading">Shop Share & Self</p>
								<p>Follow us on:</p>
								<ul className="list-unstyled display_flex">
									<li><a href='#'><i className="fa-brands fa-facebook-square"></i></a></li>
									<li><a href='#'><i className="fa-brands fa-twitter-square"></i></a></li>
									<li><a href='#'><i className="fa-brands fa-linkedin"></i></a></li>
									<li><a href='#'><i className="fa-brands fa-instagram-square"></i></a></li>
								</ul>
							</div>
						</div>
						<div className="col-md-3"></div>
						<div className="col-md-3">
							<p className="footer_sub_heading padding-bottom-20">Contact us</p>
							<ul className="list-unstyled footer_link">
								<li><a href='#'>About us</a></li>
								<li><a href='#'>Mission</a></li>
								<li><a href='#'>Vision</a></li>
								<li><a href='#'>Products</a></li>
								<li><a href='#'>Services</a></li>
								<li><a href='#'>support@edgewrapper.com</a></li>
							</ul>
						</div>
						<div className="col-md-3">
							<p className="footer_sub_heading padding-bottom-20">Download the app on</p> <img src="images/home/Google_Play_Store_badge_EN.svg.png" alt="" className="footer_store" /> <img src="images/home/apple-app-store-logo-500x173 copy.png" alt="" className="footer_store" /> </div>
					</div>
					<div className="footer_copyright">
						<p>Powered by Edgewrapper Technology Private Limited©2020-2024.</p>
					</div>
				</div>
			</div>

			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
		</>
	)
}

export default Friend1;