/* eslint-disable */
import { Header, ProfileSideBar } from 'components';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { user_signin } from 'store/account/accountActions';
import { jesssu_users_update_profile } from 'store/account/api';
import ReactTooltip from "react-tooltip";
import { get_user_profile_details } from "../../store/account/api"
import { Helmet } from 'react-helmet';
import { get_all_brandlist_pagination, get_brandlist_pagination_by_search } from "../../store/category/api"
import { LazyLoadImage } from 'react-lazy-load-image-component';
const ProfileBrand = () => {
	const dispatch = useDispatch();
	const [brandlist, setBrandList] = useState([]);
	const state_account = useSelector(state => state.account);
	const [selectedbrands, setSelectedBrands] = useState([]);
	const [selectedbrandname, setSelectedBrandName] = useState([]);
	const [isendlist, setIsEndList] = useState(false);
	const [issaving, setIsSaving] = useState(false);
	const [issaved, setIsSaved] = useState(false);

	let offset = 0;
	const count = 41;
	const listInnerRef = useRef();
	let selectedbrandnamenew = []

	useEffect(() => {
		let isMounted = true;
		if (isMounted) initSelectedBrand();
		return () => { isMounted = false };
	}, []);
	const get_profile_details = () => {
		let payload = {
			"token": state_account.profile.token,
			"email": state_account.profile.email,
		}

		dispatch(get_user_profile_details(payload, (res) => { dispatch(user_signin(res.data));
		}))
	}
	const initSelectedBrand = () => {
		if (state_account?.profile?.branddata?.length > 0) {
			for (let i = 0; i < state_account.profile.branddata.length; i++) {
				let brand = {
					_id: state_account.profile.branddata[i].brandname,
					image: state_account.profile.branddata[i].image,
				}
				selectedbrandnamenew.push(brand._id)
				setSelectedBrands(current => [...current, brand]);
				setSelectedBrandName(current => [...current, brand._id]);
				if (i === (state_account.profile.branddata.length - 1)) {
					get_brands(selectedbrandnamenew)
				}

			}

		} else {
			get_brands(selectedbrandnamenew)
		}

	}
	function get_brands(selectedbrandnamenew) {
		var brandpayload =
		{
			"gender": state_account.profile.gender,
			"COO": state_account.profile.COO,
		}
		dispatch(get_all_brandlist_pagination(brandpayload, offset, count, (res) => {
			for (let i = 0; i < res.data.length; i++) {

				if (selectedbrandnamenew.includes(res.data[i]._id)) {
					res.data[i].status = true
				} else {
					res.data[i].status = false
				}
				setBrandList(current => [...current, res.data[i]]);

			}
			if (res.data.length === 0) {
				setIsEndList(true)
			}
		}));
	}
	function search_brands(e) {
		setBrandList([])
		setIsEndList(false);
		if (e.target.id === "searchBrand") {
			var brandpayload =
			{
				"gender": state_account.profile.gender,
				"COO": state_account.profile.COO,
				"search": e.target.value,
			}
			dispatch(get_brandlist_pagination_by_search(brandpayload, offset, count, (res) => {
				if (res.status === 200) {
					for (let i = 0; i < res.data.length; i++) {

						if (selectedbrandname.includes(res.data[i]._id)) {
							res.data[i].status = true
						} else {
							res.data[i].status = false
						}
						setBrandList(current => [...current, res.data[i]]);

					}
				}
			}));
		}
	}

	function selectBrand(brand, index) {
		if (selectedbrands.some(e => e._id === brand._id)) {
			const newList = selectedbrands.filter((item) => item._id !== brand._id);
			setSelectedBrands(newList);

		} else {
			setSelectedBrands(current => [...current, brand]);

		}

		if (brandlist[index].status === true) {
			const newbrandlist = brandlist.map((i = typeof any, count) => {
				if (count === index) {
					return { ...i, status: false };
				}
				return i;
			});
			setBrandList(newbrandlist);
		} else {
			const newbrandlist = brandlist.map((i = typeof any, count) => {
				if (count === index) {
					return { ...i, status: true };
				}
				return i;
			});
			setBrandList(newbrandlist);
		}



		if (selectedbrandname.some(e => e === brand._id)) {
			const newList = selectedbrandname.filter((item) => item !== brand._id);
			setSelectedBrandName(newList);
		} else {
			setSelectedBrandName(current => [...current, brand._id]);
		}
	}


	function updateBrand() {
		setIsSaving(true);
		var brandpayload = {
			brand: selectedbrandname
		}
		dispatch(user_signin(brandpayload));
		const userData = state_account.profile
		userData.brand = selectedbrandname
		dispatch(jesssu_users_update_profile(userData, (res) => {
			if (res.status === 200) {
				setIsSaving(false);
				setIsSaved(true);
				get_profile_details();
			}
		}));
	}


	function onScroll() {
		if (listInnerRef.current && isendlist === false) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight > (scrollHeight-40)) {
				offset = offset + 1;
				get_brands(selectedbrandnamenew);
			}
		}
	};

	return (
		<>
			<div className="thetop"></div>
			<Header />
			<Helmet>
                <title>Explore Top Brands | Discover & Shop Quality Products</title>
                <meta name="description" content="Discover top brands on Jesssu. Browse through a curated selection of high-quality products, find your favorite brands, and shop the latest collections." />
                <meta name="keywords" content="Top brands" />
            </Helmet>
			<div className="stash_my_look_area">
				<div className="container">
					<div className="row">
						<ProfileSideBar />
						<div className="col-md-6">
							<div className="profile_area">
								<div className="comment_text_area brand_2_search">
									<input className="social_page_comment_text" placeholder="Search brands" id="searchBrand" onChange={(e) => search_brands(e)} />
								</div>
								<p className="text_bold_600 text-center margin-bottom-30">Select 6 or more brands</p>
								<div className="classic_height" onScroll={() => onScroll()} ref={listInnerRef}>
									<div className="brand_1_area">
										<div className="row" >
											{brandlist.map((brand, i) => (
												<div className="brand_1 col-md-4 col-xs-6" key={i}>
													{brand.status === false &&
														<input className="checkbox-btn" name="checkbox-collection" value={brand._id}
															id={i} type="checkbox" onChange={() => { selectBrand(brand, i) }} />}
													{brand.status === true &&
														<input className="checkbox-btn" name="checkbox-collection" value={brand._id}
															id={i} type="checkbox" onChange={() => { selectBrand(brand, i) }} checked={true} />}
													<label className="checkbox-label" htmlFor={i}>
														<div className="itemcontent">
															<div>
																<LazyLoadImage src={`${brand.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(brand.image)
																	? brand.image
																	: "/images/defultimg.svg"
																	}`} alt={`${brand?._id} brand image`} />
																{/* <LazyLoadImage src={brand.image}></img> */}
															</div>
														</div>
													</label>
													<p className="brand_1_heading" data-tip data-for={`brandtip${i}`}>{brand._id !== null && brand._id !== undefined ? brand._id.substring(0, 7) : ""}...</p>
													<ReactTooltip id={`brandtip${i}`} place="top" effect="solid">{brand._id}</ReactTooltip>
												</div>
											)
											)
											}
										</div>
									</div>
								</div>

							</div>
						</div>

						<div className="col-md-3">
							<div className="related_product">
								<p className="related_product_heading">Selected brands</p>
								<div className="classic_container no_margin profile_right_sidebar">
									<div className="classic_height brand_1_right">
										<div className="row">

											{selectedbrands.length === 0 ? <></> :
												selectedbrands.map((brand, key) => (
													<div className="brand_1 col-xs-6" key={key}>
														<div className="border_area">
															<LazyLoadImage src={`${brand.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(brand.image)
																? brand.image
																: "/images/defultimg.svg"
																}`} alt={`${brand?._id} brand image`} />
															{/* <LazyLoadImage src={brand.image} alt="" /> */}
														</div>
														<p className="brand_1_heading" data-tip data-for={`selectedbrandtip${key}`}>{brand._id !== null && brand._id !== undefined ? brand._id.substring(0, 7) : ""}</p>
														<ReactTooltip id={`selectedbrandtip${key}`} place="top" effect="solid">{brand._id}</ReactTooltip>
													</div>))}
										</div>
									</div>
									<p><a onClick={() => { updateBrand() }} className="save_btn">{issaving ? "SAVING..." : issaved ? "UPDATED" : "SAVE"}</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
		</>
	)
}

export default ProfileBrand;