/* eslint-disable */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from 'react-router-dom';
import $ from "jquery";
import { useState } from "react";
import { get_friend_list_success } from '../../store/account/accountActions'
import { get_friend_list } from "../../store/friend/api"

const ProfileSideBar = () => {
    const history = useNavigate();
    const dispatch = useDispatch();

    const state_account = useSelector(state => state.account);
    const [urlvalue, seturlvalue] = useState("/profile/details")
    const routetotimeline = (i) => {
        history('/timeline', { state: { friendemail: i } });
    }
    useEffect(() => {
        getfriendlist()
        seturlvalue(window.location.pathname)
        function mediaSize() {
            /* Set the matchMedia */
            $(".desktop_show").addClass("in");
            if (window.matchMedia('(max-width: 768px)').matches) {
                /* Changes when we reach the min-width  */
                $(".desktop_show").removeClass("in");
                $(".desktop_show.in").css("height", "auto");
                $(".button_toggle").click(function () {
                    $(".desktop_show.in").css("display", "block");
                });

            } else {
                /* Reset for CSS changes – Still need a better way to do this! */
                $('body, strong').removeAttr('style');
            }
        };

        /* Call the function */
        mediaSize();
        /* Attach the function to the resize event listener */
        window.addEventListener('resize', mediaSize, false);

    }, [state_account.profile.email]);
    function getfriendlist() {
        let userpayload =
        {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_friend_list(userpayload, (res) => {
            if (res.status === 200) {
                dispatch(get_friend_list_success(res.data))
            }
        }));
    }
    const logout = () => {
        window.localStorage.clear();
        history('/login');
    }


    return (
        <div className="col-md-3">
            <div className="name_area">
                <div className="display_flex align_item_center post-space_between2">
                    <div className="name_img_area">
                        <img onClick={() => { routetotimeline(state_account.profile.email) }} src={state_account.profile.profileimage === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${state_account.profile.profileimage}`} alt="" className="name_img" />
                    </div>
                    <div className="name_content">
                        <p className="name_content_heading">{state_account.profile.firstname} {state_account.profile.lastname}</p>
                        <p><i className="fa-solid fa-circle-dollar-to-slot"></i> {state_account.profile.jesssucoin} Jesssu coins</p>
                    </div>
                    <div className="button_toggle"><a data-toggle="collapse" href="#collapse2" className="collapse_btn"><i className="fa-solid fa-list-ul"></i></a></div>
                </div>
            </div>
            <div id="collapse2" className="desktop_show panel-collapse collapse">
                <div className="name_area">
                    {urlvalue === "/profile/details" ?
                        <div className="display_flex profile_list" >
                            <img src="/images/social/profile-details-active.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/details`} className="active_color">Profile Details</Link></p>
                        </div>
                        :
                        <div className="display_flex profile_list" >
                            <img src="/images/social/profile-details.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/details`}>Profile Details</Link></p>
                        </div>}
                    {urlvalue === "/profile/friends" ?
                        <div className="display_flex profile_list">
                            <img src="/images/social/friend-active.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/friends`} className="active_color">Friends</Link></p>
                        </div>
                        :
                        <div className="display_flex profile_list">
                            <img src="/images/social/friend.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/friends`}>Friends</Link></p>
                        </div>}
                    {urlvalue === "/profile/coins" ?
                        <div className="display_flex profile_list">
                            <img src="/images/social/jesssu-coins-active.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/coins`} className="active_color">Jesssu Coins</Link></p>
                        </div>
                        :
                        <div className="display_flex profile_list">
                            <img src="/images/social/jesssu-coins.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/coins`}>Jesssu Coins</Link></p>
                        </div>}
                    {urlvalue === "/profile/categories" ?
                        <div className="display_flex profile_list">
                            <img src="/images/social/category-active.svg" width={"20px"} alt=""/>
                            <p><Link to={`/profile/categories`} className="active_color">Categories</Link></p>
                        </div>
                        :
                        <div className="display_flex profile_list">
                            <img src="/images/social/category.svg" width={"20px"} alt=""/>
                            <p><Link to={`/profile/categories`}>Categories</Link></p>
                        </div>}
                    {urlvalue === "/profile/brands" ?
                        <div className="display_flex profile_list">
                            <img src="/images/social/brand-active.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/brands`} className="active_color">Brands</Link></p>
                        </div>
                        :
                        <div className="display_flex profile_list">
                            <img src="/images/social/brand.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/brands`}>Brands</Link></p>
                        </div>}
                    {urlvalue === "/notifications" ?
                        <div className="display_flex profile_list">
                            <img src="/images/social/notifications-active.svg" width={"18px"} alt=""/>
                            <p><Link to={`/notifications`} className="active_color">Notification</Link></p>
                        </div>
                        :
                        <div className="display_flex profile_list">
                            <img src="/images/social/notifications.svg" width={"18px"} alt=""/>
                            <p><Link to={`/notifications`}>Notification</Link></p>
                        </div>}
                    {urlvalue === "/profile/invite" ?
                        <div className="display_flex profile_list">
                            <img src="/images/social/refer&earn-active.svg" width={"22px"} alt=""/>
                            <p><Link to={`/profile/invite`} className="active_color">Refer & Earn</Link></p>
                        </div>
                        :
                        <div className="display_flex profile_list">
                            <img src="/images/social/refer&earn.svg" width={"22x"} alt=""/>
                            <p><Link to={`/profile/invite`}>Refer & Earn</Link></p>
                        </div>}
                    {state_account.profile.email === null
                        ?
                        <></>
                        :
                        <p>
                            <a href="#!" onClick={() => { logout(); }} className="save_btn">
                                <i className="fa-solid fa-sign-out">
                                </i>
                                Logout
                            </a>
                        </p>}
                </div>
            </div>
        </div>
    );
}
export default ProfileSideBar;