/* eslint-disable */
import { Footer, Header } from "components";
import React from "react";

const Mission = () => {
    return (<>

        <div className="thetop"></div>
        {/* <!-- Navigation Menu --> */}
        <Header />
        {/* <!-- Navigation Menu closed Here --> */}

        <div className="mission-box">
            <div className="contactus_area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1">
                            <div className="mission-back">
                                {/* <img src="images/onboarding anirban/Icon ionic-md-arrow-round-back.svg" alt="" /> */}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="mission-section">
                                <h2>Our Vision</h2>
                                <p >Our vision to  build an ecosystem for future where people can express themselves freely and confidently.
                                    We want our users to fit in to stand out. We are approaching this by building a semi-immersive, personalized, social shopping experience for clothing that bridges the gap
                                    between the digital and physical words.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="target-img">
                                <img src="/images/home/target.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="social-section">
                        <div className="row">
                            <div className="col-md-1"></div>

                            <div className="col-md-5">
                                <div className="target-img">
                                    <img src="/images/home/fashion.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="mission-section">
                                    <h2>Social</h2>
                                    <p>"Join us on our journey to Shop, Share, and Stash! Our social page is dedicated to building a community where individuals from all walks of life can come together and engage on our online eCommerce platform, creating their own style with the products they desire. Whether you're looking for a platform to share your fashion sense, showcase your favorite products, express your opinions, or simply make new friends, we've got you covered. With a wide range of products and an engaged community, you're sure to find your tribe here. So, why wait? Join us today and be part of something special!"</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-5">
                            <div className="mission-section">
                                <h2>Virtual Dress <br />try-on</h2>
                                <p>Virtual trial rooms have transformed the online shopping experience by providing customers with the ability to try on clothing and accessories virtually. One of the key features of virtual trial rooms is the option to create a complete look using items from their wishlist. This feature allows customers to build an outfit with multiple items and see how the items look together in a single virtual fitting. This helps customers make more informed purchasing decisions by allowing them to visualize how different items work together, providing an immersive and interactive shopping experience. Additionally, the ability to save and share the look with others makes it easy for customers to receive feedback and opinions on their outfits. Overall, virtual trial rooms with the ability to create a look with wishlist products offer a convenient and innovative solution for customers who are looking to shop for clothing and accessories online.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="target-img">
                                <img src="images/home/dressup.png" alt="" />
                            </div>
                        </div>

                    </div>
                    <div className="social-section">
                        <div className="row">
                            <div className="col-md-1"></div>

                            <div className="col-md-5">
                                <div className="target-img">
                                    <img src="/images/home/fashion.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="mission-section">
                                    <h2>Stash</h2>
                                    <p>The stash section in our platform provides a unique and interactive shopping experience by connecting users with their friends. With the ability to send friend requests to friends who join through your invitation, the stash section allows you to build a network of friends and share your shopping experiences with them.

                                        In this section, users are able to like, share, and comment on their friends' shared looks and posts. This creates a community-driven shopping experience where friends can support and inspire each other's fashion choices. By having the ability to see what your friends are wearing, you can get new ideas for your own wardrobe and see how different items look on real people.

                                        Furthermore, the stash section makes it easy to share and receive feedback on your latest fashion finds, helping you make informed purchasing decisions. With the ability to comment and like on your friends' posts, you can get instant feedback on your fashion choices and find out what your friends think of your latest look.

                                        In conclusion, the stash section in our platform is an innovative feature that provides customers with a social and interactive shopping experience. By connecting users with their friends, the stash section helps users make informed purchasing decisions, get inspired by their friends' fashion choices, and build a community of like-minded fashion lovers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-5">
                            <div className="mission-section">
                                {/* <h2>Virtual dress <br />try-on</h2> */}
                                <p>Etiam facilisis ligula nec velit posuere egestas. Nunc dictum lectus sem,
                                    vel dignissim purus luctus quis. Vestibulum et ligula suscipit, hendrerit erat a,
                                    ultricies velit.
                                    Praesent convallis in lorem nec blandit. Phasellus a porta tellus. Suspendisse sagittis
                                    metus enim.
                                    Sed molestie libero id sem pulvinar, quis euismod mauris suscipit.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="target-img">
                                <img src="images/home/dressup.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>);
}
export default Mission;