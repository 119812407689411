/* eslint-disable */
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import ScriptTag from 'react-script-tag';
import { user_signin } from "store/account/accountActions";
import { delete_profile_look_image, get_user_profile_details, jesssu_image_upload_by_email } from "store/account/api";


const OwnLook = () => {
    const state_account = useSelector(state => state.account);
    const email = state_account.profile.email;
    const token = state_account.profile.token;
    const dispatch = useDispatch();

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            get_profile_details();
        }
        return () => { isMounted = false };
    }, []);

    const delete_image = (e) => {
        let payload = {
            "email": email,
            "image": e
        }
        delete_profile_look_image(payload, (res) => {
            if (res.success === true) {
                get_profile_details()
            }
        })
    }

    const get_profile_details = () => {
        let payload = {
            "token": token,
            "email": email,
        }
        dispatch(get_user_profile_details(payload, (res) => {
            if (res.status === 200) {
                
                dispatch(user_signin(res.data));

            }
        }));
    }

    const upload_image = (e) => {
        if (e) {
            const formData = new FormData();
            formData.append(
                "file",
                e.target.files[0]
            );
            let COO = state_account.profile.COO.length === 0 ? "USA" : state_account.profile.COO[0];
            let email = state_account.profile.email;
            jesssu_image_upload_by_email(formData, COO, email, (res) => {
                
                if (res.success === true) {
                    get_profile_details()
                }
            })
        }
    }


    return (<>
        <div className="dotted-border container">
            <i className="fa-solid fa-upload upload-icon"></i>
            <p className="upload-text">Upload images from your device to create your look</p>
            <div className="image-upload-box1">
                <input type="file" id="img01" accept=".png, .jpg, .jpeg" onChange={(e) => { upload_image(e) }} />
                <label htmlFor="img01" className="upload-imgbox1">
                    <button type="button" className="upload-own-image-button"
                    >Choose file</button>
                </label>
            </div>
        </div>
        <div className="container">
            <div className="select_for_area">
                {(state_account.profile.lookimages !== undefined && state_account.profile.lookimages.length > 0) &&
                    state_account.profile.lookimages.map((i, key) =>
                    (<><div className="col-md-2" key={key}>
                        <div className="uploaded-image-box" >
                            <a href="#" className="trash-image-icon" onClick={() => { delete_image(i) }}><i className="fa-solid fa-trash "></i></a>
                            <img className="uploaded-img-main" src={`${i}`} alt=""/>
                        </div>
                    </div>                       
                    </>))}
            </div>
        </div>

    </>);
}
export default OwnLook;