/* eslint-disable */
import React, { useEffect, useState } from "react";
import { get_product_details, get_product_variation } from "store/products/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_product_list_pagination } from "store/products/api";
import { Footer, Header, Productshare } from "components";
import { add_delete_wishlist_product, get_wishlist } from "store/stash/api";
import { get_product_data_success } from "store/account/accountActions";
// import ScriptTag from "react-script-tag";
// import { add_to_cart, get_cart_value } from "../../store/account/api";
// import {
//   notification_message_status,
// } from "../../store/account/accountActions";
import ReactTooltip from "react-tooltip";
import "./style.css";
// import Swal from "sweetalert2";
// import { share_a_new_product } from "store/social/api";
import { amazonassociatetag } from '../../config';

const ProductDetails = () => {
  const dispatch = useDispatch();
  const [showsizedata, setShowSizeData] = useState(false);
  const [showcolordata, setShowColorData] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const state_account = useSelector((state) => state.account);
  const productdata = state_account.productdetails;
  const [recommandedProductList, setRecommandedProductList] = useState([]);
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);
  const [selectedsize, setSelectedSize] = useState("");
  const [selectedcolor, setSelectedColor] = useState("");
  console.log(selectedsize, selectedcolor)
  const { id } = useParams();
  const [shareddata, setshareddata] = useState(typeof any);
  const [reshare, setreshare] = useState(false);
  // const [showNotification, setShowNotification] = useState(false);
  // const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [newArrivalIndex, setnewArrivalIndex] = useState(0);
  const [singleproduct, setsingleproduct] = useState([]);
  const [selectSize, setselectSize] = useState("");
  const showmodal = false;
  const history = useNavigate();

  const handlePrevClick = (type) => {
    if (type === "NewArrivals") {
      setnewArrivalIndex(newArrivalIndex - 1);
    }
  };
  const handleNextClick = (type) => {
    if (type === "NewArrivals") {
      setnewArrivalIndex(newArrivalIndex + 1);
    }
  };
  const handleScroll = (e, type) => {
    if (e.deltaX > 0) {
      if (type === "NewArrivals") {
        if (newArrivalIndex + 4 < recommandedProductList.length) {
          setnewArrivalIndex(newArrivalIndex + 1);
        }
      }
    } else {
      if (type === "NewArrivals") {
        if (newArrivalIndex !== 0) {
          setnewArrivalIndex(newArrivalIndex - 1);
        }
      }
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProductDetails();
      get_wishlists();
    }
    return () => {
      isMounted = false;
    };

  }, []);

  const Redirect = () => {
    history("/login");
    localStorage.setItem("isLoggedin", true)
    return;

  }



  // const addtocart = (data) => {


  //   setshowmodal(!showmodal);
  //   setTimeout(() => {
  //     setshowmodal((showmodal) => !showmodal);
  //   }, 2000);
  //   if (
  //     singleproduct[0].Sizes != undefined &&
  //     singleproduct[0].Sizes.length > 0
  //   ) {
  //     if (selectSize != "") {
  //       var payload = {
  //         token: state_account.profile.token,
  //         user: state_account.profile.email,
  //         product: selectSize.asin,
  //         quantity: 1,
  //         pimage: data.ProductImage,
  //         brand: data.Brand,
  //         pname: data.Name,
  //         price: data.SalePrice,
  //         COO: data.COO,
  //         currency: "₹",
  //         email: state_account.profile.email,
  //       };
  //       dispatch(
  //         add_to_cart(payload, (res) => {
  //           if (res.status == 200) {
  //             // console.log(res.data)
  //             setShowNotification(true);
  //             // setShowNotificationMessage("Product added to cart")
  //             closenotification();
  //             const newpayload = {
  //               userid: state_account.profile.email,
  //               token: state_account.profile.token,
  //             };
  //             dispatch(
  //               get_cart_value(
  //                 newpayload,
  //                 state_account.profile.email,
  //                 (res) => {
  //                   if (res.status == 200) {
  //                   }
  //                 }
  //               )
  //             );
  //           }
  //         })
  //       );
  //     } else {
  //       setShowNotification(true);
  //       // setShowNotificationMessage("Please select a size")
  //     }
  //   } else {
  //     var payload = {
  //       token: state_account.profile.token,
  //       user: state_account.profile.email,
  //       product: data.SKU,
  //       quantity: 1,
  //       pimage: data.ProductImage,
  //       brand: data.Brand,
  //       pname: data.Name,
  //       price: data.SalePrice,
  //       COO: data.COO,
  //       currency: "₹",
  //       email: state_account.profile.email,
  //     };

  //     dispatch(
  //       add_to_cart(payload, (res) => {
  //         if (res.status == 200) {
  //           // console.log(res.data)
  //           setShowNotification(true);
  //           // setShowNotificationMessage("Product added to cart")
  //           closenotification();
  //           const newpayload = {
  //             userid: state_account.profile.email,
  //             token: state_account.profile.token,
  //           };
  //           dispatch(
  //             get_cart_value(newpayload, state_account.profile.email, (res) => {
  //               if (res.status == 200) {
  //               }
  //             })
  //           );
  //         }
  //       })
  //     );

  //   }
  // };
  // function closenotification() {
  //   setTimeout(function () {
  //     setShowNotification(false);
  //     dispatch(notification_message_status(false));
  //   }, 5000);
  // }
  function getproductdataforshare(data) {
    setshareddata(data);
    setreshare(true);
  }

  // const closeNotification = () => {
  //   setShowNotification(false);
  //   dispatch(notification_message_status(false));
  // };

  // function closenotification() {
  //   setTimeout(function () {
  //     setShowNotification(false);
  //     dispatch(notification_message_status(false));
  //   }, 5000);
  // }
  function changevalueforurl(data) {
    let step1 = data.replace("&", "-");
    let step2 = step1.replace("/", ".");
    let step3 = step2.replace("/", ".");
    return step3;
  }

  const get_wishlists = () => {
    let userpayload = {
      email: state_account.profile.email,
      token: state_account.profile.token,
    };
    dispatch(
      get_wishlist(userpayload, (res) => {
        if (res.status === 200) {
          // res.data.map((i) => {
          //   setWishlist((current) => [...current, i.item_id[0]]);
          // });
          for (let i = 0; i < res.data.length; i++) {
            setWishlist(current => [...current, res.data[i].item_id[0]]);
          }
        }
      })
    );
  };
  async function addproductinwishlist(data) {
    let payload = {
      email: state_account.profile.email,
      token: state_account.profile.token,
      productid: data.SKU,
      url: data.MainImage,
    };

    dispatch(
      add_delete_wishlist_product(payload, (res) => {
        if (res.status === 200) {
          if (res.data === "Product added to wishist") {
            setWishlist((current) => [...current, data.SKU]);
          } else {
            setWishlist((prevState) =>
              prevState.filter((prevItem) => prevItem !== data.SKU)
            );
          }
        }
      })
    );
  }
  const getProductDetails = () => {
    let payload = {
      'token': state_account.profile.token,
      'userid': state_account.profile.email,
      "COO": state_account.profile.COO,
    };
    dispatch(
      get_product_details(payload, id, (res) => {
        if (res.status === 200) {
          getProductVariation(res.data);
          if (res.data.length !== 0) {
            setSize((current) => [...current, res.data[0].Size]);
            setColor((current) => [...current, res.data[0].Color]);
            setSelectedSize(res.data[0].Size);
            setSelectedColor(res.data[0].Color);
          }
          if (
            res.data[0].variations !== undefined ||
            res.data[0].variations !== null
          ) {
            let sizelist = size;
            let colorlist = color;
            for (let i = 0; i < res.data[0].variations.length; i++) {
              sizelist.push(res.data[0].variations[i].Size);
              colorlist.push(res.data[0].variations[i].Color);

              // setSize(current => [...current, res.data[0].variations[i].Size]);
              // setColor(current => [...current, res.data[0].variations[i].Color]);
            }
            setSize(Array.from(new Set(sizelist)));
            setColor(Array.from(new Set(colorlist)));
          }
          getRecommandedProduct(res.data[0].Brand, res.data[0].Primary);
        }
      })
    );
  };
  const getProductVariation = (data) => {
    var payload = {
      token: null,
      userid: null,
      COO: [],
    };
    dispatch(
      get_product_variation(payload, id, (res) => {
        if (res.status === 200) {
          data[0].variations = res.data;
          dispatch(get_product_data_success(data));
          setsingleproduct([data[0]]);
        }
      })
    );
  };

  function getRecommandedProduct(brand, category) {
    var payload = {
      brands: [brand],
      categories: [category],
      minprice: "",
      maxprice: "",
      sortby: "",
      subcategories: [],
      token: null,
      userid: null,
      gender: "",
      COO: [],
    };
    dispatch(
      get_product_list_pagination(payload, 0, 20, (res) => {
        if (res.status === 200) {
          setRecommandedProductList(res.data);
        }
      })
    );
  }
  function variationchange(i) {
    let pdata = productdata;
    pdata[0].Name = i.Name;
    pdata[0].ProductImage = i.ProductImage;
    pdata[0].ProductId = i.ProductId;
    pdata[0].SKU = i.SKU;
    pdata[0].SalePrice = i.SalePrice;
    pdata[0].RetailPrice = i.RetailPrice;
    pdata[0].Availability = i.Availability;
    dispatch(get_product_data_success(pdata));
    setSelectedColor(i.Color);
    setSelectedSize(i.Size);
  }
  // function selectData(colordata, sizedata) {
  //     setSelectedColor(colordata);
  //     setSelectedSize(sizedata);
  //     let pdata = productdata;

  //     for (let i = 0; i < productdata[0].variations.length; i++) {

  //         if (productdata[0].variations[i].Color == colordata && productdata[0].variations[i].Size == sizedata) {
  //             pdata[0].Name = pdata[0].variations[i].Name;
  //             pdata[0].ProductImage = pdata[0].variations[i].ProductImage;
  //             pdata[0].ProductId = pdata[0].variations[i].ProductId;
  //             pdata[0].SKU = pdata[0].variations[i].SKU;
  //             pdata[0].SalePrice = pdata[0].variations[i].SalePrice;
  //             pdata[0].RetailPrice = pdata[0].variations[i].RetailPrice;
  //             pdata[0].Availability = pdata[0].variations[i].Availability;
  //         }
  //     }
  //     dispatch(get_product_data_success(pdata))

  // }
  function changeView(val) {
    if (val === "Color") {
      if (showcolordata) {
        setShowColorData(false);
      } else {
        setShowColorData(true);
      }
    } else if (val === "Size") {
      if (showsizedata) {
        setShowSizeData(false);
      } else {
        setShowSizeData(true);
      }
    }
  }

  async function changeProduct(data) {
    setselectSize("");
    // productdata.map((i) => {
    //   if (i.SKU === data) {
    //     setsingleproduct([i]);
    //   }
    // });
    for (let i = 0; i < productdata.length; i++) {
      if (productdata[i].SKU === data) {
        setsingleproduct([productdata[i]]);
        break; // Exit the loop after finding the matching product
      }
    }
  }

  async function selectproductsize(data) {
    setselectSize(data);
  }
  // function addcartmodal() {
  //     setshowmodal(!showmodal)
  // }
  // const _onClick = () => {
  //     setPopupOpen(!popupOpen);

  //     setTimeout(() => {
  //         setPopupOpen(popupOpen => !popupOpen)
  //     }, 2000);
  // };

  // const [productimg, setproductimg] = useState([]);

  // useEffect(() => {
  //     // singleproduct[0].MainImage
  //     // setproductimg(singleproduct)
  //     // console.log(productimg.MainImage)
  // }, []);
  // var add = singleproduct.MainImage.split("")
  // console.log(singleproduct.MainImage)
  // console.log(add)

  return singleproduct.length === 0 ? (
    <></>
  ) : (
    <>
      <div className="thetop"></div>
      <Header />
      {/* <!-- Navigation Menu closed Here --> */}
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/shop`}>
              <i className="fa-solid fa-house-chimney"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={`/products/any&brand=${changevalueforurl(
                singleproduct[0].Brand
              )}`}
            >
              {singleproduct[0].Brand}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {singleproduct[0].Name}
          </li>
        </ol>
      </div>

      {/* <!--tshirt area start--> */}
      <div className="tshirt_area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2">
                  <img
                    src={`${singleproduct[0].MainImage &&
                      /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(
                        singleproduct[0].MainImage
                      )
                      ? singleproduct[0].MainImage
                      : "/images/defultimg.svg"
                      }`}
                    className="p_small_img img-responsive"
                    alt=""
                  />
                </div>
                <div className="col-md-10">
                  <div className="p_big_img product-imgsection">
                    <img
                      srcSet={`${singleproduct[0].MainImage &&
                        /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(
                          singleproduct[0].MainImage
                        )
                        ? singleproduct[0].MainImage
                        : "/images/defultimg.svg"
                        }`}
                      className="img-responsive"
                      alt=""
                    />
                  </div>
                  {singleproduct[0].likedislike.length === 0 ? (
                    <></>
                  ) : (
                    <div className="classic_top_icon">
                      <ul className="list-unstyled">
                        <li>
                          <a href="#">
                            <img src="/images/icons/like-product.svg" alt="" />
                          </a>
                          <h4>
                            {singleproduct[0].likedislike[0].likes.length}
                          </h4>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src="/images/icons/dislike-product.svg"
                              alt=""
                            />
                          </a>
                          <h4>
                            {singleproduct[0].likedislike[0].dislikes.length}
                          </h4>
                        </li>
                        <li>
                          <a href="#">
                            <img src="/images/icons/share-product.svg" alt="" />
                          </a>
                          <h4>
                            {singleproduct[0].likedislike[0].comments.length}
                          </h4>
                        </li>
                      </ul>
                    </div>
                  )}
                  {(singleproduct[0].variations !== undefined ||
                    singleproduct[0].variations !== null) &&
                    singleproduct[0].variations.length > 0 && (
                      <div className="similer-product-section">
                        <p>
                          {singleproduct[0].variations.length} Similar Products
                        </p>
                      </div>
                    )}
                  <div className="variation-row">
                    {(singleproduct[0].variations !== undefined ||
                      singleproduct[0].variations !== null) &&
                      singleproduct[0].variations.length > 0 &&
                      singleproduct[0].variations.map((i) => (
                        <a href="#"
                          onClick={() => {
                            variationchange(i);
                          }}
                        >
                          <img src={`${i.ProductImage}`} alt="" />
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="tshirt_area_text">
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      <span className="sale_badge">
                        {singleproduct[0].Availability}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p className="product_id">
                      Product ID: <span>{singleproduct[0].SKU}</span>
                    </p>
                  </div>
                </div>
                <h2 className="p-b-15">{singleproduct[0].Name}</h2>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list-unstyled display_flex space_between2">
                      <li>
                        <a className="real_price">
                          {singleproduct[0].COO === "USA" ? "$" : "₹"}
                          {singleproduct[0].SalePrice}
                        </a>
                      </li>
                      <li>
                        <span className="old_price">
                          {singleproduct[0].COO === "USA" ? "$" : "₹"}
                          {singleproduct[0].RetailPrice}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <p className="house_my_brand">{singleproduct[0].Brand}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <ul className="list-unstyled display_flex space_between2 p-b-15">
                      <li>Color</li>
                    </ul>
                    <p className="p-b-15">
                      <a href="#"
                        onClick={() => {
                          changeView("Color");
                        }}
                        className="choose_size_btn"
                      >
                        Choose Color <i className="fa-solid fa-angle-right"></i>
                      </a>{" "}
                    </p>
                    {/* {showcolordata && */}
                    <div className="productforsize">
                      <ul className="product-size list-unstyled display_flex p-b-15">
                        {productdata.length > 0 &&
                          productdata.map((i) => (
                            <li
                              onClick={() => changeProduct(i.SKU)}
                              style={{
                                backgroundColor:
                                  i.Color === singleproduct[0].Color
                                    ? "yellow"
                                    : "white",
                              }}
                            >
                              {i.Color}
                            </li>
                          ))}
                      </ul>
                    </div>
                    {/* } */}

                    <ul className="list-unstyled display_flex space_between2 p-b-15">
                      <li>Size</li>
                    </ul>
                    <p className="p-b-15">
                      <a href="#"
                        onClick={() => {
                          changeView("Size");
                        }}
                        className="choose_size_btn"
                      >
                        Choose size <i className="fa-solid fa-angle-right"></i>
                      </a>{" "}
                    </p>
                    {/* {showsizedata && */}
                    <div className="productforsize">
                      <ul className="product-size list-unstyled display_flex p-b-15">
                        {singleproduct[0].Sizes !== undefined &&
                          singleproduct[0].Sizes.length > 0 &&
                          singleproduct[0].Sizes.map((i) => (
                            <li
                              style={{
                                backgroundColor:
                                  selectSize === i ? "yellow" : "white",
                              }}
                              onClick={() => selectproductsize(i)}
                            >
                              {i.size}
                            </li>
                          ))}{" "}
                      </ul>
                    </div>
                    {/* } */}
                    <div className="col-md-8">
                      <ul className="list-unstyled display_flex space_between2">
                        {singleproduct[0].COO === "USA" && (
                          <li>
                            <a
                              href={singleproduct[0]?.channels[0]?.channel_name === "Amazon" ? `${singleproduct[0]?.channels[0]?.url}?AssociateTag=${amazonassociatetag}` : `${singleproduct[0]?.channels[0]?.url}`}
                              target="_blank"  // This opens the link in a new tab/window
                              rel="noopener noreferrer"  // Use rel="noopener noreferrer" for security
                              className="buy_now_btn buy_now_btn_bg cursor-pointer"
                            >
                              Buy Now
                            </a>
                          </li>
                        )}
                        {singleproduct[0].COO === "INDIA" && state_account.profile.email !== "" && (
                          <li>
                            <a
                              //   data-toggle="modal"

                              href={singleproduct[0]?.channels[0]?.channel_name === "Amazon" ? `${singleproduct[0]?.channels[0]?.url}?AssociateTag=${amazonassociatetag}` : `${singleproduct[0]?.channels[0]?.url}`}
                              target="_blank"  // This opens the link in a new tab/window
                              rel="noopener noreferrer"  // Use rel="noopener noreferrer" for security
                              className="buy_now_btn buy_now_btn_bg cursor-pointer"
                            >
                              Buy Now
                            </a>
                          </li>
                        )}

                        {state_account.profile.email === "" && (
                          <li data-toggle="modal"
                            data-target="#addTocart" >
                            <a
                              href={`${singleproduct[0].channels[0].url}`}
                              className="buy_now_btn buy_now_btn_bg cursor-pointer"
                            >
                              Buy Now
                            </a>
                          </li>
                        )}



                        {/* s */}
                        <div className="modal fade" id="addTocart" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="feed_details modal-dialog offer_dialog modal-md">
                            <div className="modal-body">
                              <div className="friends-timeline-pop-up">
                                <div className="stash-pop-up-section">
                                  <div className="stash-pop-up-box">
                                    <div className="wishlist-Just-checking-section">
                                      <h6>ADD TO CART</h6>
                                    </div>
                                    <div className="delete-section">
                                      <h6>please login to continue</h6>
                                    </div>
                                    <div className="checking-row">
                                      <button className="checkingforyes-btn-default" onClick={() => { Redirect() }} data-dismiss="modal">YES</button>
                                      <button className="checking-btn-default" data-dismiss="modal">NO</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        {wishlist.includes(singleproduct[0].SKU) === true && (
                          <a href="#"
                            onClick={() =>
                              addproductinwishlist(singleproduct[0])
                            }
                          >
                            <i
                              className="fa-solid fa-heart buy_now_icon product-details-wish-icon-background"
                            ></i>
                          </a>
                        )}
                        {wishlist.includes(singleproduct[0].SKU) === false && (
                          <a href="#"
                            onClick={() =>
                              addproductinwishlist(singleproduct[0])
                            }
                          >
                            <i className="fa-solid fa-heart buy_now_icon"></i>
                          </a>
                        )}
                        <li>
                          <a href="#"
                            data-toggle="modal"
                            data-target={`#example-sharemodel${singleproduct[0].SKU}`}
                          >
                            <i className="fa-solid fa-share buy_now_icon"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <Productshare
                      productdata={singleproduct[0]}
                      pkey={"productdetails"}
                    />
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--tshirt area closed-->


	   <!--Description area start --> */}
      <div className="description_area">
        <div className="container">
          <p className="text-center">
            <a className="btn_desc">Description</a>
          </p>
          <div className="row">
            <div className="col-md-6">
              <div className="detail_area">
                <div className="detail_icon">
                  <i className="fa-solid fa-file-lines"></i>
                </div>
                <p className="heading">Details and product description</p>
                <p>{singleproduct[0].Long}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="detail_area">
                <div className="detail_icon">
                  <i className="fa-solid fa-file-lines"></i>
                </div>
                <p className="heading">Material(s) and care</p>
                <p>
                  {singleproduct[0].Primary} - {singleproduct[0].Secondary}
                </p>
                <div className="material_icon">
                  <ul className="display_flex">
                    <li>
                      <i className="fa-solid fa-house-chimney"></i>
                    </li>
                    <li>
                      <i className="fa-brands fa-galactic-republic"></i>
                    </li>
                    <li>
                      <i className="fa-solid fa-jedi"></i>
                    </li>
                    <li>
                      <i className="fa-solid fa-peace"></i>
                    </li>
                    <li>
                      <i className="fa-solid fa-award"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product_today">
        <div className="container">
          <div className="row">
            <div className="col-md-10">
              <h3>Selected Just for you</h3>
            </div>
            <div className="col-md-2">
              <p>
                <a href="#" className="see_more">See More</a>
              </p>
            </div>
          </div>
          {/* </div> */}
          {recommandedProductList.length > 1 && (
            <div className="curusol_share_section curusol_category_section">
              <button
                nClick={() => handlePrevClick("NewArrivals")}
                disabled={newArrivalIndex === 0}
                className="arrow_btn"
              >
                {newArrivalIndex === "0" ? (
                  <img src="/images/shop/arrow-left.svg" alt=""/>
                ) : (
                  <img src="/images/shop/arrow-left-active.svg" alt=""/>
                )}
              </button>

              <div
                className="select_for_area product-details-scroll"
                onWheel={(e) => handleScroll(e, "NewArrivals")}
              >
                {recommandedProductList
                  .slice(newArrivalIndex, newArrivalIndex + 4)
                  .map((product, i) => (
                    <div
                      className="col-md-6 home_page_dialog"
                      key={`newarrivals${i}`}
                    >
                      <a className="product-box">
                        <a
                          className="select_for_img img-responsive"
                          href={`/product/${product.SKU}`}
                        >
                          {/* <a href={`/product/${product.SKU}`}> */}
                          <img
                            src={`${product.MainImage &&
                              /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(
                                product.MainImage
                              )
                              ? product.MainImage
                              : "/images/defultimg.svg"
                              }`}
                            alt=""
                          />
                          {/* </a> */}
                          {product.RetailPrice > product.SalePrice && (
                            <div className="badge_pro">
                              {parseFloat(
                                ((product.RetailPrice - product.SalePrice) /
                                  product.RetailPrice) *
                                100
                              ).toFixed(0)}
                              % off
                            </div>
                          )}
                          <div className="like_share_product">
                            <div className="social_section_show"></div>
                            <div className="social_section">
                              {wishlist.includes(product.SKU) === true && (
                                <a 
                                  data-toggle="modal"
                                  data-target="#defult"
                                  onClick={() => addproductinwishlist(product)}
                                >
                                  <i
                                    className="fa-regular fa-heart product-details-wish-icon-background"
                                  ></i>
                                </a>
                              )}
                              {wishlist.includes(product.SKU) === false && (
                                <a
                                  data-toggle="modal"
                                  data-target="#defult"
                                  onClick={() => addproductinwishlist(product)}
                                >
                                  <i className="fa-regular fa-heart"></i>
                                </a>
                              )}
                              <a
                                data-toggle="modal"
                                data-target={`#example-sharemodel${product.SKU}`}
                                onClick={() => {
                                  getproductdataforshare(product);
                                }}
                              >
                                <i className="fa-solid fa-share"></i>
                              </a>
                            </div>
                          </div>
                        </a>
                        <div className="select_for_text">
                          <a
                            href={`/product/${product.SKU}`}
                            data-tip
                            data-for={`suggestedTip${i}`}
                          >
                            {(product.Name) !== null ? (product.Name).substring(0, 32) : (product.Name)}
                          </a>
                          <ReactTooltip
                            id={`suggestedTip${i}`}
                            place="top"
                            effect="solid"
                          >
                            {product.Name}
                          </ReactTooltip>
                          <ul className="list-unstyled display_flex space_between2">
                            <li>
                              <a
                                href={`/product/${product.SKU}`}
                                className="real_price"
                              >
                                {product.COO === "USA" ? "$" : "₹"}
                                {product.SalePrice}
                              </a>
                            </li>
                            <li>
                              <span className="old_price">
                                {product.COO === "USA" ? "$" : "₹"}
                                {product.RetailPrice}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </a>
                    </div>
                  ))}
              </div>

              <button
                onClick={() => handleNextClick("NewArrivals")}
                disabled={newArrivalIndex + 4 >= recommandedProductList.length}
                className="arrow_btn"
              >
                {recommandedProductList === true ? (
                  <img src="/images/shop/arrow-right.svg" alt=""/>
                ) : (
                  <img src="/images/shop/arrow-right-active.svg" alt=""/>
                )}
              </button>
            </div>
          )}
        </div>
        {/* <div className="product_today">
                        <div className="container"> */}
        {/* <button onClick={() => handlePrevClick("NewArrivals")} disabled={newArrivalIndex === 0}>
                                Prev
                            </button> */}
        {/* <div className="select_for_area" onWheel={(e) => handleScroll(e, "NewArrivals")} style={{ msOverflowX: "auto" }}>
                                {recommandedProductList.slice(newArrivalIndex, newArrivalIndex + 4).map((product, i) =>
                                    <div className="col-md-3 select_for-box home_page_dialog" key={`newarrivals${i}`}>
                                        <a href={`/product/${product.SKU}`}>
                                            <div className="select_for_img img-responsive"> <img src={product.MainImage} alt="" />

                                                {product.RetailPrice > product.SalePrice &&
                                                    <div className="badge_pro">{parseFloat(((product.RetailPrice - product.SalePrice) / product.RetailPrice) * 100).toFixed(0)}% off</div>}

                                                <div className="like_share_product">
                                                    <div className="social_section_show"></div>
                                                    <div className="social_section">
                                                        {wishlist.includes(product.SKU) == true &&
                                                            <a onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                        {wishlist.includes(product.SKU) == false &&
                                                            <a onClick={() => addproductinwishlist(product)} className="social_section_left"><i className="fa-regular fa-heart" ></i></a>}

                                                        <a data-toggle="modal" data-target={`#example-sharemodel${product.SKU}`} onClick={() => { getproductdataforshare(product) }} className="social_section_right"><i className="fa-solid fa-share"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="select_for_text">
                                                <a href={`/product/${product.SKU}`} data-tip data-for={`registerTip${i}`}>{(product.Name).substring(0, 70)}</a>
                                                <ReactTooltip id={`registerTip${i}`} place="top" effect="solid">
                                                    {product.Name}
                                                </ReactTooltip>
                                                <ul className="list-unstyled display_flex space_between2">
                                                    <li><a href={`/product/${product.SKU}`} className="real_price">{product.COO == "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                    <li><span className="old_price">{product.COO == "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>
                                                </ul>
                                            </div>
                                        </a>
                                    </div>
                                )}
                            </div>
                            <button
                                onClick={() => handleNextClick("NewArrivals")}
                                disabled={newArrivalIndex + 4 >= recommandedProductList.length}
                            >
                                Next
                            </button> */}
        {/* </div>
                    </div>} */}
      </div>

      <Footer />
      {reshare === true ? (
        <Productshare productdata={shareddata} pkey={"productdetails"} />
      ) : (
        <></>
      )}

      <div className="scroll icon scrolltop text-right">
        <i className="fa-solid fa-arrow-turn-up"></i>
      </div>
      {/* {recommandedProductList.length > 0 ? (
                <ScriptTag type="text/javascript" src="/js/product_detail_carousel.js" />
            ) : <></>} */}
      {/* {state_account.showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
                        <h5>{state_account.showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )} */}
      {/* {showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
                        <h5>{showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )} */}

      {/* <div className="modal fade" id="add_cart" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showmodal ? "block" : "none" }}>
                <div className="create_post_area post_area modal-lg modal-dialog offer_dialog modal-md">
                    <div className="modal-content feed_modal_content">
                        <button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
                        <div className="modal-body" style={{ background: "#ffff" }}>
                            <form method="post" style={{ textAlign: "center" }}> */}
      <div
        style={{ display: showmodal ? "block" : "none" }}
        className="gif-popup"
      >
        <div className="popup-containt">
          <img src="/images/gif/addtocart.gif" alt=""/>
        </div>
      </div>
      {/* </form>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default ProductDetails;
