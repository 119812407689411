/* eslint-disable */
import { Footer, Header, ProfileSideBar } from 'components';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_jesssu_coin_tnx } from "../../store/account/api"
// import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function JesssuCoin() {
	const dispatch = useDispatch();
	const [cointnxlist, setCoinTnxList] = useState([]);
	let offset = 0;
	const count = 50;
	const state_account = useSelector(state => state.account);

	useEffect(() => {
		let isMounted = true;
		getJesssuCoins();
		return () => { isMounted = false };
	}, []);
	const getJesssuCoins = () => {
		let userpayload =
		{
			// "userid": "62f0c6223289bc306a47d6bf",
			"email": state_account.profile.email,
			"token": state_account.profile.token,
		}
		dispatch(get_jesssu_coin_tnx(userpayload, offset, count, (res) => {
			if (res.status === 200) {
				setCoinTnxList(res.data);
			}
		}));
	}
	return (
		<>
			<div className="thetop"></div>
			<Header />
			<Helmet>
                <title>Jesssu Coins Summary | View Balance & Transaction History</title>
                <meta name="description" content="Check your Jesssu Coin balance and transaction history. Monitor your rewards, review earnings and spending, and keep track of all your rewards activity." />
                <meta name="keywords" content="Jesssu coins" />
            </Helmet>
			<div className="stash_my_look_area">
				<div className="container">
					<div className="row">
						<ProfileSideBar />
						<div className="col-md-6">
							<div className="profile_area">
								<div className="coin_area">
									<img src="/images/home/jesssu_coin.png" alt="Jesssu coin icon" className="img-responsive" />
									<p><a href='#' className="coin_btn">Total Jesssu Coins <span><i className="fa-solid fa-circle-dollar-to-slot"></i> {state_account.profile.jesssucoin}</span></a></p>
								</div>
								{/* <div className="withdrawl_area">
									<ul className="display_flex space_between2 list-unstyled">
										<li><a  className="coin_btn" data-toggle="modal" data-target="#exampleModal2">Withdraw</a></li>
										<li><a  className="coin_btn">Transaction</a></li>
									</ul>
								</div> */}
							</div>
						</div>
						{/* 
						<div className="modal fade" id="exampleModal2" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div className="feed_details modal-dialog offer_dialog modal-md">
								<div className="modal-content feed_modal_content">

									<button type="button" className="close modal_close" data-dismiss="modal">&times;</button>

									<div className="modal-body">
										<div className="withdrawl_modal">
											<p className="text_bold_600 withdraw_heading">Withdraw</p>
											<p className="text_bold_600">Withdraw Jesssu Coin to your bank account</p>
											<p><a  className="coin_btn">Total Jesssu Coins <span><i className="fa-solid fa-circle-dollar-to-slot"></i> 100</span></a>
											</p>
											<p className="text_bold_600">Submit Amount</p>
											<form method="post">
												<div className="comment_text_area brand_2_search">

													<input type="number" className="social_page_comment_text" placeholder="Withdraw amount" />

												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div> */}

						<div className="col-md-3">
							<div className="related_product">
								<p className="related_product_heading request_heading">History</p>
								<div className="classic_container no_margin history_container">
									<div className="classic_height">

										{cointnxlist.length === 0 ?
											<></>
											:
											cointnxlist.map((i, key) => (
												<div className="history_area display_flex space_between2" key={key}>
													<div className="display_flex">
														<div className="history_icon">
															<i className="fa-regular fa-circle-user"></i>
															<div className="history_no purple_color text_bold_600"> {i.txnamount} </div>
														</div>
														<div className="history_desc">
															<p className="text_bold_600">{i.txnremarks}</p>
															<p className="purple_color">{new Intl.DateTimeFormat('en-GB', {
																month: 'long',
																day: '2-digit',
																year: 'numeric',
															}).format(new Date(i.txndate))}
															</p>
														</div>
													</div>

												</div>))}

									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
			</div>
			<Footer />
			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
		</>
	)
}

export default JesssuCoin;