import * as types from "./socialTypes";

export function get_all_share_shop(payload) {
    return {
        type: types.GET_ALL_SOCIAL_SUCCESS_SHOP,
        payload: payload,
      };

}

export function get_all_categories_shop(payload) {
  return {
      type: types.GET_ALL_CATEGORIES_SUCCESS,
      payload: payload,
    };

}

export function get_all_brands_shop(payload) {
  return {
      type: types.GET_ALL_BRANDS_SUCCESS,
      payload: payload,
    };

}

export function get_single_share(payload) {
  return {
      type: types.GET_SINGLE_SHARE_SUCCESS,
      payload: payload,
    };

}

export function get_single_share_like(payload) {
  return {
      type: types.GET_SINGLE_SHARE_LIKE_SUCCESS,
      payload: payload,
    };

}

export function get_single_share_dislike(payload) {
  return {
      type: types.GET_SINGLE_SHARE_DISLIKE_SUCCESS,
      payload: payload,
    };

}

export function get_single_share_comment(payload) {
  return {
      type: types.GET_SINGLE_SHARE_COMMENT_SUCCESS,
      payload: payload,
    };

}

export function get_single_share_shareby(payload) {
  return {
      type: types.GET_SINGLE_SHARE_SHAREBY_SUCCESS,
      payload: payload,
    };

}