/* eslint-disable */
import { Footer, ProfileSideBar, FriendRequestCard, ReceiveRequestCard, Header, Reshare } from "components";
import React, { useEffect, useState } from "react";
import { get_timeline_user_profile_details } from "../../store/account/api"
import { useDispatch, useSelector } from "react-redux";
import { get_user_timeline, jesssu_get_comments_for_single_post, jesssu_get_comments_for_sharefeed } from 'store/social/api';
import { jesssu_get_shared_like } from 'store/social/api';
import { jesssu_get_shared_dislike } from 'store/social/api';
import { useLocation, useNavigate } from "react-router-dom";
import "./popup.css"
import { accept_friends, get_friend_list, get_friend_status, send_friend_request, un_friend } from 'store/friend/api'
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { get_product_details } from 'store/products/api';


const UserTimeline = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useNavigate();
    // const [friendlist, setFriendList] = useState([]);
    const [timelinefriendlist, settimelinefriendlist] = useState([]);
    // const [shareuserselection, setshareuserselection] = useState("Share With");
    // const [postmessage, setpostmessage] = useState("");
    const [offset, setoffset] = useState(0);
    const [reshare, setreshare] = useState(false)
    const [shareddata, setshareddata] = useState(typeof any)

    const count = 10;
    const [allSocial, setallSocial] = useState([]);
    // const [likes, setlikes] = useState([]);
    // const [dislikes, setdislikes] = useState(null);
    const [comment, setcomment] = useState("");
    const [postcomment, setpostcomment] = useState([]);
    const [singlepost, setsinglepost] = useState(typeof any);
    const [timelineuserdata, settimelineuserdata] = useState(typeof any);
    // const [publicshare, setpublicshare] = useState([]);
    // const [allfriendshare, setallfriendsshare] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const state_account = useSelector(state => state.account);
    const userfirstname = 'string';
    const useremail = state_account.profile.email;
    const [timelineuseremail, settimelineuseremail] = useState(location?.state?.friendemail === undefined ? state_account.profile.email : location?.state?.friendemail)
    const usertoken = state_account.profile.token;
    const [userstatus, setUserStatus] = useState(typeof any);
    // const [friendcount, setFriendCount] = useState(0);
    const [timelinefriendcount, settimelinefriendcount] = useState(0)
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            gettimelineuserdetails()
            getfriendlist()
            getSocialdetails();
            setoffset(0)
        }
        return () => { isMounted = false };
    }, [timelineuseremail]);

    const gettimelineuserdetails = () => {
        var userpayload =
        {
            'email': timelineuseremail,
            'token': usertoken
        }
        dispatch(get_timeline_user_profile_details(userpayload, (res) => {

            if (res.status === 300) {

            }
            else if (res.status === 200) {
                settimelineuserdata(res.data);
                getuserstatus();
            }
        }));
    }
    function closenotification() {
        setTimeout(function () {
            setShowNotification(false);
            setShowNotificationMessage("")

        }, 5000);
    }

    const likeapost = (count, shareid) => {

        var payload =
        {
            'email': useremail,
            'id': shareid,
            'token': usertoken
        }
        dispatch(jesssu_get_shared_like(payload, (res => {
            if (res.status === 200) {

                if (allSocial[count].isliked === false) {
                    if (allSocial[count].isdisliked === true) {
                        const newStateforlike = allSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberoflikes: parseInt(`${allSocial[count].Numberoflikes}`) + 1, isliked: true, Numberofdislikes: parseInt(`${allSocial[count].Numberofdislikes}`) - 1, isdisliked: false };
                            }
                            return i;
                        });
                        setallSocial(newStateforlike);
                    }
                    else {
                        const newStateforlike = allSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberoflikes: parseInt(`${allSocial[count].Numberoflikes}`) + 1, isliked: true, };
                            }
                            return i;
                        });
                        setallSocial(newStateforlike);
                    }
                    setShowNotificationMessage("Like has been submitted successfully")
                    setShowNotification(true);
                    closenotification()
                } else {
                    const newStateforlike = allSocial.map((i = typeof any, index) => {
                        if (index === count) {
                            return { ...i, Numberoflikes: parseInt(`${allSocial[count].Numberoflikes}`) - 1, isliked: false };
                        }
                        return i;
                    });
                    setallSocial(newStateforlike);
                    setShowNotificationMessage("Like has been removed successfully")
                    setShowNotification(true);
                    closenotification()
                }

            }
        })));
    }

    const getpostcomment = (data) => {
        // setpostmessage("")
        setsinglepost(data)
        var payload =
        {
            'email': useremail,
            "shareid": data._id,
            'token': usertoken
        }
        dispatch(jesssu_get_comments_for_single_post(payload, (res => {
            if (res.status === 200) {
                res.data.map((i) => {
                    setpostcomment(current => [...current, i])
                })
            }
        })));

    }
    // const savesharedata = (sharedata) => {
    //     setsinglepost(sharedata)
    // }
    const clearpost = () => {
        setpostcomment([])
        setsinglepost(typeof any)
    }
    // const publicshares = (shareid, sharedto) => {
    //     setpublicshare(shareid, sharedto)

    // }
    // const allfriendshares = (shareid, sharedto) => {
    //     setallfriendsshare(shareid, sharedto)
    // }


    const dislikeapost = (count, shareid) => {

        var payload =
        {
            'email': useremail,
            'id': shareid,
            'token': usertoken
        }
        dispatch(jesssu_get_shared_dislike(payload, (res => {
            if (res.status === 200) {
                if (allSocial[count].isdisliked === false) {
                    if (allSocial[count].isliked === true) {
                        const newStatefordislike = allSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberofdislikes: parseInt(`${allSocial[count].Numberofdislikes}`) + 1, isdisliked: true, Numberoflikes: parseInt(`${allSocial[count].Numberoflikes}`) - 1, isliked: false };
                            }
                            return i;
                        });
                        setallSocial(newStatefordislike);
                    } else {
                        const newStatefordislike = allSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberofdislikes: parseInt(`${allSocial[count].Numberofdislikes}`) + 1, isdisliked: true };
                            }
                            return i;
                        });
                        setallSocial(newStatefordislike);
                    }
                    setShowNotificationMessage("Dislike has been submitted successfully")
                    setShowNotification(true);
                    closenotification()

                } else {
                    const newStatefordislike = allSocial.map((i = typeof any, index) => {
                        if (index === count) {
                            return { ...i, Numberofdislikes: parseInt(`${allSocial[count].Numberofdislikes}`) - 1, isdisliked: false };
                        }
                        return i;
                    });
                    setallSocial(newStatefordislike);
                    setShowNotificationMessage("Dislike has been removed successfully")
                    setShowNotification(true);
                    closenotification()

                }
            }
        })));

    }
    const closeNotification = () => {
        setShowNotification(false);
    };
    // const reshareapost = (shareid, singleuser) => {

    //     if (singleuser === "") {
    //         let sharedtovalue = []
    //         if (shareuserselection === "Public") {
    //             sharedtovalue = []
    //             setShowNotificationMessage("Product has been shared successfuly as a public feed")
    //         }
    //         else if (shareuserselection === "All friends") {
    //             friendlist.map(i => {
    //                 sharedtovalue.push(i.friendemail[0])
    //             })
    //             setShowNotificationMessage("Product has been shared successfuly to all your friends")
    //         }
    //         var payload =
    //         {
    //             'email': useremail,
    //             'sharedto': sharedtovalue,
    //             'shareid': shareid._id,
    //             'token': usertoken
    //         }
    //         dispatch(jesssu_get_reshares(payload, (res) => {
    //             if (res.status === 200) {

    //                 const newState = allSocial.map((i = typeof any, index) => {
    //                     if (i._id === shareid._id) {
    //                         return { ...i, Numberofshares: parseInt(`${allSocial[index].Numberofshares}`) + 1 };
    //                     }
    //                     return i;
    //                 });
    //                 setallSocial(newState);
    //                 setShowNotificationMessage("You have successfully reshared the post")
    //                 setShowNotification(true);
    //                 closenotification()
    //             }
    //         }))
    //     }
    //     else {
    //         const payload = {

    //             'email': useremail,
    //             'sharedto': singleuser,
    //             'shareid': shareid._id,
    //             'token': usertoken

    //         }
    //         dispatch(jesssu_get_reshares(payload, (res) => {
    //             if (res.status === 200) {

    //                 const newState = allSocial.map((i = typeof any, index) => {
    //                     if (i._id === shareid._id) {
    //                         return { ...i, Numberofshares: parseInt(`${allSocial[index].Numberofshares}`) + 1 };
    //                     }
    //                     return i;
    //                 });
    //                 setallSocial(newState);
    //                 setShowNotificationMessage("You have successfully reshared the post")
    //                 setShowNotification(true);
    //                 closenotification()
    //             }
    //         }))
    //     }


    // }
    const commentbox = (shareid) => {

        if (comment !== "") {
            var payload =
            {
                'email': useremail,
                'token': usertoken,
                'shareid': shareid,
                'comment': comment,
            }
            dispatch(jesssu_get_comments_for_sharefeed(payload, (res => {

                if (res.status === 200) {
                    const newState = allSocial.map((j, index) => {
                        if (j._id === shareid) {
                            let commentdata = {
                                "user": useremail,
                                "comment": comment
                            }
                            j.comments.push(commentdata)
                            let data = { ...j, Numberofcomment: parseInt(`${allSocial[index].Numberofcomment}`) + 1, };
                            getpostcomment(data)
                            return data;
                        }
                        return j;
                    });
                    setallSocial(newState);
                    setcomment("")
                    setShowNotificationMessage("Your comment has been posted successfully")
                    setShowNotification(true);
                    closenotification()
                }
            })));
        }



    }
    // function updatesharewithvalue(data) {

    //     setshareuserselection(data)
    // }

    const getfriendlist = () => {
        settimelinefriendlist([])
        settimelinefriendcount(0)
        const payloadforfriend = {
            "useremail": useremail,
            "token": usertoken
        }
        dispatch(get_friend_list(payloadforfriend, (res) => {

            // if (res.status === 200) {
            //     // setFriendList(res.data);
            //     // setFriendCount(res.data.length)
            // }
        }));

        const payloadfortimelineuserfriend = {
            "useremail": timelineuseremail,
            "token": usertoken
        }
        dispatch(get_friend_list(payloadfortimelineuserfriend, (res) => {

            if (res.status === 200) {
                settimelinefriendlist(res.data);
                settimelinefriendcount(res.data.length)
            }
        }));


    }
    // async function handelchange(e) {
    //     setpostmessage(e.target.value)
    // }

    const getSocialdetails = () => {
        var payload =
        {
            'token': usertoken,
            'email': useremail,
            "searchuseremail": timelineuseremail
        }
        dispatch(get_user_timeline(payload, offset, count, (res => {
            if (res.status === 200) {

                res.data.map((i) => {
                    i.isliked = false
                    i.isdisliked = false
                    i.isshared = false
                    i.likeby[0].map((j) => {
                        if (j === userfirstname) {
                            i.isliked = true
                        }
                    })
                    i.dislikeby[0].map((k) => {
                        if (k === userfirstname) {
                            i.isdisliked = true
                        }
                    })
                    i.sharedby.map((k) => {
                        if (k === userfirstname) {
                            i.isshared = true
                        }
                    })
                    setallSocial(current => [...current, i]);
                })

            }

        })));
    }



    window.onscroll = function () {
        if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)) {
            setoffset(offset + 1)
            get_more_social();
        }
    }
    const get_more_social = () => {

        var payload =
        {
            'token': usertoken,
            'email': useremail,
            "searchuseremail": timelineuseremail
        }
        dispatch(get_user_timeline(payload, offset, count, (res => {
            if (res.status === 200) {

                res.data.map((i) => {
                    i.isliked = false
                    i.isdisliked = false
                    i.isshared = false
                    i.likeby[0].map((j) => {
                        if (j === userfirstname) {
                            i.isliked = true
                        }
                    })
                    i.dislikeby[0].map((k) => {
                        if (k === userfirstname) {
                            i.isdisliked = true
                        }
                    })
                    i.sharedby.map((k) => {
                        if (k === userfirstname) {
                            i.isshared = true
                        }
                    })
                    setallSocial(current => [...current, i]);
                })

            }

        })));
    }

    const handleChangeComment = (e) => {
        setcomment(e.target.value)
    }
    const getuserstatus = () => {
        const payloadforfriend = {
            "useremail": useremail,
            "token": usertoken,
            "friendemail": timelineuseremail,

        }
        dispatch(get_friend_status(payloadforfriend, (res) => {

            if (res.status === 200) {
                setUserStatus(res.data);
            }
        }));
    }
    function getresharedata(data) {
        setshareddata(data)
        setreshare(true)
    }
    const approverequest = () => {
        let payload = {
            "useremail": useremail,
            "token": usertoken,
            'friendemail': timelineuseremail
        }
        dispatch(accept_friends(payload, (res) => {
            if (res.status === 200) {
                getuserstatus()
            }
        }));
    }
    const cancelrequest = () => {
        let payload = {
            "useremail": useremail,
            "token": usertoken,
            'friendemail': timelineuseremail
        }
        dispatch(un_friend(payload, (res) => {
            if (res.status === 200) {
                getuserstatus()
            }
        }));
    }
    function routetotimeline(i) {
        history({
            pathname: "/timeline",
            state: { friendemail: i },
        });
        settimelineuseremail(i)
        setallSocial([])
        // gettimelineuserdetails()
        // getfriendlist()
        // getSocialdetails();
    }

    const sendfriendrequest = () => {
        let payload = {
            "useremail": useremail,
            "token": usertoken,
            'friendemail': timelineuseremail
        }
        dispatch(send_friend_request(payload, (res) => {
            if (res.status === 200) {
                getuserstatus();
            }
        }));
    }
    async function redirectToProductPagefromSocial(id) {
        let payload = {
            'token': state_account.profile.token,
            'email': state_account.profile.email,
            "COO": state_account.profile.COO,
        };
        dispatch(get_product_details(payload, id, (res) => {
            if (res.data && res.data.length > 0) {
                window.open(res.data[0]?.channels[0].url, '_blank');
            }
        }))
    }
    return (<>
        <div className="thetop"></div>
        <Header />
        <div className="stash_my_look_area">
            <div className="container">
                <div className="row">
                    <ProfileSideBar />
                    <div className="col-md-6">
                        <div className="wishlist_area">
                            <div className="social_page">
                                <div className="classic_height">
                                    <div className="classic_container">
                                        <div className="profile_box">
                                            <div className="profile_header_bg"></div>
                                            <div className="profile_header_content">
                                                <div className="timelinefriends space_between2 align_item_center">
                                                    <div className="timelinefriends align_item_center">
                                                        <div className="timelinefriends-profile_user_img">
                                                            <img src={timelineuserdata?.profileimage} onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = `${timelineuserdata.gender === "" || undefined || null ? "images/onboarding/man.png" : "images/onboarding/woman.png"}`;
                                                            }} alt="" title="" />
                                                            {/* <img src={timelineuserdata.profileimage === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${timelineuserdata.profileimage}`} alt="" /> */}
                                                        </div>
                                                        <div className="timeline-profile_data_content">
                                                            <div className="name_content">
                                                                <p className="name_content_heading">{timelineuserdata.firstname} {timelineuserdata.lastname}</p>


                                                                <div className="timelinefriends-counting-follower">
                                                                    <div className="img-grid-for">
                                                                        <div className="image-section-timeline">
                                                                            {timelinefriendlist[0] !== undefined &&
                                                                                <><img alt="" data-tip data-for="friendtip0" src={timelinefriendlist[0].profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${timelinefriendlist[0].profileimage[0]}`} className="mutual-one cursor-pointer" onClick={() => { routetotimeline(timelinefriendlist[0].friendemail[0]) }} />

                                                                                    <ReactTooltip id="friendtip0" place="top" effect="solid">
                                                                                        {timelinefriendlist[0].friendfirstname[0]} {timelinefriendlist[0].friendlastname[0]}
                                                                                    </ReactTooltip></>}

                                                                            {timelinefriendlist[1] !== undefined &&
                                                                                <><img alt="" data-tip data-for="friendtip1" src={timelinefriendlist[1].profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${timelinefriendlist[1].profileimage[0]}`} className="mutual-two cursor-pointer" onClick={() => { routetotimeline(timelinefriendlist[1].friendemail[0]) }} />
                                                                                    <ReactTooltip id="friendtip1" place="top" effect="solid">
                                                                                        {timelinefriendlist[1].friendfirstname[0]} {timelinefriendlist[1].friendlastname[0]}
                                                                                    </ReactTooltip></>}

                                                                            {timelinefriendlist[2] !== undefined &&
                                                                                <><img alt="" data-tip data-for="friendtip2" src={timelinefriendlist[2].profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${timelinefriendlist[2].profileimage[0]}`} className="mutual-three cursor-pointer" onClick={() => { routetotimeline(timelinefriendlist[2].friendemail[0]) }} />
                                                                                    <ReactTooltip id="friendtip2" place="top" effect="solid">
                                                                                        {timelinefriendlist[2].friendfirstname[0]} {timelinefriendlist[2].friendlastname[0]}
                                                                                    </ReactTooltip></>}


                                                                            {timelinefriendlist[3] !== undefined &&
                                                                                <><img alt=""  data-tip data-for="friendtip3" src={timelinefriendlist[3].profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${timelinefriendlist[3].profileimage[0]}`} className="mutual-four cursor-pointer" onClick={() => { routetotimeline(timelinefriendlist[3].friendemail[0]) }} />
                                                                                    <ReactTooltip id="friendtip3" place="top" effect="solid">
                                                                                        {timelinefriendlist[3].friendfirstname[0]} {timelinefriendlist[3].friendlastname[0]}
                                                                                    </ReactTooltip></>}

                                                                        </div>

                                                                    </div>
                                                                    <div className="mutual-friend">
                                                                        {/* <h6>{timelinefriendcount} friends</h6> */}
                                                                    </div>

                                                                </div>
                                                                <div className="mutual-friend">
                                                                    <h6>{timelinefriendcount} friends</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {timelineuseremail === useremail ? <><p className="timelinefriends-profile_delete delete_icon">
                                                        <a className="btn btn-color">Your Timeline</a>
                                                    </p></> : (userstatus === 0 || userstatus === 3) ?
                                                        <p className="timelinefriends-profile_delete delete_icon">
                                                            <a onClick={() => { sendfriendrequest() }} className="btn btn-color">Add Friend</a>
                                                        </p> :
                                                        userstatus === 2 ? <><p className="timelinefriends-profile_delete delete_icon">
                                                            <a className="btn btn-color">Friend</a>
                                                        </p></> :
                                                            userstatus === 13 ? <p className="timelinefriends-profile_delete delete_icon">
                                                                <a onClick={() => { cancelrequest() }} className="btn btn-color">Cancel Request</a>
                                                            </p> :
                                                                userstatus === 12 ? <p className="timelinefriends-profile_delete delete_icon">
                                                                    <a onClick={() => { approverequest() }} className="btn btn-color">Accept</a>
                                                                    <a onClick={() => { cancelrequest() }} className="btn btn-color">Decline</a>
                                                                </p> : <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {allSocial.map((data, index) => (
                                        <div key={index}>
                                            <div className="classic_container">
                                                <div className="classNameic_container">
                                                    <div className="social_box social_box_2">
                                                        <div className="social_box">
                                                            <div className="name_area">
                                                                <div className="display_flex" data-tip data-for={`gettimeline${data.sharedbyId[0]}`}>
                                                                    <div className="name_img_area cursor-pointer" onClick={() => { routetotimeline(data.sharedbyId[0]) }}>
                                                                        <img src={`${data.imageofsharedby[0] && (data?.providerofsharedby[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.imageofsharedby[0]): {})
                                                                            ? data.imageofsharedby[0]
                                                                            : data.genderofsharedby === undefined ? "images/onboarding/man.png" : data.genderofsharedby[0] === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                            }`} alt="" className="name_img" />
                                                                        {/* <img src={data.imageofsharedby[0]} onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null; // prevents looping
                                                                            currentTarget.src = `${data.genderofsharedby[0] === "" || undefined || null ? "images/onboarding/man.png" : "images/onboarding/woman.png"}`;
                                                                        }} alt="" title="" className="name_img" /> */}
                                                                        {/* <img src={data.imageofsharedby[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.imageofsharedby[0]}`} alt="" className="name_img" /> */}
                                                                    </div>
                                                                    <div className="name_content">
                                                                        <p className="name_content_heading cursor-pointer" onClick={() => { routetotimeline(data.sharedbyId[0]) }}>{data.sharedby[0]}</p>
                                                                        <p><i className="fa-solid fa-circle-dollar-to-slot"></i>Shared on {new Intl.DateTimeFormat('en-GB', {
                                                                            month: 'long',
                                                                            day: '2-digit',
                                                                            year: 'numeric',
                                                                        }).format(new Date(data.createdAt))}</p>
                                                                    </div>
                                                                </div>
                                                                <ReactTooltip id={`gettimeline${data.sharedbyId[0]}`} place="top" effect="solid">
                                                                    Check {data.sharedby[0]}'s post
                                                                </ReactTooltip>
                                                            </div>
                                                            <Link to={`/post/${data._id}`}>
                                                                <div className="social_box_desc" data-tip data-for={`gettimeline${data._id}`}>
                                                                    <p style={{ "color": "black" }}>{data.note[0].note}</p>
                                                                </div></Link>
                                                            <ReactTooltip id={`gettimeline${data._id}`} place="top" effect="solid">
                                                                Check full post
                                                            </ReactTooltip>
                                                            {data.type === 1 &&
                                                                <div className="social_box_img">
                                                                    <Link to={`#`}><img onClick={() => redirectToProductPagefromSocial(data.productid)} src={data.postimage} alt="" className="img-responsive" />
                                                                    </Link>
                                                                </div>}

                                                            {data.type === 3 &&
                                                                <div className="social_box_img">
                                                                    <a>
                                                                        <img src={data.postimage} alt="" className="img-responsive" /></a>
                                                                </div>}


                                                            {data.type === 6 &&
                                                                // <div className="classic_formate" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                //     <div className="classic_formate_scroll">
                                                                //         <div className="row">
                                                                //             {data.bundleproduct[0] != undefined &&
                                                                //                 <div className="col-md-6">
                                                                //                     <div className="classic_img_area">  <Link to={`/product/${data.bundleproduct[0].productid}`}><img
                                                                //                         src={data.bundleproduct[0].productimage}
                                                                //                         alt="" className="classic_img" /> </Link></div>
                                                                //                 </div>}
                                                                //             {data.bundleproduct[1] != undefined &&
                                                                //                 <div className="col-md-6">
                                                                //                     <div className="classic_img_area"><Link to={`/product/${data.bundleproduct[1].productid}`}> <img
                                                                //                         src={data.bundleproduct[1].productimage} alt=""
                                                                //                         className="classic_img" /></Link></div>
                                                                //                 </div>}
                                                                //         </div>
                                                                //         <div className="row">
                                                                //             {data.bundleproduct[2] != undefined &&
                                                                //                 <div className="col-md-6">
                                                                //                     <div className="classic_img_area"><Link to={`/product/${data.bundleproduct[2].productid}`}> <img
                                                                //                         src={data.bundleproduct[2].productimage}
                                                                //                         alt="" className="classic_img" /></Link> </div>
                                                                //                 </div>}
                                                                //             {data.bundleproduct[3] != undefined &&
                                                                //                 <div className="col-md-6">
                                                                //                     <div className="classic_img_area"><Link to={`/product/${data.bundleproduct[3].productid}`}> <img
                                                                //                         src={data.bundleproduct[3].productimage} alt=""
                                                                //                         className="classic_img" /></Link> </div>
                                                                //                 </div>}
                                                                //         </div>
                                                                //         <div className="row">
                                                                //             {data.bundleproduct[4] != undefined &&
                                                                //                 <div className="col-md-6">
                                                                //                     <div className="classic_img_area"><Link to={`/product/${data.bundleproduct[4].productid}`}> <img
                                                                //                         src={data.bundleproduct[4].productimage}
                                                                //                         alt="" className="classic_img" /></Link> </div>
                                                                //                 </div>}
                                                                //             {data.bundleproduct[5] != undefined &&
                                                                //                 <div className="col-md-6">
                                                                //                     <div className="classic_img_area"><Link to={`/product/${data.bundleproduct[5].productid}`}> <img
                                                                //                         src={data.bundleproduct[5].productimage} alt=""
                                                                //                         className="classic_img" /></Link> </div>
                                                                //                 </div>}
                                                                //         </div>
                                                                //     </div>
                                                                // </div>
                                                                <div>
                                                                    {
                                                                        data.layout[0] === "2-2-1" ?
                                                                            <div className="classic_formate" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>

                                                                                <div className="classic_formate_scroll1 no_scroll">
                                                                                    <div className="row">
                                                                                        <div className="col-md-5 col-xs-5">
                                                                                            <div className="classic_img_area">
                                                                                                {data.bundleproduct.length > 0 &&
                                                                                                    <img src={`${data.bundleproduct[0].productimage}`} alt=""
                                                                                                        className="classic_img" />
                                                                                                }
                                                                                            </div>
                                                                                            <div className="classic_img_area">
                                                                                                {data.bundleproduct.length > 1 &&
                                                                                                    <img src={`${data.bundleproduct[1].productimage}`} alt=""
                                                                                                        className="classic_img" />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3 col-xs-3">
                                                                                            <div className="classic-img-area2">
                                                                                                {data.bundleproduct.length > 2 &&
                                                                                                    <img src={`${data.bundleproduct[2].productimage}`} alt=""
                                                                                                        className="classic_img" />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-3 col-xs-3">
                                                                                            <div className="classic-img-area2">
                                                                                                {data.bundleproduct.length > 3 &&
                                                                                                    <img src={`${data.bundleproduct[3].productimage}`} alt=""
                                                                                                        className="classic_img" />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6 col-xs-6">
                                                                                            <div className="classic-img-area3">
                                                                                                {data.bundleproduct.length > 4 &&
                                                                                                    <img src={`${data.bundleproduct[4].productimage}`} alt=""
                                                                                                        className="classic_img" />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            data.layout[0] === "3-2" ?
                                                                                <div className="classic_formate" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                                    <div className="classic_formate_scroll1 no_scroll">
                                                                                        <div className="row">
                                                                                            <div className="col-md-4 col-xs-4">
                                                                                                <div className="classic-img-area4">
                                                                                                    {data.bundleproduct.length > 0 &&
                                                                                                        <img src={`${data.bundleproduct[0].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-4 col-xs-4">
                                                                                                <div className="classic-img-area4">
                                                                                                    {data.bundleproduct.length > 1 &&
                                                                                                        <img src={`${data.bundleproduct[1].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-4 col-xs-4">
                                                                                                <div className="classic-img-area4">
                                                                                                    {data.bundleproduct.length > 2 &&
                                                                                                        <img src={`${data.bundleproduct[2].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic-img-area5">
                                                                                                    {data.bundleproduct.length > 3 &&
                                                                                                        <img src={`${data.bundleproduct[3].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic-img-area5">
                                                                                                    {data.bundleproduct.length > 4 &&
                                                                                                        <img src={`${data.bundleproduct[4].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : data.layout[0] === "4-4" || data.layout[0] === "" ?

                                                                                    <div className="classic_formate" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }} >
                                                                                        <div className="classic_formate_scroll no_scroll">
                                                                                            <div className="row">
                                                                                                {data.bundleproduct.length === 0
                                                                                                    ?
                                                                                                    <></>
                                                                                                    :
                                                                                                    data.bundleproduct.map((data, key) => (
                                                                                                        <div className="col-md-6 col-xs-6" key={key}>
                                                                                                            <div className="classic_img_area">
                                                                                                                <img src={`${data.productimage}`} alt="" className="classic_img" />
                                                                                                            </div>
                                                                                                        </div>)
                                                                                                    )
                                                                                                }

                                                                                            </div>
                                                                                        </div>

                                                                                    </div> : <></>
                                                                    }
                                                                </div>
                                                            }



                                                            <ul className="list-unstyled display_flex space_between2 social_page_like">
                                                                {data.isdisliked === true &&
                                                                    <li><a className="active_color cursor-pointer"><i onClick={() => { dislikeapost(index, data._id) }} className="fa-solid fa-thumbs-down"></i></a>
                                                                        <span className="active_color" data-tip data-for={`disliketip${index}`}>{data.Numberofdislikes} dislikes</span></li>}
                                                                {data.isdisliked === false &&
                                                                    <li><a className='cursor-pointer'><i onClick={() => { dislikeapost(index, data._id) }} className="fa-solid fa-thumbs-down"></i></a>
                                                                        <span data-tip data-for={`disliketip${index}`}>{data.Numberofdislikes} dislikes</span></li>}

                                                                <ReactTooltip id={`disliketip${index}`} place="top" effect="solid">
                                                                    {(data.dislikeby[0] || []).map((dislikedata, i) => ((i <= 5 && <div key={i}>{dislikedata}</div>)))}
                                                                </ReactTooltip>
                                                                {data.isliked === true &&
                                                                    <li><a className="active_color cursor-pointer"><i onClick={() => { likeapost(index, data._id) }} className="fa-solid fa-thumbs-up"></i></a>
                                                                        <span className="active_color" data-tip data-for={`liketip${index}`}>{data.Numberoflikes} likes </span></li>}
                                                                {data.isliked === false &&
                                                                    <li><a className="cursor-pointer"><i onClick={() => { likeapost(index, data._id) }} className="fa-solid fa-thumbs-up"></i></a>
                                                                        <span data-tip data-for={`liketip${index}`}>{data.Numberoflikes} likes </span></li>}

                                                                <ReactTooltip id={`liketip${index}`} place="top" effect="solid">
                                                                    {(data.likeby[0] || []).map((likedata, i) => ((i <= 5 && <div key={i}>{likedata}</div>)))}
                                                                </ReactTooltip>
                                                                {data.isshared === false &&
                                                                    <><li>
                                                                        <a data-toggle="modal" data-target={`#create_post_alertreshare${data._id}`} onClick={() => getresharedata(data)} className="cursor-pointer">
                                                                            <i className="fa-solid fa-share"></i></a>
                                                                        <span data-tip data-for={`sharetip${index}`}>{data.Numberofshares} shares </span></li></>
                                                                }
                                                                {data.isshared === true &&
                                                                    <><li>
                                                                        <a data-toggle="modal" data-target={`#create_post_alertreshare${data._id}`} className="active_color cursor-pointer" onClick={() => getresharedata(data)}>
                                                                            <i className="fa-solid fa-share"></i></a>
                                                                        <span className="active_color" data-tip data-for={`sharetip${index}`}>{data.Numberofshares} shares </span></li></>}

                                                                <ReactTooltip id={`sharetip${index}`} place="top" effect="solid">
                                                                    {(data.sharedby || []).map((sharedbydata, i) => ((i <= 5 && <div key={i}>{sharedbydata}</div>)))}
                                                                </ReactTooltip>
                                                                {data.Numberofcomment > 0 &&
                                                                    <li><a className="active_color" style={{ cursor: "pointer" }}><i className="fa-solid fa-comment"></i> </a>
                                                                        <span className="active_color">{data.Numberofcomment} comments</span></li>}
                                                                {data.Numberofcomment <= 0 &&
                                                                    <li><a style={{ cursor: "pointer" }}><i className="fa-solid fa-comment"></i> </a>
                                                                        <span>{data.Numberofcomment} comments</span></li>}
                                                            </ul>
                                                            <div className="social_page_comment">
                                                                <p onClick={() => { getpostcomment(data) }}><a data-toggle="modal" data-target="#exampleModal2" style={{ cursor: "pointer" }}>View all {data.Numberofcomment} comments</a></p>

                                                                {data.comments.length === 1 &&
                                                                    <div className="social_page_comment">
                                                                        <div>
                                                                            <p>@{data.comments[data.comments.length - 1].user}</p>
                                                                            <p>{data.comments[data.comments.length - 1].comment}</p>
                                                                        </div>
                                                                    </div>}
                                                                {data.comments.length >= 2 &&
                                                                    <div className="social_page_comment">
                                                                        <div>
                                                                            <p>@{data.comments[data.comments.length - 2].user}</p>
                                                                            <p>{data.comments[data.comments.length - 2].comment}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>@{data.comments[data.comments.length - 1].user}</p>
                                                                            <p>{data.comments[data.comments.length - 1].comment}</p>
                                                                        </div>
                                                                    </div>}


                                                                <form method="post">
                                                                    <div className="comment_text_area">
                                                                        <input className="social_page_comment_text" placeholder="Add a comment" value={comment} onChange={handleChangeComment} />
                                                                        <button type="button"><i className="fa-solid fa-paper-plane" onClick={() => { commentbox(data._id) }}></i></button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="related_product">
                            <p className="related_product_heading request_heading">Requests</p>
                            <FriendRequestCard />
                            <ReceiveRequestCard />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Stash My Look Area Closed Here --> */}



        <div className="modal fade" id="exampleModal2" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="feed_details modal-dialog offer_dialog modal-md">
                <div className="modal-body">
                    <div className="friends-timeline-pop-up">
                        <div className="pop-up-section">'
                            <div className="pop-up-box">
                                <button type="button" className="close modal_close" data-dismiss="modal" onClick={() => { clearpost() }}>&times;</button>
                                <div className="row">
                                    <div className="col-md-6">
                                        {(singlepost.type === 1) &&
                                            <div className="pop-up-img" style={{ "height": "480px", "paddingTop": "3rem" }}>
                                                <img src={singlepost.postimage}
                                                    alt="" className="img-responsive" />
                                            </div>
                                        }
                                        {(singlepost.type === 3) &&
                                            <div className="pop-up-img" style={{ "height": "480px", "paddingTop": "3rem" }}>
                                                <img src={singlepost.postimage}
                                                    alt="" className="img-responsive" />
                                            </div>
                                        }
                                        {(singlepost.type === 6) &&
                                            <div className="pop-up-img" style={{ "height": "480px", "paddingTop": "3rem", "width": "100%" }}>
                                                <div className="mylook_post">
                                                    <div data-toggle="buttons">
                                                        <label className="btn btn-default">
                                                            <input type="checkbox" name="var_id[]"
                                                                autoComplete="off" value="" />
                                                            <div className="row">
                                                                {singlepost.bundleproduct.length === 0 ? <></> :
                                                                    // singlepost.bundleproduct.map((data, index) => (
                                                                    //     <div className="col-md-6 col-xs-6" key={index}>
                                                                    //         <div className="post_select_for_img1">
                                                                    //             <img src={`${data.productimage}`}
                                                                    //                 alt="" className="img-responsive" />
                                                                    //         </div>
                                                                    //     </div>))
                                                                    <div>
                                                                        {
                                                                            singlepost.layout[0] === "2-2-1" ?
                                                                                <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }}>

                                                                                    <div className="classic_formate_scroll1 no_scroll">
                                                                                        <div className="row">
                                                                                            <div className="col-md-5 col-xs-5">
                                                                                                <div className="classic_img_area">
                                                                                                    {singlepost.bundleproduct.length > 0 &&
                                                                                                        <img src={`${singlepost.bundleproduct[0].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="classic_img_area">
                                                                                                    {singlepost.bundleproduct.length > 1 &&
                                                                                                        <img src={`${singlepost.bundleproduct[1].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-xs-3">
                                                                                                <div className="classic-img-area2">
                                                                                                    {singlepost.bundleproduct.length > 2 &&
                                                                                                        <img src={`${singlepost.bundleproduct[2].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-xs-3">
                                                                                                <div className="classic-img-area2">
                                                                                                    {singlepost.bundleproduct.length > 3 &&
                                                                                                        <img src={`${singlepost.bundleproduct[3].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic-img-area3">
                                                                                                    {singlepost.bundleproduct.length > 4 &&
                                                                                                        <img src={`${singlepost.bundleproduct[4].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                singlepost.layout[0] === "3-2" ?
                                                                                    <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }}>
                                                                                        <div className="classic_formate_scroll1 no_scroll">
                                                                                            <div className="row">
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {singlepost.bundleproduct.length > 0 &&
                                                                                                            <img src={`${singlepost.bundleproduct[0].productimage}`} alt=""
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {singlepost.bundleproduct.length > 1 &&
                                                                                                            <img src={`${singlepost.bundleproduct[1].productimage}`} alt=""
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {singlepost.bundleproduct.length > 2 &&
                                                                                                            <img src={`${singlepost.bundleproduct[2].productimage}`} alt=""
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic-img-area5">
                                                                                                        {singlepost.bundleproduct.length > 3 &&
                                                                                                            <img src={`${singlepost.bundleproduct[3].productimage}`} alt=""
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic-img-area5">
                                                                                                        {singlepost.bundleproduct.length > 4 &&
                                                                                                            <img src={`${singlepost.bundleproduct[4].productimage}`} alt=""
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : singlepost.layout[0] === "4-4" || singlepost.layout[0] === "" ?

                                                                                        <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }} >
                                                                                            <div className="classic_formate_scroll no_scroll">
                                                                                                <div className="row">
                                                                                                    {singlepost.bundleproduct.length === 0
                                                                                                        ?
                                                                                                        <></>
                                                                                                        :
                                                                                                        singlepost.bundleproduct.map((data, key) => (
                                                                                                            <div className="col-md-6 col-xs-6" key={key}>
                                                                                                                <div className="classic_img_area">
                                                                                                                    <img src={`${data.productimage}`} alt="" className="classic_img" />
                                                                                                                </div>
                                                                                                            </div>)
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                        </div> : <></>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="comments-section">
                                            <div className="profile-comment-img-row">
                                                <p>{singlepost.postname}</p>
                                            </div>
                                            <div className="timeline-comments">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="friend-comments-section">
                                                            <p>{singlepost.Numberofcomment} comments</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="timeline-brand-content">
                                                {postcomment.map((data, index) => (
                                                    <div className="comment-img-row" key={index}>
                                                        <img src={`${data.profileimage[0] && (data?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0]): {})
                                                            ? data.profileimage[0]
                                                            : data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                            }`} alt="" />
                                                        {/* <img src={data.profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`} alt="" /> */}
                                                        <p>@{data.userid[0]}</p>
                                                        <p>{data.comment}</p>
                                                    </div>))}

                                            </div>
                                            <div className="timeline-comment-section">
                                                <form method="post">
                                                    <div className="timeline-comment_text_area" >

                                                        <input className="timeline-social_page_comment_text" value={comment}
                                                            placeholder="write your comment..."
                                                            onChange={handleChangeComment} />
                                                        <a><i
                                                            className="fa-solid fa-paper-plane" onClick={() => { commentbox(singlepost._id) }} style={{ "cursor": "pointer" }}></i></a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {reshare === true ? (
            <Reshare shareddata={shareddata} pkey={"productdetails"} />
        ) : <></>}
        {showNotification ? (
            <div className="addwishlist-pop-up-box">
                <div className="add-wishlist-row-section">
                    <img src="images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
                    <h5>{showNotificationMessage}</h5>
                </div>
            </div>
        ) : (
            <></>
        )}

        {/* <!-- Footer Section Start --> */}
        <Footer />

    </>);
}

export default UserTimeline;