/* development */
export const apiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;
export const cardanoUrl = process.env.REACT_APP_BLOCKFROST_API_URL;
export const sunstepApi = process.env.REACT_APP_EDGEWRAPPER_SUNSTEP_API_URL;
export const facebookApi = "https://graph.facebook.com/";

// export const jesssuApi = "https://api.jesssu.com";

// export const jesssurecomendatioApi = "http://18.116.8.76:5000"
export const jesssurecomendatioApi = "https://ai.jesssu.com"
// export const jesssuApi = "https://46eb-202-142-70-193.in.ngrok.io";
// export const jesssuApi = "http://localhost:3500";
export const jesssuApi = "https://api.jesssu.com";
// export const jesssuApi = "https://api.jesssu.com";

// export const jesssuApi = "http://192.168.0.105:3500";
// export const jesssuApi = "http://3.111.219.218:8000";
// export const jesssuApi = "https://2e9d-59-92-65-25.in.ngrok.io";

//process.env.REACT_APP_EDGEWRAPPER_JESSSU_API_URL;

export const amazonassociatetag = "jesssuamazon-21"
export const GoogleCaptchaKey = '6LcDfW0eAAAAAKsSoSGk3S8J3xbmYTEBD9YQn8s8'
// export const urls = {
//   root: "http://martify.io/",
//   cardanoscan: "",
//   ipfs: "https://infura-ipfs.io/ipfs/",
// }
