/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { get_subcategroylist_pagination } from "../../store/category/api"
import { useParams } from 'react-router-dom';
import { Footer, Header, Productshare, Iframe } from "components";
import { get_cart_value, update_cart_product_value, add_transaction, remove_cart_product } from "store/account/api";
import { add_delete_wishlist_product, get_wishlist } from "store/stash/api";
import { amazonassociatetag } from '../../config';
import { get_product_details, get_product_list_pagination } from 'store/products/api';
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import "./cart.css";
function Cart() {
    // const history = useNavigate();
    const dispatch = useDispatch();
    const state_account = useSelector(state => state.account);
    const [cartvalue, setcartvalue] = useState([])
    const [wishlist, setWishlist] = useState([]);
    const buynowvalue = false
    const [amazonurl, setamazonurl] = useState("")
    const [recommandedProductList, setRecommandedProductList] = useState([]);
    // const { id } = useParams();
    const [shareddata, setshareddata] = useState(typeof any)
    const [reshare, setreshare] = useState(false)
    const [showmodal, setshowmodal] = useState(false)
    const [coinvalue, setcoinvalue] = useState("")


    // const routetoproductdetails = (i) => {
    //     history({
    //         pathname: "/products",
    //         state: {
    //             categories: [id],
    //             brands: [],
    //             subcategories: [i._id],
    //             minprice: "",
    //             maprice: ""
    //         }
    //     });
    // }
    useEffect(() => {
        getcartvalue()
        get_wishlists()
    }, []);
    // function changevalueforurl(data) {
    //     let step1 = data.replace("&", "-")
    //     let step2 = step1.replace("/", ".")
    //     let step3 = step2.replace("/", ".")
    //     return step3
    // }
    // function changevaluefromurl(data) {
    //     let step1 = data.replace("-", "&")
    //     let step2 = step1.replace(".", "/")
    //     let step3 = step2.replace("-", "&")
    //     return step3
    // }
    function increaseValue(count, data) {
        ;
        const newstateforcart = cartvalue.map((i = typeof any, index) => {
            if (index === count) {
                const payload = {
                    userid: state_account.profile.email,
                    token: state_account.profile.token,
                    user: state_account.profile.email,
                    product: data.product,
                    quantity: parseInt(`${i.quantity}`) + 1
                }
                updateproductquantity(payload)
                return { ...i, count: parseInt(`${i.count}`) + 1, quantity: parseInt(`${i.quantity}`) + 1 };
            }
            return i;
        });
        setcartvalue(newstateforcart);


    }
    function get_wishlists() {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }
        dispatch(get_wishlist(userpayload, (res) => {

            if (res.status === 200) {
                // res.data.map((i) => {
                //     setWishlist(current => [...current, i.item_id[0]])
                // })
                for (let i = 0; i < res.data.length; i++) {
                    setWishlist(current => [...current, res.data[i].item_id[0]]);
                  }
            }
        }));
    }
    function getproductdataforshare(data) {
        setshareddata(data)
        setreshare(true)
    }
    function removeproductfromcart(data) {
        const payload = {
            userid: state_account.profile.email,
            token: state_account.profile.token,
            user: state_account.profile.email
        }
        dispatch(remove_cart_product(payload, state_account.profile.email, data.product, (res) => {
            if (res.status === 200) {
                getcartvalue()
            }
        }))
    }
    function decreaseValue(count, data) {
        if (data.count > 1) {
            const newstateforcart = cartvalue.map((i = typeof any, index) => {
                if (index === count) {
                    const payload = {
                        userid: state_account.profile.email,
                        token: state_account.profile.token,
                        user: state_account.profile.email,
                        product: data.product,
                        quantity: parseInt(`${i.quantity}`) - 1
                    }
                    updateproductquantity(payload)
                    return { ...i, count: parseInt(`${i.count}`) - 1, quantity: parseInt(`${i.quantity}`) - 1 };
                }
                return i;
            });
            setcartvalue(newstateforcart);
        }
        else {
            removeproductfromcart(data)
        }
    }
    function getProductDetails(id) {
        var payload =
        {
            'token': state_account.profile.token,
            'userid': state_account.profile.email,
            "COO": state_account.profile.COO,
        }
        dispatch(get_product_details(payload, id, (res) => {
            if (res.status === 200) {
                getRecommandedProduct(res.data[0].Brand, res.data[0].Primary)
            }
        }));
    }
    // function gotosuggestedproductlist() {
    //     let category = ""
    //     state_account.profile.category.forEach(i => {
    //         const data = changevalueforurl(i)
    //         category = category + "&category=" + data
    //     })
    //     //console.log(category)
    //     let brand = ''
    //     state_account.profile.brand.forEach(i => {
    //         const data = changevalueforurl(i)
    //         brand = brand + "&brand=" + data
    //     })
    //     //console.log(brand)
    //     history(`products/any${category}${brand}`)
    // }

    function getRecommandedProduct(brand, category) {
        var payload =
        {
            'brands': [brand],
            'categories': [category],
            'minprice': "",
            'maxprice': "",
            'sortby': "",
            'subcategories': [],
            'token': null,
            'userid': null,
            'gender': "",
            "COO": [],
        }
        dispatch(get_product_list_pagination(payload, 0, 4, (res) => {
            if (res.status === 200) {
                setRecommandedProductList(res.data);
            }
        }));
    }
    async function addproductinwishlist(data) {
        let payload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "productid": data.SKU,
            "url": data.MainImage
        }

        dispatch(add_delete_wishlist_product(payload, (res) => {
            if (res.status === 200) {
                if (res.data === "Product added to wishist") {
                    setWishlist(current => [...current, data.SKU])
                }
                else {
                    setWishlist((prevState) =>
                        prevState.filter((prevItem) => prevItem !== data.SKU)
                    );
                }
            }
        }));
    }
    function updateproductquantity(payload) {
        dispatch(update_cart_product_value(payload, (res) => {
            if (res.status === 200) {

            }
        }))
    }
    function getcartvalue() {
        setcartvalue([])
        const payload = {
            userid: state_account.profile.email,
            token: state_account.profile.token
        }
        dispatch(get_cart_value(payload, state_account.profile.email, (res) => {
            if (res.status === 200) {
                if (res.data[0] !== undefined) {
                    getProductDetails(res.data[0].product)
                }
                // res.data.map((i) => {
                //     i.count = i.quantity

                //     setcartvalue(current => [...current, i]);
                // })
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i].count = res.data[i].quantity;
                    setcartvalue(current => [...current, res.data[i]]);
                  }
            }
        }))
    }

    async function buynow() {
        console.log(cartvalue)
        setshowmodal(!showmodal)
        let jessucoincount = 0
        if (cartvalue !== 0) {
            var url = "https://www.amazon.in/gp/aws/cart/add.html?";
            let a = 1;
            cartvalue.map((i) => {
                url = `${url}ASIN.${a}=${i.product}&Quantity.$${a}=${i.quantity}&`;
                a++;
                console.log(url)
                const payload = {
                    userid: state_account.profile.email,
                    token: state_account.profile.token,
                    txntype: "Product buy",
                    txnstatus: "Pending",
                    activityid: i.product,
                    txndate: new Date(),
                    txnamount: ((i.total * 6.5 / 100) * 10 / 100).toFixed(0),
                    txnremarks: `Commission for buy product ${i.product}`
                }
                dispatch(add_transaction(payload))
                jessucoincount = ((i.total * 6.5 / 100) * 10 / 100).toFixed(0)
                setcoinvalue(jessucoincount)
            });
            const finalurl = `${url}AssociateTag=${amazonassociatetag}&add=add&output=embed`;
            console.log(finalurl)
            // console.log(finalurl)
            setamazonurl(finalurl)


            // setbuynowvalue(true)
        }
    }
    function closejessucoinpopup() {
        setshowmodal(!showmodal);
        window.open(amazonurl, "_blank")
    }

    return (
        <>
            <div className="thetop"></div>
            <Header />
            {buynowvalue === true &&
                <Iframe url={amazonurl}></Iframe>
            }
            <div className="post-details-box">
                <div className="cart_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="cart-heading">
                                    <p>Your Cart</p>
                                    <h6>You have {state_account.cartvalue.length} items in your cart</h6>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                        {cartvalue.map((data, index) => (
                            <div className="cart-wrap" key={index}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="cart-box-section">
                                            <div className="cart-image-section-gap cart-image-section-row">
                                                <div className="cart-count-row">
                                                    <div className="cart-image cartitemimg">
                                                        <Link to={`/product/${data.product}`}><img src={`${data.pimage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.pimage)
                                                            ? data.pimage
                                                            : "/images/defultimg.svg"
                                                            }`} alt="" /></Link>
                                                    </div>
                                                    <div className="name-of-product">
                                                        <p>{data.brand}</p>
                                                        <Link to={`/product/${data.product}`}><h6 data-tip data-for={`productname${index}`} style={{ cursor: "pointer !important" }}>{data.pname}</h6></Link>
                                                        <ReactTooltip id={`productname${index}`} place="top" effect="solid">
                                                            {data.pname}
                                                        </ReactTooltip>
                                                        <span>ID: {data.product}</span>
                                                    </div>
                                                </div>
                                                <div className="cart-itemcount-section">
                                                    <div className="adding-cart-row">
                                                        <form>
                                                            <div className="value-button" id="decrease" onClick={() => decreaseValue(index, data)} defaultValue="Decrease Value">-</div>
                                                            <input type="number" id="number" value={data.quantity} />
                                                            <div className="value-button" id="increase" onClick={() => increaseValue(index, data)} defaultValue="Increase Value">+</div>
                                                        </form>
                                                    </div>
                                                    <div className="price-section">
                                                        <p>{data.currency} {(data.count * data.price).toFixed(2)}</p>
                                                        <span style={{ "cursor": "pointer" }}><u onClick={() => removeproductfromcart(data)}>Remove</u></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>))}

                        <div className="row">
                            <div className="col-md-10">
                            </div>
                            <div className="col-md-2">
                                {cartvalue.length === 0 ? <><p></p></> :
                                    <p><a className="see_more" data-toggle="modal" data-target="#buynowpopup">Buy Now</a></p>}
                            </div>
                        </div>

                    </div>
                    <div className="modal fade" id={'buynowpopup'} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="feed_details modal-dialog offer_dialog modal-md">
                            <div className="modal-body">
                                <div className="friends-timeline-pop-up">
                                    <div className="stash-pop-up-section">
                                        <div className="stash-pop-up-box">
                                            <div className="wishlist-Just-checking-section">
                                                <h6>BUY NOW </h6>
                                            </div>
                                            <div className="delete-section">
                                                <h6>You will be redirected to Amazon cart page.</h6>
                                            </div>
                                            <div className="checking-row">
                                                <button className="checkingforyes-cart-btn-default" onClick={() => buynow()} data-dismiss="modal">YES</button>
                                                <button className="checking-cart-btn-default" data-dismiss="modal">NO</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="product_today">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10">
                                    {recommandedProductList.length === 0 ? <><h3></h3></> :
                                        <h3>Suggested products</h3>}
                                </div>
                                {/* <div className="col-md-2">
                                    {recommandedProductList.length == 0 ? <><p></p></> :
                                        <p><a onClick={() => gotosuggestedproductlist()} className="see_more">SHOW MORE</a></p>}
                                </div> */}
                            </div>
                            <div className="row">
                                <>
                                    {recommandedProductList.length === 0 ? <></> :
                                        recommandedProductList.map((product, i) =>
                                        (
                                            <div className="col-md-3">
                                                <div className="select_for-box home_page_dialog">
                                                    <div className="post-select_for_img"> <img src={product.MainImage}
                                                        alt="" className="img-responsive" />
                                                        <div className="post-like_share_product">
                                                            {wishlist.includes(product.SKU) === true &&
                                                                <a onClick={() => addproductinwishlist(product)}><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }}></i></a>}
                                                            {wishlist.includes(product.SKU) === false &&
                                                                <a onClick={() => addproductinwishlist(product)}><i className="fa-regular fa-heart" ></i></a>}
                                                            <a data-toggle="modal" data-target={`#example-sharemodel${product.SKU}`} onClick={() => { getproductdataforshare(product) }}><i className="fa-solid fa-share"></i></a>
                                                        </div>
                                                    </div>
                                                    <div className="post-select_for_text">
                                                        <p><a href={`/product/${product.SKU}`}>{product.Name}</a></p>
                                                        <ul className="list-unstyled display_flex space_between2">
                                                            <li><a href={`/product/${product.SKU}`} className="post-real_price">{product.COO === "USA" ? "$" : "₹"}{product.SalePrice}</a></li>
                                                            <li><span className="post-old_price">{product.COO === "USA" ? "$" : "₹"}{product.RetailPrice}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        )
                                    }</>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {reshare === true ? (
                <Productshare productdata={shareddata} pkey={"productdetails"} />
            ) : <></>}
            <Footer />
            <div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>

            <div className="gif-popup" style={{ display: showmodal ? "block" : "none" }}>
                <div className="popup-containt">
                    <div className="popup-animation-wrapp">
                        <div className="popup-animationimg-wrapp">
                            <img src="/images/gif/celebration.gif" className="celebrationanimation" alt=""/>
                            <img src="/images/gif/coin-animation.gif" className="coinanimation" alt=""/>
                            <div className="coincounshow">
                                <h5>
                                    Congratulation<br />
                                    {`You can earn ${coinvalue} Jesssu`}<br />
                                    coins on this purchase
                                </h5>
                                <button className="ok-btn" onClick={closejessucoinpopup}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Cart;