import * as types from "./actionTypes";

export function user_signin(payload) {
  return {
    type: types.USER_SIGNIN,
    payload: payload,
  };
}

export function user_social_feed(payload) {
  return {
    type: types.USER_SOCIAL_FEED,
    payload: payload,
  };
}
export function user_recommended_users(payload) {
  return {
    type: types.USER_SOCIAL_FEED,
    payload: payload,
  };
}

export function update_firebase_tokenid_flag(paylaod){
  return {
    type: types.FIREBASE_TOKENID_SAVE_SUCCESS,
    paylaod: paylaod
  }
}

export function user_profile_image_upload(payload) {
  return {
    type: types.USER_PROFILE_IMAGE_UPLOAD,
    payload: payload,
  };
}

export function user_get_followercount(followercount){
  return{
    type: types.USER_FOLLOWER_COUNT,
    payload: followercount
  }
}

export function user_get_followingcount(followingcount){
  return{
    type: types.USER_FOLLOWING_COUNT,
    payload: followingcount
  }
}
export function user_allfeedcount(payload) {
  return {
    type: types.USER_ALL_FEEDCOUNT,
    payload: payload,
  };
}

export function notification_message_set(payload) {
  return {
    type: types.NOTIFICATION_MESSAGE,
    payload: payload,
  };
}

export function notification_message_status(payload) {
  return {
    type: types.NOTIFICATION_SUCCESS,
    payload: payload,
  };
}

export function get_friend_list_success(payload) {
  return {
    type: types.FRIEND_LIST_SUCCESS,
    payload: payload,
  };
}

export function get_wishlist_list_success(payload) {
  return {
    type: types.USER_WISHLIST_PRODUCT_DATA_SUCCESS,
    payload: payload,
  };
}

export function get_look_list_success(payload) {
  return {
    type: types.USER_LOOK_DATA_SUCCESS,
    payload: payload,
  };
}

export function get_product_data_success(payload){
  return {
    type: types.PRODUCT_DETAILS_SUCCESS,
    payload: payload,
  };
}
export function get_notification_count_action(payload){
  return {
    type: types.NOTIFICATION_COUNT,
    payload: payload,
  };
}

export function get_cart_value_success(payload){
  return {
    type: types.GET_CART_VALUE_SUCCESS,
    payload: payload,
  };
}

export function updateSocialCountforToogleBox(payload){
  return {
    type: types.UPDATE_TOGGLE_BOX_SOCIAL_COUNT_SUCCESS,
    payload: payload,
  };
}

export function new_search(payload){
  return{
    type: types.NEW_SEARCH_SUCCESS,
    payload: payload
  }
}

export function update_category(payload){
  return{
    type: types.CATEGORY_LIST_SUCCESS,
    payload: payload
  }
}

export function update_brand(payload){
  return{
    type: types.BRAND_LIST_SUCCESS,
    payload: payload
  }
}

