/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_notifications, get_notification_count, read_notification } from "../../store/account/api"
import { useNavigate } from 'react-router-dom';
import { FriendSideBar, Header, ProfileSideBar } from "components";
import './style.scss'

function ProfileNotification() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [notifications, setNotifications] = useState([]);
    const state_account = useSelector(state => state.account);
    let notificationidlist = [];
    let offset = 0;
    const count = 100;


    useEffect(() => {
        if(state_account.profile.email===""){
            history('/login')
        }
        let isMounted = true;
        if (isMounted) get_notification_list();
        return () => { isMounted = false };
    }, []);

    const get_notification_list = () => {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }
        dispatch(get_notifications(userpayload, offset, count, (res) => {
            if (res.status === 200) {
                for (let i = 0; i < res.data.length; i++) {
                    setNotifications(current => [...current, res.data[i]])
                }
                createnotificationidlist(res.data)

            }
        }));
    }
    const createnotificationidlist = (data) => {
        notificationidlist = [];
        for (let i = 0; i < data.length; i++) {
            notificationidlist.push(data[i]._id)
        }
        seennotification();
    }
    const seennotification = () => {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "notificationIds": notificationidlist
        };
        dispatch(read_notification(userpayload, (res) => {
            if (res.status === 200) {
                getnotificationcount()
            }
        }));
    }
    const getnotificationcount = async () => {
        let payload = {
            "token": state_account.profile.token,
            "email": state_account.profile.email,
        }
        dispatch(get_notification_count(payload, (res) => {
        }))
    }
    window.onscroll = function () {
        if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)) {
            offset = offset + 1
            get_notification_list();
        }
    }

  

    return (
        <>
            <div className="thetop"></div>
            <Header />

            <div className="stash_my_look_area">
                <div className="container">
                    <div className="row">
                        <ProfileSideBar />
                        <div className="col-md-6">
                            <div className="nortification_area">
                                {/* <div className="container"> */}
                                    <div className="heading text-center">Notification</div>
                                    <div className="all_nortification">
                                        {notifications.length === 0 ? <div>No more notifications</div> :
                                            notifications.map((i, key) => (
                                                <div className="detch" key={key}>
                                                    {/* <div className="display_flex align_item_center"> */}
                                                    <div className="full-width display_flex notification_bg">
                                                        <div className="nortification_img notifications_img">
                                                            <img src={`${i.image
                                                                ? i.image
                                                                : "/images/onboarding/man.png"
                                                                }`} alt="" className="name_img" />
                                                            {/* <img src={`${i.image ? i.image : "/images/onboarding/man.png"}`} alt="" /> */}
                                                        </div>
                                                        <div>
                                                            <p className="all_nortification_name">{i.message}</p>
                                                            <p>{new Intl.DateTimeFormat('en-GB', {
                                                                month: 'long',
                                                                day: '2-digit',
                                                                year: 'numeric',
                                                            }).format(new Date(i.time))}</p>
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                            )
                                            )
                                        }
                                    </div>
                                {/* </div> */}
                            </div>
                        </div>
                        <FriendSideBar />
                    </div>
                </div>
            </div>
            <div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
        </>
    )
}

export default ProfileNotification;