// import { async } from "@firebase/util";
import axios from "axios"
import { jesssuApi } from '../../config';
import {get_all_share_shop}from "./socialActions"

export const jesssu_get_all_shares =
    (payload, pageNo, size, callback) => async (dispatch) => {

        axios.post(`${jesssuApi}/api/user/getallsharedfeed/${pageNo}/${size}`, payload)
            .then(res => {

                callback({ success: true, data: res.data, status: res.status });
                dispatch(get_all_share_shop(res.data, payload))
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_posted_shares =
    (payload, pageNo, size, callback) => async (dispatch) => {

        axios.post(`${jesssuApi}/api/user/getallpostedsharedfeed/${pageNo}/${size}`, payload)
            .then(res => {

                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_received_shares =
    (payload, pageNo, size, callback) => async (dispatch) => {

        axios.post(`${jesssuApi}/api/user/getallreceivedsharedfeed/${pageNo}/${size}`, payload)
            .then(res => {

                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_like =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/likeasharefeed/`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_dislike =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/dislikeasharefeed/`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_comments =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getcomments/`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_shares =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/reshareafeed/`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }
export const create_new_post =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/shareapost`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_comments_for_sharefeed =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/commentonasharefeed`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_comments_for_single_post =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getcomments`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const jesssu_get_reshares =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/reshareafeed`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const jesssu_get_shared_dislike =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/dislikeasharefeed`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const jesssu_get_shared_like =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/likeasharefeed`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const share_a_new_product =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/shareanewproduct`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const share_a_look =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/shareabundle`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const get_user_timeline =
    (payload,pageNo, size, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getusertimeline/${pageNo}/${size}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const archived_a_share_by_user =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/archive/share/user`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const get_single_share_details =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getsingleshare`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const get_single_share_like_details =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getsharelike`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const get_single_share_dislike_details =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getsharedislike`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const get_single_share_comment_details =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getcomments`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const get_single_share_shareby_details =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getsharedbyuserdetails`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const get_single_share_bonus_details =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getsharebonus`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }


    export const report_abuse =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/add/abusereport`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }