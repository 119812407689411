/* eslint-disable */
import React from 'react'


function Stashnewlook() {
	return (
		<>

			<div className="thetop"></div>

			<div className="navbar margin_none home_position navigation_menu navbar_area" role="navigation">
				<div className="container">
					<div className="navbar-header">
						<button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
						<a className="navbar-brand" ><img src="images/logo.png" alt="" className="navbar_brand_img" /></a>
					</div>
					<div className="navbar-collapse collapse">
						<ul className="nav navbar-nav">
							<li className="active"><a >Discover</a></li>
							<li className="active"><a href="#about">Activity</a></li>
							<li className="active"><a href="#portfolio">Social </a></li>
							<li className="active"><a href="#contact">Stash</a></li>
						</ul>
						<ul className="nav navbar-nav navbar-right mobile_flex">
							<li className="active"><a ><i className="fa-solid fa-magnifying-glass"></i></a></li>
							<li className="active"><a href="#about"><i className="fa-solid fa-cart-arrow-down"></i></a></li>
							<li className="active"><a href="#portfolio"><i className="fa-solid fa-user-large"></i></a></li>
							<li className="active"><a href="#contact"><i className="fa-solid fa-bell"></i></a></li>
						</ul>
					</div>
				</div>
			</div>




			<div className="stash_my_look_area">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="name_area">
								<div className="display_flex">
									<div className="name_img_area"><img src="images/home/cate_for1.jpg" alt="" className="name_img" />
									</div>
									<div className="name_content">
										<p className="name_content_heading">Roger Fox</p>
										<p><i className="fa-solid fa-circle-dollar-to-slot"></i> 100 Jesssu coins</p>
									</div>
								</div>
							</div>
							<div className="name_area">
								<div className="display_flex profile_list">
									<i className="fa-regular fa-circle-user"></i>
									<p><a >Profile Details</a></p>
								</div>
								<div className="display_flex profile_list">
									<i className="fa-solid fa-users"></i>
									<p><a >Friends</a></p>
								</div>
								<div className="display_flex profile_list">
									<i className="fa-regular fa-star"></i>
									<p><a >Jesssu Coins</a></p>
								</div>
								<div className="display_flex profile_list">
									<i className="fa-solid fa-shirt"></i>
									<p><a >Categories</a></p>
								</div>
								<div className="display_flex profile_list">
									<i className="fa-solid fa-wand-magic"></i>
									<p><a >Brands</a></p>
								</div>
								<div className="display_flex profile_list">
									<i className="fa-solid fa-heart"></i>
									<p><a >Personalization</a></p>
								</div>
								<div className="display_flex profile_list">
									<i className="fa-solid fa-bell"></i>
									<p><a >Notification</a></p>
								</div>
								<div className="display_flex profile_list">
									<i className="fa-solid fa-people-arrows-left-right"></i>
									<p><a >Refer & Earn</a></p>
								</div>
							</div>

						</div>
						<div className="col-md-6">
							<div className="wishlist_area">
								<div>
									<div className="row">
										<div className="col-md-8">
											<div className="wishlist_box">

												<ul className="nav nav-pills nav-pills-justified display_flex space_between2">
													<li className="active"><a href="#home" data-toggle="tab">My Wishlist</a></li>
													<li><a href="#about" data-toggle="tab">My Look</a></li>
												</ul>
											</div>

										</div>
										<div className="col-md-4">
											<div className="wish_button">
												<a className="buy_now_btn buy_now_btn_bg">
													<i className="fa-solid fa-plus"></i> Create</a>
											</div>
										</div>
									</div>


									<div className="tab-content">
										<div role="tabpanel" className="tab-pane active" id="home">
											<div className="classNameic_container">
												<div className="classNameic_height">
													<div className="classNameic_formate">
														<p className="formal_btn"><a >classNameic Formals</a></p>

														<div className="classNameic_right_icon">
															<ul className="list-unstyled">
																<li><a ><i className="fa-solid fa-share-nodes"></i></a></li>
																<li><a ><i className="fa-solid fa-images"></i></a></li>
																<li><a ><i className="fa-regular fa-message"></i></a></li>
																<li><a ><i className="fa-solid fa-trash"></i></a></li>
															</ul>
														</div>
														<div className="classNameic_formate_scroll">
															<div className="row">
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="classNameic_formate">
														<p className="formal_btn"><a >classNameic Formals</a></p>

														<div className="classNameic_right_icon">
															<ul className="list-unstyled">
																<li><a ><i className="fa-solid fa-share-nodes"></i></a></li>
																<li><a ><i className="fa-solid fa-images"></i></a></li>
																<li><a ><i className="fa-regular fa-message"></i></a></li>
																<li><a ><i className="fa-solid fa-trash"></i></a></li>
															</ul>
														</div>
														<div className="classNameic_formate_scroll">
															<div className="row">
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
															</div>
															<div className="row">
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="classNameic_img_area">
																		<img src="images/home/new-arrival-1.png" alt="" className="classNameic_img" />
																	</div>
																</div>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
										<div role="tabpanel" className="tab-pane" id="about">
										</div>
									</div>

								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="related_product">
								<p className="related_product_heading">Related Product</p>
								<div className="classNameic_container">
									<div className="classNameic_height">
										<div className="row">
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>

										</div>

										<div className="row">
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>

										</div>

										<div className="row">
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>

										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>

										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="select_for-box home_page_dialog">
													<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
														<div className="like_share_product">
															<a ><i className="fa-regular fa-heart"></i></a>
														</div>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="footer_first"> <img src="images/logo.png" alt="" className="img-responsive footer-logo" />
								<p className="footer_sub_heading">Shop Share & Self</p>
								<p>Follow us on:</p>
								<ul className="list-unstyled display_flex">
									<li><a ><i className="fa-brands fa-facebook-square"></i></a></li>
									<li><a ><i className="fa-brands fa-twitter-square"></i></a></li>
									<li><a ><i className="fa-brands fa-linkedin"></i></a></li>
									<li><a ><i className="fa-brands fa-instagram-square"></i></a></li>
								</ul>
							</div>
						</div>
						<div className="col-md-3"></div>
						<div className="col-md-3">
							<p className="footer_sub_heading padding-bottom-20">Contact us</p>
							<ul className="list-unstyled footer_link">
								<li><a >About us</a></li>
								<li><a >Mission</a></li>
								<li><a >Vision</a></li>
								<li><a >Products</a></li>
								<li><a >Services</a></li>
								<li><a >support@edgewrapper.com</a></li>
							</ul>
						</div>
						<div className="col-md-3">
							<p className="footer_sub_heading padding-bottom-20">Download the app on</p> <img src="images/home/Google_Play_Store_badge_EN.svg.png" alt="" className="footer_store" /> <img src="images/home/apple-app-store-logo-500x173 copy.png" alt="" className="footer_store" /> </div>
					</div>
					<div className="footer_copyright">
						<a href='https://www.edgewrapper.com/'>Powered by Edgewrapper Technology Private Limited©2020-2024.</a>
					</div>
				</div>
			</div>

			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
			{/*<script src="js/jquery.min.js"></script>
		<script src="js/popper.min.js"></script>
		<script src="owlcarousel/owl.carousel.js"></script>
		<script src="js/bootstrap.min.js"></script>
		<script src="js/script.js"></script>*/}


		</>
	)
}

export default Stashnewlook;