/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { create_new_post, share_a_new_product, share_a_look } from "../../store/social/api"
// import { get_wishlist, get_mylook_list } from "../../store/stash/api"
// import { get_friend_list } from 'store/friend/api'
// import $ from "jquery";
import { notification_message_set, notification_message_status } from "../../store/account/accountActions"
// import { jesssu_user_profileimage_upload } from "../../store/account/api"
import { useNavigate } from 'react-router-dom';
// import ScriptTag from 'react-script-tag';
// import { Link } from "react-router-dom";
import "./style.css"

const CreatePost = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [imageurl, setImageUrl] = useState("");
    const [note, setNote] = useState('');
    // const [selecteduser, setSelectedUser] = useState([]);
    // const [mylook, setMyLook] = useState([]);
    const state_account = useSelector(state => state.account);
    const [selectlookdata, setselectlookdata] = useState(typeof any)
    // const [state_account.userfriendlist, setFriendList] = useState(state_account.userfriendlist);
    const [shareuserselection, setshareuserselection] = useState("Share With")
    // const [innermodal, setinnermodal] = useState(false)
    const [sharetype, setsharetype] = useState("Post")
    const [selectproductdata, setselectproductdata] = useState(typeof any)
    const [selectedfriendlist, setselectedfriendlist] = useState([])
    const [error, seterror] = useState(false)
    const [uploadimage, setuploadimage] = useState(false)
    const timeOptions = [
        "5 mins", "10 mins", "20 mins", "30 mins"
    ]
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOption, setSelectedOption] = useState('5 mins');
    const handleInputChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const handleOptionChange = (option) => {
        setSelectedOption(option);
        setShowDropdown(!showDropdown)
    };
    async function convertToMilliseconds(str) {
        const regex = /\d+/g; // Regular expression to match numbers
        const numberArray = str.match(regex).map(Number); // Extract numbers and convert them to actual numbers

        if (numberArray.length > 0) {
            const firstNumber = numberArray[0];
            const milliseconds = firstNumber * 60000;
            return milliseconds;
        } else {
            return null; // Return null if no numbers are found in the string
        }
    }
    function reset() {

        setImageUrl("")
        setNote("")
        setshareuserselection("Share With")
        setsharetype("Post")
        setselectproductdata(typeof any)
        setselectedfriendlist([])
        $(".chkFriend").removeAttr('checked');
    }

    useEffect(() => {
        // updatefriendlist();
        // getfriendlist();
    }, []);


    function userselectionupdate(data) {
        setshareuserselection(data)
        seterror(false)
    }
    function getselectedfriend(data, e) {
        if (e.target.checked) {
            setselectedfriendlist(current => [...current, data.friendemail[0]])
        }
        else {
            setselectedfriendlist((prevState) =>
                prevState.filter((prevItem) => prevItem !== data.friendemail[0])
            );
        }
    }
    function Lookselect(data) {
        setuploadimage(false)
        setselectlookdata(data)
        setsharetype("Look")
    }
    function Productselect(data) {
        setuploadimage(false)
        setselectproductdata(data)
        setsharetype("Product")
    }
    function Selectpost(data) {
        setuploadimage(false)
        setsharetype(data)
        setselectproductdata(typeof any)
        setselectlookdata(typeof any)
    }
    // const upload_profile_image = (e) => {
    //     if (e) {
    //         setuploadimage(false)
    //         const formData = new FormData();
    //         formData.append(
    //             "file",
    //             e.target.files[0]
    //         );
    //         jesssu_user_profileimage_upload(formData, (res) => {
    //             setImageUrl(res.data)
    //         })
    //     }
    // }
    const handleChange = (e) => {
        if (e.target.id === "note") {
            setNote(e.target.value)
        }
    };
    async function share() {
        let sharedtovalue = []
        if (shareuserselection === "Public") {
            sharedtovalue = []
            if (sharetype === "Post") {
                if (imageurl !== "") {
                    await shareapost(sharedtovalue)
                } else {
                    setuploadimage(true)
                }
            } else if (sharetype === "Product") {
                await shareaproduct(sharedtovalue, selectproductdata)
            } else if (sharetype === "Look") {
                await sharealook(sharedtovalue, selectlookdata)
            }
        } else if (shareuserselection === "Friends") {
            // state_account.userfriendlist.map(i => {
            //     sharedtovalue.push(i.friendemail[0])
            // })
            for (let i = 0; i < state_account.userfriendlist.length; i++) {
                sharedtovalue.push(state_account.userfriendlist[i].friendemail[0]);
              }
            if (sharetype === "Post") {
                if (imageurl !== "") {
                    await shareapost(sharedtovalue)
                } else {
                    setuploadimage(true)
                }
            } else if (sharetype === "Product") {
                await shareaproduct(sharedtovalue, selectproductdata)
            } else if (sharetype === "Look") {
                await sharealook(sharedtovalue, selectlookdata)
            }
        } else if (shareuserselection === "Specific Friends") {
            sharedtovalue = selectedfriendlist
            if (sharetype === "Post") {
                if (imageurl !== "") {
                    await shareapost(sharedtovalue)
                } else {
                    setuploadimage(true)
                }
            } else if (sharetype === "Product") {
                await shareaproduct(sharedtovalue, selectproductdata)
            } else if (sharetype === "Look") {
                await sharealook(sharedtovalue, selectlookdata)
            }
        }
        else {
            seterror(true)
        }
    }
    async function shareapost(sharedtovalue) {
        var payload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            'sharedto': sharedtovalue,
            'notes': note,
            'shareimage': imageurl,
            'name': "Jesssu",
        }
        dispatch(create_new_post(payload, (res) => {
            if (res.status === 200) {
                dispatch(notification_message_status(true))
                dispatch(notification_message_set("Your post has been shared successfully"))
                // history('/social');
            }
        }));
    }
    function gotostash() {
        setTimeout(function () {
            history("/stash")
        }, 1000);

    }
    async function shareaproduct(sharedtovalue, product) {
        let time = await convertToMilliseconds(selectedOption)
        const payload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "notes": note,
            "sharedto": sharedtovalue,
            "productid": product.item_id[0],
            "likes": [],
            "dislikes": [],
            "timeLimit": time
        }
        dispatch(share_a_new_product(payload, (res) => {
            if (res.status === 200) {
                dispatch(notification_message_status(true))
                dispatch(notification_message_set("Your product has been shared successfully"))              // setShowNotification(true);
                closenotification()
            }
        }))
    }

    function closenotification() {
        setTimeout(function () {
            dispatch(notification_message_status(false))
            dispatch(notification_message_set(""))
        }, 5000);
    }
    async function sharealook(sharedtovalue, look) {
        let time = await convertToMilliseconds(selectedOption)
        const payload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "notes": note,
            "sharedto": sharedtovalue,
            "backgroundimage": look.backgroundimage,
            "bundleproducts": look.productdetails,
            "bundleid": look.bundleid,
            "likes": [],
            "dislikes": [],
            "timeLimit": time
        }
        dispatch(share_a_look(payload, (res) => {
            if (res.status === 200) {
                dispatch(notification_message_status(true))
                dispatch(notification_message_set("Your look has been shared successfully"))
                closenotification()
                // setShowNotification(true);
            }
        }))
    }
    const closeFriendPopup = () => {
        $('#specific_post_alert_friends').modal('hide');
    };
    return (<>
        {/* <!-- Slider Section Closed Here--> */}
        <a href='#' data-toggle="modal" data-target="#create_post_alert" className="btn btn-color" onClick={() => reset()}><i className="fa-solid fa-plus"></i> Create</a>
        <div className="modal fade" id="create_post_alert" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="create_post_area post_area modal-lg modal-dialog offer_dialog modal-md">
                <div className="modal-content feed_modal_content">
                    <button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
                    <div className="modal-body">
                        <form method="post">
                            <div className="row">
                                <div className="col-md-8">
                                    {sharetype === "Post" &&
                                        <h4 className="create_post_heading">Create Post</h4>}
                                    {sharetype === "Product" &&
                                        <h4 className="create_post_heading">Share Wishlist Product</h4>}
                                    {sharetype === "Look" &&
                                        <h4 className="create_post_heading">Share Your Look</h4>}
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* <!-- Upload  --> */}
                                            {sharetype === "Post" &&
                                                <div id="file-upload-form" className="uploader">
                                                    {/* <input id="file-upload" type="file" name="fileUpload" accept=".png, .jpg, .jpeg" /> */}
                                                    <label htmlFor="file-upload" className="" id="file-drag">
                                                        {imageurl === "" || imageurl === null ? <><div id="start">
                                                            {/* <i className="fa fa-download" aria-hidden="true"></i> */}
                                                            <div>Select item from
                                                                your wishlist or select a look for create a post</div>
                                                            {/* <div id="notimage" className="hidden">Please select an image</div>
                                                            <span id="file-upload-btn" className="btn btn-primary">Select a
                                                                file</span> */}
                                                        </div></> : <img id="file-image" src={imageurl} alt="Preview" />
                                                        }
                                                        <div id="response" className="hidden">
                                                            <div id="messages"></div>
                                                            <progress className="progress" id="file-progress" value="0">
                                                                <span>0</span>%
                                                            </progress>
                                                        </div>
                                                    </label>
                                                </div>}
                                            {sharetype === "Product" &&
                                                <div className="post_nav_content" style={{ "height": "160px", "width": "100%" }} >
                                                    <img src={`${selectproductdata.other_image_url[0]}`}
                                                        alt="" className="img-responsive" />
                                                </div>}
                                            {sharetype === "Look" &&
                                                <div className="post_nav_content" style={{ "height": "160px", "width": "100%" }}>
                                                    <div className="mylook_post">
                                                        <div data-toggle="buttons">
                                                            <label className="btn btn-default social-look-section-wrapp">
                                                                <div className="row social-look-section">
                                                                    {selectlookdata.productdetails.length === 0 ? <></> :
                                                                        // selectlookdata.productdetails.map((data, i) => (
                                                                        //     <div className="col-md-6 col-xs-6" key={i}>
                                                                        //         <div className="post_select_for_img1">
                                                                        //             <img src={`${data.productimage}`}
                                                                        //                 alt="" className="img-responsive" />
                                                                        //         </div>
                                                                        //     </div>))
                                                                        <div>
                                                                            {selectlookdata.layout === "2-2-1" ?
                                                                                <div className="page2_right_area" style={{ backgroundImage: selectlookdata.backgroundimage === "" || selectlookdata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${selectlookdata.backgroundimage})` }}>
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 col-xs-6">
                                                                                            <div className="classic_img_area">
                                                                                                {selectlookdata.productdetails[0] !== null &&
                                                                                                    // <Link to={`/product/${selectlookdata.productdetails[0].productid}`}>
                                                                                                    <img src={selectlookdata.productdetails[0].productimage} alt="" className="classic_img" />}
                                                                                                {/* </Link>}  */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6 col-xs-6">
                                                                                            <div className="classic_img_area">
                                                                                                {selectlookdata.productdetails[1] !== null &&
                                                                                                    // <Link to={`/product/${selectlookdata.productdetails[1].productid}`}>
                                                                                                    <img src={selectlookdata.productdetails[1].productimage} alt="" className="classic_img" />}
                                                                                                {/* </Link>} */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 col-xs-12">
                                                                                            <div className="classic_img_area">
                                                                                                {selectlookdata.productdetails[2] !== null &&
                                                                                                    // <Link to={`/product/${selectlookdata.productdetails[2].productid}`}>
                                                                                                    <img src={selectlookdata.productdetails[2].productimage} alt="" className="classic_img" />}
                                                                                                {/* </Link>} */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 col-xs-6">
                                                                                            <div className="classic_img_area">
                                                                                                {selectlookdata.productdetails[3] !== null &&
                                                                                                    // <Link to={`/product/${selectlookdata.productdetails[3].productid}`}>
                                                                                                    <img src={selectlookdata.productdetails[3].productimage} alt="" className="classic_img" />}
                                                                                                {/* </Link>}  */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6 col-xs-6">
                                                                                            <div className="classic_img_area">
                                                                                                {selectlookdata.productdetails[4] !== null &&
                                                                                                    // <Link to={`/product/${selectlookdata.productdetails[4].productid}`}>
                                                                                                    <img src={selectlookdata.productdetails[4].productimage} alt="" className="classic_img" />}
                                                                                                {/* </Link>} */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                selectlookdata.layout === "3-2" ?
                                                                                    <div className="page2_right_area" style={{ backgroundImage: selectlookdata.backgroundimage === "" || selectlookdata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${selectlookdata.backgroundimage})` }}>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area">
                                                                                                    {selectlookdata.productdetails[0] !== null &&
                                                                                                        // <Link to={`/product/${selectlookdata.productdetails[0].productid}`}>
                                                                                                        <img src={selectlookdata.productdetails[0].productimage} alt="" className="classic_img" />}
                                                                                                    {/* </Link>}  */}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area">
                                                                                                    {selectlookdata.productdetails[1] !== null &&
                                                                                                        // <Link to={`/product/${selectlookdata.productdetails[1].productid}`}>
                                                                                                        <img src={selectlookdata.productdetails[1].productimage} alt="" className="classic_img" />}
                                                                                                    {/* </Link>}  */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area">
                                                                                                    {selectlookdata.productdetails[2] !== null &&
                                                                                                        // <Link to={`/product/${selectlookdata.productdetails[2].productid}`}>
                                                                                                        <img src={selectlookdata.productdetails[2].productimage} alt="" className="classic_img" />}
                                                                                                    {/* </Link>} */}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area">
                                                                                                    {selectlookdata.productdetails[3] !== null &&
                                                                                                        // <Link to={`/product/${selectlookdata.productdetails[3].productid}`}>
                                                                                                        <img src={selectlookdata.productdetails[3].productimage} alt="" className="classic_img" />}
                                                                                                    {/* </Link>} */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12 col-xs-12">
                                                                                                <div className="classic_img_area">
                                                                                                    {selectlookdata.productdetails[4] !== null &&
                                                                                                        // <Link to={`/product/${selectlookdata.productdetails[4].productid}`}>
                                                                                                        <img src={selectlookdata.productdetails[4].productimage} alt="" className="classic_img" />}
                                                                                                    {/* </Link>} */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : selectlookdata.layout === "4-4" || selectlookdata.layout === "" ?
                                                                                        <div className="page2_right_area four_box" style={{ backgroundImage: selectlookdata.backgroundimage === "" || selectlookdata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${selectlookdata.backgroundimage})` }}>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area">
                                                                                                        {selectlookdata.productdetails[0] !== null &&
                                                                                                            // <Link to={`/product/${selectlookdata.productdetails[0].productid}`}>
                                                                                                            <img src={selectlookdata.productdetails[0].productimage} alt="" className="classic_img" />}
                                                                                                        {/* </Link>} */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {selectlookdata.productdetails[1] !== null &&
                                                                                                        // <Link to={`/product/${selectlookdata.productdetails[1].productid}`}>
                                                                                                        <img src={selectlookdata.productdetails[1].productimage} alt="" className="classic_img" />}
                                                                                                        {/* </Link>} */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {selectlookdata.productdetails[2] !== null &&
                                                                                                        // <Link to={`/product/${selectlookdata.productdetails[2].productid}`}>
                                                                                                        <img src={selectlookdata.productdetails[2].productimage} alt="" className="classic_img" />}
                                                                                                        {/* </Link>} */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {selectlookdata.productdetails[3] !== null &&
                                                                                                        // <Link to={`/product/${selectlookdata.productdetails[3].productid}`}>
                                                                                                        <img src={selectlookdata.productdetails[3].productimage} alt="" className="classic_img" />}
                                                                                                        {/* </Link>}  */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> : <></>}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="second_row margin-bottom-20">
                                                {error === false && uploadimage === false &&
                                                    <p className="text-white">Who can see your post? </p>}
                                                {error === true && uploadimage === false &&
                                                    <p className="text-white" style={{ "color": "Red" }}>Please select who can see your post </p>}
                                                {uploadimage === true &&
                                                    <p className="text-white" style={{ "color": "Red" }}>Please upload image for post or select product or look</p>}
                                                <div className="share_area">
                                                    <ul className="share_list">
                                                        <li>
                                                            <input type="radio" id="p-option" name="selector" />
                                                            <label htmlFor="p-option" className="who_share_icon" onClick={() => userselectionupdate("Public")}>
                                                                <i className="fa-solid fa-globe"></i> Public</label>
                                                            {shareuserselection !== "Public" &&
                                                                <div className="check">
                                                                    <div className="inside"></div>
                                                                </div>}
                                                            {shareuserselection === "Public" &&
                                                                <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                                    <div className="inside"></div>
                                                                </div>}
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="f-option" name="selector" />
                                                            <label htmlFor="f-option" className="who_share_icon" onClick={() => userselectionupdate("Friends")}>
                                                                <i className="fa-solid fa-user"></i> Friends</label>
                                                            {shareuserselection !== "Friends" &&
                                                                <div className="check">
                                                                    <div className="inside"></div>
                                                                </div>}
                                                            {shareuserselection === "Friends" &&
                                                                <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                                    <div className="inside"></div>
                                                                </div>}
                                                        </li>
                                                        <li>
                                                            <input type="radio" id="s-option" name="selector" />
                                                            <label htmlFor="s-option" data-toggle="modal"
                                                                data-target="#specific_post_alert_friends" className="who_share_icon" onClick={() => userselectionupdate("Specific Friends")}>
                                                                <i className="fa-solid fa-user-tag"></i> Specific
                                                                Friends</label>
                                                            {shareuserselection !== "Specific Friends" &&
                                                                <div className="check">
                                                                    <div className="inside"></div>
                                                                </div>}
                                                            {shareuserselection === "Specific Friends" &&
                                                                <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                                    <div className="inside"></div>
                                                                </div>}
                                                            <div className="modal fade" id="specific_post_alert_friends" tabIndex="10"
                                                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="post_area modal-sm modal-dialog offer_dialog">
                                                                    <div className="sub_modal modal-content feed_modal_content">
                                                                        <button type="button" className="close modal_close"
                                                                            id="modal2" onClick={closeFriendPopup}>&times;</button>
                                                                        <div className="modal-body">
                                                                            <p>Select Your Friends !</p>
                                                                            <div className="specific_checkbox_fd">
                                                                                {(state_account.userfriendlist || []).map((data, index) => (
                                                                                    <div className="form-area" key={index}>
                                                                                        <div>
                                                                                            <img src={`${data.profileimage[0] && (data?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0]): {})
                                                                                                ? data.profileimage[0]
                                                                                                : data.gender === undefined ? "images/onboarding/man.png" : data.gender === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                                                }`} alt="" className="specific_user_icon" />

                                                                                            {/* <img src={data.profileimage[0]}
                                                                                                alt=""
                                                                                                className="specific_user_icon" onError={({ currentTarget }) => {
                                                                                                    currentTarget.onerror = null; // prevents looping
                                                                                                    currentTarget.src = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                                                                                                }} /> */}
                                                                                        </div>
                                                                                        <div><label htmlFor={index}>{data.friendfirstname[0]} {data.friendlastname[0]}</label></div>
                                                                                        <div> <input type="checkbox" className="chkFriend" id={index} onChange={(e) => getselectedfriend(data, e)} />
                                                                                        </div>
                                                                                    </div>))}
                                                                                <div className="sub_modal_row display_flex confirm_submit">
                                                                                    <p><a href="#" className="grey_text" id="cancelfriendlist" onClick={closeFriendPopup}>Cancel</a></p>
                                                                                    <p><a href="#" className="red_text" id="selectfriendlist" onClick={closeFriendPopup}>Confirm</a></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="write_message uploader">
                                        <textarea rows="6" cols="10" className="form-control" id="note"
                                            placeholder="message here" onChange={(e) => { handleChange(e) }}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-4 bg_post_color">
                                    <div className="select_from">
                                        <h4 className="create_post_heading">Select from</h4>
                                        <p>Select from your wishlisted or my look items or <a href="#" onClick={() => Selectpost("Post")}>Make your own post</a></p>

                                        <div className="post_nav">
                                            {/* <!-- Nav tabs --> */}
                                            <ul className="nav nav-pills display_flex space_between2 post_tabs">
                                                <li className="active"><a href="#home" data-toggle="tab">My Wishlist</a></li>
                                                <li><a href="#about" data-toggle="tab">My Look</a></li>
                                            </ul>

                                            <div className="tab-content">
                                                <div className="tab-pane active" id="home">
                                                    <div>
                                                        {state_account.userwishlistproducts.length !== 0 ?
                                                            <>
                                                                <div className="row">
                                                                    {state_account.userwishlistproducts.map((i, key) =>
                                                                    (<div className="col-md-6 col-xs-6" key={key}>
                                                                        <div className="btn-group itemcontent">
                                                                            <label className={selectproductdata && selectproductdata?.item_id &&selectproductdata?.item_id[0]=== i?.item_id[0] ?"btn btn-default active":"btn btn-default"} onClick={() => Productselect(i)}>
                                                                                <div className="itemcontent">
                                                                                    {/* <input type="checkbox" name="var_id[]"
                                                                                            autoComplete="off" value="" /> */}
                                                                                    <div className="wishlist_section">
                                                                                        <img src={`${i.other_image_url[0]}`}
                                                                                            alt="" className="wishlist_section_img img-responsive" />
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </div>))}
                                                                </div>
                                                            </> :
                                                            <div className="post_img_icon">
                                                                <img src="images/icons/post-empty.png" alt="" />
                                                                <h4>Empty Wishlist</h4>
                                                                <p>There is no products on your wishlist</p>
                                                                <p onClick={gotostash}><a href="#" data-dismiss="modal">Explore now</a></p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="tab-pane" id="about">
                                                    <div>
                                                        {state_account.userlooklist.length === 0 ?
                                                            <div className="post_img_icon">
                                                                <img src="images/icons/post-empty.png" alt="" />
                                                                <h4>Empty MyLook</h4>
                                                                <p>There is no products on your mylook</p>
                                                                <p onClick={gotostash}><a href="#" data-dismiss="modal">Explore now</a></p>
                                                            </div> :
                                                            state_account.userlooklist.map((look, iLook) => (<>
                                                                <div className="mylook_post" key={iLook}>
                                                                    <div data-toggle="buttons">
                                                                        <label className="btn btn-default social-look-section-wrapp" onClick={() => Lookselect(look)}>
                                                                            <div className="row social-look-section-wrapp">
                                                                                {look.productdetails.length === 0 ? <></> :
                                                                                    <div>
                                                                                        {look.layout === "2-2-1" ?
                                                                                            <div className="page2_right_area" style={{ backgroundImage: look.backgroundimage === "" || look.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${look.backgroundimage})` }}>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {look.productdetails[0] !== null &&
                                                                                                            // <Link to={`/product/${look.productdetails[0].productid}`}>
                                                                                                            <img src={look.productdetails[0].productimage} alt="" className="classic_img" />}
                                                                                                            {/* </Link>} */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {look.productdetails[1] !== null &&
                                                                                                            // <Link to={`/product/${look.productdetails[1].productid}`}>
                                                                                                            <img src={look.productdetails[1].productimage} alt="" className="classic_img" />}
                                                                                                            {/* </Link>} */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-12 col-xs-12">
                                                                                                        <div className="classic_img_area"> {look.productdetails[2] !== null &&
                                                                                                            // <Link to={`/product/${look.productdetails[2].productid}`}>
                                                                                                            <img src={look.productdetails[2].productimage} alt="" className="classic_img" />}
                                                                                                            {/* </Link>} */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {look.productdetails[3] !== null &&
                                                                                                            // <Link to={`/product/${look.productdetails[3].productid}`}>
                                                                                                            <img src={look.productdetails[3].productimage} alt="" className="classic_img" />}
                                                                                                            {/* </Link>} */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {look.productdetails[4] !== null &&
                                                                                                            // <Link to={`/product/${look.productdetails[4].productid}`}>
                                                                                                            <img src={look.productdetails[4].productimage} alt="" className="classic_img" />}
                                                                                                            {/* </Link>} */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            look.layout === "3-2" ?
                                                                                                <div className="page2_right_area" style={{ backgroundImage: look.backgroundimage === "" || look.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${look.backgroundimage})` }}>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-6 col-xs-6">
                                                                                                            <div className="classic_img_area"> {look.productdetails[0] !== null &&
                                                                                                                // <Link to={`/product/${look.productdetails[0].productid}`}>
                                                                                                                <img src={look.productdetails[0].productimage} alt="" className="classic_img" />}
                                                                                                                {/* </Link>} */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 col-xs-6">
                                                                                                            <div className="classic_img_area"> {look.productdetails[1] !== null &&
                                                                                                                // <Link to={`/product/${look.productdetails[1].productid}`}>
                                                                                                                <img src={look.productdetails[1].productimage} alt="" className="classic_img" />}
                                                                                                                {/* </Link>} */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-6 col-xs-6">
                                                                                                            <div className="classic_img_area"> {look.productdetails[2] !== null &&
                                                                                                                // <Link to={`/product/${look.productdetails[2].productid}`}>
                                                                                                                <img src={look.productdetails[2].productimage} alt="" className="classic_img" />}
                                                                                                                {/* </Link>} */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-md-6 col-xs-6">
                                                                                                            <div className="classic_img_area"> {look.productdetails[3] !== null &&
                                                                                                                // <Link to={`/product/${look.productdetails[3].productid}`}>
                                                                                                                <img src={look.productdetails[3].productimage} alt="" className="classic_img" />}
                                                                                                                {/* </Link>} */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-12 col-xs-12">
                                                                                                            <div className="classic_img_area"> {look.productdetails[4] !== null &&
                                                                                                                // <Link to={`/product/${look.productdetails[4].productid}`}>
                                                                                                                <img src={look.productdetails[4].productimage} alt="" className="classic_img" />}
                                                                                                                {/* </Link>}  */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                : look.layout === "4-4" || look.layout === "" ?
                                                                                                    <div className="page2_right_area four_box" style={{ backgroundImage: look.backgroundimage === "" || look.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${look.backgroundimage})` }}>
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                                <div className="classic_img_area">
                                                                                                                    {look.productdetails[0] !== null &&
                                                                                                                        // <Link to={`/product/${look.productdetails[0].productid}`}>
                                                                                                                        <img src={look.productdetails[0].productimage} alt="" className="classic_img" />}
                                                                                                                    {/* </Link>} */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                                <div className="classic_img_area"> {look.productdetails[1] !== null &&
                                                                                                                    // <Link to={`/product/${look.productdetails[1].productid}`}>
                                                                                                                    <img src={look.productdetails[1].productimage} alt="" className="classic_img" />}
                                                                                                                    {/* </Link>} */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                                <div className="classic_img_area"> {look.productdetails[2] !== null &&
                                                                                                                    // <Link to={`/product/${look.productdetails[2].productid}`}>
                                                                                                                    <img src={look.productdetails[2].productimage} alt="" className="classic_img" />}
                                                                                                                    {/* </Link>} */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                                <div className="classic_img_area"> {look.productdetails[3] !== null &&
                                                                                                                    // <Link to={`/product/${look.productdetails[3].productid}`}>
                                                                                                                    <img src={look.productdetails[3].productimage} alt="" className="classic_img" />}
                                                                                                                    {/* </Link>} */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div> : <></>}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <li>
                                                    <div className="time_activity">
                                                        <div className="time_activity">
                                                            <img src="/images/social-share/clock.svg" alt=""/>
                                                            <h5>
                                                                Set a active time for like, dislikes, and comments<br />
                                                                <span>
                                                                    When someone receives a post they can only like or dislike it within the given time
                                                                </span>
                                                            </h5>
                                                        </div>


                                                        <div className="input-box">
                                                            <input
                                                                type="text"
                                                                placeholder="Type a custom value or click for options..."
                                                                value={selectedOption}
                                                                onChange={handleInputChange}
                                                                onClick={() => setShowDropdown(!showDropdown)}
                                                            />
                                                            {showDropdown && (
                                                                <div className="dropdown-options">
                                                                    {timeOptions.map((option, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="dropdown-option"
                                                                            onClick={() => handleOptionChange(option)}
                                                                        >
                                                                            {option}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                </li>
                            <div className="post_submit_btn_section">
                                <button type="button" className="post_submit_btn" value="submit" data-dismiss="modal" onClick={() => { share() }}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        {/* <ScriptTag type="text/javascript"
            src=
            "/js/createpost.js" /> */}

    </>);
}

export default CreatePost;