/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_mylook_list } from "../../store/stash/api"
import MyLookCard from './MyLookCard';
import { Link } from "react-router-dom";
import './look.css'
const Mylook = () => {
    const dispatch = useDispatch();
    const [mylook, setMyLook] = useState([]);
    // const [isloading, setIsLoading] = useState(true);
    const state_account = useSelector(state => state.account);

    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            get_look();
        return () => { isMounted = false };
    }, []);
    const get_look = () => {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_mylook_list(userpayload, (res) => {
            if (res.status === 200) {
                setMyLook(res.data);
            }
        }));
    }

    return (
        <div className="classic_container">
            <div className="classic_height">
                {mylook.length === 0 ? <>
                    <div class="look-empty-img">
                        <img src="/images/my-look.svg" alt="" />
                    </div>
                    <div class="look-empty-heading-section">
                        <p>You have no look right now</p>
                        <h6>Please create your first look to see them here</h6>
                    </div>
                    <div class="explore-look-btn">
                        <Link to={`/createnewlook`} class="save_btn-look ui-link"> Create Look</Link>
                    </div>
                </> :
                    mylook.map((i, key) =>
                        (<MyLookCard i={i} key={key} />))}
            </div>
        </div>);
}
export default Mylook;