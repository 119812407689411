/* eslint-disable */
import React, { useEffect, useState } from "react";
import { jesssu_user_register, get_user_profile_details, googleRecaptchaVerify, jesssu_google_login, jesssu_facebook_login } from "../../store/account/api"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { requestForToken } from "../../firebase";
import {
	user_signin,
} from "../../store/account/accountActions";
import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { notification_message_status } from "../../store/account/accountActions"
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { GoogleCaptchaKey } from "../../config"
const Login = () => {
	const [email, setEmail] = useState("")
	const history = useNavigate();
	const dispatch = useDispatch();
	const state_account = useSelector(state => state.account);
	const [firebasetoken, setFirebaseToken] = useState("");
	const [errMsg, setErrMsg] = useState("");
	const [showNotification, setShowNotification] = useState(false);
	const [showNotificationMessage, setShowNotificationMessage] = useState(false);
	const [disableButton, setDisableButton] = useState(false)
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
	const [triggerReCaptcha, setTriggerReCaptcha] = useState(true)
	const auth = getAuth();
	const handleChange = (e) => {
		if (e.target.id === "txtEmail")
			setEmail(e.target.value);
	};
	useEffect(() => {
		requestForToken().then(val => { setFirebaseToken(val) });
		if (localStorage.getItem('user-info')) {
			const user = JSON.parse(localStorage.getItem('user-info'));
			var userpayload =
			{
				'email': user.email,
				'token': user.token,
			}
			dispatch(get_user_profile_details(userpayload, (res) => {
				if (res.status === 300) {
					window.location.href ="/referral"
				}
				else if (res.status === 200) {
					dispatch(user_signin(res.data))
					if ((res.data.brand.length === 0) || (res.data.category.length === 0)) {
						window.location.href ="/onboarding2"
					}
					else {
						window.location.href = "/social";
					}

				}
			}));
			if (user === null) {
				history("/login")
			}
			else {
				window.location.href ="/shop"
			}
		}
	}, [])

	const handleRecaptchaLoad = () => {
		console.log("reCAPTCHA script loaded successfully");
		setTriggerReCaptcha(false);  // Update the state to indicate reCAPTCHA is loaded
	  };

	const closeNotification = () => {
		setShowNotification(false);
		dispatch(notification_message_status(false))
	}
	function closenotification() {
		setTimeout(function () {
			setShowNotification(false);
			dispatch(notification_message_status(false))
		}, 5000);
	}
	function connect_account() {
		setErrMsg("")
		setDisableButton(true)
		var userpayload =
		{
			'email': email,
			'tokenid': firebasetoken,
			'referalcode': state_account.profile.referalcode,
			'provider': 'Phonelogin',
		}
		dispatch(jesssu_user_register(userpayload, (res) => {
			setDisableButton(false)
			if (res.status === 300) {
				history("/referral")
			}
			else if (res.status === 200) {
				if (res.data === "Facebooklogin") {
					setShowNotification(true)
					setShowNotificationMessage("Please login with Facebook account.")
					closenotification()
				}
				else if (res.data === "Googlelogin") {
					setShowNotification(true)
					setShowNotificationMessage("Please login with Google account.")
					closenotification()
				}
				else {
					history("/otp")
				}
			}
		}));
	}
	const facebooklogin = async () => {
		setErrMsg("")
		const provider = new FacebookAuthProvider();
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user;
				let displayname = user.displayName.split(' ');
				let payload = {
					"email": user.providerData[0].email,
					"tokenid": firebasetoken,
					"firstname": displayname[0],
					"lastname": displayname[1],
					"profileimage": user.photoURL,
					"gender": "",
					"age": "",
					"provider": 'Facebooklogin',
				}
				dispatch(jesssu_facebook_login(payload, (res) => {
					if (res.status === 200) {
						if (res.data === "Googlelogin") {
							setShowNotification(true)
							setShowNotificationMessage("Please login with Google account.")
							closenotification()
						}
						else if (res.data === "Phonelogin") {
							setShowNotification(true)
							setShowNotificationMessage("Please login with email.")
							closenotification()
						}
						else if (res.data.token.length > 0) {
							if (res.data.brand.length > 0) {
								let product = JSON.parse(localStorage.getItem("ShareProduct"))
								if (product !== null) {

									// history(`/product/${product.SKU}`)

								}
								else {
									history("/shop")
								}
							}
							else {
								history("/onboarding")
							}
						}
					}
				}));
			})
			.catch((error) => {
				setErrMsg('Error with facebook method. Try to login with different method')
				console.error(error)
			});
	}

	const googlelogin = async () => {
		setErrMsg("")
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user;
				let displayname = user.displayName.split(' ');
				let payload = {
					"email": user.email,
					"tokenid": firebasetoken,
					"firstname": displayname[0],
					"lastname": displayname[1],
					"profileimage": user.photoURL,
					"gender": "",
					"age": "",
					"provider": 'Googlelogin',
				}
				dispatch(jesssu_google_login(payload, (res) => {
					if (res.status === 200) {
						if (res.data === "Facebooklogin") {
							setShowNotification(true)
							setShowNotificationMessage("Please login with Facebook account.")
							closenotification()
						}
						else if (res.data === "Phonelogin") {
							setShowNotification(true)
							setShowNotificationMessage("Please login with email.")
							closenotification()
						}
						else if (res.data.token.length > 0) {
							if (res.data.brand.length > 0) {
								let product = JSON.parse(localStorage.getItem("ShareProduct"))
								console.log(product)
								if (product !== null) {

									// history(`/product/${product.SKU}`)

								}
								else {
									history("/shop")
								}
							}
							else {
								history("/onboarding")
							}

						}
					}

				}));
			})
			.catch((error) => {
				setErrMsg('Error with google method. Try to login with different method')
			});
	}

	const setTokenFunc = (getToken) => {
		if (!getToken) {
			console.error("reCAPTCHA token is undefined.");
			return;
		}
		try {
			var paylaod =
			{
				'token': getToken,
			}
			dispatch(googleRecaptchaVerify(paylaod, (res) => {
				if (res.status === 200) {
					setRefreshReCaptcha(true)
				}
				else {
					setTriggerReCaptcha(true)
					console.error("reCAPTCHA verification failed: ", res.message);
					setTimeout(() => {
						setTriggerReCaptcha(false);  // Force refresh after a short delay
					}, 1000);
				}
			}));
		}
		catch (error) {
			console.error("Error verifying reCAPTCHA: ", error.message);
		}
	};
	return (
		<>
			{refreshReCaptcha === false ? <div style={{  "display": "flex",
						"justify-content": "center",
						"align-items": "center"}}>
							<img src="images/Jesssu loading.gif" alt="Loading" className="centered-image"/>
							<GoogleReCaptchaProvider reCaptchaKey={GoogleCaptchaKey} onLoad={handleRecaptchaLoad} >
				<GoogleReCaptcha
					className="google-recaptcha-custom-class"
					onVerify={setTokenFunc}
					size="visible"
					refreshReCaptcha={!triggerReCaptcha} // Trigger refresh only when necessary
					

				/>
			</GoogleReCaptchaProvider></div> :
				<div className="login_area">
					<div className="container">

						<div className="row">

							<div className="col-md-1"></div>
							<div className="col-md-4">
								<img src="images/logo.png" alt="" className="login_logo" />
								<div className="login_content">
									<h1 className="h1 text_bold_600">Hello,<span className="">Welcome to Jesssu</span></h1>
									<p>To Login or Sign up, click on google or facebook below</p>
									<form>
										<div className="form-area">
											<p>Enter your email address</p>
											<input id="txtEmail" type="email" className="form-control" placeholder="email@domain.com" onChange={handleChange} />
											<p className="text-center">
												<Link className="refer_code" to="/referral/code">
													Have Referral Code?
												</Link>
											</p>
											<button type="button" disabled={disableButton} className="get_otp" onClick={() => connect_account()}>GET OTP</button>

											{errMsg !== "" && <div className="error_box">
												<h6 className="danger-text">{errMsg}</h6>
												<img src="/images/icons/cross.svg" alt="" className="img-cross" onClick={() => { setErrMsg("") }} />
											</div>}
											<div className="social-login-section">
												<img src="/images/icons/facebooklogin.svg" alt="" onClick={() => { facebooklogin() }} />
												<img src="/images/icons/googlelogin.svg" alt="" onClick={() => { googlelogin() }} />
											</div>
										</div>

									</form>
								</div>
							</div>
							<div className="col-md-1"></div>
							<div className="col-md-5">
								<img src="images/login/login.png" alt="" className="img-responsive" />
							</div>
						</div>
						{showNotification ? (
							<div className="addwishlist-pop-up-box">
								<div className="add-wishlist-row-section">
									<img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
									<h5>{showNotificationMessage}</h5>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>}
		</>
	)
}


export default Login;