/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jesssu_get_reshares } from "../../store/social/api"
// import $ from "jquery";
import { notification_message_set, notification_message_status } from "../../store/account/accountActions"
// import { jesssu_user_profileimage_upload } from "../../store/account/api"
// import { useNavigate } from 'react-router-dom';
const Reshareforhomepage = ({ shareddata, pkey }) => {

    // const history = useNavigate();
    const dispatch = useDispatch();
    // const [imageurl, setImageUrl] = useState("");
    const [note, setNote] = useState('');
    // const [selecteduser, setSelectedUser] = useState([]);
    const state_account = useSelector(state => state.account);
    // const [state_account.userfriendlist, setFriendList] = useState(state_account.userfriendlist);
    const [shareuserselection, setshareuserselection] = useState("Share With")
    // const [innermodal, setinnermodal] = useState(false)
    const sharetype = "Post"
    const [selectedfriendlist, setselectedfriendlist] = useState([])

    useEffect(() => {

    }, []);

    function userselectionupdate(data) {
        setshareuserselection(data)
    }
    function getselectedfriend(data, e) {
        if (e.target.checked) {
            setselectedfriendlist(current => [...current, data.friendemail[0]])
        }
        else {
            setselectedfriendlist((prevState) =>
                prevState.filter((prevItem) => prevItem !== data.friendemail[0])
            );
        }
    }


    const handleChange = (e) => {
        if (e.target.id === "note") {
            setNote(e.target.value)
        }
    };

    async function share() {
        let sharedtovalue = []
        if (shareuserselection === "Public") {
            sharedtovalue = []
            if (sharetype === "Post") {

                await reshareapost(sharedtovalue)

            }
        } else if (shareuserselection === "Friends") {
            // state_account.userfriendlist.map(i => {
            //     sharedtovalue.push(i.friendemail[0])
            // })
            for (let i = 0; i < state_account.userfriendlist.length; i++) {
                sharedtovalue.push(state_account.userfriendlist[i].friendemail[0]);
              }
            if (sharetype === "Post") {

                await reshareapost(sharedtovalue)

            }
        } else if (shareuserselection === "Specific Friends") {
            sharedtovalue = selectedfriendlist
            if (sharetype === "Post") {

                await reshareapost(sharedtovalue)

            }
        }


    }

    async function reshareapost(sharedtovalue) {
        var payload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            'sharedto': sharedtovalue,
            'notes': note,
            'shareid': shareddata._id,
            'name': "Jesssu",
        }
        dispatch(jesssu_get_reshares(payload, (res) => {
            if (res.status === 200) {
                dispatch(notification_message_status(true))
                dispatch(notification_message_set("You have successfully reshared the post"))
            }
        }))
    }
    // async function shareaproduct(sharedtovalue, product) {

    //     const payload = {
    //         "email": state_account.profile.email,
    //         "token": state_account.profile.token,
    //         "notes": note,
    //         "sharedto": sharedtovalue,
    //         "productid": product.item_id[0],
    //         "likes": [],
    //         "dislikes": []
    //     }
    //     dispatch(share_a_new_product(payload, (res) => {
    //         if (res.status == 200) {
    //             dispatch(notification_message_status(true))
    //             dispatch(notification_message_set("Your product has been shared successfully"))              // setShowNotification(true);
    //             closenotification()
    //         }
    //     }))

    // }

    // function closenotification() {
    //     setTimeout(function () {
    //         dispatch(notification_message_status(false))
    //         dispatch(notification_message_set(""))
    //     }, 5000);
    // }
    const closeFriendPopup = () => {
        $('#specific_post_alert').modal('hide'); // Using jQuery to hide the modal
    };
    return (<>
        {/* <li><a data-toggle="modal" data-target={`#create_post_alertreshare${shareddata._id}`}><i className="fa-solid fa-share"></i></a></li> */}
        {pkey != null ? <><a href="#" data-toggle="modal" data-target={`#create_post_alertreshare${shareddata._id}`} key={shareddata._id}></a></> : <li><a href="#" data-toggle="modal" data-target={`#create_post_alertreshare${shareddata._id}`} key={shareddata._id}><i className="fa-solid fa-share"></i></a></li>}
        <div className="modal fade" id={`create_post_alertreshare${shareddata._id}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="post_area modal-lg modal-dialog offer_dialog modal-md">
                <div className="modal-content feed_modal_content">
                    <button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
                    <div className="modal-body">
                        <form method="post">
                            <div className="create_post_heading row">
                                <div className="col-md-12">
                                    {sharetype === "Post" &&
                                        <h4 className="create_post_heading">Reshare This Post</h4>}
                                    {sharetype === "Product" &&
                                        <h4 className="create_post_heading">Share Wishlist Product</h4>}
                                    {sharetype === "Look" &&
                                        <h4 className="create_post_heading">Share Your Look</h4>}
                                    <div className="row">
                                        <div className="modal_row">
                                            <div>
                                                {/* <!-- Upload  --> */}
                                                {(sharetype === "Post" && shareddata.type === 1) &&
                                                    <div className="post_nav_content" style={{ "height": "160px", "width": "255px" }}>
                                                        <img src={shareddata.postimage}
                                                            alt="" className="img-responsive" />
                                                    </div>
                                                }
                                                {(sharetype === "Post" && shareddata.type === 3) &&
                                                    <div className="post_nav_content" style={{ "height": "160px", "width": "255px" }}>
                                                        <img src={shareddata.postimage}
                                                            alt="" className="img-responsive" />
                                                    </div>
                                                }
                                                {(sharetype === "Post" && shareddata.type === 6) &&
                                                    <div className="post_nav_content" style={{ "height": "160px", "width": "255px" }}>
                                                        <div>
                                                            {
                                                                shareddata.layout === "3-2" ?
                                                                    <div className="classic_formate" style={{ backgroundImage: shareddata.backgroundimage === "" || shareddata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${shareddata.backgroundimage})` }}>
                                                                        <div>
                                                                            <div className="row">
                                                                                <div className="col-md-5 col-xs-5">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 0 &&
                                                                                            <img src={`${shareddata.bundleproduct[0].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 1 &&
                                                                                            <img src={`${shareddata.bundleproduct[1].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-5 col-xs-5">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 2 &&
                                                                                            <img src={`${shareddata.bundleproduct[2].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-5 col-xs-5">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 3 &&
                                                                                            <img src={`${shareddata.bundleproduct[3].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-5 col-xs-5">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 4 &&
                                                                                            <img src={`${shareddata.bundleproduct[4].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    shareddata.layout === "2-2-1" ?
                                                                        <div className="classic_formate" style={{ backgroundImage: shareddata.backgroundimage === "" || shareddata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${shareddata.backgroundimage})` }}>
                                                                            {/* <div className="classic_formate_scroll1 no_scroll"> */}
                                                                            {/* <div className="row"> */}
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 0 &&
                                                                                            <img src={`${shareddata.bundleproduct[0].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 1 &&
                                                                                            <img src={`${shareddata.bundleproduct[1].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 2 &&
                                                                                            <img src={`${shareddata.bundleproduct[2].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 3 &&
                                                                                            <img src={`${shareddata.bundleproduct[3].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6 col-xs-6">
                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                        {shareddata.bundleproduct.length > 4 &&
                                                                                            <img src={`${shareddata.bundleproduct[4].productimage}`} alt=""
                                                                                                className="classic_img" />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* </div> */}
                                                                            {/* </div> */}
                                                                        </div>
                                                                        : shareddata.layout === "4-4" || shareddata.layout === "" ?

                                                                            <div className="classic_formate" style={{ backgroundImage: shareddata.backgroundimage === "" || shareddata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${shareddata.backgroundimage})` }} >
                                                                                <div className="classic_formate_scroll no_scroll">
                                                                                    <div className="row">
                                                                                        {shareddata.bundleproduct.length === 0
                                                                                            ?
                                                                                            <></>
                                                                                            :
                                                                                            shareddata.bundleproduct.slice(0, 4).map((data, key) => (
                                                                                                <div className="col-md-6 col-xs-6" key={key}>
                                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                        <img src={`${data.productimage}`} alt="" className="classic_img" />
                                                                                                    </div>
                                                                                                </div>)
                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                </div>

                                                                            </div> : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                            <div className="write_message uploader">
                                                <textarea rows="6" cols="10" className="form-control" id="note"
                                                    placeholder="message here" onChange={(e) => { handleChange(e) }}></textarea>
                                            </div>
                                        </div>
                                        <div className="second_row margin-bottom-20">
                                            <p className="text-white">Who can see your post? </p>
                                            <div className="share_area">
                                                <ul className="share_list">
                                                    <li>
                                                        <input type="radio" id="p-option" name="selector" />
                                                        <label htmlFor="p-option" className="who_share_icon" onClick={() => userselectionupdate("Public")}>
                                                            <i className="fa-solid fa-globe"></i> Public</label>
                                                        {shareuserselection !== "Public" &&
                                                            <div className="check">
                                                                <div className="inside"></div>
                                                            </div>}
                                                        {shareuserselection === "Public" &&
                                                            <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                                <div className="inside"></div>
                                                            </div>}
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="f-option" name="selector" />
                                                        <label htmlFor="f-option" className="who_share_icon" onClick={() => userselectionupdate("Friends")}>
                                                            <i className="fa-solid fa-user"></i> Friends</label>
                                                        {shareuserselection !== "Friends" &&
                                                            <div className="check">
                                                                <div className="inside"></div>
                                                            </div>}
                                                        {shareuserselection === "Friends" &&
                                                            <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                                <div className="inside"></div>
                                                            </div>}
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="s-option" name="selector" />
                                                        <label htmlFor="s-option" data-toggle="modal"
                                                            data-target={`#specific_post_alert`} className="who_share_icon" onClick={() => userselectionupdate("Specific Friends")}>
                                                            <i className="fa-solid fa-user-tag"></i> Specific
                                                            Friends</label>
                                                        {shareuserselection !== "Specific Friends" &&
                                                            <div className="check">
                                                                <div className="inside"></div>
                                                            </div>}
                                                        {shareuserselection === "Specific Friends" &&
                                                            <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                                <div className="inside"></div>
                                                            </div>}
                                                        <div className="modal fade" id={`specific_post_alert`} tabIndex="10"
                                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="post_area modal-sm modal-dialog offer_dialog">
                                                                <div className="sub_modal modal-content feed_modal_content">
                                                                    <button type="button" className="close modal_close" data-dismiss="modal"
                                                                        id="modal2" >&times;</button>
                                                                    <div className="modal-body">
                                                                        <p>Select Your Friends !</p>
                                                                        <div className="specific_checkbox_fd">
                                                                            {(state_account.userfriendlist || []).map((data, index) => (
                                                                                <div className="form-area">
                                                                                    <div>
                                                                                    <img src={`${data.profileimage[0] && (data?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0]): {})
                                                                                                ? data.profileimage[0]
                                                                                                : data.gender === undefined ? "images/onboarding/man.png" : data.gender === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                                                }`} alt="" className="specific_user_icon" />
                                                                                        {/* <img src={data.profileimage[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`}
                                                                                            alt=""
                                                                                            className="specific_user_icon" /> */}
                                                                                    </div>
                                                                                    <div><label htmlFor={index}>{data.friendfirstname[0]} {data.friendlastname[0]}</label></div>
                                                                                    <div> <input type="checkbox" id={index} onChange={(e) => getselectedfriend(data, e)} />
                                                                                    </div>
                                                                                </div>))}
                                                                            <div className="display_flex confirm_submit">
                                                                                <p><a href="#" 
                                                                                    className="grey_text" onClick={closeFriendPopup}>Cancel</a></p>
                                                                                <p><a href="#" 
                                                                                    className="red_text" onClick={closeFriendPopup}>Confirm</a></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="post_submit_btn_section">
                                            <button type="button" className="post_submit_btn" value="submit" data-dismiss="modal" onClick={() => { share(), closeFriendPopup() }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Reshareforhomepage;