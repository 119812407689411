import * as types from "./friendTypes";


let friendobj = {
  loaded: false,
  loading: false,
  friendlist: [],
  receivedrequestlist: [],
  sentrequestlist: [],
  recomendedfriendlist: []

};



export default function friendReducer(state = friendobj, { type, payload }) {
  switch (type) {
    case types.FRIEND_LIST:
      return {
        ...state,
        friendlist: (payload !== undefined && payload.length > 0) ? payload : [],
        loaded: true,
        loading: false,
      };

    case types.RECEIVED_REQUEST:
      return {
        ...state,
        receivedrequestlist: (payload !== undefined && payload.length > 0) ? payload : [],
        loaded: true,
        loading: false,
      };

    case types.SENT_REQUEST:
      return {
        ...state,
        sentrequestlist: (payload !== undefined && payload.length > 0) ? payload : [],
        loaded: true,
        loading: false,
      };

    case types.RECOMENDED_FRIEND_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        recomendedfriendlist: payload,
      }

    default:
      return state;
  }
}
