/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_received_request, accept_friends, un_friend, get_friend_list } from "../../store/friend/api"
import { useNavigate } from 'react-router-dom';
const ReceiveRequestCard = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
    // const [friendlist, setFriendList] = useState([]);
    const state_account = useSelector(state => state.account);
    const state_friend = useSelector(state => state.friend);


    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            getreceiverequestlist();
        return () => { isMounted = false };
    }, []);


    const routetotimeline = (i) => {
        history('/timeline', { state: { friendemail: i } });
    }

    const approverequest = (friendemail) => {
        let payload = {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
            'friendemail': friendemail
        }
        dispatch(accept_friends(payload, (res) => {
            if (res.status === 200) {
                getreceiverequestlist();
                getfriendlist();
            }
        }));
    }
    const cancelrequest = (friendemail) => {
        let payload = {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
            'friendemail': friendemail
        }
        dispatch(un_friend(payload, (res) => {
            if (res.status === 200) {
                getreceiverequestlist();
            }
        }));
    }
    const getreceiverequestlist = () => {
        let userpayload =
        {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_received_request(userpayload, (res) => {
            // if (res.status === 200) {
            //     setFriendList(res.data);
            // }
        }));
    }


    const getfriendlist = () => {
        let userpayload =
        {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token
        }
        dispatch(get_friend_list(userpayload, (res) => {
            if (res.status === 200) {
            }
        }));
    }

    return (
        <>
            <div className="classNameic_height">
                {state_friend.receivedrequestlist.length === 0 ? <></> :
                    state_friend.receivedrequestlist.map((i) => (
                        <div className="name_area">
                            <div className="display_flex" onClick={() => { routetotimeline(i.friendemail[0]) }}>
                                <div className="friends_images_section name_img_area">
                                    <img src={`${i.profileimage[0] && (i?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(i.profileimage[0]): {})
                                        ? i.profileimage[0]
                                        : i.gender === undefined ? "/images/onboarding/man.png" : i.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                        }`} alt="" className="name_img" />
                                    {/* <img src={i.profileimage[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${i.profileimage[0]}`} alt="" className="friends_img name_img" /> */}
                                </div>
                                <div className="name_content" onClick={() => { routetotimeline(i.friendemail[0]) }}>
                                    <p><span className="text_bold_600">{i.friendfirstname[0]} {i.friendlastname[0]}</span> wants to add you as friends</p>
                                </div>
                            </div>
                            <ul className="request_area list-unstyled display_flex space_between2">
                                <li><a href="#" onClick={() => { approverequest(i.friendemail[0]) }} className="btn btn-color">Accept</a></li>
                                <li><a href="#" onClick={() => { cancelrequest(i.friendemail[0]) }} className="btn btn-default">Decline</a></li>
                            </ul>
                        </div>
                    )
                    )
                }
            </div>
        </>
    );
}
export default ReceiveRequestCard;