/* eslint-disable */
import { jesssu_user_signin, jesssu_user_register } from "../../store/account/api"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
// import ScriptTag from 'react-script-tag';
// import { get_user_profile_details } from "../../store/account/api"
import { user_signin } from 'store/account/accountActions';
import { notification_message_status } from "../../store/account/accountActions"



const OTP = () => {
	const dispatch = useDispatch();
	const [referralCode1, setreferralCode1] = useState("")
	const [referralCode2, setreferralCode2] = useState("")
	const [referralCode3, setreferralCode3] = useState("")
	const [referralCode4, setreferralCode4] = useState("")
	const [referralCode5, setreferralCode5] = useState("")
	const [referralCode6, setreferralCode6] = useState("")
	const state_account = useSelector(state => state.account);
	const [showNotification, setShowNotification] = useState(false);
	const [showNotificationMessage, setShowNotificationMessage] = useState(false);
	const history = useNavigate();
	function connect_account_otp() {
		let otp = referralCode1 + referralCode2 + referralCode3 + referralCode4 + referralCode5 + referralCode6

		var userpayload =
		{
			'email': state_account.profile.email,
			'password': otp,
		}
		dispatch(jesssu_user_signin(userpayload, (res) => {
			dispatch(user_signin(res.data));

			if (res.success === false) {
				setShowNotification(true)
				setShowNotificationMessage(res.data.data.error)
				closenotification()
				setreferralCode1('')
				setreferralCode2('')
				setreferralCode3('')
				setreferralCode4('')
				setreferralCode5('')
				setreferralCode6('')
			}
			else if (res.data.token.length > 0) {
				if (res.data.brand.length > 0) {
					let product = JSON.parse(localStorage.getItem("ShareProduct"))
					if(product!==null){

						// history(`/product/${product.SKU}`)

					}
					else{

					history("/shop")}
				}
				else {
					history("/onboarding")
				}

			}
		}));
	}
	const closeNotification = () => {
		setShowNotification(false);
		dispatch(notification_message_status(false))
	}
	function closenotification() {
		setTimeout(function () {
			setShowNotification(false);
			dispatch(notification_message_status(false))
		}, 5000);
	}
	function connect_account() {
		var userpayload =
		{
			'email': state_account.profile.email
		}
		dispatch(jesssu_user_register(userpayload, (res) => {
			if (res.status === 300) {
				history("/referral")
			}
			else if (res.status === 200) {
				// otpclock()
				history("/otp")
			}
		}));
	}
	// const get_profile_details = () => {

	// }
	let user = null;
	useEffect(() => {

		if (localStorage.getItem('user-info')) {
			user = JSON.parse(localStorage.getItem('user-info'));
		
		if(user==null){
			history("/login")
		}else{
			history("/shop")
		}}
		if (state_account.profile.email === null || state_account.profile.email === '') {
			history("/login")
		}
		let in1 = document.getElementById('otc-1'),
			ins = document.querySelectorAll('input[type="number"]'),
			splitNumber = function (e) {
				let data = e.data || e.target.value; // Chrome doesn't get the e.data, it's always empty, fallback to value then.
				if (!data) return; // Shouldn't happen, just in case.
				if (data.length === 1) return; // Here is a normal behavior, not a paste action.

				popuNext(e.target, data);
				//for (i = 0; i < data.length; i++ ) {ins[i].value = data[i]; }
			},
			popuNext = function (el, data) {
				el.value = data[0]; // Apply first item to first input
				data = data.substring(1); // remove the first char.
				if (el.nextElementSibling && data.length) {
					// Do the same with the next element and next data
					popuNext(el.nextElementSibling, data);
				}
			};

		ins.forEach(function (input) {
			/**
			 * Control on keyup to catch what the user intent to do.
			 * I could have check for numeric key only here, but I didn't.
			 */
			input.addEventListener('keyup', function (e) {
				// Break if Shift, Tab, CMD, Option, Control.
				if (e.keyCode === 16 || e.keyCode === 9 || e.keyCode === 224 || e.keyCode === 18 || e.keyCode === 17) {
					return;
				}

				// On Backspace or left arrow, go to the previous field.
				if ((e.keyCode === 8 || e.keyCode === 37) && this.previousElementSibling && this.previousElementSibling.tagName === "INPUT") {
					this.previousElementSibling.select();
				} else if (e.keyCode !== 8 && this.nextElementSibling) {
					this.nextElementSibling.select();
				}

				// If the target is populated to quickly, value length can be > 1
				if (e.target.value.length > 1) {
					splitNumber(e);
				}
			});

			/**
			 * Better control on Focus
			 * - don't allow focus on other field if the first one is empty
			 * - don't allow focus on field if the previous one if empty (debatable)
			 * - get the focus on the first empty field
			 */
			input.addEventListener('focus', function (e) {
				// If the focus element is the first one, do nothing
				if (this === in1) return;

				// If value of input 1 is empty, focus it.
				if (in1.value === '') {
					in1.focus();
				}

				// If value of a previous input is empty, focus it.
				// To remove if you don't wanna force user respecting the fields order.
				if (this.previousElementSibling.value === '') {
					this.previousElementSibling.focus();
				}
			});
		});
	}, []);
	const handleChange = (e) => {
		let num1 = "", num2 = "", num3 = "", num4 = "", num5 = "", num6 = "";
		if (e.target.id === "otc-1") {
			num1 = e.target.value;
			setreferralCode1(num1)
		}
		else if (e.target.id === "otc-2") {
			num2 = e.target.value;
			setreferralCode2(num2)
		}
		else if (e.target.id === "otc-3") {
			num3 = e.target.value;
			setreferralCode3(num3)
		}
		else if (e.target.id === "otc-4") {
			num4 = e.target.value;
			setreferralCode4(num4)
		}
		else if (e.target.id === "otc-5") {
			num5 = e.target.value;
			setreferralCode5(num5)
		}
		else if (e.target.id === "otc-6") {
			num6 = e.target.value;
			setreferralCode6(num6)
		}
	};

	return (
		<>
			<div className="login_area">
				<div className="container">

					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-4">
							<img src="images/logo.png" alt="" className="login_logo" />
							<div className="login_content">
								<h1 className="h1 text_bold_600">Hello,<span className="">Welcome back</span></h1>
								<p>We sent a 6 digit OTP to '{state_account.profile.email}'</p>
								<form className="otc-section otc" name="one-time-code">
									<div className="form-area">
										<ul className="list-unstyled display_flex space_between2">
											<li>Enter OTP</li>
											<li id="demo"></li>
										</ul>
										<fieldset>
											<label htmlFor="otc-1">Number 1</label>
											<label htmlFor="otc-2">Number 2</label>
											<label htmlFor="otc-3">Number 3</label>
											<label htmlFor="otc-4">Number 4</label>
											<label htmlFor="otc-5">Number 5</label>
											<label htmlFor="otc-6">Number 6</label>
											<ul className="list-unstyled-section list-unstyled display_flex form_number">
												<input type="number" pattern="[0-9]*" inputtype="numeric"
													autoComplete="one-time-code" id="otc-1" required onChange={(e) => handleChange(e)} value={referralCode1} />
												<input type="number" pattern="[0-9]*" min="0" max="9" maxLength="1"
													inputtype="numeric" id="otc-2" required onChange={(e) => handleChange(e)} value={referralCode2} />
												<input type="number" pattern="[0-9]*" min="0" max="9" maxLength="1"
													inputtype="numeric" id="otc-3" required onChange={(e) => handleChange(e)} value={referralCode3} />
												<input type="number" pattern="[0-9]*" min="0" max="9" maxLength="1"
													inputtype="numeric" id="otc-4" required onChange={(e) => handleChange(e)} value={referralCode4} />
												<input type="number" pattern="[0-9]*" min="0" max="9" maxLength="1"
													inputtype="numeric" id="otc-5" required onChange={(e) => handleChange(e)} value={referralCode5} />
												<input type="number" pattern="[0-9]*" min="0" max="9" maxLength="1"
													inputtype="numeric" id="otc-6" required onChange={(e) => handleChange(e)} value={referralCode6} />
											</ul>
										</fieldset>
										<button type="button" id="validate" className="get_otp" onClick={() => connect_account_otp()}>VALIDATE OTP</button>
										<p id="resend" className="text-center">Didn't get the OTP? <a href="#" className="refer_code" onClick={() => connect_account()} id="resend">Resend OTP</a></p>
									</div>
								</form>
							</div>
						</div>
						<div className="col-md-1"></div>
						<div className="col-md-5">
							<img src="images/login/login-1.png" alt="" className="img-responsive" />
						</div>
					</div>
				</div>
			</div>
			{showNotification ? (
				<div className="addwishlist-pop-up-box">
					<div className="add-wishlist-row-section">
						<img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
						<h5>{showNotificationMessage}</h5>
					</div>
				</div>
			) : (
				<></>
			)}
			{/* <ScriptTag isHydrating={true} type="text/javascript"
				src=
				"/js/otp.js" /> */}

		</>
	)
}

export default OTP;