// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-imgsection {
    display: flex;
    width: 395px;
    height: 395px;
    padding: 10px;
    align-items: center;
}

.product-imgsection img {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/ProductDetails/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".product-imgsection {\n    display: flex;\n    width: 395px;\n    height: 395px;\n    padding: 10px;\n    align-items: center;\n}\n\n.product-imgsection img {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
