/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { share_a_new_product, jesssu_get_reshares } from "../../store/social/api"
// import $ from "jquery";
// import ScriptTag from 'react-script-tag';
// import { notification_message_set, notification_message_status } from "../../store/account/accountActions"
// import { jesssu_user_profileimage_upload } from "../../store/account/api"
// import { useNavigate } from 'react-router-dom';
import { get_single_share_bonus_details } from 'store/social/api';

const Coindetails = ({ productdata, pkey }) => {

    // const history = useNavigate();
    const dispatch = useDispatch();
    // const [imageurl, setImageUrl] = useState("");
    // const [note, setNote] = useState('');
    // const [selecteduser, setSelectedUser] = useState([]);
    const state_account = useSelector(state => state.account);
    const [amount, setamount] = useState(0)


    useEffect(() => {
        getsharebonusamountuserwise()
    }, [productdata]);


    async function getsharebonusamountuserwise() {
        setamount(0)
        var payload =
        {
            'useremail': productdata.sharedbyId[0],
            'shareid': productdata._id,
            'token': state_account.profile.token
        }
        dispatch(get_single_share_bonus_details(payload, (res => {
            if (res.status === 200) {
                if (res.data.length !== 0) {
                    setamount(res.data[0].txnamount)

                } else {
                    setamount(0)
                }

            }
        })));



    }
    return (<>
        {/* <!-- Slider Section Closed Here--> */}
        {/* <a  data-toggle="modal" data-target="#create_post_alert" className="btn btn-color" onClick={() => reset()}><i className="fa-solid fa-plus"></i> Create</a> */}

        {pkey != null ? <></> : <a href='#' data-toggle="modal" data-target={`#coindetails${productdata._id}`}><i className="fa-solid fa-share"></i></a>}

        <div className="modal fade" id={`coindetails${productdata._id}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="post_area modal-lg modal-dialog offer_dialog modal-md" style={{    "text-align": "-webkit-center","padding": "25rem 0rem 0"}}>
                
                <div className="modal-content feed_modal_content" style={{ "width": "430px" }}>
                    <button type="button" className="close modal_close" data-dismiss="modal">&times;</button>

                    <div className="modal-body" style={{ "width": "400px" }}>
                        <p>{productdata.sharedby[0]} earned {amount} Jesssu coin for share this post.</p>
                    </div>
                </div>
            </div>
        </div>

    </>);
}

export default Coindetails;

