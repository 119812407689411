/* eslint-disable */
import { FriendSideBar, Header, ProfileSideBar } from 'components';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { refer_friend } from 'store/account/api';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Referearn = () => {
	const dispatch = useDispatch();
	const state_account = useSelector(state => state.account);
	const [email, setEmail] = useState("");
	const [isinvited, setIsInvited] = useState(false);
	const [isloading, setIsLoading] = useState(false);

	const getinputvalue = (e) => {
		if (e.target.id === "email") {
			setEmail(e.target.value);
		}
	}

	const referfriend = () => {
		setIsLoading(true);
		var userpayload =
		{
			"referpersonemail": email,
			"firstname": state_account.profile.firstname,
			"lastname": state_account.profile.lastname,
			"countrycode": "+91",
			"referalcode": state_account.profile.referalcode,
			"email": state_account.profile.email,
			"token": state_account.profile.token,
			"applink": "https://jesssu.com"
		}
		dispatch(refer_friend(userpayload, (res) => {
			if (res.status === 200) {
				setIsInvited(true)
				setIsLoading(false)
			}
		}));
	}
	return (
		<>
			<div className="thetop"></div>
			<Header />
			<Helmet>
                <title>Invite & Earn | Refer Friends and Get Exclusive Benefits</title>
                <meta name="description" content="Join our referral program on Jesssu! Invite friends, earn Jesssu Coins for every successful sign-up, and unlock exclusive rewards. Share your unique link and start earning today!" />
                <meta name="keywords" content="refer, invite & earn" />
            </Helmet>
			<div className="stash_my_look_area">
				<div className="container">
					<div className="row">
						<ProfileSideBar />
						<div className="col-md-6">
							<div className="profile_area">
								<div className="refer_earn">
									<LazyLoadImage src="/images/home/refer_earn.png" alt="Refer and earn icon" className="img-responsive" />
									<p><a>  </a></p>
									<p><a  className="coin_btn" data-toggle="modal" data-target="#exampleModal2">Refer Now </a></p>

									<p className="text_bold_600">Earn 100 Jesssu Coin</p>
									<p>Refer your friends to Jesssu via <span> WhatsApp / SMS / Email and Earn 100 Jesssu Coins</span> </p>
									<p className="text_bold_600">Referal Code</p>
									<p><a  className="referal_code">{state_account.profile.referalcode}</a></p>
									<div className="modal fade" id="exampleModal2" tabindex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
										<div className="feed_details modal-dialog offer_dialog modal-md">
											<div className="modal-body">
												<div className="friends-timeline-pop-up">
													<div className="stash-pop-up-section">'
														<div className="stash-pop-up-box">
															<button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
															<div className="referemail-section">
																<h6>Enter Recipient's Email id</h6>
															</div>
															<div className="row">
																<div className="col-md-2"></div>
																<div className="col-md-8">
																	<div className="onboarding-form-group">
																		<input type="text" placeholder="name@email.com" className="emailrefer-form-control" id="email" onChange={(e) => getinputvalue(e)} />
																	</div>
																</div>
															</div>
															<div className="email-send-section">
																<div className="row">
																	<div className="col-md-2"></div>
																	<div className="col-md-8">
																		<button type="button" className="emailrefer-get_otp" onClick={() => { referfriend() }} >{isloading?"Sending Invitation...":isinvited?"Invitaion Sent":"SEND"}</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<FriendSideBar />
					</div>
				</div>
			</div>
			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
		</>
	)
}

export default Referearn;