// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .empty-box {
    background: #fff;
    padding: 60px;
    border-radius: 10px;
    border: 1px solid #fff;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 30px;
    margin-bottom: 30px;
}
.wishlist-empty-img{
    text-align: center;
    padding: 4rem 0 0;
}
.wishlist-empty-img img {
    width: 70%;
    height: 20rem;
}
.wishlist-empty-heading-section {
    text-align: center;
    padding: 3rem 0 0;
}
.wishlist-empty-heading-section p {
    font: normal normal normal 18px/20px Poppins;
letter-spacing: 0px;
color: #8C8C8C;
font-weight: 500;
}
.explore-wishlist-btn {
    padding: 0 40px 4rem;
}
.save_btn-wishlist {
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 12px;
    color: #fff;
    background: transparent linear-gradient(180deg, #C373F2 0%, #F977CE 100%) 0% 0% no-repeat padding-box;
    display: block;
    text-align: center;
    margin-top: 24px;
}
.save_btn-wishlist:hover {
    color: #fff;
    text-decoration: none;
    background: transparent linear-gradient(180deg,#F977CE 100% ,#C373F2 0%) 0% 0% no-repeat padding-box;
}
.explore-btn {
    padding: 0 40px 4rem;
}

@media (max-width: 700px) {
    .empty-box {
        padding: 20px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Wishlist/wishlist.css"],"names":[],"mappings":";EACE;IACE,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,iCAAiC;IACjC,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,4CAA4C;AAChD,mBAAmB;AACnB,cAAc;AACd,gBAAgB;AAChB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,qGAAqG;IACrG,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,oGAAoG;AACxG;AACA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,aAAa;IACjB;EACF","sourcesContent":["\n  .empty-box {\n    background: #fff;\n    padding: 60px;\n    border-radius: 10px;\n    border: 1px solid #fff;\n    box-shadow: 0px 3px 6px #00000029;\n    margin-top: 30px;\n    margin-bottom: 30px;\n}\n.wishlist-empty-img{\n    text-align: center;\n    padding: 4rem 0 0;\n}\n.wishlist-empty-img img {\n    width: 70%;\n    height: 20rem;\n}\n.wishlist-empty-heading-section {\n    text-align: center;\n    padding: 3rem 0 0;\n}\n.wishlist-empty-heading-section p {\n    font: normal normal normal 18px/20px Poppins;\nletter-spacing: 0px;\ncolor: #8C8C8C;\nfont-weight: 500;\n}\n.explore-wishlist-btn {\n    padding: 0 40px 4rem;\n}\n.save_btn-wishlist {\n    width: 100%;\n    padding: 10px 20px;\n    font-size: 18px;\n    border-radius: 12px;\n    color: #fff;\n    background: transparent linear-gradient(180deg, #C373F2 0%, #F977CE 100%) 0% 0% no-repeat padding-box;\n    display: block;\n    text-align: center;\n    margin-top: 24px;\n}\n.save_btn-wishlist:hover {\n    color: #fff;\n    text-decoration: none;\n    background: transparent linear-gradient(180deg,#F977CE 100% ,#C373F2 0%) 0% 0% no-repeat padding-box;\n}\n.explore-btn {\n    padding: 0 40px 4rem;\n}\n\n@media (max-width: 700px) {\n    .empty-box {\n        padding: 20px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
