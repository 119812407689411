/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// import $ from "jquery";
// import ScriptTag from 'react-script-tag'

import { jesssu_user_register, jesssu_user_referral_validate } from "../../store/account/api"


const Referral = () => {
	const dispatch = useDispatch();
	const history = useNavigate();

	const state_account = useSelector(state => state.account);
	const [referralCode1, setreferralCode1] = useState("")
	const [referralCode2, setreferralCode2] = useState("")
	const [referralCode3, setreferralCode3] = useState("")
	const [referralCode4, setreferralCode4] = useState("")
	const [referralCode5, setreferralCode5] = useState("")
	const [referralCode6, setreferralCode6] = useState("")

	useEffect(() => {

		const data = window.location.pathname
		const lastSegment = data.split("/").pop()
		const refercodelength = lastSegment.length
		if(refercodelength===6){
			setreferralCode1(lastSegment[0])
			setreferralCode2(lastSegment[1])
			setreferralCode3(lastSegment[2])
			setreferralCode4(lastSegment[3])
			setreferralCode5(lastSegment[4])
			setreferralCode6(lastSegment[5])
		}
		


		let in1 = document.getElementById('otc-1'),
			ins = document.querySelectorAll('input[type="number"]'),
			splitNumber = function (e) {
				let data = e.data || e.target.value; // Chrome doesn't get the e.data, it's always empty, fallback to value then.
				if (!data) return; // Shouldn't happen, just in case.
				if (data.length === 1) return; // Here is a normal behavior, not a paste action.

				popuNext(e.target, data);
				//for (i = 0; i < data.length; i++ ) {ins[i].value = data[i]; }
			},
			popuNext = function (el, data) {
				el.value = data[0]; // Apply first item to first input
				data = data.substring(1); // remove the first char.
				if (el.nextElementSibling && data.length) {
					// Do the same with the next element and next data
					popuNext(el.nextElementSibling, data);
				}
			};

		ins.forEach(function (input) {

			input.addEventListener('keyup', function (e) {
				// Break if Shift, Tab, CMD, Option, Control.
				if (e.keyCode === 16 || e.keyCode === 9 || e.keyCode === 224 || e.keyCode === 18 || e.keyCode === 17) {
					return;
				}

				// On Backspace or left arrow, go to the previous field.
				if ((e.keyCode === 8 || e.keyCode === 37) && this.previousElementSibling && this.previousElementSibling.tagName === "INPUT") {
					this.previousElementSibling.select();
				} else if (e.keyCode !== 8 && this.nextElementSibling) {
					this.nextElementSibling.select();
				}

				// If the target is populated to quickly, value length can be > 1
				if (e.target.value.length > 1) {
					splitNumber(e);
				}
			});

			input.addEventListener('focus', function (e) {
				// If the focus element is the first one, do nothing
				if (this === in1) return;

				// If value of input 1 is empty, focus it.
				if (in1.value === '') {
					in1.focus();
				}

				// If value of a previous input is empty, focus it.
				// To remove if you don't wanna force user respecting the fields order.
				if (this.previousElementSibling.value === '') {
					this.previousElementSibling.focus();
				}
			});
		});
	}, []);
	const handleChange = (e) => {
		let num1 = "", num2 = "", num3 = "", num4 = "", num5 = "", num6 = "";
		if (e.target.id === "otc-1") {
			num1 = e.target.value;
			setreferralCode1(num1)
		}
		else if (e.target.id === "otc-2") {
			num2 = e.target.value;
			setreferralCode2(num2)
		}
		else if (e.target.id === "otc-3") {
			num3 = e.target.value;
			setreferralCode3(num3)
		}
		else if (e.target.id === "otc-4") {
			num4 = e.target.value;
			setreferralCode4(num4)
		}
		else if (e.target.id === "otc-5") {
			num5 = e.target.value;
			setreferralCode5(num5)
		}
		else if (e.target.id === "otc-6") {
			num6 = e.target.value;
			setreferralCode6(num6)
		}
	};
	function connect_account() {
		let ref = referralCode1 + referralCode2 + referralCode3 + referralCode4 + referralCode5 + referralCode6
		let email = "", token = "";
		if (state_account.profile.email.length > 0) {
			email = state_account.profile.email;
			token = state_account.profile.token;
		}
		let userpayload =
		{
			'email': email,
			'tokenid': token,
			'referalcode': ref,
			'provider': 'Phonelogin',
		}
		if (state_account.profile.email.length > 0) {
			dispatch(jesssu_user_register(userpayload, (res) => {
				if (res.status) {
					if (state_account.profile.email.length > 0 && state_account.profile.referalcode.length > 0) {
						history("/otp")
					}
				}
			}));
		}
		else {
			dispatch(jesssu_user_referral_validate(userpayload, (res) => {
				if (res.status === 200) {
					history("/login")
				}
			}));
		}
	}

	return (
		<>
			<div className="login_area">
				<div className="container">

					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-4">
							<img src="/images/logo.png" alt="" className="login_logo" />
							<div className="login_content">
								<h1 className="h1 text_bold_600">Hello,<span className=""> User</span></h1>
								<p>Enter the referral code you have</p>
								<form className="otc-section otc" name="one-time-code">
									<div className="form-area">
										<ul className="list-unstyled display_flex space_between2">
											<li className="text_bold_600">Enter Referral Code</li>
										</ul>
										<fieldset>

											<label htmlFor="otc-1">Number 1</label>
											<label htmlFor="otc-2">Number 2</label>
											<label htmlFor="otc-3">Number 3</label>
											<label htmlFor="otc-4">Number 4</label>
											<label htmlFor="otc-5">Number 5</label>
											<label htmlFor="otc-6">Number 6</label>
											<ul className="list-unstyled display_flex form_number">
												<input type="text" inputtype="text" value={referralCode1}
													autoComplete="one-time-code" id="otc-1" required onChange={(e) => handleChange(e)} />
												<input type="text" maxLength="1" value={referralCode2}
													inputtype="text" id="otc-2" required onChange={(e) => handleChange(e)} />
												<input type="text"  maxLength="1" value={referralCode3}
													inputtype="text" id="otc-3" required onChange={(e) => handleChange(e)} />
												<input type="text"  maxLength="1" value={referralCode4}
													inputtype="text" id="otc-4" required onChange={(e) => handleChange(e)} />
												<input type="text"  maxLength="1" value={referralCode5}
													inputtype="text" id="otc-5" required onChange={(e) => handleChange(e)} />
												<input type="text"  maxLength="1" value={referralCode6}
													inputtype="text" id="otc-6" required onChange={(e) => handleChange(e)} />
											</ul>
										</fieldset>
										<button type="button" className="get_otp" onClick={() => connect_account()}>VERIFY</button>
									</div>
									
									{/* <p className="text-center">Didn't have a Referral Code?
										<a  className="refer_code">Enter Email</a>
									</p> */}
								</form>
							</div>
						</div>
						<div className="col-md-6">
							<img src="/images/login/login.png" alt="" className="img-responsive" />
						</div>
					</div>
				</div>
			</div>
			{/* <ScriptTag isHydrating={true} type="text/javascript"
				src=
				"/js/otp.js"/>
			<ScriptTag isHydrating={true} type="text/javascript"
                    src=
                    "/js/referal.focus.js" /> */}
		</>
	)
}

export default Referral;