/* eslint-disable */
import React from "react";
// import Swal from 'sweetalert2'


const Footer = () => {
    const twitter = "https://twitter.com/Jesssushop";
    const facebook = "https://www.facebook.com/Jesssushop";
    const linkedin = "https://www.linkedin.com/showcase/jesssu";
    const instagram = "https://www.instagram.com/jesssushop";
    const playstore = "https://play.google.com/store/apps/details?id=com.socialshop.jesssu";
    const appstore  = "https://apps.apple.com/us/app/jesssu/id6444154822";
    // async function playstore() {
    //     Swal.fire('Comming soon', '', 'info')

    // }
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer_first">
                                <img src="/images/logo.png" alt="" className="img-responsive footer-logo" />
                                {/* <p className="footer_sub_heading">Shop Share & Stash</p> */}
                                <p>Follow us on:</p>
                                <ul className="list-unstyled display_flex">
                                    <li><a href={`${facebook}`} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-square"></i></a></li>
                                    <li><a href={`${twitter}`} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter-square"></i></a></li>
                                    <li><a href={`${linkedin}`} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin"></i></a></li>
                                    <li><a href={`${instagram}`} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram-square"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                            <p className="footer_sub_heading padding-bottom-20">Contact us</p>
                            <ul className="list-unstyled footer_link">
                                <li><a href={"/about"} target={"_self"}>About us</a></li>
                                <li><a href={"/privacy-policy"} target={"_self"}>Privacy Policy</a></li>
                                <li><a href={"/contact-us"}>Contact us</a></li>
                                <li><a href={"/mission"}>Mission</a></li>

                                <li><a href="mailto:support@jesssu.com">support@jesssu.com</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <p className="footer_sub_heading padding-bottom-20">Download the app on</p> <a href={`${playstore}`} target="_blank" rel="noopener noreferrer"><img src="/images/home/Google_Play_Store_badge_EN.svg.png" alt="" className="footer_store" /></a><a href={`${appstore}`} target="_blank" rel="noopener noreferrer"> <img src="/images/home/apple-app-store-logo-500x173 copy.png" alt="" className="footer_store" onClick={playstore} /></a> </div>
                    </div>
                    <div className="footer_copyright">
                        <p>Powered by <a href="https://edgewrapper.com" target="_blank" className="footer-underline-text">Edgewrapper Technology Private Limited</a> ©2020-2024</p>
                    </div>

                </div>
            </div>
        </>
    );
}
export default Footer;


