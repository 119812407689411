export const GET_ALL_SOCIAL_SUCCESS_SHOP = 'GET_ALL_SOCIAL_SUCCESS_SHOP';

export const GET_ALL_SOCIAL_SUCCESS_SHARE = 'GET_ALL_SOCIAL_SUCCESS_SHARE';

export const GET_ALL_POSTED_SOCIAL_SUCCESS_SHARE = 'GET_ALL_POSTED_SOCIAL_SUCCESS_SHARE';

export const GET_ALL_RECEIVED_SOCIAL_SUCCESS_SHARE = 'GET_ALL_RECEIVED_SOCIAL_SUCCESS_SHARE';

export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';

export const GET_ALL_BRANDS_SUCCESS = 'GET_ALL_BRANDS_SUCCESS';

export const GET_SINGLE_SHARE_SUCCESS = 'GET_SINGLE_SHARE_SUCCESS';

export const GET_SINGLE_SHARE_LIKE_SUCCESS = 'GET_SINGLE_SHARE_LIKE_SUCCESS';

export const GET_SINGLE_SHARE_DISLIKE_SUCCESS = 'GET_SINGLE_SHARE_DISLIKE_SUCCESS';

export const GET_SINGLE_SHARE_COMMENT_SUCCESS = 'GET_SINGLE_SHARE_COMMENT_SUCCESS';

export const GET_SINGLE_SHARE_SHAREBY_SUCCESS = 'GET_SINGLE_SHARE_SHAREBY_SUCCESS';

