/* eslint-disable */
import { Header } from "components";
import React from "react";
// import { useDispatch } from "react-redux";


const AccountDeletionRequest = () => {
    // const dispatch = useDispatch();
    // const [issend, setIsSend] = useState(false);
    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [message, setMessage] = useState("");
    // const twitter = "https://twitter.com/Jesssushop";
    // const facebook = "https://www.facebook.com/Jesssushop";
    // const linkedin = "https://www.linkedin.com/showcase/jesssu";
    // const instagram = "https://www.instagram.com/jesssushop";

    return (
        <>
            <div className="thetop"></div>
            <Header />

            <div className="mission-box">
                <div className="contactus_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Jesssu - Account Deletion Request</h2>
                                    <p >Thank you for using Jesssu! We respect your privacy and offer a simple process for account deletion. Follow the steps below to request the deletion of your account:</p>

                                    <div className="request-list">
                                        <ol>
                                            <li><p>Open the app on your device.</p></li>
                                            <li><p>Go to the "ME" section.</p></li>
                                            <li><p>Find the "Close Account" option.</p></li>
                                            <li><p>Follow the on-screen instructions to confirm the deletion.</p></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Data Retention</h2>
                                    <p >We will delete the following types of data associated with your account:</p>
                                    <ul >
                                        <li><p>Your personal information.</p></li>
                                        <li><p>Transaction history.</p></li>
                                    </ul>
                                    <p>Any additional retention period for specific data will be communicated during the account deletion process.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Contact</h2>
                                    <p> For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <b>support@jesssu.com</b> or directly submit a ticket <a href="/contact-us" target="_blank" rel="noopener noreferrer">here.</a>
                                        <br />Last updated: 27-Apr-2023
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>










        </>);
}
export default AccountDeletionRequest;