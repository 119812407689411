import React from "react";
import PropTypes from "prop-types";

const MainLayout = ({ children }) => {
  return (
    <React.Fragment>
      <div>{children}</div>
    </React.Fragment>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
