/* eslint-disable */
import { Footer, Header } from 'components';
import React, { useEffect, useState } from "react";
// import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { useDispatch, useSelector } from "react-redux";
import { get_product_list_pagination, get_product_list_filter_product_count, get_product_maxamount, get_product_newarrivals, get_product_list_newarrival_count, get_product_details } from 'store/products/api';
import { useNavigate } from 'react-router';
// import { useLocation } from "react-router-dom";
import { share_a_new_product } from 'store/social/api';
import "./loader.css"
import { get_categroylist_pagination, get_subcategroylist_pagination, get_categorylist_by_brand_pagination, get_brandlist_pagination } from "store/category/api";
import { get_friend_list } from 'store/friend/api'
import { get_wishlist, add_delete_wishlist_product } from "../../store/stash/api"
// import { data } from 'jquery';
import { Productshare } from 'components';
import { get_friend_list_success, notification_message_status, update_brand, update_category } from '../../store/account/accountActions'
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
// import { useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductList = () => {
	const state_account = useSelector(state => state.account);
	// const location = useLocation();
	const useremail = state_account.profile.email;
	const usertoken = state_account.profile.token;
	const history = useNavigate();
	const dispatch = useDispatch();
	const [productlist, setProductList] = useState([]);
	const [shareuserselection, setshareuserselection] = useState("Share With")
	const [offset, setoffset] = useState(0);
	const count = 18;
	const [productcount, setproductcount] = useState(0);
	const [subcategories, setSubcategories] = useState([]);
	const [productdata, setproductdata] = useState(typeof any)
	const userCOO = state_account.profile.COO
	const usergender = state_account.profile.gender
	const [value, setValue] = React.useState([0, 20000]);
	const [sortnamevalue, setsortnamevalue] = useState("Sort by price")
	const [loading, setLoading] = useState(true);
	const [searchvalue, setsearchvalue] = useState(typeof any);
	const [friendlist, setFriendList] = useState([]);
	const [postmessage, setpostmessage] = useState("")
	const [showNotification, setShowNotification] = useState(false);
	const [showNotificationMessage, setShowNotificationMessage] = useState(false);
	const [wishlist, setWishlist] = useState([]);
	const [shareddata, setshareddata] = useState(typeof any)
	const [reshare, setreshare] = useState(false)
	// const [searchTerm, setSearchTerm] = useState('');
	// const [searchBrand, setSearchBrand] = useState('');
	const [filteredCategories, setfilteredcategories] = useState([])
	const [filteredBrands, setfilteredBrands] = useState([])
	const [pricefiltermaxamount, setpricefiltermaxamount] = useState(20000)

	// const path = window.location.pathname


	// const handleSearch = (event) => {
	// 	setSearchTerm(event.target.value.toLowerCase());
	// };
	// const handleSearchBrand = (event) => {
	// 	setSearchBrand(event.target.value.toLowerCase());
	// };

	async function searchfilterdcategories(searchTerm) {
		const filteredCategoriesupdate = state_account.categorylist.filter((category) =>

			category.categoryname.toLowerCase().includes(searchTerm)

		)
		setfilteredcategories(filteredCategoriesupdate)
	}
	async function searchfilterdbrands(searchTerm) {
		const filteredBrandsupdate = state_account.brandlist.filter((brands) =>

			brands.brandname.toLowerCase().includes(searchTerm)

		);
		setfilteredBrands(filteredBrandsupdate)
	}
	// const filteredCategories = state_account.categorylist.filter((category) => {
	// 	if (category.categoryname != undefined) {
	// 		category.categoryname.toLowerCase().includes(searchTerm)
	// 	}
	// });
	// const filteredBrands = state_account.brandlist.filter((brands) => {
	// 	if (brands.brandname != undefined) {
	// 		brands.brandname.toLowerCase().includes(searchBrand)
	// 	}
	// });
	const handleSearchInputKeyDown = (event) => {
		if (event.keyCode === 13) {
			event.preventDefault();
		}
	};






	useEffect(() => {

		let isMounted = true;
		setproductcount(0)
		if (isMounted) {
			geturldata();
			getfriendlist();
			get_wishlists();
		}

		// return () => { isMounted = false };

	}, [state_account.search]);

	function getproductdataforshare(data) {
		setshareddata(data)
		setreshare(true)
	}
	const get_wishlists = () => {
		var userpayload =
		{
			"email": useremail,
			"token": usertoken
		}
		dispatch(get_wishlist(userpayload, (res) => {
			if (res.status === 200) {
				// res.data.map((i) => {
				// 	setWishlist(current => [...current, i.item_id[0]])

				// })
				for (let i = 0; i < res.data.length; i++) {
					setWishlist(current => [...current, res.data[i].item_id[0]]);
				}
			}
		}));

	}
	function changevaluefromurl(data) {
		let step1 = data.replace("~", "&")
		let step2 = step1.replace(".", "/")
		return step2
	}
	function changevalueforurl(data) {
		let step1 = data.replace("&", "~")
		let step2 = step1.replace("/", ".")
		return step2
	}
	async function addproductinwishlist(data) {
		if(state_account.profile.email!=""){
			let payload = {
			"email": useremail,
			"productid": data.SKU,
			"url": data.MainImage
		}

		dispatch(add_delete_wishlist_product(payload, (res) => {
			if (res.status === 200) {
				if (res.data === "Product added to wishist") {
					setWishlist(current => [...current, data.SKU])
					setShowNotificationMessage("Product has been added successfuly to your wishlist")
					setShowNotification(true);
					closenotification()
				}
				else {
					setWishlist((prevState) =>
						prevState.filter((prevItem) => prevItem !== data.SKU)
					);
					setShowNotificationMessage("Product has been removed successfuly from your wishlist")
					setShowNotification(true);
					closenotification()
				}
			}
		}));}
	}
	// async function getproductdata(p_data) {
	// 	setpostmessage("")
	// 	setshareuserselection("Share With")
	// 	setproductdata(p_data)
	// }
	const getfriendlist = () => {
		let userpayload =
		{
			"useremail": state_account.profile.email,
			"token": state_account.profile.token,
		}
		dispatch(get_friend_list(userpayload, (res) => {
			if (res.status === 200) {
				setFriendList(res.data);
				dispatch(get_friend_list_success(res.data))
			}
		}));
	}

	async function shareaproduct(i, singleuser) {
		if (singleuser === "") {
			let sharedtovalue = []
			if (shareuserselection === "Public") {
				sharedtovalue = []
				setShowNotificationMessage("Product has been shared successfuly as a public feed")
				closenotification()
			}
			else if (shareuserselection === "All friends") {
				// friendlist.map(i => {
				// 	sharedtovalue.push(i.friendemail[0])
				// })
				for (let i = 0; i < friendlist.length; i++) {
					sharedtovalue.push(friendlist[i].friendemail[0]);
				}
				setShowNotificationMessage("Product has been shared successfuly to all your friends")
				closenotification()

			}
			const payload = {
				"email": useremail,
				"token": usertoken,
				"notes": postmessage,
				"sharedto": sharedtovalue,
				"productid": i.SKU,
				"likes": [],
				"dislikes": []
			}
			dispatch(share_a_new_product(payload, (res) => {
				if (res.status === 200) {

					setShowNotification(true);
					closenotification()
				}
			}))
		}
		else {
			const payload = {
				"email": useremail,
				"token": usertoken,
				"notes": postmessage,
				"sharedto": singleuser,
				"productid": i.SKU,
				"likes": [],
				"dislikes": []
			}
			dispatch(share_a_new_product(payload, (res) => {
				if (res.status === 200) {

					setShowNotificationMessage("Product has been shared successfuly to your selected friend")
					setShowNotification(true);
					closenotification()
				}
			}))
		}
	}
	function updatesharewithvalue(data) {
		setshareuserselection(data)
	}
	async function handelchange(e) {
		setpostmessage(e.target.value)
	}
	// Changing State when volume increases/decreases
	async function rangeSelector(event, newValue) {
		setValue(newValue);

		let olddata = { minprice: value[0], maxprice: value[1] }
		var oldqueryString1 = Object.keys(olddata).map(key => key + '=' + olddata[key]).join('&');
		var oldqueryString = `&${oldqueryString1}`


		let newsearchparams = ""
		let data = { minprice: newValue[0], maxprice: newValue[1] }
		var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
		let urldata = (window.location.pathname)
		let urls = urldata.split("/")
		let searchparams = `${urls[2]}`
		let searchparamswithoutold = searchparams.replace(oldqueryString, "")
		newsearchparams = `${searchparamswithoutold}&${queryString}`
		// let finalvalue = newsearchparams.replace(`&search=${searchvalue}`, "")

		history(`/products/${newsearchparams}`)
		geturldata()
	};
	const geturldata = async () => {

		setoffset(0)
		// setbrandvalue([])
		// setcategoryvalue([])
		// setsubcategoryvalue([])
		let branddetails = []
		let categorydetails = []
		let subcategorydetails = []
		let sort = ""
		let minprice = ""
		let maxprice = ""
		let search = null
		let newarrivals = false

		let urldata = (window.location.pathname)
		let urls = urldata.split("/")
		let searchparams = `?${urls[2]}`
		const params = new URLSearchParams(searchparams);
		let urlvalues = []
		for (const [key, value] of params) {
			urlvalues.push({ key, value })
		}
		for (let i = 0; i < urlvalues.length; i++) {
			if (urlvalues[i].key === "sort") {
				if (urlvalues[i].value === "LTH") {
					sort = urlvalues[i].value;
				} else if (urlvalues[i].value === "HTL") {
					sort = urlvalues[i].value;
				}
			} else if (urlvalues[i].key === "subcategory") {
				if (urlvalues[i].value !== "") {
					let data = changevaluefromurl(urlvalues[i].value);
					subcategorydetails.push(data);
				}
			} else if (urlvalues[i].key === "category") {
				if (urlvalues[i].value !== "") {
					let data = changevaluefromurl(urlvalues[i].value);
					categorydetails.push(data);
				}
			} else if (urlvalues[i].key === "brand") {
				if (urlvalues[i].value !== "") {
					let data = changevaluefromurl(urlvalues[i].value);
					branddetails.push(data);
				}
			} else if (urlvalues[i].key === "maxprice") {
				if (urlvalues[i].value !== "") {
					maxprice = urlvalues[i].value;
				}
			} else if (urlvalues[i].key === "minprice") {
				if (urlvalues[i].value !== "") {
					minprice = urlvalues[i].value;
				}
			} else if (urlvalues[i].key === "search") {
				if (urlvalues[i].value !== "") {
					search = urlvalues[i].value;
					setsearchvalue(search);
				}
			} else if (urlvalues[i].key === "newarrivals") {
				if (urlvalues[i].value !== "") {
					newarrivals = true;
				}
			}
		}
		// if (minprice != "") {
		// 	if (maxprice != "") {
		// 		setValue([`${minprice}`, `${maxprice}`])
		// 	}
		// }
		setLoading(true)

		await get_products(branddetails, categorydetails, subcategorydetails, sort, minprice, maxprice, search, newarrivals)

	}

	async function geturldataforpagination() {
		let branddetails = []
		let categorydetails = []
		let subcategorydetails = []
		let newarrivals = false
		let sort = ""
		let minprice = ""
		let maxprice = ""
		let pageno = ""
		let search = null
		let urldata = (window.location.pathname)
		let urls = urldata.split("/")
		let searchparams = `?${urls[2]}`
		const params = new URLSearchParams(searchparams);
		let urlvalues = []
		for (const [key, value] of params) {
			urlvalues.push({ key, value })
		}
		for (let i = 0; i < urlvalues.length; i++) {
			if (urlvalues[i].key === "sort") {
				if (urlvalues[i].value === "LTH") {
					sort = urlvalues[i].value;
				} else if (urlvalues[i].value === "HTL") {
					sort = urlvalues[i].value;
				}
			} else if (urlvalues[i].key === "subcategory") {
				if (urlvalues[i].value !== "") {
					subcategorydetails.push(urlvalues[i].value);
				}
			} else if (urlvalues[i].key === "category") {
				if (urlvalues[i].value !== "") {
					categorydetails.push(urlvalues[i].value);
				}
			} else if (urlvalues[i].key === "brand") {
				if (urlvalues[i].value !== "") {
					branddetails.push(urlvalues[i].value);
				}
			} else if (urlvalues[i].key === "maxprice") {
				if (urlvalues[i].value !== "") {
					maxprice = urlvalues[i].value;
				}
			} else if (urlvalues[i].key === "minprice") {
				if (urlvalues[i].value !== "") {
					minprice = urlvalues[i].value;
				}
			} else if (urlvalues[i].key === "pageno") {
				if (urlvalues[i].value !== "") {
					pageno = urlvalues[i].value;
				}
			} else if (urlvalues[i].key === "search") {
				if (urlvalues[i].value !== "") {
					search = urlvalues[i].value;
					setsearchvalue(search);
				}
			} else if (urlvalues[i].key === "newarrivals") {
				if (urlvalues[i].value !== "") {
					newarrivals = true;
				}
			}
		}
		// if (minprice != "") {
		// 	if (maxprice != "") {
		// 		setValue([`${minprice}`, `${maxprice}`])
		// 	}
		// }
		setLoading(true)

		await get_products_forpagination(branddetails, categorydetails, subcategorydetails, sort, minprice, maxprice, pageno, search, newarrivals)

	}
	async function get_products_forpagination(branddetails, categorydetails, subcategorydetails, sort, minprice, maxprice, pageno, search, newarrivals) {

		if (newarrivals === true) {
			var userpayload =
			{
				"gender": state_account.profile.gender,
				"COO": state_account.profile.COO
			}
			dispatch(get_product_newarrivals(userpayload, pageno, count, (res => {
				if (res.status === 200) {
					setProductList(res.data)
					setLoading(false)
				}
			})));
		}
		else {

			var payload =
			{
				'brands': branddetails,
				'categories': categorydetails,
				'minprice': minprice,
				'maxprice': maxprice,
				'sortby': sort,
				'subcategories': subcategorydetails,
				'token': state_account.profile.token,
				'userid': state_account.profile.email,
				'gender': usergender,
				"COO": userCOO,
				"search": search
			}


			dispatch(get_product_list_pagination(payload, pageno, count, (res) => {
				if (res.status === 200) {
					setProductList(res.data)
					setLoading(false)
				}
			}))
		}
	}
	function closenotification() {
		setTimeout(function () {
			setShowNotification(false);
			dispatch(notification_message_status(false))
		}, 5000);
	}
	async function get_products(branddetails, categorydetails, subcategorydetails, sort, minprice, maxprice, search, newarrivals) {
		console.log(branddetails, categorydetails, subcategorydetails, sort, minprice, maxprice, search, newarrivals)
		if (newarrivals === true) {
			const payloadforcategory = {
				"gender": usergender,
				"COO": state_account.profile.COO
			}

			// dispatch(update_category([]))
			// dispatch(update_category([]))
			dispatch(get_categroylist_pagination(payloadforcategory, 0, 100, (res) => {
				if (res.status === 200) {
					let categorydata = []
					for (let i = 0; i < res.data.length; i++) {
						let c_data = {
							"categoryname": res.data[i]._id,
							"ischecked": categorydetails.includes(res.data[i]._id)
						};
						categorydata.push(c_data);
					}
					dispatch(update_category(categorydata))
					setfilteredcategories(categorydata)

				}
			}))
			const payloadforbrand = {
				"gender": usergender,
				"COO": userCOO,
				"category": categorydetails
			}
			// setBrands([])
			setSubcategories([])
			dispatch(get_brandlist_pagination(payloadforbrand, 0, 100, (res) => {
				if (res.status === 200) {
					let newbranddata = []
					for (let i = 0; i < res.data.length; i++) {
						let b_data = {
							"brandname": res.data[i]._id,
							"ischecked": branddetails.includes(res.data[i]._id)
						};
						newbranddata.push(b_data);
					}
					console.log(newbranddata)
					dispatch(update_brand(newbranddata))
					setfilteredBrands(newbranddata)

				}
			}))
			var userpayload =
			{
				'brands': branddetails,
				'categories': categorydetails,
				'minprice': minprice,
				'maxprice': maxprice,
				'sortby': sort,
				'subcategories': subcategorydetails,
				'token': state_account.profile.token,
				'userid': state_account.profile.email,
				'gender': usergender,
				"COO": userCOO,
				"search": search
			}

			dispatch(get_product_list_newarrival_count(userpayload, (res => {
				if (res.status === 200) {
					setproductcount(res.data);
					dispatch(get_product_newarrivals(userpayload, offset, count, (res => {
						if (res.status === 200) {
							setProductList(res.data)
							setLoading(false)
						}
					})));
				}
			})))
			var payload =
			{
				'brands': branddetails,
				'categories': categorydetails,
				'minprice': minprice,
				'maxprice': maxprice,
				'sortby': sort,
				'subcategories': subcategorydetails,
				'token': state_account.profile.token,
				'userid': state_account.profile.email,
				'gender': usergender,
				"COO": userCOO,
				"search": search
			}
			if (maxprice === "") {
				dispatch(get_product_maxamount(payload, (res => {
					if (res.status === 200) {

						// setLoading(false)
						setValue([0, parseInt(res.data[0].maxPrice)])
						setpricefiltermaxamount(parseInt(res.data[0].maxPrice))
					}
				})));
			}



		}
		else if (branddetails.length === 0 && categorydetails.length === 0) {
			const payloadforcategory = {
				"gender": usergender,
				"COO": state_account.profile.COO
			}

			// dispatch(update_category([]))
			dispatch(get_categroylist_pagination(payloadforcategory, 0, 100, (res) => {
				if (res.status === 200) {
					let categorydata = []
					for (let i = 0; i < res.data.length; i++) {
						let c_data = {
							"categoryname": res.data[i]._id,
							"ischecked": categorydetails.includes(res.data[i]._id)
						};
						categorydata.push(c_data);
					}
					dispatch(update_category(categorydata))
					setfilteredcategories(categorydata)
				}
			}))
			const payloadforbrand = {
				"gender": usergender,
				"COO": userCOO,
				"category": categorydetails
			}
			dispatch(get_brandlist_pagination(payloadforbrand, 0, 100, (res) => {
				if (res.status === 200) {
					let newbranddata = []
					for (let i = 0; i < res.data.length; i++) {
						let b_data = {
							"brandname": res.data[i]._id,
							"ischecked": branddetails.includes(res.data[i]._id)
						};
						newbranddata.push(b_data);
					}
					console.log(newbranddata)
					dispatch(update_brand(newbranddata))
					setfilteredBrands(newbranddata)
				}
			}))
			var payload =
			{
				'brands': branddetails,
				'categories': categorydetails,
				'minprice': minprice,
				'maxprice': maxprice,
				'sortby': sort,
				'subcategories': subcategorydetails,
				'token': state_account.profile.token,
				'userid': state_account.profile.email,
				'gender': usergender,
				"COO": userCOO,
				"search": search
			}
			dispatch(get_product_list_filter_product_count(payload, (result) => {
				if (result.status === 200) {
					setproductcount(result.data);
					dispatch(get_product_list_pagination(payload, offset, count, (res) => {
						if (res.status === 200) {
							setProductList(res.data)
							setLoading(false)
						}
					}));
				}
			}));
			if (maxprice === "") {
				dispatch(get_product_maxamount(payload, (res => {
					if (res.status === 200) {

						// setLoading(false)
						setValue([0, parseInt(res.data[0].maxPrice)])
						setpricefiltermaxamount(parseInt(res.data[0].maxPrice))
					}
				})));
			}




		}
		else if (categorydetails.length === 0 && branddetails.length !== 0) {
			const payloadforbrand = {
				"gender": usergender,
				"COO": userCOO,
				"category": categorydetails
			}
			dispatch(get_brandlist_pagination(payloadforbrand, 0, 100, (res) => {
				if (res.status === 200) {
					let newbranddata = []
					for (let i = 0; i < res.data.length; i++) {
						let b_data = {
							"brandname": res.data[i]._id,
							"ischecked": branddetails.includes(res.data[i]._id)
						};
						newbranddata.push(b_data);
					}
					console.log(newbranddata)
					dispatch(update_brand(newbranddata))
					setfilteredBrands(newbranddata)
				}
			}))
			const payloadforcategory = {
				"gender": usergender,
				"brands": branddetails,
				"COO": userCOO
			}

			// dispatch(update_category([]))

			dispatch(get_categorylist_by_brand_pagination(payloadforcategory, 0, 100, (res) => {
				if (res.status === 200) {
					let categorydata = []
					for (let i = 0; i < res.data.length; i++) {
						let c_data = {
							"categoryname": res.data[i]._id,
							"ischecked": false
						};
						categorydata.push(c_data);
					}

					dispatch(update_category(categorydata))
					setfilteredcategories(categorydata)

				}

			}))
			const payloadforsubcategories = {
				"gender": usergender,
				"COO": userCOO,
				"category": categorydetails,
				"brands": branddetails
			}
			setSubcategories([])
			dispatch(get_subcategroylist_pagination(payloadforsubcategories, 0, 100, (res) => {
				if (res.status === 200) {
					for (let i = 0; i < res.data.length; i++) {
						let is_checked = subcategorydetails.includes(res.data[i]._id);
						let s_data = {
							"subcategoryname": res.data[i]._id,
							"ischecked": is_checked
						};
						setSubcategories(current => [...current, s_data]);
					}
				}
			}))
			var payload =
			{
				'brands': branddetails,
				'categories': categorydetails,
				'minprice': minprice,
				'maxprice': maxprice,
				'sortby': sort,
				'subcategories': subcategorydetails,
				'token': state_account.profile.token,
				'userid': state_account.profile.email,
				'gender': usergender,
				"COO": userCOO,
			}
			dispatch(get_product_list_filter_product_count(payload, (result) => {
				if (result.status === 200) {
					setproductcount(result.data);
					dispatch(get_product_list_pagination(payload, offset, count, (res) => {
						if (res.status === 200) {
							setProductList(res.data)
							setLoading(false)
						}
					}));
				}
			}));
			if (maxprice === "") {
				dispatch(get_product_maxamount(payload, (res => {
					if (res.status === 200) {

						// setLoading(false)
						setValue([0, parseInt(res.data[0].maxPrice)])
						setpricefiltermaxamount(parseInt(res.data[0].maxPrice))
					}
				})));
			}

		}
		else if (categorydetails.length !== 0 && branddetails.length === 0) {
			const payloadforcategory = {
				"gender": usergender,
				"COO": state_account.profile.COO
			}

			// dispatch(update_category([]))
			dispatch(get_categroylist_pagination(payloadforcategory, 0, 100, (res) => {
				if (res.status === 200) {
					let categorydata = []
					for (let i = 0; i < res.data.length; i++) {
						let c_data = {
							"categoryname": res.data[i]._id,
							"ischecked": categorydetails.includes(res.data[i]._id)
						};
						categorydata.push(c_data);
					}
					dispatch(update_category(categorydata))
					setfilteredcategories(categorydata)
				}
			}))
			const payloadforbrand = {
				"gender": usergender,
				"COO": userCOO,
				"category": categorydetails
			}

			dispatch(get_brandlist_pagination(payloadforbrand, 0, 100, (res) => {
				if (res.status === 200) {
					let newbranddata = [];
					for (let i = 0; i < res.data.length; i++) {
						let b_data = {
							"brandname": res.data[i]._id,
							"ischecked": branddetails.includes(res.data[i]._id)
						};
						newbranddata.push(b_data);
					}
					console.log(newbranddata);
					dispatch(update_brand(newbranddata));
					setfilteredBrands(newbranddata);
				}
			}))
			setSubcategories([])
			const payloadforsubcategories = {
				"gender": usergender,
				"COO": userCOO,
				"category": categorydetails,
				"brands": branddetails
			}
			dispatch(get_subcategroylist_pagination(payloadforsubcategories, 0, 100, (res) => {
				if (res.status === 200) {
					for (let i = 0; i < res.data.length; i++) {
						let is_checked = subcategorydetails.includes(res.data[i]._id);
						let s_data = {
							"subcategoryname": res.data[i]._id,
							"ischecked": is_checked
						};
						setSubcategories(current => [...current, s_data]);
					}
				}
			}))
			var payload =
			{
				'brands': branddetails,
				'categories': categorydetails,
				'minprice': minprice,
				'maxprice': maxprice,
				'sortby': sort,
				'subcategories': subcategorydetails,
				'token': state_account.profile.token,
				'userid': state_account.profile.email,
				'gender': usergender,
				"COO": userCOO,
			}
			dispatch(get_product_list_filter_product_count(payload, (result) => {
				if (result.status === 200) {
					setproductcount(result.data);
					dispatch(get_product_list_pagination(payload, offset, count, (res) => {
						if (res.status === 200) {
							setProductList(res.data)
							setLoading(false)
						}
					}));
				}
			}));
			if (maxprice === "") {
				dispatch(get_product_maxamount(payload, (res => {
					if (res.status === 200) {

						// setLoading(false)
						setValue([0, parseInt(res.data[0].maxPrice)])
						setpricefiltermaxamount(parseInt(res.data[0].maxPrice))
					}
				})));
			}

		}
		else if (categorydetails.length !== 0 && branddetails.length !== 0) {
			const payloadforcategory = {
				"gender": usergender,
				"COO": state_account.profile.COO
			}

			// dispatch(update_category([]))
			dispatch(get_categroylist_pagination(payloadforcategory, 0, 100, (res) => {
				if (res.status === 200) {
					let categorydata = []
					for (let i = 0; i < res.data.length; i++) {
						let c_data = {
							"categoryname": res.data[i]._id,
							"ischecked": categorydetails.includes(res.data[i]._id)
						};
						categorydata.push(c_data);
					}
					dispatch(update_category(categorydata))
					setfilteredcategories(categorydata)
				}
			}))
			const payloadforbrand = {
				"gender": usergender,
				"COO": userCOO,
				"category": categorydetails
			}

			dispatch(get_brandlist_pagination(payloadforbrand, 0, 100, (res) => {
				if (res.status === 200) {
					let newbranddata = []; // Initialize an empty array outside the loop

					for (let i = 0; i < res.data.length; i++) {
						let b_data = {
							"brandname": res.data[i]._id,
							"ischecked": branddetails.includes(res.data[i]._id)
						};
						newbranddata.push(b_data);
					}
					console.log(newbranddata)
					dispatch(update_brand(newbranddata))
					setfilteredBrands(newbranddata)
				}
			}))

			setSubcategories([])
			const payloadforsubcategories = {
				"gender": usergender,
				"COO": userCOO,
				"category": categorydetails,
				"brands": branddetails
			}
			dispatch(get_subcategroylist_pagination(payloadforsubcategories, 0, 100, (res) => {
				if (res.status === 200) {
					for (let i = 0; i < res.data.length; i++) {
						let is_checked = subcategorydetails.includes(res.data[i]._id);
						let s_data = {
							"subcategoryname": res.data[i]._id,
							"ischecked": is_checked
						};
						setSubcategories(current => [...current, s_data]);
					}
				}
			}))
			var payload =
			{
				'brands': branddetails,
				'categories': categorydetails,
				'minprice': minprice,
				'maxprice': maxprice,
				'sortby': sort,
				'subcategories': subcategorydetails,
				'token': state_account.profile.token,
				'userid': state_account.profile.email,
				'gender': usergender,
				"COO": userCOO,
			}
			dispatch(get_product_list_filter_product_count(payload, (result) => {
				if (result.status === 200) {
					setproductcount(result.data);
					dispatch(get_product_list_pagination(payload, offset, count, (res) => {
						if (res.status === 200) {
							setProductList(res.data)
							setLoading(false)
						}
					}));
				}
			}));
			if (maxprice === "") {
				dispatch(get_product_maxamount(payload, (res => {
					if (res.status === 200) {

						// setLoading(false)
						setValue([0, parseInt(res.data[0].maxPrice)])
						setpricefiltermaxamount(parseInt(res.data[0].maxPrice))
					}
				})));
			}

		}
	}
	function updatesharewithvalue(data) {
		setshareuserselection(data)
	}
	async function selectbrand(count, e) {
		let newsearchparams = ""
		const stateforbrand = await state_account.brandlist.map((i = typeof any, index) => {
			if (index === count) {
				if (state_account.brandlist[count].ischecked === true) {
					let selectbrandname = changevalueforurl(`${state_account.brandlist[count].brandname}`)
					let data = { brand: selectbrandname }
					var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
					let urldata = (window.location.pathname)
					let urls = urldata.split("/")
					let searchparams = `${urls[2]}`
					let replacequerystring = queryString.replace(/ /g, "%20")
					var regExp = new RegExp('&' + replacequerystring)
					let result = searchparams.replace(regExp, " ")
					let finalvalue = result.replace(`&pageno=${offset}`, "")
					let finalvalue2 = finalvalue.replace(`&search=${state_account.search}`, "")
					let finalvalue3 = finalvalue2.replace("&newarrivals=true", "")
					newsearchparams = finalvalue3
					return { ...i, "ischecked": false }
				}
				else {

					let selectbrandname = changevalueforurl(`${state_account.brandlist[count].brandname}`)
					let data = { brand: selectbrandname }
					var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
					let urldata = (window.location.pathname)
					let urls = urldata.split("/")
					let searchparams = `${urls[2]}`
					let finalvalue = searchparams.replace(`&pageno=${offset}`, "")
					let finalvalue2 = finalvalue.replace(`&search=${state_account.search}`, "")
					let finalvalue3 = finalvalue2.replace("&newarrivals=true", "")
					newsearchparams = `${finalvalue3}&${queryString}`
					return { ...i, "ischecked": true }
				}
			}
			return i;
		});


		dispatch(update_brand(stateforbrand))
		setfilteredBrands(stateforbrand)

		history(`/products/${newsearchparams}`)
		geturldata()

	}
	async function selectcategory(count, e) {

		let newsearchparams = ""
		const stateforcategory = state_account.categorylist.map((i = typeof any, index) => {
			if (index === count) {
				if (state_account.categorylist[count].ischecked === true) {

					let selectcategoryname = changevalueforurl(`${state_account.categorylist[count].categoryname}`)
					let data = { category: selectcategoryname }
					var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
					let urldata = (window.location.pathname)
					let urls = urldata.split("/")
					let searchparams = `${urls[2]}`
					let replacequerystring = queryString.replace(/ /g, "%20")
					var regExp = new RegExp('&' + replacequerystring)
					let result = searchparams.replace(regExp, " ")
					let finalvalue = result.replace(`&pageno=${offset}`, "")
					let finalvalue2 = finalvalue.replace(`&search=${state_account.search}`, "")
					let finalvalue3 = finalvalue2.replace("&newarrivals=true", "")
					newsearchparams = finalvalue3
					return { ...i, "ischecked": false }
				}
				else {
					let selectcategoryname = changevalueforurl(`${state_account.categorylist[count].categoryname}`)
					let data = { category: selectcategoryname }
					var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
					let urldata = (window.location.pathname)
					let urls = urldata.split("/")
					let searchparams = `${urls[2]}`
					let finalvalue = searchparams.replace(`&pageno=${offset}`, "")
					let finalvalue2 = finalvalue.replace(`&search=${state_account.search}`, "")
					let finalvalue3 = finalvalue2.replace("&newarrivals=true", "")
					newsearchparams = `${finalvalue3}&${queryString}`
					return { ...i, "ischecked": true }
				}
			}
			return i;
		});
		dispatch(update_category(stateforcategory))
		setfilteredcategories(stateforcategory)
		// setCategories(stateforcategory);
		history(`/products/${newsearchparams}`)
		geturldata()
	}
	async function selectsubcategory(count, e) {
		let newsearchparams = ""
		const stateforsubcategory = subcategories.map((i = typeof any, index) => {
			if (index === count) {
				if (subcategories[count].ischecked === true) {
					let selectsubcategoryname = changevalueforurl(`${subcategories[count].subcategoryname}`)
					let data = { subcategory: selectsubcategoryname }
					var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
					let urldata = (window.location.pathname)
					let urls = urldata.split("/")
					let searchparams = `${urls[2]}`
					let replacequerystring = queryString.replace(/ /g, "%20")
					var regExp = new RegExp('&' + replacequerystring)
					let result = searchparams.replace(regExp, " ")
					let finalvalue = result.replace(`&pageno=${offset}`, "")
					let finalvalue2 = finalvalue.replace(`&search=${state_account.search}`, "")
					let finalvalue3 = finalvalue2.replace("&newarrivals=true", "")
					newsearchparams = finalvalue3
					return { ...i, "ischecked": false }
				}
				else {
					let selectsubcategoryname = changevalueforurl(`${subcategories[count].subcategoryname}`)
					let data = { subcategory: selectsubcategoryname }
					var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
					let urldata = (window.location.pathname)
					let urls = urldata.split("/")
					let searchparams = `${urls[2]}`
					let finalvalue = searchparams.replace(`&pageno=${offset}`, "")
					let finalvalue2 = finalvalue.replace(`&search=${state_account.search}`, "")
					let finalvalue3 = finalvalue2.replace("&newarrivals=true", "")
					newsearchparams = `${finalvalue3}&${queryString}`
					// history(`${newsearchparams}`)
					return { ...i, "ischecked": true }
				}
			}
			return i;
		});
		setSubcategories(stateforsubcategory);
		history(`/products/${newsearchparams}`)
		geturldata()
	}
	async function hightolow() {
		let dataforlth = { sort: "LTH" }
		let dataforhtl = { sort: "HTL" }
		var queryStringforlth = Object.keys(dataforlth).map(key => key + '=' + dataforlth[key]).join('&');
		var queryStringforhtl = Object.keys(dataforhtl).map(key => key + '=' + dataforhtl[key]).join('&');
		let urldata = (window.location.pathname)
		let urls = urldata.split("/")
		let searchparams = `${urls[2]}`
		let replacequerystringlth = queryStringforlth.replace(/ /g, "%20")
		let replacequerystringhtl = queryStringforhtl.replace(/ /g, "%20")
		var regExplth = new RegExp('&' + replacequerystringlth)
		var regExphtl = new RegExp('&' + replacequerystringhtl)
		let resultlth = searchparams.replace(regExplth, "")
		let resulthtl = resultlth.replace(regExphtl, "")
		let finalvalue = resulthtl.replace(`&pageno=${offset}`, "")
		// let finalvalue2 = finalvalue.replace(`&search=${searchvalue}`, "")
		setsortnamevalue("High to low")
		let newsearchparams = ""
		let data = { sort: "HTL" }
		var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
		newsearchparams = `${finalvalue}&${queryString}`
		history(`/products/${newsearchparams}`)
		geturldata()
	}
	async function lowtohigh() {

		let dataforlth = { sort: "LTH" }
		let dataforhtl = { sort: "HTL" }
		var queryStringforlth = Object.keys(dataforlth).map(key => key + '=' + dataforlth[key]).join('&');
		var queryStringforhtl = Object.keys(dataforhtl).map(key => key + '=' + dataforhtl[key]).join('&');

		let urldata = (window.location.pathname)
		let urls = urldata.split("/")
		let searchparams = `${urls[2]}`
		let replacequerystringlth = queryStringforlth.replace(/ /g, "%20")
		let replacequerystringhtl = queryStringforhtl.replace(/ /g, "%20")
		var regExplth = new RegExp('&' + replacequerystringlth)
		var regExphtl = new RegExp('&' + replacequerystringhtl)
		let resultlth = searchparams.replace(regExplth, "")
		let resulthtl = resultlth.replace(regExphtl, "")
		let finalvalue = resulthtl.replace(`&pageno=${offset}`, "")
		// let finalvalue2 = finalvalue.replace(`&search=${searchvalue}`, "")


		setsortnamevalue("Low to high")
		let newsearchparams = ""
		let data = { sort: "LTH" }
		var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
		newsearchparams = `${finalvalue}&${queryString}`
		history(`/products/${newsearchparams}`)
		geturldata()
	}
	async function removesortfilter() {

		let dataforlth = { sort: "LTH" }
		let dataforhtl = { sort: "HTL" }
		var queryStringforlth = Object.keys(dataforlth).map(key => key + '=' + dataforlth[key]).join('&');
		var queryStringforhtl = Object.keys(dataforhtl).map(key => key + '=' + dataforhtl[key]).join('&');

		let urldata = (window.location.pathname)
		let urls = urldata.split("/")
		let searchparams = `${urls[2]}`
		let replacequerystringlth = queryStringforlth.replace(/ /g, "%20")
		let replacequerystringhtl = queryStringforhtl.replace(/ /g, "%20")
		var regExplth = new RegExp('&' + replacequerystringlth)
		var regExphtl = new RegExp('&' + replacequerystringhtl)
		let resultlth = searchparams.replace(regExplth, "")
		let resulthtl = resultlth.replace(regExphtl, "")
		// let finalvalue = resulthtl.replace(`&search=${searchvalue}`, "")
		setsortnamevalue("Sort by price")
		let newsearchparams = ""
		newsearchparams = `${resulthtl}`
		history(`/products/${newsearchparams}`)
		geturldata()
	}
	async function nextpage(count) {
		setoffset(count)
		let oldsearchparams = ""
		let newsearchparams = ""
		let data = { pageno: `${count - 1}` }
		var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
		let urldata = (window.location.pathname)
		let urls = urldata.split("/")
		let searchparams = `${urls[2]}`
		var regExp = new RegExp('&' + queryString)
		let result = searchparams.replace(regExp, "")
		oldsearchparams = result;
		let newdata = { pageno: `${count}` }
		var queryStringlatest = Object.keys(newdata).map(key => key + '=' + newdata[key]).join('&');
		newsearchparams = `${oldsearchparams}&${queryStringlatest}`
		history(`/products/${newsearchparams}`)
		geturldataforpagination()
	}
	async function previouspage(count) {
		setoffset(count)
		let oldsearchparams = ""
		let newsearchparams = ""
		let data = { pageno: `${count + 1}` }
		var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
		let urldata = (window.location.pathname)
		let urls = urldata.split("/")
		let searchparams = `${urls[2]}`
		var regExp = new RegExp('&' + queryString)
		let result = searchparams.replace(regExp, "")
		oldsearchparams = result;
		let newdata = { pageno: `${count}` }
		var queryStringlatest = Object.keys(newdata).map(key => key + '=' + newdata[key]).join('&');
		newsearchparams = `${oldsearchparams}&${queryStringlatest}`
		history(`/products/${newsearchparams}`)
		geturldataforpagination()
	}
	const closeNotification = () => {
		setShowNotification(false);
		dispatch(notification_message_status(false))
	};

	function closenotification() {
		setTimeout(function () {
			setShowNotification(false);
			dispatch(notification_message_status(false))
		}, 5000);
	}

	function addProductinAudit(id) {
		debugger;
		let payload = {
			'token': state_account.profile.token,
			'email': state_account.profile.email,
			"COO": state_account.profile.COO,
		};
		dispatch(get_product_details(payload, id, (res) => { }))
	}

	function redirectToProductPage(product) {
		if (product && product.channels[0] && product.channels[0].url) {
			window.open(product.channels[0].url, '_blank');
		}
		if (wishlist.includes(product.SKU) === false) {
			addproductinwishlist(product)
		}
	}
	function escapeSelector(selector) {
		return selector.replace(/([ #;?%&,.+*~\':"!^$[\]()=>|/@])/g, '\\$1');
	}
	return (
		<>

			<div className="thetop"></div>

			<Header />
			<div className="container">

				<ol className="breadcrumb">
					<li className="breadcrumb-item"><i className="fa-solid fa-house-chimney"></i></li>
					<li className="breadcrumb-item active" aria-current="page">All products</li>
				</ol>
			</div>


			<div className="product_filter">
				<div className="container">
					<div className="row" >
						<div className="col-md-3">
							<form>
								<div className="a filter_left_area">


									<div className="panel-group">
										{(filteredCategories.filter(data => data.ischecked).length > 0 || filteredBrands.filter(data => data.ischecked).length > 0) && (<div className="panel panel-default">
											<div className="panel-heading">
												<h4 className="panel-title">
													<a data-toggle="collapse" href="#collapse6"> Filters</a>
												</h4> </div>
											<div id="collapse6" className="panel-collapse collapse in">
												<div className="panel-body">

													<div className="filter_wrap _2">
														<div className="control-pagination">
															{(state_account.categorylist || []).map((data, index) => (
																<div key={`cat${index}`}>
																	{data.ischecked === true &&
																		<div className="chip">
																			{data.categoryname}
																			<span className="closebtnfilter" onClick={e => selectcategory(index, e)}>&times;</span>
																		</div>}</div>
															))}
														</div>
														<div className="control-pagination">
															{(state_account.brandlist || []).map((data, index) => (
																<div>
																	{data.ischecked === true &&
																		<div className="chip">
																			{data.brandname}
																			<span className="closebtnfilter" onClick={e => selectbrand(index, e)}>&times;</span>
																		</div>}
																</div>))}
														</div>
														<div className="control-pagination">
															{(subcategories || []).map((data, index) => (
																<div key={`subcategory${index}`}>
																	{data.ischecked === true &&
																		<div className="chip">
																			{data.subcategoryname}
																			<span className="closebtnfilter" onClick={e => selectsubcategory(index, e)}>&times;</span>
																		</div>}
																</div>))}

														</div>
														<div className="control-pagination">
															{sortnamevalue !== "Sort by price" &&
																<div>
																	{sortnamevalue === "High to low" &&
																		<div className="chip">
																			High to low
																			<span className="closebtnfilter" onClick={removesortfilter}>&times;</span>
																		</div>}

																	{sortnamevalue === "Low to high" &&
																		<div className="chip">
																			Low to high
																			<span className="closebtnfilter" onClick={removesortfilter}>&times;</span>
																		</div>}
																</div>}

														</div>
													</div>
												</div>
											</div>
										</div>)}
									</div>
									{state_account.categorylist.length !== 0 &&
										<div className="panel-group">
											<div className="panel panel-default">
												<div className="panel-heading">
													<h4 className="panel-title">
														<a data-toggle="collapse" href="#collapse1"> Category</a>
													</h4> </div>
												<div id="collapse1" className="panel-collapse collapse in">
													<div className="panel-body">
														<div>
															<input type="text" className='searchBar' placeholder="Search Categories..." onChange={(e) => searchfilterdcategories(e.target.value.toLowerCase())} onKeyDown={handleSearchInputKeyDown} />
														</div>
														<div className="filter_wrap _2">
															<div style={{
																"height": "250px",
																"overflowY": "auto",
																"overflowX": "hidden"
															}}>
																<div className="control-pagination">

																	{(filteredCategories || []).map((data, index) => (
																		<div className="form-group" key={`filteredcategory${index}`}>
																			<input type="checkbox" id={data.categoryname} data-value={data.categoryname} checked={data.ischecked} onChange={e => selectcategory(index, e)} />
																			<label htmlFor={data.categoryname}>{data.categoryname}  <span></span></label>
																		</div>))}

																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>}
									{state_account.brandlist.length !== 0 &&
										<div className="panel-group">
											<div className="panel panel-default">
												<div className="panel-heading">
													<h4 className="panel-title">
														<a data-toggle="collapse" href="#collapse2"> Brand</a>
													</h4> </div>
												<div id="collapse2" className="panel-collapse collapse in">
													<div className="panel-body">
														<div>
															<input type="text" className='searchBar' placeholder="Search Brands..." onChange={(e) => searchfilterdbrands(e.target.value.toLowerCase())} onKeyDown={(e) => searchfilterdbrands(e.target.value.toLowerCase())} />
														</div>
														<div className="filter_wrap _2">
															<div style={{
																"height": "250px",
																"overflowY": "auto",
																"overflowX": "hidden"
															}}>
																<div className="control-pagination">

																	{(filteredBrands || []).map((data, index) => (
																		<div className="form-group" key={`filteredbrand${index}`}>
																			<input type="checkbox" id={data.brandname} data-value={data.brandname} checked={data.ischecked} onChange={e => selectbrand(index, e)} />
																			<label htmlFor={data.brandname}>{data.brandname}  <span></span></label>
																		</div>))}

																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>}
									{/* {subcategories.length != 0 &&
										<div className="panel-group">
											<div className="panel panel-default">
												<div className="panel-heading">
													<h4 className="panel-title">
														<a data-toggle="collapse" href="#collapse4"> Sub-category</a>
													</h4> </div>
												<div id="collapse4" className="panel-collapse collapse in">
													<div className="panel-body">
														<div className="filter_wrap _2">
															<div style={{
																"height": "250px",
																"overflowY": "auto",
																"overflowX": "hidden"
															}}>
																<div className="control-pagination">
																	{(subcategories || []).map((data, index) => (
																		<div className="form-group">
																			<input type="checkbox" id={data.subcategoryname} data-value={data.subcategoryname} checked={data.ischecked} onChange={e => selectsubcategory(index, e)} />
																			<label htmlFor={data.subcategoryname}>{data.subcategoryname}  <span></span></label>
																		</div>))}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>} */}

									<div className="panel-group">
										<div className="panel panel-default">
											<div className="panel-heading">
												<h4 className="panel-title">
													<a data-toggle="collapse" href="#collapse3"> Price</a>
												</h4>
											</div>
											<div id="collapse3" className="panel-collapse collapse in">
												<div className="panel-body">
													<div className="filter_wrap _2">
														<p>Price Range</p>
														<Slider
															value={value}

															onChangeCommitted={rangeSelector}
															valueLabelDisplay="auto"
															min={0}
															step={100}
															max={pricefiltermaxamount}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</form>
						</div>
						<div className="col-md-9">
							<div className="menstop_heading">
								<div className="display_flex space_between2">
									<h3>Total Products <span>({productcount})</span></h3>
									<div className="display_flex sorting_product_filter">
										<li className="dropdown">
											<a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{sortnamevalue} <span className="caret"></span></a>
											<ul className="dropdown-menu">
												<li><a className="dropdown-item" onClick={lowtohigh}>Price Low to High</a></li>
												<li><a className="dropdown-item" onClick={hightolow}>Price High to Low</a></li>
												<li><a className="dropdown-item" onClick={removesortfilter}>Remove filter</a></li>
											</ul>
										</li>

									</div>
								</div>
							</div>
							<div className="products_filter_all">
								{loading ? (

									<div className="loader"></div>

								) : (
									<div className="select_for_box_wrapp row">
										{productlist.length === 0 ? <></> :
											productlist.map((i, index) =>
											(
												<>
													<div className="col-md-4" key={index}>
														{/* <a href={`/product/${i.SKU}`}> */}
														<div className="select_for_box_section select_for-box home_page_dialog">
															<a className="select_for_img img-responsive" href={`${i?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(i.SKU)? "": addproductinwishlist(i), addProductinAudit(i?.SKU) }}>
																<LazyLoadImage onClick={() => redirectToProductPage(i)} src={`${i.MainImage && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(i.MainImage) && i.MainImage!=".jpg"
																	? i.MainImage
																	: "/images/defultimg.svg"
																	}`} alt="" />
																{i.RetailPrice > i.SalePrice &&
																	<div className="badge_pro">{parseFloat(((i.RetailPrice - i.SalePrice) / i.RetailPrice) * 100).toFixed(0)}% off</div>}
																<div className="like_share_product">
																	<div className="social_section_show"></div>
																	<div className="social_section">
																		{wishlist.includes(i.SKU) === true &&
																			<a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(i)}><i className="fa-regular fa-heart" style={{ "background": "#ffa71d" }} ></i></a>}
																		{wishlist.includes(i.SKU) === false &&
																			<a data-toggle="modal" data-target="#defult" onClick={() => addproductinwishlist(i)}><i className="fa-regular fa-heart" ></i></a>}
																		{/* <Productshare productdata={i} /> */}
																		{state_account.profile.email==="" ? <a><i className="fa-solid fa-share"></i></a> : <a data-toggle="modal" data-target={`#example-sharemodel${escapeSelector(i.SKU)}`} onClick={() => { getproductdataforshare(i) }}><i className="fa-solid fa-share"></i></a>}
																	</div>
																</div>
															</a>
															<div className="select_for_text">
																<Link to={`${i?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(i.SKU)?"":addproductinwishlist(i), addProductinAudit(i?.SKU) }} data-tip data-for={`productName${index}`}>{(i.Name).substring(0, 70)}</Link>
																<ReactTooltip id={`productName${index}`} place="top" effect="solid">
																	{i.Name}
																</ReactTooltip>
																<ul className="list-unstyled display_flex space_between2">
																	<li><a href={`${i?.channels[0]?.url}`} target='_blank' onClick={() => { wishlist.includes(i.SKU)?"":addproductinwishlist(i), addProductinAudit(i?.SKU) }} className="real_price">{i.COO === "USA" ? "$" : "₹"}{i.SalePrice}</a></li>
																	{i.SalePrice !== i.RetailPrice && <li><span className="old_price">{i.COO === "USA" ? "$" : "₹"}{i.RetailPrice}</span></li>}
																</ul>
															</div>
														</div>
														{/* </a> */}
													</div>
												</>
											))}
									</div>)}
							</div>
							{(productcount / 18) > 1 &&
								<nav className="text-center">
									<ul className="pagination pagination-lg" role="navigation">
										{offset >= 1 &&
											<li><a href='#' onClick={e => previouspage(offset - 1)}>Prev</a></li>}
										<li className="active"><a>{offset + 1}</a></li>
										{(productcount / 18) >= (offset + 1) &&
											<li><a href='#' onClick={e => nextpage(offset + 1)}>Next</a></li>}
									</ul>
								</nav>}
						</div>
					</div>
				</div>
			</div >


			<Footer />

			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
			<div className="modal fade" id="example-sharemodel" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="feed_details modal-dialog offer_dialog modal-md">
					<div className="modal-content feed_modal_content">
						<button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
						<div className="modal-body">
							<div className="row">
								<div className="col-md-4">
									<div className="select_for-box home_page_dialog">
										<div className="select_for_img"> <LazyLoadImage src={productdata.MainImage} alt="" className="img-responsive" />
										</div>
										<div className="select_for_text">
											<p>{productdata.Name}</p>
											<ul className="list-unstyled display_flex space_between2">
												<li><a className="real_price">{productdata.COO === "USA" ? "$" : "₹"}{productdata.SalePrice}</a></li>
												<li><span className="old_price">{productdata.COO === "USA" ? "$" : "₹"}{productdata.RetailPrice}</span></li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-md-8">
									<div className="write_message">
										<form>
											<textarea rows="6" cols="10" className="form-control" placeholder="message here" onChange={handelchange}></textarea>
										</form>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-4">
									<div className="dropdown"> <a data-toggle="dropdown" className="share_with_btn" >
										{shareuserselection} <i className="fa-solid fa-angle-down"></i>
									</a>
										<ul className="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
											<li><a onClick={() => updatesharewithvalue("Public")}>Public</a></li>
											<li><a onClick={() => updatesharewithvalue("All friends")}>All friends</a></li>
											<li className="dropdown dropdown-submenu"> <a className="dropdown-toggle" data-toggle="dropdown">Specific friends</a>
												<ul className="dropdown-menu specific_friend_container">
													{friendlist.map((data, i) => (
														<ul className="display_flex space_between2 specific_friend" key={`friend${i}`}>

															<li>
																<LazyLoadImage alt="" src={`${data.profileimage[0] && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0])
																	? data.profileimage[0]
																	: data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
																	}`} />
																{/* <LazyLoadImage src={data.profileimage[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`} alt="" /> */}
															</li>
															<li>{data.friendfirstname[0]} {data.friendlastname[0]}</li>
															<li className="friend_send_btn" onClick={() => shareaproduct(productdata, data.friendemail)}>Send</li>
														</ul>
													))}

												</ul>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-8">
									<p><a className="modal_share_btn" onClick={() => shareaproduct(productdata, "")}>Share</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{reshare === true ? (
				<Productshare productdata={shareddata} pkey={"productdetails"} />
			) : <></>}
			{showNotification ? (
				<div className="addwishlist-pop-up-box">
					<div className="add-wishlist-row-section">
						<LazyLoadImage src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
						<h5>{showNotificationMessage}</h5>
					</div>
				</div>
			) : (
				<></>
			)}
			{state_account.showNotification ? (
				<div className="addwishlist-pop-up-box">
					<div className="add-wishlist-row-section">
						<LazyLoadImage src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
						<h5>{state_account.showNotificationMessage}</h5>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	)

}


export default ProductList;