/* eslint-disable */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get_sent_request, send_friend_request } from "../../store/friend/api"
const FriendCard = ({ i }) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const state_account = useSelector(state => state.account);
    const [issent, setIsSent] = useState(false);
    const sendfriendrequest = (friendemail) => {
        let payload = {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
            'friendemail': friendemail
        }
        dispatch(send_friend_request(payload, (res) => {
            if (res.status === 200) {
                setIsSent(true);
                getfriendlist();
            }
        }));
    }

    const getfriendlist = () => {
        let userpayload =
        {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token
        }
        dispatch(get_sent_request(userpayload, (res) => { }));
    }
    const routetotimeline = (i) => {
        history('/timeline', { state: { friendemail: i } });
    }

    // console.log(i)
    // debugger

    return (<>{state_account.profile.email === i.email ? <></> :
        <div className="friend_div display_flex space_between2 align_item_center">
            <div className="display_flex align_item_center">
                <div className="name_img_area" onClick={() => { routetotimeline(i.email) }} >
                    <img src={`${i.profileimage[0] && (i?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(i.profileimage[0]): {})
                        ? i.profileimage[0]
                        : i.gender === undefined ? "/images/onboarding/man.png" : i.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                        }`} alt="" className="name_img" />
                    {/* <img src={i.profileimage} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `${i.genderofsharedby == "" || undefined || null ? "images/onboarding/man.png" : "images/onboarding/woman.png"}`;
                    }} /> */}
                    {/* <img src={(i.profileimage == "" || i.profileimage == null) ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${i.profileimage}`} alt="" className="name_img" /> */}
                </div>
                <div className="name_content">
                    <p className="text_bold_600" onClick={() => { routetotimeline(i.email) }}>{i.firstname === "" && i.lastname === "" ? "Undefined" : `${i.firstname} ${i.lastname}`}</p>
                </div>
            </div>
            {i.status === 0 ?
                issent === false ?
                    <div className="remove_btn">
                        <a href="#" onClick={() => { sendfriendrequest(i.email) }}>{"Send"}</a>
                    </div>
                    : <div className="remove_btn">
                        <a href="#" >{"Request Sent"}</a>
                    </div> : <></>}
        </div>}
    </>);
}
export default FriendCard;