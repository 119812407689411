/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { get_subcategroylist_pagination } from "../../store/category/api"
import { useParams } from 'react-router-dom';
import { Footer, Header } from "components";
import { Link } from "react-router-dom";

function Subcategory() {
	// const history = useNavigate();
	const dispatch = useDispatch();
	const [subcategorylist, setSubategoryList] = useState([]);
	const offset = 0;
	const count = 50;
	const { id } = useParams();
	// const routetoproductdetails = (i) => {
	// 	history({
	// 		pathname: "/products",
	// 		state: {
	// 			categories: [id],
	// 			brands: [],
	// 			subcategories: [i._id],
	// 			minprice: "",
	// 			maprice: ""
	// 		}
	// 	});
	// }
	useEffect(() => {
		let isMounted = true;
		if (isMounted) get_subcategories();
		return () => { isMounted = false };
	}, []);
    function changevalueforurl(data) {
        let step1 = data.replace("&", "-")
        let step2 = step1.replace("/", ".")
        let step3 = step2.replace("/", ".")
        return step3
    }
	function changevaluefromurl(data) {
		let step1 = data.replace("-", "&")
		let step2 = step1.replace(".", "/")
		let step3 = step2.replace("-", "&")
		return step3
	}
	const get_subcategories = () => {
		let category = changevaluefromurl(id)
		var userpayload =
		{
			"gender": "",
			"COO": [],
			"category": [category],
		}
		dispatch(get_subcategroylist_pagination(userpayload, offset, count, (res) => {
			if (res.status === 200) {
				setSubategoryList(res.data);
			}
		}));
	}
	return (
		<>
			<div className="thetop"></div>
			<Header />
			<div className="brands_area">
				<div className="container">
					<div className="brand_header">Subcategories</div>
					<div className="brand_area_content">
						<div className="row">
							<div className="col-md-1"></div>
							<div className="col-md-10">
								<div className="row">
									{subcategorylist.length === 0 ? <></> :
										subcategorylist.map((i) =>
										(
											<div className="col-md-3 col-xs-6">
												<div className="brand_img_area">
													<div className="brand_img">
														<img src={i.image} alt="" classNamel="img-responsive" />
													</div>
													<p className="brand_title"><Link to={`/products/any&subcategory=${changevalueforurl(i._id)}`}>{i._id}</Link></p>
												</div>
											</div>))}
								</div>
							</div>
							<div className="col-md-1"></div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
		</>
	)
}

export default Subcategory;