/* eslint-disable */
import { Footer, Header } from "components";
import React from "react";

const About = () => {
  const twitter = "https://twitter.com/Jesssushop";
  const facebook = "https://www.facebook.com/Jesssushop";
  const linkedin = "https://www.linkedin.com/showcase/jesssu";
  const instagram = "https://www.instagram.com/jesssushop";
  return (
    <>
      <div className="thetop"></div>
      <Header />
      <div className="aboutus-box">
        <div className="contactus_area">
          <div className="container">
            <div className="row">
              <div className="col-md-1">
                <div className="aboutus-back">
                  <img src="images/contact/back.svg" alt="" />
                </div>
              </div>
              <div className="col-md-9">
                <div className="aboutus-section">
                  <h2>About Us</h2>
                  <div className="aboutusfixhead">
                    <div className="aboutus-description">
                      <p>JESSSU is an e-commerce shopping platform that provides you a new way of online
                        shopping experience. It's Powered by eDgeWrapper. The three 's' in Jesssu signifies our tag line that is Shop Share
                        and Self. <br></br>
                        <br></br>
                        In JESSSU there are variety of products across all different categories
                        for Men's and Women's fashion,
                        where you can find great outfits for your everyday's looks. <br></br>
                        <br></br>
                        The main feature of Jesssu is it's own
                        SOCIAL section that makes Jesssu stands out among the other shopping platforms.
                        Here you can Invite and Connect to your Friends and share your wishlist with them.
                        You can also Create Post or share any product in the SOCIAL section. You can Like
                        Comment and Share any post.  <br></br>
                        <br></br>
                        Beside this JESSSU has a STASH section. In this section you will get an option
                        'CREATE LOOK',
                        where you can Create your own Look by your Wishlisted products and Share them in the
                        SOCIAL section.  <br></br>
                        <br></br>
                        Also you can Earn JESSSU COINS by Shopping and Sharing. You can Withdraw these coinsto your bank
                        account as a Real cash. <br></br>
                        <br></br>
                        We are giving our full effort to make our dreams come true.
                        The dream of
                        taking our company into the list of the best companies in the country, the dream of
                        making it a company
                        known for it's Quality Products, Quality Services and Commitment to work. And all we
                        need to
                        achieve this is your support. So stay connected with us and let's enjoy this journey
                        together.</p>
                    </div>
                    <div className="aboutus-row">
                      <a href={`${twitter}`} target="_blank" rel="noopener noreferrer"><img src="images/contact/twitter-black.svg" alt="" /></a>
                      <a href={`${facebook}`} target="_blank" rel="noopener noreferrer"><img src="images/contact/facebook-black.svg" alt="" /></a>
                      <a href={`${linkedin}`} target="_blank" rel="noopener noreferrer"><img src="images/contact/linkedin-black.svg" alt="" /></a>
                      <a href={`${instagram}`} target="_blank" rel="noopener noreferrer"><img src="images/contact/instagram-black.svg" alt="" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
