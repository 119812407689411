/* eslint-disable */
import { FriendSideBar, Header, ProfileSideBar } from 'components';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { user_signin } from 'store/account/accountActions';
import { get_user_profile_details, jesssu_user_profileimage_upload, jesssu_users_update_brand_category, jesssu_users_update_profile } from "../../store/account/api"
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
function Profiledetails() {
	const history = useNavigate();
	const dispatch = useDispatch();
	const state_account = useSelector(state => state.account);
	const [profiledata, setProfiledata] = useState(state_account.profile);
	const [profileimage, setprofileImage] = useState('https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y');
	const [firstName, setfirstName] = useState('');
	const [lastName, setlastName] = useState('');
	const [email, setemail] = useState('');
	const [category, setCategory] = useState('');
	const [brand, setBrand] = useState('');
	const [model, setmodel] = useState(false)
	const [age, setage] = useState('');
	const [gender, setgender] = useState('');
	const [COO, setCOO] = useState('')
	const [previousCOO, setpreviousCOO] = useState('')


	const [issaving, setIsSaving] = useState(false);
	const [issaved, setIsSaved] = useState(false);

	useEffect(() => {
		if(state_account.profile.email===""){
            history('/login')
        }
		let isMounted = true;
		if (isMounted) get_profile_details();
		return () => { isMounted = false };
	}, []);

	const handleChange = (e) => {
		if (e.target.id === "firstName") {
			setfirstName(e.target.value)
		}
		else if (e.target.id === "lastName") {
			setlastName(e.target.value)
		}
		else if (e.target.id === "email") {
			setemail(e.target.value)
		}
		else if (e.target.id === "age") {
			setage(e.target.value)
		}
		else if (e.target.id === "male") {
			setgender("Men")
		}
		else if (e.target.id === "female") {
			setgender("Women")
		}
		else if (e.target.id === "unisex") {
			setgender("Unisex")
		}
		else if (e.target.id === "USA") {
			setCOO("USA")
		}
		else if (e.target.id === "INDIA") {
			setCOO("INDIA")
		}
	};
	const upload_profile_image = (e) => {
		if (e) {
			const formData = new FormData();
			formData.append(
				"file",
				e.target.files[0]
			);
			jesssu_user_profileimage_upload(formData, (res) => {
				
				setprofileImage(res.data)
				profiledata.profileimage = res.data				
				dispatch(user_signin(profiledata))
			})
		}
	}

	const get_profile_details = () => {
		let payload = {
			"token": state_account.profile.token,
			"email": state_account.profile.email,
		}

		dispatch(get_user_profile_details(payload, (res) => {
			if (res.status === 200) {
				// console.log(res.data)
				dispatch(user_signin(res.data));
				setProfiledata(res.data);
				if (res.data.profileimage === "") {
					setprofileImage("https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y")
				} else {
					setprofileImage(res.data.profileimage);
				}
				setfirstName(res.data.firstname);
				setlastName(res.data.lastname);
				setage(res.data.age);
				setemail(res.data.email);
				setCategory(res.data.category);
				setBrand(res.data.brand);
				setgender(res.data.gender);
				setCOO(res.data.COO[0])
				setpreviousCOO(res.data.COO[0])
			}
		}));
	}

	function update_profile() {
		if (COO === previousCOO) {
			edit_profile()
		}
		else {
			setmodel(true)
		}
	}




	function edit_profile() {
		setIsSaving(true);
		let userpayload = {
			firstname: firstName,
			gender: gender,
			lastname: lastName,
			age: age,
			brand: state_account.profile.brand,
			category: state_account.profile.category,
			profileimage: profileimage,
			COO: [COO],
			token: state_account.profile.token,
			email: state_account.profile.email,
		}
		// dispatch(user_signin(userpayload));
		dispatch(jesssu_users_update_profile(userpayload, (res) => {
			if (res.status === 200) {
				get_profile_details();
				setIsSaving(false);
				setIsSaved(true);
			}
		}));
	}
	function edit_profile_with_new_country_of_origin() {
		setIsSaving(true);
		let userpayload = {
			firstname: firstName,
			gender: gender,
			lastname: lastName,
			age: age,
			brand: state_account.profile.brand,
			category: state_account.profile.category,
			profileimage: profileimage,
			COO: [COO],
			token: state_account.profile.token,
			email: state_account.profile.email,
		}
		// dispatch(user_signin(userpayload));
		dispatch(jesssu_users_update_profile(userpayload, (res) => {
			if (res.status === 200) {
				dispatch(jesssu_users_update_brand_category(userpayload, (result) => {
					if(result.status === 200){
					window.localStorage.clear();
					history('/login');}
				}))
			}
		}));
	}


	return (
		<>
			<div className="thetop"></div>
			<Header />
			<Helmet>
                <title>Your Profile | View Your Personal Details & Activity</title>
                <meta name="description" content="Access your Jesssu profile. View your personal details, activity history, and interactions. Manage your account and stay updated with your contributions." />
                <meta name="keywords" content="profile, personal details" />
            </Helmet>
			<div className="stash_my_look_area">
				<div className="container">
					<div className="row">
						<ProfileSideBar />
						<div className="col-md-6">
							<div className="profile_area">
								<div className="profile_box">
									<div className="profile_header_bg"></div>
									<div className="profile_header_content">
										<div className="display_flex space_between2 align_item_center">
											<div className="display_flex align_item_center">
												<div className="profile_user_img">
													<LazyLoadImage src={`${state_account.profile.profileimage}`} alt="User profile image" />
													<div className='avatar-edit'>
														<input type='file' id="imageUpload" className='imageUpload'
															accept=".png, .jpg, .jpeg" onChange={upload_profile_image} />
														<a href='#'><label htmlFor="imageUpload"><i className="fa-solid fa-camera"></i></label></a>
													</div>
												</div>
												<div className="profile_data_content">
													<div className="name_content">
														<p className="name_content_heading">{profiledata.firstname} {profiledata.lastname}</p>
														<p><i className="fa-solid fa-circle-dollar-to-slot"></i> {state_account.profile.jesssucoin} Jesssu coins</p>
													</div>
												</div>
											</div>
											{/* <p className="profile_delete delete_icon"><i className="fa-solid fa-pencil"></i></p> */}
										</div>
									</div>
									<div className="profile_second">
										<div className="row">
											<div className="col-md-6">
												<p className="related_product_heading text_left">Personal Information</p>
												<div className="profile_update">
													<div className="form-area">
														<p>First Name </p>
														<input type="text" defaultValue={profiledata.firstname} id="firstName" onChange={(e) => handleChange(e)} /> </div>
													<div className="form-area">
														<p>Last Name </p>
														<input type="text" defaultValue={profiledata.lastname} id="lastName" onChange={(e) => handleChange(e)} /> </div>
													<div className="form-area">
														<p>Country of Origin </p>
														<ul className="gender_area display_flex space_between2 list-unstyled">
															<li>
																<input type='radio' id='USA' name='COO' onChange={(e) => handleChange(e)} checked={COO === "USA" ? true : false} />
																<label htmlFor='USA'>	<div className="brand_circle_title">
																	<LazyLoadImage src="/images/onboarding/USA.png" alt="USA flag icon" />
																</div> USA</label>
															</li>
															<li>
																<input type='radio' id='INDIA' name='COO' onChange={(e) => handleChange(e)} checked={COO === "INDIA" ? true : false} />
																<label htmlFor='INDIA'>	<div className="brand_circle_title">
																	<LazyLoadImage src="/images/onboarding/INDIA.png" alt="INDIA flag icon" />
																</div> INDIA</label>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<p className="related_product_heading text_left"> Contact Details </p>
												<div className="profile_update">
													<div className="form-area">
														<p>Email </p>
														<input type="email" defaultValue={profiledata.email} id="email" onChange={(e) => handleChange(e)} disabled /> </div>
													<div className="form-area">
														<p>Age </p>
														<input type="number" defaultValue={profiledata.age} id="age" onChange={(e) => handleChange(e)} /> </div>
													<div className="form-area">
														<p className="text-bold text_bold_600">GENDER </p>
														<ul className="gender_area display_flex space_between2 list-unstyled">
															<li>
																<input type='radio' id='male' name='gender' onChange={(e) => handleChange(e)} checked={gender === "Men" ? true : false} />
																<label htmlFor='male'>	<div className="brand_circle_title">
																	<LazyLoadImage src="/images/onboarding/man.png" alt="Male icon" />
																</div> Male</label>
															</li>
															<li>
																<input type='radio' id='female' name='gender' onChange={(e) => handleChange(e)} checked={gender === "Women" ? true : false} />
																<label htmlFor='female'>	<div className="brand_circle_title">
																	<LazyLoadImage src="/images/onboarding/woman.png" alt="Female icon" />
																</div> Female</label>
															</li>
															<li>
																<input type='radio' id='unisex' name='gender' onChange={(e) => handleChange(e)} checked={gender === "Unisex" ? true : false} />
																<label htmlFor='unisex'>	<div className="brand_circle_title">
																	<LazyLoadImage src="/images/onboarding/transgender.png" alt="Transgender icon" />
																</div> Other</label>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										<button type="button" className="get_otp" data-toggle="modal" data-target="#key" onClick={() => { update_profile() }}>{issaving ? "SAVING..." : issaved ? "PROFILE DETAILS UPDATED" : "SAVE"}</button>
									</div>

								</div>
							</div>
						</div>
						<FriendSideBar />
					</div>
				</div>
			</div>
			{model === true &&
				<div className="modal fade" id="key" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="feed_details modal-dialog offer_dialog modal-md">
						<div className="modal-body">
							<div className="friends-timeline-pop-up">
								<div className="stash-pop-up-section">'
									<div className="stash-pop-up-box">
										<div className="wishlist-Just-checking-section">
											<h6>New country of origin selection detected!</h6>
										</div>
										<div className="delete-section">
											<h6>To update new country of origin you need to select brands and categories for new country after new login</h6>
										</div>
										<div className="checking-row">
											<button className="checkingforyes-btn-default" data-dismiss="modal" onClick={() => { edit_profile_with_new_country_of_origin() }}>YES</button>
											<button  className="checking-btn-default" data-dismiss="modal" onClick={() => { edit_profile() }}>NO</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>}
			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
		</>
	)
}

export default Profiledetails;