
import axios from "axios"
import { jesssuApi, jesssurecomendatioApi } from '../../config';


export const getallbrands = async (payload, pageno, count, callback) => {
    await axios.post(`${jesssuApi}/jesssu/newproducts/getbrandlist/${pageno}/${count}`, payload)
        .then(res => {
            const value = res.data

            callback({ success: true, data: value });
        })
        .catch(err => {
            console.log(err)
        })
}

export const getSearchSuggestion = (payload, callback) => async (dispatch) => {
    await axios.post(`${jesssurecomendatioApi}/search-suggestions`, payload)
        .then(res => {
            const value = res.data
            callback({ success: true, data: value });
        })
        .catch(err => {
            console.log(err)
        })
}

export const get_product_list_pagination =
    (payload, offset, count, callback) => async (dispatch) => {

        axios.post(`${jesssuApi}/jesssu/newproducts/getfiltered/type/${offset}/${count}`, payload)
            .then(res => {

                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const get_product_list_filter_product_count =
    (payload, callback) => async (dispatch) => {

        axios.post(`${jesssuApi}/jesssu/newproducts/getfiltered/count/filteredproduct/total/forjesssu`, payload)
            .then(res => {

                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const get_product_list_newarrival_count =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/productlist/newarrivalscount`, payload)
            .then(res => {

                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }


export const get_product_details =
    (payload, productid, callback) => async (dispatch) => {

        axios.post(`${jesssuApi}/jesssu/newproducts/getsingleproduct/${productid}`, payload)
            .then(res => {

                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const getallproducts = async (payload, SKU, callback) => {
    await axios.post(`${jesssuApi}/jesssu/newproducts/getsingleproduct/${SKU}`, payload)
        .then(res => {
            const value = res.data

            callback({ success: true, data: value });
        })
        .catch(err => {
            console.log(err)
        })
}

export const get_product_newarrivals =
    (payload, pageno, size, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/productlist/newarrivals/${pageno}/${size}`, payload)
            .then(res => {

                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const get_product_maxamount =
    (payload, callback) => async () => {
        axios.post(`${jesssuApi}/jesssu/newproducts/getfiltered/maxprice`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }


export const get_product_list_homepagesection =
    (payload, pageno, size, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/products/getdataforproductlist/homepagesection/${pageno}/${size}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }


export const get_product_details_homepagesection =
    (payload, pageno, size, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/products/getdataforproductdetails/homepagesection/${pageno}/${size}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const get_product_variation =
    (payload, productid, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/getsingleproductvaritation/${productid}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const get_selected_brand_category_product =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/userselected/brandcategory/get/products`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

export const get_user_visited_product =
    (payload, pageno, size, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/products/jesssu/products/getnewvisitedproducts/${pageno}/${size}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    }

    export const get_AI_Recommended_Product = (payload, callback) => async (dispatch) => {
        await axios.post(`${jesssuApi}/api/getairecommendedproducts`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status })
            })
            .catch(err => {
                callback({ success: false, status: err.status });
            })
    }
