import axios from "axios"
import { jesssuApi } from '../../config';
import { add_wishlist_state } from "./stashActions";

export const get_wishlist =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getwishlistproduct`, payload)
            .then(res => {
                dispatch(add_wishlist_state(res.data))
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const add_delete_wishlist_product =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/gettransparentimage`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const get_mylook_list =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getbundles`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const delete_single_look =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/deletesinglebundles`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const get_look_background =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/look/getlookimages`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const create_new_look =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/createnewbundle`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };
export const edit_my_look =
    (payload, callback) => async (dispatch) => {
        axios.patch(`${jesssuApi}/api/editbundle`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };