// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.look-empty-heading-section h6 {
    font: normal normal normal 16px/15px Poppins;
    letter-spacing: 0px;
    color: #8C8C8C;
}


.look-empty-box {
    background: #fff;
    padding: 60px;
    border-radius: 10px;
    border: 1px solid #fff;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 30px;
    margin-bottom: 30px;
}

.look-empty-img {
    text-align: center;
    padding: 4rem 0 0;
}

.look-empty-img img {
    width: 70%;
    height: 20rem;
}

.look-empty-heading-section {
    text-align: center;
    padding: 3rem 0 0;
}

.look-empty-heading-section p {
    font: normal normal normal 18px/20px Poppins;
    letter-spacing: 0px;
    color: #8C8C8C;
    font-weight: 500;
}

.explore-look-btn {
    padding: 0 40px 2rem;
}

.save_btn-look {
    width: 100%;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 12px;
    color: #000000;
    display: block;
    text-align: center;
    margin-top: 24px;
    border: 1px solid #afafb5;
}

.save_btn-look:hover {
    text-decoration: none;
    color: #fff;
    background: transparent linear-gradient(90deg, #FF9712 0%, #FFF456 100%) 0% 0% no-repeat padding-box;
}

@media (max-width: 700px) {
    .look-empty-box {
        padding: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Mylook/look.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,mBAAmB;IACnB,cAAc;AAClB;;;AAGA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,iCAAiC;IACjC,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,4CAA4C;IAC5C,mBAAmB;IACnB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,oGAAoG;AACxG;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".look-empty-heading-section h6 {\n    font: normal normal normal 16px/15px Poppins;\n    letter-spacing: 0px;\n    color: #8C8C8C;\n}\n\n\n.look-empty-box {\n    background: #fff;\n    padding: 60px;\n    border-radius: 10px;\n    border: 1px solid #fff;\n    box-shadow: 0px 3px 6px #00000029;\n    margin-top: 30px;\n    margin-bottom: 30px;\n}\n\n.look-empty-img {\n    text-align: center;\n    padding: 4rem 0 0;\n}\n\n.look-empty-img img {\n    width: 70%;\n    height: 20rem;\n}\n\n.look-empty-heading-section {\n    text-align: center;\n    padding: 3rem 0 0;\n}\n\n.look-empty-heading-section p {\n    font: normal normal normal 18px/20px Poppins;\n    letter-spacing: 0px;\n    color: #8C8C8C;\n    font-weight: 500;\n}\n\n.explore-look-btn {\n    padding: 0 40px 2rem;\n}\n\n.save_btn-look {\n    width: 100%;\n    padding: 10px 20px;\n    font-size: 18px;\n    border-radius: 12px;\n    color: #000000;\n    display: block;\n    text-align: center;\n    margin-top: 24px;\n    border: 1px solid #afafb5;\n}\n\n.save_btn-look:hover {\n    text-decoration: none;\n    color: #fff;\n    background: transparent linear-gradient(90deg, #FF9712 0%, #FFF456 100%) 0% 0% no-repeat padding-box;\n}\n\n@media (max-width: 700px) {\n    .look-empty-box {\n        padding: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
