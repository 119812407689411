import axios from "axios"
import { jesssuApi } from '../../config';

export const get_categroylist_pagination =
    (payload, offset, count, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/getcategory/${offset}/${count}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const get_subcategroylist_pagination =
    (payload, offset, count, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/getsubcategorybycategory/${offset}/${count}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const get_brandlist_pagination =
    (payload, pageno, size, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/get/brands/bycategory/${pageno}/${size}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

    export const get_all_brandlist_pagination =
    (payload, pageno, size, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/getbrandlist/${pageno}/${size}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };


export const get_brandlist_pagination_by_search =
    (payload, offset, count, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/search/getbrands/${offset}/${count}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

    export const get_categorylist_pagination_by_search =
    (payload, offset, count, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/search/getcategories/${offset}/${count}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const get_categorylist_by_brand_pagination =
    (payload, pageno, size, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/jesssu/newproducts/get/categories/bybrand/${pageno}/${size}`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };