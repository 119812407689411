import * as types from "./actionTypes";
let user = null;
if (localStorage.getItem('user-info')) {
  user = JSON.parse(localStorage.getItem('user-info'));
}
let userobj = {
  loaded: false,
  loading: false,
  token: "",
  profile: {
    walletaddress: "",
    email: user == null ? "" : user.email,
    firstname: user == null ? "" : user.firstname,
    profileimage: user == null ? "" : user.profileimage,
    lastname: user == null ? "" : user.lastname,
    age: user == null ? "" : user.age,
    COO: user == null ? ["INDIA"] : user.COO,
    gender: user == null ? "" : user.gender,
    profilebannerimage: user == null ? "" : user.profilebannerimage,
    bio: user == null ? "" : "",
    category: user == null ? [] : user.category,
    brand: user == null ? [] : user.brand,
    categorydata: user == null ? [] : user.categorydata,
    branddata: user == null ? [] : user.branddata,
    token: user == null ? "" : user.token,
    data: {},
    jesssucoin: user == null ? 0 : user.jesssucoin,
    lookimages: user == null ? 0 : user.lookimages,
    provider: user == null ? "" : user.provider

  },
  favourites: [],
  followers: [],
  followings: [],
  recommenedusers: [],
  feed: [],
  followercount: 0,
  followingcount: 0,
  showNotification: false,
  showNotificationMessage: "",
  userfriendlist: [],
  userwishlistproducts: [],
  userlooklist: [],
  productdetails: [],
  notificationcount: 0,
  cartvalue: [],
  toogleboxsocialcount: 0,
  search: '',
  brandlist: [],
  categorylist: [],
  firebasetokenidflag: false

};



export default function accountReducer(state = userobj, { type, payload }) {
  switch (type) {
    // case types.USER_SIGNIN:
    //   if (payload.firstname !== undefined && payload.firstname.length > 0)
    //     state.profile.firstname = payload.firstname
    //   if (payload.lastname !== undefined && payload.lastname.length > 0)
    //     state.profile.lastname = payload.lastname
    //   if (payload.profileimage !== undefined)
    //     state.profile.profileimage = payload.profileimage
    //   if (payload.email !== undefined && payload.email.length > 0)
    //     state.profile.email = payload.email
    //   if (payload.age !== undefined && payload.age.length > 0)
    //     state.profile.age = payload.age
    //   if (payload.COO !== undefined && payload.COO.length > 0)
    //     state.profile.COO = payload.COO
    //   if (payload.gender !== undefined && payload.gender.length > 0)
    //     state.profile.gender = payload.gender
    //   if (payload.referalcode !== undefined && payload.referalcode.length > 0)
    //     state.profile.referalcode = payload.referalcode
    //   if (payload.category !== undefined && payload.category.length > 0)
    //     state.profile.category = payload.category
    //   if (payload.brand !== undefined && payload.brand.length > 0)
    //     state.profile.brand = payload.brand
    //   if (payload.categorydata !== undefined && payload.categorydata.length > 0)
    //     state.profile.categorydata = payload.categorydata
    //   if (payload.branddata !== undefined && payload.branddata.length > 0 && payload.branddata !== null )
    //     state.profile.branddata = payload.branddata
    //   if (payload.token !== undefined && payload.token.length > 0)
    //     state.profile.token = payload.token
    //   if (payload.jessucoin !== undefined && payload.jessucoin !== null)
    //     state.profile.jesssucoin = payload.jessucoin
    //   if (payload.lookimages !== undefined && payload.lookimages > 0)
    //     state.profile.lookimages = payload.lookimages

    //   return {
    //     ...state,
    //     loaded: true,
    //     loading: false,
    //   };
    case types.USER_SIGNIN:
      return {
        ...state,
        profile: {
          ...state.profile,
          firstname: (payload.firstname !== undefined && payload.firstname.length > 0) ? payload.firstname : state.profile.firstname,
          lastname: (payload.lastname !== undefined && payload.lastname.length > 0) ? payload.lastname : state.profile.lastname,
          profileimage: (payload.profileimage !== undefined) ? payload.profileimage : state.profile.profileimage,
          email: (payload.email !== undefined && payload.email.length > 0) ? payload.email : state.profile.email,
          age: (payload.age !== undefined && payload.age.length > 0) ? payload.age : state.profile.age,
          COO: (payload.COO !== undefined && payload.COO.length > 0) ? payload.COO : state.profile.COO,
          gender: (payload.gender !== undefined && payload.gender.length > 0) ? payload.gender : state.profile.gender,
          referalcode: (payload.referalcode !== undefined && payload.referalcode.length > 0) ? payload.referalcode : state.profile.referalcode,
          category: (payload.category !== undefined && payload.category.length > 0) ? payload.category : state.profile.category,
          brand: (payload.brand !== undefined && payload.brand.length > 0) ? payload.brand : state.profile.brand,
          categorydata: (payload.categorydata !== undefined && payload.categorydata.length > 0) ? payload.categorydata : state.profile.categorydata,
          branddata: (payload.branddata !== undefined && payload.branddata.length > 0 && payload.branddata !== null) ? payload.branddata : state.profile.branddata,
          token: (payload.token !== undefined && payload.token.length > 0) ? payload.token : state.profile.token,
          jesssucoin: (payload.jessucoin !== undefined && payload.jessucoin !== null) ? payload.jessucoin : state.profile.jesssucoin,
          lookimages: (payload.lookimages !== undefined && payload.lookimages > 0) ? payload.lookimages : state.profile.lookimages,
          provider: (payload.provider !== undefined && payload.provider.length > 0) ? payload.provider : state.profile.provider,
        },
        loaded: true,
        loading: false,
      };
    case types.USER_ALL_USERS:
      return {
        ...state,
        loaded: true,
        loading: false,
        recommenedusers: payload,
      }

    case types.BRAND_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        brandlist: payload,
      }
    case types.CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        categorylist: payload,
      }
    case types.NEW_SEARCH_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        search: payload,
      }
    case types.UPDATE_TOGGLE_BOX_SOCIAL_COUNT_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        toogleboxsocialcount: payload,
      };
    case types.USER_FOLLOWER_COUNT:
      return {
        ...state,
        loaded: true,
        loading: false,
        followercount: payload,
      };
    case types.NOTIFICATION_SUCCESS:
      return {
        ...state,
        showNotification: payload,
      };

    case types.NOTIFICATION_MESSAGE:
      return {
        ...state,
        showNotificationMessage: payload,
      };
    case types.NOTIFICATION_COUNT:
      return {
        ...state,
        notificationcount: payload,
      };
    case types.FRIEND_LIST_SUCCESS:
      return {
        ...state,
        userfriendlist: payload,
      };
    case types.USER_LOOK_DATA_SUCCESS:
      return {
        ...state,
        userlooklist: payload,
      };
    case types.USER_WISHLIST_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        userwishlistproducts: payload,
      };
    case types.PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productdetails: payload,
      };

    case types.GET_CART_VALUE_SUCCESS:
      return {
        ...state,
        cartvalue: payload,
      };
    case types.FIREBASE_TOKENID_SAVE_SUCCESS:
      return {
        ...state,
        firebasetokenidflag: payload
      }

    default:
      return state;
  }
}
