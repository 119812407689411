import * as types from "./socialTypes";
// let user = null;
// if (localStorage.getItem('user-info')) {
//   user = JSON.parse(localStorage.getItem('user-info'));
// }
let socialobj = {
  allsocialshop: [],
  allsocialshare: [],
  allcategories: [],
  allbrands: [],

  singleshare: [typeof any],
  singlesharelike: [typeof any],
  singlesharedislike: [typeof any],
  singlesharecomment: [typeof any],
  singleshareshareby: [typeof any],

};



export default function socailReducer(state = socialobj, { type, payload }) {
  switch (type) {
    case types.GET_ALL_SOCIAL_SUCCESS_SHOP:
      return {
        ...state,
        allsocialshop: payload,
      };
    case types.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        allcategories: payload,
      };
    case types.GET_ALL_BRANDS_SUCCESS:
      return {
        ...state,
        allbrands: payload,
      };

    case types.GET_SINGLE_SHARE_SUCCESS:
      return {
        ...state,
        singleshare: payload,
      };

    case types.GET_SINGLE_SHARE_LIKE_SUCCESS:
      return {
        ...state,
        singlesharelike: payload,
      };

    case types.GET_SINGLE_SHARE_DISLIKE_SUCCESS:
      return {
        ...state,
        singlesharedislike: payload,
      };

    case types.GET_SINGLE_SHARE_COMMENT_SUCCESS:
      return {
        ...state,
        singlesharecomment: payload,
      };

    case types.GET_SINGLE_SHARE_SHAREBY_SUCCESS:
      return {
        ...state,
        singleshareshareby: payload,
      };


    default:
      return state;
  }
}
