import * as types from "./friendTypes";


export function friend_list_action(payload) {
  return {
    type: types.FRIEND_LIST,
    payload: payload,
  };
}

export function receive_request_action(payload) {
  return {
    type: types.RECEIVED_REQUEST,
    payload: payload,
  };
}

export function sent_request_action(payload) {
  return {
    type: types.SENT_REQUEST,
    payload: payload,
  };
}

export function recomended_friendlist_success(payload) {
  return {
    type: types.RECOMENDED_FRIEND_LIST_SUCCESS,
    payload: payload,
  };
}