/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_categroylist_pagination } from "../../store/category/api"
import { Header, ProfileSideBar } from "components";
import { user_signin } from "store/account/accountActions";
import { jesssu_users_update_profile } from "store/account/api";
import ReactTooltip from "react-tooltip";
import { get_user_profile_details } from "../../store/account/api"
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProfileCategory = () => {
	const dispatch = useDispatch();
	const [categories, setCategories] = useState([]);
	const state_account = useSelector(state => state.account);
	const [selectedcategories, setSelectedCategories] = useState([]);
	const [selectedcategoryname, setSelectedCategoryName] = useState([]);
	const [issaving, setIsSaving] = useState(false);
	const [issaved, setIsSaved] = useState(false);
	let selectedcategorynamenew = []
	let offset = 0;
	const count = 60;
	useEffect(() => {
		let isMounted = true;
		// getcategories();
		if (isMounted) initSelectedCategory()
		return () => { isMounted = false };
	}, []);
	function initSelectedCategory() {
		if (state_account?.profile?.categorydata?.length > 0) {
			// let selectedcategoryname = []
			for (let i = 0; i < state_account.profile.categorydata.length; i++) {
				let cat = {
					_id: state_account.profile.categorydata[i].categoryname,
					image: state_account.profile.categorydata[i].categoryimage,
				}
				setSelectedCategories(current => [...current, cat]);
				setSelectedCategoryName(current => [...current, cat._id]);
				selectedcategorynamenew.push(cat._id)

				if (i === (state_account.profile.categorydata.length - 1)) {
					getcategories(selectedcategorynamenew)
				}
			}
			// dispatch(get_selected_category_name_success(selectedcategoryname))

		}
		else {
			getcategories(selectedcategorynamenew)
		}

	}

	const get_profile_details = () => {
		let payload = {
			"token": state_account.profile.token,
			"email": state_account.profile.email,
		}

		dispatch(get_user_profile_details(payload, (res) => { dispatch(user_signin(res.data));
		}))
	}

	async function getcategories(selectedcategorynamenew) {
		let userpayload =
		{
			"email": state_account.profile.email,
			"token": state_account.profile.token,
			"COO": state_account.profile.COO,
			"gender": state_account.profile.gender
		}
		dispatch(get_categroylist_pagination(userpayload, offset, count, (res) => {
			// if (res.status == 200) {
			for (let i = 0; i < res.data.length; i++) {

				if (selectedcategorynamenew.includes(res.data[i]._id)) {
					res.data[i].status = true
				} else {
					res.data[i].status = false
				}
				setCategories(current => [...current, res.data[i]]);


			}
			// setCategories(res.data);
			// initSelectedCategory();
			// }
		}));
	}
	function selectCategory(category, index) {
		if (selectedcategories.some(e => e._id === category._id)) {
			const newList = selectedcategories.filter((item) => item._id !== category._id);
			setSelectedCategories(newList);

		} else {
			setSelectedCategories(current => [...current, category]);

		}

		if (categories[index].status === true) {
			const newcategorylist = categories.map((i = typeof any, count) => {
				if (count === index) {
					return { ...i, status: false };
				}
				return i;
			});
			setCategories(newcategorylist);
		} else {
			const newcategorylist = categories.map((i = typeof any, count) => {
				if (count === index) {
					return { ...i, status: true };
				}
				return i;
			});
			setCategories(newcategorylist);
		}



		if (selectedcategoryname.some(e => e === category._id)) {
			const newList = selectedcategoryname.filter((item) => item !== category._id);
			setSelectedCategoryName(newList);
		} else {
			setSelectedCategoryName(current => [...current, category._id]);
		}
	}

	function updateCategory() {
		setIsSaving(true);

		var categorypayload = {
			category: selectedcategoryname
		}
		dispatch(user_signin(categorypayload));
		const userPayload = state_account.profile
		userPayload.category = selectedcategoryname
		dispatch(jesssu_users_update_profile(userPayload, (res) => {
			if (res.status === 200) {
				setIsSaving(false);
				setIsSaved(true);
				get_profile_details()
			}
		}));
	}
	return (
		<>
			<div className="thetop"></div>
			<Header />
			<Helmet>
                <title>Select Categories & Get Tailored Product Suggestions | Jesssu</title>
                <meta name="description" content="Select your favorite categories on Jesssu and get tailored product recommendations just for you. Explore top picks and find the best deals based on your preferences." />
                <meta name="keywords" content="catagories, tailored product" />
            </Helmet>
			<div className="stash_my_look_area">
				<div className="container">
					<div className="row">
						<ProfileSideBar />
						<div className="col-md-6">
							<div className="profile_area">
								<p className="text_bold_600 text-center margin-bottom-30">Select 6 or more categories</p>
								<div className="classic_height">
									<div className="brand_1_area">
										<div className="row">
											{categories.map((category, i) => (
												<div className="brand_1 col-md-4 col-xs-6" key={i}>
													{category.status === false &&
														<input className="checkbox-btn" name="checkbox-collection" value={category._id}
															id={i} type="checkbox" onChange={() => { selectCategory(category, i) }} />}
													{category.status === true &&
														<input className="checkbox-btn" name="checkbox-collection" value={category._id}
															id={i} type="checkbox" onChange={() => { selectCategory(category, i) }} checked={true} />}
													<label className="checkbox-label" htmlFor={i}>
														<div className="itemcontent">
															<div className="brand_circle_title">
																<LazyLoadImage src={`${category.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(category.image)
																	? category.image
																	: "/images/defultimg.svg"
																	}`} alt={`${category?._id} category image`} />
																{/* <LazyLoadImage src={category.image}></img> */}
															</div>
														</div>
													</label>
													<p className="brand_1_heading" data-tip data-for={`categorytip${i}`}>{category._id !== null && category._id !== undefined ? category._id.substring(0, 7) : ""}...</p>
													<ReactTooltip id={`categorytip${i}`} place="top" effect="solid">{category._id}</ReactTooltip>
												</div>
											)
											)
											}
										</div>
									</div>
								</div>

							</div>
						</div>

						<div className="col-md-3">
							<div className="related_product">
								<p className="related_product_heading">Selected categories</p>
								<div className="classic_container no_margin profile_right_sidebar">
									<div className="classic_height brand_1_right">
										<div className="row">

											{selectedcategories.length === 0 ? <></> :
												selectedcategories.map((category, key) => (
													<div className="brand_1 col-xs-6" key={key}>
														<div className="border_area">
															<LazyLoadImage src={`${category.image && /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(category.image)
																? category.image
																: "/images/defultimg.svg"
																}`} alt={`${category?._id} category image`} />
															{/* <LazyLoadImage src={category.image} alt="" /> */}
														</div>
														<p className="brand_1_heading" data-tip data-for={`selectedcategorytip${key}`}>{category._id !== null && category._id !== undefined ? category._id.substring(0, 7) : ""}...</p>
														<ReactTooltip id={`selectedcategorytip${key}`} place="top" effect="solid">{category._id}</ReactTooltip>
													</div>))}
										</div>
									</div>
									<p><a onClick={() => { updateCategory() }} className="save_btn">{issaving ? "SAVING..." : issaved ? "UPDATED" : "SAVE"}</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
		</>
	)
}

export default ProfileCategory;