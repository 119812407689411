/* eslint-disable */
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { get_notification_count, get_cart_value } from "store/account/api";
// import ScriptTag from "react-script-tag";
import { new_search } from "store/account/accountActions"

import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
const Header = () => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const state_account = useSelector((state) => state.account);
  // const [number, setnumber] = useState(0)

  useEffect(() => {
    localStorage.setItem("Number", 0)
    $(".login_btn_nav_show, .search_nav_button").click(function () {
      $(".login_btn_nav, .search_nav").slideToggle();
      $(".search_nav_button").hide();
    });
    $(".id-search-clear").keypress(function (event) {
      var keycode = event.keyCode ? event.keyCode : event.which;
      if (keycode === 13) {
        const val = $("#searchinput").val();
        if (val.length > 2) {
          dispatch(new_search(val))
          history(`/products/any&search=${val}`);
        }
      }
    });
  }, []);
  useEffect(() => {
    if (location.pathname.indexOf("shop") > -1) {
      $(".navbar").addClass("position_absolute");
      $(".navbar").removeClass("navbar_area");
    } else {
      $(".navbar").removeClass("position_absolute");
      $(".navbar").addClass("navbar_area");
    }
    getnotificationcount();
    getcartvalue();
  }, []);
  async function searchByButton() {
    const val = $("#searchinput").val();
    if (val.length > 2) {
      dispatch(new_search(val))
      history(`/products/any&search=${val}`);
    }
  }
  //console.log(state_account.cartvalue);
  function getcartvalue() {
    const payload = {
      userid: state_account.profile.email,
      token: state_account.profile.token,
    };
    dispatch(
      get_cart_value(payload, state_account.profile.email, (res) => {
        if (res.status === 200) {
        }
      })
    );
  }
  const getnotificationcount = async () => {
    let payload = {
      token: state_account.profile.token,
      email: state_account.profile.email,
    };
    dispatch(get_notification_count(payload, (res) => { }));
  };
  const [togglebtn, settogglebtn] = useState(false)
  function navtogglebtn() {
    settogglebtn(!togglebtn)
  }

  const twitter = "https://twitter.com/Jesssushop";
  const facebook = "https://www.facebook.com/Jesssushop";
  const linkedin = "https://www.linkedin.com/showcase/jesssu";
  const instagram = "https://www.instagram.com/jesssushop";
  const linkname = window.location.pathname
  return (
    <>
      {/* <div className="navbar home_position navigation_menu" role="navigation">
        <div className="container header-container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <Link className="navbar-brand" to={`/shop`}>
              <img
                src="/images/logo.png"
                alt=""
                className="navbar_brand_logo_img navbar_brand_img"
              />
            </Link>
          </div>
          <div className="navbar-collapse collapse">
            <ul className="nav navbar-nav">
              <li style={{ cursor: "pointer" }}>
                <Link to={`/shop`}>Shop</Link>
              </li>
              <li style={{ cursor: "pointer" }}>
                <Link to={`/social`}>Share</Link>
              </li>
              <li style={{ cursor: "pointer" }}>
                <Link to={`/stash`}>Stash</Link>
              </li>
            </ul>
          </div>
          <div className="header-right">
            <ul className="nav navbar-nav navbar-right mobile_flex">
              <li>
                <a className="search_nav_box">
                  <form>
                    <div className="form-area search_nav">
                      <input id="searchinput" type="text" name="" />
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </form>
                </a>
              </li>

              <li>
                <a className="search_nav_button">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </a>
              </li>

              {state_account.cartvalue != undefined &&
                state_account.cartvalue.length != 0 && (
                  <>
                    <li style={{ cursor: "pointer" }}>
                      <Link to={`/cart`} className="badge_area">
                        <i className="fa-solid fa-cart-arrow-down"></i>
                        <span className="nav_badge">
                          {state_account.cartvalue.length}
                        </span>
                      </Link>
                    </li>
                  </>
                )}

              <li style={{ cursor: "pointer" }}>
                <Link className="user_nav_login" to={`/profile/details`}>
                  <span>
                    <img
                      src={`${state_account.profile.profileimage
                        ? state_account.profile.profileimage
                        : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                        }`}
                      alt=""
                    />
                  </span>
                </Link>
              </li>
              <li style={{ cursor: "pointer" }}>
                <Link to={"/notifications"} className="badge_area">
                  <i className="fa-solid fa-bell"></i>
                  <span className="nav_badge">
                    {state_account.notificationcount}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      {/* new header */}
      {togglebtn === true ?
        (<div>
          {/* <div className="header-fixed"> */}
          <div className="navabar-row">
            <div></div>
            <div className="navabar-row-gap navabar-row">
              <a href={`${facebook}`} target="_blank" rel="noopener noreferrer"><img src="/images/header/facebook-icon.svg" alt="" /></a>
              <a href={`${twitter}`} target="_blank" rel="noopener noreferrer"><img src="/images/header/twitter-icon.svg" alt="" /></a>
              <a href={`${linkedin}`} target="_blank" rel="noopener noreferrer"><img src="/images/header/linkedin-icon.svg" alt="" /></a>
              <a href={`${instagram}`} target="_blank" rel="noopener noreferrer"><img src="/images/header/instagram-icon.svg" alt="" /></a>
            </div>
            <button type="button" onClick={navtogglebtn} className="close modal_close">×</button>
          </div>
          <div className="navabar-row-fixed-item navabar-row">
            {linkname.includes("products" || "shop") ?
              (<div className="navabar-item-active navabar-item">
                <Link to={`/shop`}>Shop</Link>
              </div>) : (<div className="navabar-item">
                <Link to={`/shop`} >Shop</Link>
              </div>)}
            {linkname === "/social" ?
              (<div className="navabar-item-active navabar-item">
                <Link to={`/social`}>Share</Link>
              </div>) :
              (<div className="navabar-item">
                <Link to={`/social`}>Share</Link>
              </div>)}
            {linkname === "/stash" ?
              (<div className="navabar-item-active navabar-item">
                <Link to={`/stash`}>Stash</Link>
              </div>) :
              (<div className=" navabar-item">
                <Link to={`/stash`}>Stash</Link>
              </div>)}
          </div>
        </div>)
        :
        (<div className="main-header-wrapp">
          <div className="header-nav">
            <div className="navabar-row">
              <Link to={`/shop`}>
                <img src="/images/logo.png" alt="" className="navbar_brand_logo_img navbar_brand_img" />
              </Link>
              <div className="navabar-social navabar-row-gap navabar-row">
                <a href={`${facebook}`} target="_blank" rel="noopener noreferrer"><img src="/images/header/facebook-icon.svg" alt="" /></a>
                <a href={`${twitter}`} target="_blank" rel="noopener noreferrer"><img src="/images/header/twitter-icon.svg" alt="" /></a>
                <a href={`${linkedin}`} target="_blank" rel="noopener noreferrer"><img src="/images/header/linkedin-icon.svg" alt="" /></a>
                <a href={`${instagram}`} target="_blank" rel="noopener noreferrer"><img src="/images/header/instagram-icon.svg" alt="" /></a>
              </div>
              <div className="navabar-row-gap navabar-row">
                {/* <Link to={`/cart`} className="badge_area">
                  <div className="nav-icon-bg">
                    <img src="/images/header/icon-shopping-cart.svg" className="nav-icon-img" />
                    <span className="nav-badge-icon nav_badge">
                      <h6 className="nav-badge-text">{state_account.cartvalue.length}</h6>
                    </span>
                  </div>
                </Link> */}

                <Link to={state_account.profile.email==="" ? "/login" : "/notifications"} className="badge_area">
                  <div className="nav-icon-bg">
                    <img src="/images/header/icon-notifications.svg" className="nav-icon-img" alt="" />
                    <span className="nav-badge-icon nav_badge">
                      <h6 className="nav-badge-text">{state_account.notificationcount}</h6>
                    </span>
                  </div>
                </Link>
                <Link className="user_nav_login" to={state_account.profile.email==="" ? `/login` : `/profile/details`}>
                  <span>
                    <img
                      src={`${state_account.profile.profileimage
                        ? state_account.profile.profileimage
                        : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                        }`}
                      alt=""
                    />
                  </span>
                </Link>
                <div onClick={navtogglebtn} className="toggle-button">
                  <img src="/images/nav-bar-icon.svg" width={"25px"} alt="" />
                </div>

              </div>
            </div>
          </div>

          <div className="header-nav-secondery header-nav">
            <div className="navabar-secondery-section">
              <div className="navabar-row-secondery navabar-row">
                <div className="navabar-row-item navabar-row">
                  {linkname === "/shop" ?
                    (<div className="navabar-item-active navabar-item">
                      <Link to={`/shop`}>Shop</Link>
                    </div>) : (<div className="navabar-item">
                      <Link to={`/shop`} >Shop</Link>
                    </div>)}
                  {linkname === "/social" ?
                    (<div className="navabar-item-active navabar-item">
                      <Link to={`/social`}>Share</Link>
                    </div>) :
                    (<div className="navabar-item">
                      <Link to={`/social`}>Share</Link>
                    </div>)}
                  {linkname === "/stash" ?
                    (<div className="navabar-item-active navabar-item">
                      <Link to={`/stash`}>Stash</Link>
                    </div>) :
                    (<div className=" navabar-item">
                      <Link to={`/stash`}>Stash</Link>
                    </div>)}
                </div>
                <div className="navabar-search-section" style={{ "position": "relative" }}>
                  <input type="text" id="searchinput" className="id-search-clear id-search search-bar-input" placeholder="Search what are you looking for" />
                  <button type="button" onClick={() => searchByButton()} className="search-svg"><img src="../images/header/Search-icon.svg" /></button>
                </div>
              </div>
            </div>
          </div>
        </div>)
      }
    </>
  );
};
export default Header;
