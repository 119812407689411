/* eslint-disable */
import { Header } from "components";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { contact_us } from "store/contact/api";


const Contact = () => {
    const dispatch = useDispatch();
    const [issend, setIsSend] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const twitter = "https://twitter.com/Jesssushop";
    const facebook = "https://www.facebook.com/Jesssushop";
    const linkedin = "https://www.linkedin.com/showcase/jesssu";
    const instagram = "https://www.instagram.com/jesssushop";

    const contactus = () => {
        let payload = {
            "userid": "",
            "name": name,
            "email": email,
            "message": message,
            "deviceid": "",
            "countrycode": ""
        }
        dispatch(contact_us(payload, (res) => {
            if (res.status === 200) {
                setIsSend(true);
            }
        }));
    }
    const SetTextValue = (e) => {
        if (e.target.id === "Name") {
            setName(e.target.value);
        } else if (e.target.id === "Email") {
            setEmail(e.target.value);
        } else if (e.target.id === "Message") {
            setMessage(e.target.value);

        }

    }

    return (
        <>
            <div className="thetop"></div>
            {/* <!-- Navigation Menu --> */}
            <Header />
            {/* <!-- Navigation Menu closed Here --> */}

            <div className="contactus_area">

                <div className="container">

                    <div className="row">
                        <div className="col-md-1">
                            <div className="contact-us-back">
                                {/* <img src="images/contact/back.svg" alt="" /> */}
                            </div>
                        </div>

                        {issend ? <div className="col-md-4 ">
                            <div className="thank-you">
                                <p>Thank You for contacting us<br />
                                    We will get back to you</p>
                            </div>
                        </div>
                            : <div className="col-md-4">
                                <div className="contact-us-section">
                                    <h2>Contact Us</h2>
                                    <p>Feel free to contact us any time. We will <br />
                                        get back to you as soon as we can!</p>
                                    <div className="contact-section-input-section">
                                        <div className="contact-input">
                                            <input type="text" placeholder="Name" className="text-input" id="Name" onChange={SetTextValue} />
                                        </div>
                                        <div className="contact-input">
                                            <input type="text" placeholder="Email" className="text-input" id="Email" onChange={SetTextValue} />
                                        </div>
                                        <div className="contact-input">
                                            <input type="text" placeholder="Message" className="text-input" id="Message" onChange={SetTextValue} />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="contact-get_otp" onClick={() => { contactus() }}>SEND</button>
                            </div>}
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-6">

                            <div className="info-section">
                                <div className="back-img">
                                    <img src="images/contact/back4.png" alt="" />
                                </div>

                                <div className="info-yellow">
                                    <img src="images/contact/yellowbox.svg" alt="" />
                                </div>


                                <div className="info-black-section">
                                    <div className="info-heading">
                                        <h4>Info</h4>
                                    </div>
                                    <div className="messages-row">
                                        <img src="images/contact/email.svg" alt="" />
                                        <p>support@jesssu.com</p>
                                    </div>
                                    <div className="webside-row">
                                        <img src="images/contact/website.svg" alt="" />
                                        <p>www.jesssu.com</p>
                                    </div>
                                    <div className="social-row">
                                        <a href={`${twitter}`} target="_blank" rel="noopener noreferrer"><img src="images/contact/twitter.svg" alt="" /></a>
                                        <a href={`${facebook}`} target="_blank" rel="noopener noreferrer"><img src="images/contact/facebook.svg" alt="" /></a>
                                        <a href={`${linkedin}`} target="_blank" rel="noopener noreferrer"><img src="images/contact/linkedin.svg" alt="" /></a>
                                        <a href={`${instagram}`} target="_blank" rel="noopener noreferrer"><img src="images/contact/instagram.svg" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>);
}
export default Contact;