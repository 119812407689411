/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_wishlist, add_delete_wishlist_product } from "../../store/stash/api"
import { useNavigate } from 'react-router-dom';
import "./wishlist.css";
import { Link } from "react-router-dom";
import { get_product_details } from 'store/products/api';

const Wishlist = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    // const [wishlist, setWishlist] = useState([]);
    // const [offset, setOffset] = useState(0);
    // const [count, setCount] = useState(50);
    const state_account = useSelector(state => state.account);
    const state_stash = useSelector(state => state.stash);

    const routetoproductdetails = (i) => {
        let payload = {
            'token': state_account.profile.token,
            'email': state_account.profile.email,
            "COO": state_account.profile.COO,
        };
        dispatch(get_product_details(payload, i, (res) => {
            if (res.data && res.data.length > 0) {
                window.open(res.data[0]?.channels[0].url, '_blank');
            }
        }))
    }
    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            get_wishlists();
        return () => { isMounted = false };
    }, []);



    const remove_wishlist_product = (i) => {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "productid": i.item_id[0],
            "url": i.other_image_url[0]

        }
        dispatch(add_delete_wishlist_product(userpayload, (res) => {
            if (res.status === 200) {
                get_wishlists();
            }
        }));
    }
    const get_wishlists = () => {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_wishlist(userpayload, (res) => {
            // if (res.status === 200) {
            //     setWishlist(res.data);
            // }
        }));
    }
    return (
        <div className="classic_container">
            <div className="classic_height">
                {
                    state_stash.wishlist.length === 0 ?
                        <>
                            <div class="wishlist-empty-img">
                                <img src="/images/wishlist.svg" alt="" />
                            </div>
                            <div class="wishlist-empty-heading-section">
                                <p>Your wishlist is empty</p>
                            </div>
                            <div class="explore-btn">
                                <Link to={`/shop`} class="save_btn ui-link">Explore Products</Link>
                            </div>
                        </> :
                        state_stash.wishlist.filter(i => i.item_name[0]!=undefined).map((i, key) =>
                        (
                            <div className="mywishlist_area" key={key}>
                                <div className="display_flex space_between2 align_item_center">
                                    <div className="display_flex align_item_center">
                                        <div className="mywishlist_img" onClick={() => { routetoproductdetails(i.item_id[0]) }}>
                                            {(i.other_image_url[0] === "" || i.other_image_url[0] === ".jpg") ? <img src="/images/defultimg.svg" style={{"cursor":"pointer"}} alt="" className="img-responsive" /> : <img src={`${i.other_image_url[0]}`} onClick={() => { routetoproductdetails(i.item_id[0]) }} alt="" className="img-responsive" />}
                                        </div>
                                        <div className="mywishlist_content" onClick={() => { routetoproductdetails(i.item_id[0]) }}>
                                            <p className="mywishheading" >{i.item_name[0]}</p>
                                            {/* <ul className="list-unstyled">
                                                <li className="mylisticon"><i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-regular fa-star-half-stroke"></i>
                                                    <span>4.5</span>
                                                </li> */}
                                                {/* <li className="mywishlistprice">&#8377; {i.business_price[0]} <span className="price_cut">
                                                    {i.maximum_retail_price[0]}
                                                </span></li> */}
                                            {/* </ul> */}
                                        </div>
                                    </div>
                                    <p className="delete_icon" data-toggle="modal" data-target={`#${key}`}><i className="fa-solid fa-trash"></i></p>
                                    <div className="modal fade" id={`${key}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="feed_details modal-dialog offer_dialog modal-md">
                                            <div className="modal-body">
                                                <div className="friends-timeline-pop-up">
                                                    <div className="stash-pop-up-section">
                                                        <div className="stash-pop-up-box">
                                                            <div className="wishlist-Just-checking-section">
                                                                <h6>DELETE </h6>
                                                            </div>
                                                            <div className="delete-section">
                                                                <h6>Are you sure you want to delete?</h6>
                                                            </div>
                                                            <div className="checking-row">
                                                                <button className="checkingforyes-btn-default" onClick={() => { remove_wishlist_product(i); }} data-dismiss="modal">YES</button>
                                                                <button className="checking-btn-default" data-dismiss="modal">NO</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
            </div>
        </div>
    );
}
export default Wishlist;