/* eslint-disable */
import React, { useEffect } from "react";


const Iframe = ({ url, pkey }) => {

    useEffect(() => {
        // window.location.replace(url)
    }, []);


  
    return (<>
     {pkey != null ? <></> : <a href="#" data-toggle="modal" data-target="#urlpopup"><i className="fa-solid fa-share"></i></a>}
        <div className="modal fade" id="urlpopup" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="post_area modal-lg modal-dialog offer_dialog modal-md">
                <div className="modal-content feed_modal_content iframe-modal-content">
                    <button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
                    <div className="modal-body iframe-modal-body">
                    <iframe src={url+ "&output=embed"}
                    sandbox="allow-same-origin allow-scripts"
                    allow="fullscreen"
                    // srcdoc="<html><body><h1>Hello, world!</h1></body></html>"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    width="100%"
                    height="100%"
                    scrolling="auto"></iframe >
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Iframe;