/* eslint-disable */
import React, { useEffect, useState } from "react";
// import ScriptTag from 'react-script-tag';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Onboarding = () => {
	// const sliderRef = useRef(null);
	const history = useNavigate()
	const [carousel, setcarousel] = useState(false)
	const [currentSlide, setCurrentSlide] = useState(null);
	let SlideCount = localStorage.getItem("SlideCount")
	let user = null
	useEffect(() => {
		if (localStorage.getItem('user-info')) {
			user = JSON.parse(localStorage.getItem('user-info'));
		
		if(user==null){
			history("/login")
		}else{
			let product = JSON.parse(localStorage.getItem("ShareProduct"))

					if(product!==null){

						// history(`/product/${product.SKU}`)
					}
			// 		else{
			// history("/shop")}
		}}
		setcarousel(true);
		let currentIndex = $('#onboarding_carousel').index($('.carousel-item.active'));
		console.log(currentIndex)
	}, [SlideCount]);

	function handleClick(e) {

		console.log(e)
		setCurrentSlide(e)
	}
	return (
		<>

			<div className="login_area bg_login">
				<div className="container">
					{/* <div className="owl-carousel owl-theme onboarding_carousel_4" id="onboarding_carousel"> */}
					<Carousel autoPlay="true" interval="2000" infiniteLoop ="true">
						
						<div className="item">
							<div className="row">
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<img src="images/onboarding/shopping.svg" alt="" className="img-responsive" />
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-4">
									<img src="images/logo.png" alt="" className="login_logo" />
									<div className="login_content">
										<h1 className="h1 text_bold_600">Hello, <span className="">Welcome to Jesssu</span></h1>
										<p className="text-bold-black">Just earn through Shop, Share, and Stash</p>
										<div className="form-area mobile_center">
											<ul className="list-unstyled display_flex space_between2">
												<li className="p_big_size">Shop the latest and trending collection</li>

											</ul>

										</div>
									</div>

								</div>
								<div className="col-md-1"></div>
							</div>
						</div>
						<div className="item">
							<div className="row">
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<img src="/images/onboarding/jesssu_coin.svg" alt="" className="img-responsive" />
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-4">
									<img src="images/logo.png" alt="" className="login_logo" />
									<div className="login_content">
										<h1 className="h1 text_bold_600">Get Jesssu coin, <span className="">on your interactions</span></h1>
										<p className="text-bold-black">Get reward every time you shop</p>
										<div className="form-area mobile_center">
											{/* <ul className="list-unstyled display_flex space_between2">
												<li className="p_big_size">Use your Jesssu coins for unlocking virtual experience.</li>
											</ul> */}
										</div>
									</div>
								</div>
								<div className="col-md-1"></div>
							</div>
						</div>
						<div className="item">
							<div className="row">
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<img src="images/onboarding/social.svg" alt="" className="img-responsive" />
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-4">
									<img src="images/logo.png" alt="" className="login_logo" />
									<div className="login_content">
										<h1 className="h1 text_bold_600">Our in-build,<span className="">Social Section</span></h1>
										<p className="text-bold-black">A brand new integration in your shopping exprience</p>
										<div className="form-area mobile_center">
											<ul className="list-unstyled display_flex space_between2">
												<li className="p_big_size">Connect with your friends and share your choices and looks</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-md-1"></div>
							</div>
						</div>
						</Carousel>
						{/* <div className="item">
							<div className="row">
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<img src="images/login/onboarding-4.png" alt="" className="img-responsive" />
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-4">
									<img src="images/logo.png" alt="" className="login_logo" />
									<div className="login_content">
										<h1 className="h1 text_bold_600">Hello, <span className="">Welcome to Jesssu</span></h1>
										<p className="text-bold-black">Just earn through Shop, Share, and Stash</p>
										<div className="form-area mobile_center">
											<ul className="list-unstyled display_flex space_between2">
												<li className="p_big_size">Shop the latest and trending collection</li>
											</ul>
										</div>
									</div>
								</div>
								{/* <div className="col-md-1"></div> */}
						{/* </div>
				</div> */}
					{/* </div> */}
					{currentSlide >= 4 ? <p className="skip">
						<Link to="/onboarding2">Next</Link>
					</p> : <p className="skip">
						<Link to="/onboarding2">Skip</Link>
					</p>}
				</div>
			</div >
			<div onClick={(e) => handleClick(e.target.textContent)} id="slidecount"  style={{ display: "none" }}>
			</div>
			{
				carousel === true ? (
					<></>
					// <ScriptTag type="text/javascript"
					// 	src=
					// 	"/js/onboarding_carousel.js" />
				) : <></>
			}

		</>
	)
}

export default Onboarding;