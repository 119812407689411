/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_sent_request, un_friend } from "../../store/friend/api";
import { useNavigate } from "react-router-dom";
const FriendRequestCard = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    // const [friendlist, setFriendList] = useState([]);
    const state_account = useSelector(state => state.account);
    const state_friend = useSelector(state => state.friend);

    const routetotimeline = (i) => {
        history('/timeline', { state: { friendemail: i } });
    }
    const cancelrequest = (friend) => {
        let payload = {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
            'friendemail': friend.friendemail[0]
        }
        dispatch(un_friend(payload, (res) => {
            if (res.status === 200) {
                getfriendlist();
            }
        }));
    }
    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            getfriendlist();
        return () => { isMounted = false };
    }, []);
    const getfriendlist = () => {
        let userpayload =
        {
            "useremail": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_sent_request(userpayload, (res) => {
            if (res.status === 200) {
            }
        }));
    }

    return (
        <>
            <div className="classNameic_height">
                {(state_friend.sentrequestlist.length === 0 && state_friend.receivedrequestlist.length === 0) ?
                    <div className="name_area-request">
                        <div className="request-section">
                            <p>You have no friend requests. You can "Accept" or "Decline" requests from here</p>
                        </div>
                        <div className="request-img">
                            <img src={"/images/onboarding/request.svg"} alt=""/>
                        </div>
                    </div> :
                    state_friend.sentrequestlist.map((i) => (
                        <div className="name_area">
                            <div className="display_flex">
                                <div className="friends_images_section name_img_area" onClick={() => { routetotimeline(i.friendemail[0]) }}>
                                    <img src={`${i.profileimage[0] && (i?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(i.profileimage[0]): {})
                                        ? i.profileimage[0]
                                        : i.gender === undefined ? "/images/onboarding/man.png" : i.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                        }`} alt="" className="friends_img name_img" width={"100%"} height={"100%"} />
                                    {/* <img src={i.profileimage[0]} onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = `${i.genderofsharedby == "" || undefined || null ? "images/onboarding/man.png" : "images/onboarding/woman.png"}`;
                                    }} alt="" className="friends_img name_img" width={"100%"} height={"100%"} /> */}
                                    {/* <img src={`${i.profileimage[0]}` == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${i.profileimage[0]}`} alt="" className="friends_img name_img" width={"100%"} height={"100%"} /> */}
                                </div>
                                <div className="name_content">
                                    <p>Request sent to <span className="text_bold_600">{i.friendfirstname[0]} {i.friendlastname[0]}</span></p>
                                </div>
                            </div>
                            <ul className="request_area list-unstyled display_flex space_between2">
                                <li>{<a onClick={() => { cancelrequest(i) }} className="btn btn-color" href="#">Cancel</a>}</li>
                            </ul>
                        </div>
                    )
                    )
                }
            </div>
        </>);
}
export default FriendRequestCard;