export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_SIGNIN = 'USER_SIGNIN';
export const USER_FOLLOWERS = 'USER_FOLLOWERS';
export const USER_FOLLOWER_REQUEST = 'USER_FOLLOWER_REQUEST';
export const USER_REQUESTED_FOLLOWERS = 'USER_REQUESTED_FOLLOWERS';
export const USER_FOLLOWER_ACCEPT = 'USER_FOLLOWER_ACCEPT';
export const USER_SOCIAL_FEED = 'USER_SOCIAL_FEED';
export const USER_ALL_USERS = 'USER_ALL_USERS';
export const USER_FOLLOWER_COUNT = 'USER_FOLLOWER_COUNT';
export const USER_FOLLOWING_COUNT = 'USER_FOLLOWING_COUNT';
export const USER_PROFILE_IMAGE_UPLOAD = 'USER_PROFILE_IMAGE_UPLOAD';
export const USER_ALL_FEEDCOUNT = 'USER_ALL_FEEDCOUNT';

export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE';
export const NOTIFICATION_COUNT= 'NOTIFICATION_COUNT';

export const FRIEND_LIST_SUCCESS = 'FRIEND_LIST_SUCCESS';
export const USER_WISHLIST_PRODUCT_DATA_SUCCESS = 'USER_WISHLIST_PRODUCT_DATA_SUCCESS';
export const USER_LOOK_DATA_SUCCESS = 'USER_LOOK_DATA_SUCCESS';  

export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';

export const GET_CART_VALUE_SUCCESS = 'GET_CART_VALUE_SUCCESS';

export const UPDATE_TOGGLE_BOX_SOCIAL_COUNT_SUCCESS = 'UPDATE_TOGGLE_BOX_SOCIAL_COUNT_SUCCESS'
export const NEW_SEARCH_SUCCESS = 'NEW_SEARCH_SUCCESS'
export const BRAND_LIST_SUCCESS = 'BRAND_LIST_SUCCESS'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'

