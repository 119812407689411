// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-look-section-wrapp {
    padding: 10px 0;
}
.mylook_post{
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/CreatePost/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,UAAU;AACd","sourcesContent":[".social-look-section-wrapp {\n    padding: 10px 0;\n}\n.mylook_post{\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
