import axios from "axios";
import { jesssuApi, jesssurecomendatioApi } from '../../config';
import { friend_list_action, receive_request_action, sent_request_action, recomended_friendlist_success } from "./friendActions";

export const get_friend_list =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/jesssu/getfriendlist/user`, payload)
            .then(res => {
                dispatch(friend_list_action(res.data));
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };


export const ai_recomended_friend_list = (email,pageno, datacount, callback) => async (dispatch) => {
    await axios.get(`${jesssurecomendatioApi}/predict?email=${email}&limit=${datacount}&page=${pageno}`)
        .then(res => {
            dispatch(recomended_friendlist_success(res.data))
            callback({ success: true, data: res.data, status: res.status });
        })
        .catch(err => {
            console.log(err)
        })
}
export const get_received_request =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/jesssu/getincomingfriendrequest/user`, payload)
            .then(res => {
                dispatch(receive_request_action(res.data));
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };
export const get_sent_request =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/jesssu/getoutgoingfriendrequest/user`, payload)
            .then(res => {
                dispatch(sent_request_action(res.data));
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const un_friend =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/jesssu/cancelfriendrequest/user/friend`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const search_friends =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/jesssu/searchauser`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const accept_friends =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/jesssu/approvefriendrequest/user/friend`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const send_friend_request =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/jesssu/sendfriendrequest/user/friend`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const get_friend_status =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/getusertimeline/userdetails`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };

export const get_friend_recomendation =
    (payload, callback) => async (dispatch) => {
        axios.post(`${jesssuApi}/api/get/friendreferance`, payload)
            .then(res => {
                callback({ success: true, data: res.data, status: res.status });
            })
            .catch(err => {
                console.log(err)
            })
    };
