/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_notifications, get_notification_count, read_notification } from "../../store/account/api"
// import { useNavigate } from 'react-router-dom';
import { Header } from "components";

const Notification = () => {
	// const history = useNavigate();
	const dispatch = useDispatch();
	const [notifications, setNotifications] = useState([]);
	const state_account = useSelector(state => state.account);
	let notificationidlist = [];
	let offset = 0;
	const count = 100;


	useEffect(() => {
		let isMounted = true;
		if (isMounted) get_notification_list();
		return () => { isMounted = false };
	}, []);

	const get_notification_list = () => {
		var userpayload =
		{
			"email": state_account.profile.email,
			"token": state_account.profile.token
		}
		dispatch(get_notifications(userpayload, offset, count, (res) => {
			if (res.status === 200) {
				for (let i = 0; i < res.data.length; i++) {
					setNotifications(current => [...current, res.data[i]])
				}
				createnotificationidlist(res.data)

			}
		}));
	}
	const createnotificationidlist = (data) => {
		notificationidlist = [];
		for (let i = 0; i < data.length; i++) {
			notificationidlist.push(data[i]._id)
		}
		seennotification();
	}
	const seennotification = () => {
		var userpayload =
		{
			"email": state_account.profile.email,
			"token": state_account.profile.token,
			"notificationIds": notificationidlist
		};
		dispatch(read_notification(userpayload, (res) => {
			if (res.status === 200) {
				getnotificationcount()
			}
		}));
	}
	const getnotificationcount = async () => {
		let payload = {
			"token": state_account.profile.token,
			"email": state_account.profile.email,
		}
		dispatch(get_notification_count(payload, (res) => {
		}))
	}
	window.onscroll = function () {
		if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)) {
			offset = offset + 1
			get_notification_list();
		}
	}
	// console.log(notifications[0].image)

	return (
		<>
			<div className="thetop"></div>
			<Header />

			<div className="nortification_area">
				<div className="container">
					<div className="heading text-center">Notification</div>
					<div className="all_nortification">

						{notifications.length === 0 ? <div>No more notifications</div> :
							notifications.map((i) => (
								<div className="detch">
									<div className="display_flex align_item_center">
										{i.status === true &&
											<div className="active_nortification">
												<i className="fa-solid fa-circle-dot"></i>
											</div>}
										{i.status === false &&
											<div className="active_nortification">
												<i className="fa-solid fa-circle"></i>
											</div>}


										<div className="display_flex space_between2 mobile_direction_column nortification_bg align_item_center">
											<div className="all_nortification_user display_flex align_item_center">
												<div className="nortification_img notifications_img_icon">
													<img src={`${i.image ? i.image : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}`} alt="" />
													{/* <i className="fa-solid fa-thumbs-up"></i> */}
												</div>
												<p className="all_nortification_name">{i.message}</p>
											</div>
											<div className="just_now">
												<p>{new Intl.DateTimeFormat('en-GB', {
													month: 'long',
													day: '2-digit',
													year: 'numeric',
												}).format(new Date(i.time))}
													{/* <i className="fa-solid fa-circle-dot"></i> */}
												</p>
											</div>
										</div>
									</div>
								</div>
							)
							)
						}
					</div>
				</div>
			</div>

			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
		</>
	)
}

export default Notification;