import * as types from "./stashTypes";

export function add_wishlist_state(payload) {
  return {
    type: types.ADD_WISHLIST,
    payload: payload,
  };
}

export function get_wishlist_state(payload) {
  return {
    type: types.GET_WISHLIST,
    payload: payload,
  };
}