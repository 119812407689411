import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Brand,
  ProductDetails,
  Shop,
  Social,
  Stash,
  ProductList,
  Stashnewlook,
  Subcategory,
  Viewcategory,
  ProfileBrand,
  ProfileCategory,
  Friend1,
  Friends,
  Jessucoin,
  Login,
  Notification,
  Onboarding,
  Onboarding2,
  OTP,
  Referral,
  Profiledetails,
  Referearn,
  CreateLook,
  UserTimeline,
  About,
  Contact,
  Mission,
  ProfileNotification,
  Singlepost,
  Cart,
  PrivacyPolicy,
  AccountDeletionRequest
} from 'pages'
import { MainLayout } from "layouts";

import "@fortawesome/fontawesome-free/css/all.min.css";
const RenderRoutes = () => {
  return (
    <Routes>
      {/* <Route exact path="/">
        <MainLayout>
          <Login></Login>
        </MainLayout>
      </Route>
      <Route exact path="/shop">
        <MainLayout>
          <Shop></Shop>
        </MainLayout>
      </Route>
      <Route exact path="/brand">
        <MainLayout>
          <Brand></Brand>
        </MainLayout>
      </Route>
      <Route exact path="/social">
        <MainLayout>
          <Social></Social>
        </MainLayout>
      </Route>
      <Route exact path="/stash">
        <MainLayout>
          <Stash></Stash>
        </MainLayout>
      </Route>
      <Route exact path="/cart">
        <MainLayout>
          <Cart></Cart>
        </MainLayout>
      </Route>
      <Route exact path="/product/:id">
        <MainLayout>
          <ProductDetails></ProductDetails>
        </MainLayout>
      </Route>
      <Route exact path="/post/:id">
        <MainLayout>
          <Singlepost></Singlepost>
        </MainLayout>
      </Route>
      <Route exact path="/products/:id">
        <MainLayout>
          <ProductList></ProductList>
        </MainLayout>
      </Route>
      <Route exact path="/stashnewlook">
        <MainLayout>
          <Stashnewlook></Stashnewlook>
        </MainLayout>
      </Route>
      <Route exact path="/subcategories/:id">
        <MainLayout>
          <Subcategory></Subcategory>
        </MainLayout>
      </Route>
      <Route exact path="/viewcategory">
        <MainLayout>
          <Viewcategory></Viewcategory>
        </MainLayout>
      </Route>
      <Route exact path="/profile/categories">
        <MainLayout>
          <ProfileCategory></ProfileCategory>
        </MainLayout>
      </Route>
      <Route exact path="/profile/brands">
        <MainLayout>
          <ProfileBrand></ProfileBrand>
        </MainLayout>
      </Route>
      <Route exact path="/friend1">
        <MainLayout>
          <Friend1></Friend1>
        </MainLayout>
      </Route>
      <Route exact path="/profile/friends">
        <MainLayout>
          <Friends></Friends>
        </MainLayout>
      </Route>
      <Route exact path="/profile/coins">
        <MainLayout>
          <Jessucoin></Jessucoin>
        </MainLayout>
      </Route>
      <Route exact path="/login">
        <MainLayout>
          <Login></Login>
        </MainLayout>
      </Route>
      <Route exact path="/notification">
        <MainLayout>
          <Notification></Notification>
        </MainLayout>
      </Route>
      <Route exact path="/notifications">
        <MainLayout>
          <ProfileNotification></ProfileNotification>
        </MainLayout>
      </Route>
      <Route exact path="/onboarding">
        <MainLayout>
          <Onboarding></Onboarding>
        </MainLayout>
      </Route>
      <Route exact path="/onboarding2">
        <MainLayout>
          <Onboarding2></Onboarding2>
        </MainLayout>
      </Route>
      <Route exact path="/otp">
        <MainLayout>
          <OTP></OTP>
        </MainLayout>
      </Route>
      <Route exact path="/referral/:id">
        <MainLayout>
          <Referral></Referral>
        </MainLayout>
      </Route>
      <Route exact path="/profile/invite">
        <MainLayout>
          <Referearn></Referearn>
        </MainLayout>
      </Route>
      <Route exact path="/profile/details">
        <MainLayout>
          <Profiledetails></Profiledetails>
        </MainLayout>
      </Route>
      <Route exact path="/referearn">
        <MainLayout>
          <Referearn></Referearn>
        </MainLayout>
      </Route>
      <Route exact path="/createnewlook">
        <MainLayout>
          <CreateLook></CreateLook>
        </MainLayout>
      </Route>
      <Route exact path="/editlook/:id">
        <MainLayout>
          <CreateLook></CreateLook>
        </MainLayout>
      </Route>
      <Route exact path="/timeline">
        <MainLayout>
          <UserTimeline></UserTimeline>
        </MainLayout>
      </Route>
      <Route exact path="/about">
        <MainLayout>
          <About></About>
        </MainLayout>
      </Route>
      <Route exact path="/contact-us">
        <MainLayout>
          <Contact></Contact>
        </MainLayout>
      </Route>
      <Route exact path="/privacy-policy">
        <MainLayout>
          <PrivacyPolicy></PrivacyPolicy>
        </MainLayout>
      </Route>
      <Route exact path="/delete-account">
        <MainLayout>
          <AccountDeletionRequest></AccountDeletionRequest>
        </MainLayout>
      </Route>
      
      <Route exact path="/mission">
        <MainLayout>
          <Mission></Mission>
        </MainLayout>
      </Route> */}
      <Route exact path="/" element={<MainLayout><Login/></MainLayout>} />
<Route exact path="/shop" element={<MainLayout><Shop/></MainLayout>} />
<Route exact path="/brand" element={<MainLayout><Brand/></MainLayout>} />
<Route exact path="/social" element={<MainLayout><Social/></MainLayout>} />
<Route exact path="/stash" element={<MainLayout><Stash/></MainLayout>} />
<Route exact path="/cart" element={<MainLayout><Cart/></MainLayout>} />
<Route exact path="/product/:id" element={<MainLayout><ProductDetails/></MainLayout>} />
<Route exact path="/post/:id" element={<MainLayout><Singlepost/></MainLayout>} />
<Route exact path="/products/:id" element={<MainLayout><ProductList/></MainLayout>} />
<Route exact path="/stashnewlook" element={<MainLayout><Stashnewlook/></MainLayout>} />
<Route exact path="/subcategories/:id" element={<MainLayout><Subcategory/></MainLayout>} />
<Route exact path="/viewcategory" element={<MainLayout><Viewcategory/></MainLayout>} />
<Route exact path="/profile/categories" element={<MainLayout><ProfileCategory/></MainLayout>} />
<Route exact path="/profile/brands" element={<MainLayout><ProfileBrand/></MainLayout>} />
<Route exact path="/friend1" element={<MainLayout><Friend1/></MainLayout>} />
<Route exact path="/profile/friends" element={<MainLayout><Friends/></MainLayout>} />
<Route exact path="/profile/coins" element={<MainLayout><Jessucoin/></MainLayout>} />
<Route exact path="/login" element={<MainLayout><Login/></MainLayout>} />
<Route exact path="/notification" element={<MainLayout><Notification/></MainLayout>} />
<Route exact path="/notifications" element={<MainLayout><ProfileNotification/></MainLayout>} />
<Route exact path="/onboarding" element={<MainLayout><Onboarding/></MainLayout>} />
<Route exact path="/onboarding2" element={<MainLayout><Onboarding2/></MainLayout>} />
<Route exact path="/otp" element={<MainLayout><OTP/></MainLayout>} />
<Route exact path="/referral/:id" element={<MainLayout><Referral/></MainLayout>} />
<Route exact path="/profile/invite" element={<MainLayout><Referearn/></MainLayout>} />
<Route exact path="/profile/details" element={<MainLayout><Profiledetails/></MainLayout>} />
<Route exact path="/referearn" element={<MainLayout><Referearn/></MainLayout>} />
<Route exact path="/createnewlook" element={<MainLayout><CreateLook/></MainLayout>} />
<Route exact path="/editlook/:id" element={<MainLayout><CreateLook/></MainLayout>} />
<Route exact path="/timeline" element={<MainLayout><UserTimeline/></MainLayout>} />
<Route exact path="/about" element={<MainLayout><About/></MainLayout>} />
<Route exact path="/contact-us" element={<MainLayout><Contact/></MainLayout>} />
<Route exact path="/privacy-policy" element={<MainLayout><PrivacyPolicy/></MainLayout>} />
<Route exact path="/delete-account" element={<MainLayout><AccountDeletionRequest/></MainLayout>} />
<Route exact path="/mission" element={<MainLayout><Mission/></MainLayout>} />
    </Routes>
  );
};

export default RenderRoutes;