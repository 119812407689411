import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from 'react-helmet';
// redux store
import { Provider } from "react-redux";
import { configureStore } from '@reduxjs/toolkit'
import friendReducer from "./store/friend/friendReducer"
import accountReducer from "./store/account/accountReducer";
import stashReducer from "./store/stash/stashReducer";
import socailReducer from "./store/social/socailReducer";

import WebFont from 'webfontloader';

import App from "./App";


const store = configureStore({
  reducer: {
    account: accountReducer,
    stash: stashReducer,
    friend: friendReducer,
    social: socailReducer
  }
});

WebFont.load({
  google: {
    families: ['Titillium Web:300,400,700', 'sans-serif', 'Pacifico', 'cursive']
  }
});

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <Router>
        <Helmet>
          <title>Jesssu | Social Shopping : Share & Shop Trending Products</title>
          <meta name="description" content="Join the social shopping revolution! Explore and shop the latest trending products through user-shared posts. Find top deals, unique items, and enjoy a seamless shopping experience all in one place." />
          <meta name="keywords" content="Social Shopping" />
        </Helmet>
        <App />
      </Router>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <Router>
        <Helmet>
          <title>Jesssu | Social Shopping : Share & Shop Trending Products</title>
          <meta name="description" content="Join the social shopping revolution! Explore and shop the latest trending products through user-shared posts. Find top deals, unique items, and enjoy a seamless shopping experience all in one place." />
          <meta name="keywords" content="Social Shopping" />
        </Helmet>
        <App />
      </Router>
    </Provider>,
    rootElement
  );
}
