/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_wishlist, get_look_background, create_new_look, edit_my_look } from "../../store/stash/api"
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Header } from "components";
// import { get_user_profile_details } from "store/account/api";
// import { user_signin } from "store/account/accountActions";
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const CreateLook = () => {
    const location = useLocation();
    const history = useNavigate();
    const dispatch = useDispatch();
    // const [productlist, setProductList] = useState([]);
    const [grouplist, setGroupList] = useState([]);
    const [backgroundlist, setBackgroundList] = useState([]);
    const [selectedprodcts, setSelectedProducts] = useState([]);
    const [lookname, setLookName] = useState('');
    const [bg, setBG] = useState('');
    const [layout, setLayout] = useState('4-4');
    const { id } = useParams();
    const state_account = useSelector(state => state.account);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            set_look_value();
            get_wishlist_products();
            get_Look_background();
        }
        return () => { isMounted = false };
    }, []);
    // console.log(state_account.profile.lookimages)

    const set_look_value = () => {
        if (id !== undefined) {
            setSelectedProducts(location.state.bundledetails.productdetails);
            setLookName(location.state.bundledetails.bundlename);
            setBG(location.state.bundledetails.backgroundimage);
            setLayout(location.state.bundledetails.layout);
        }
    }
    const namevalueChange = event => {
        setLookName(event.target.value);
    };
    const changeBG = (image) => {
        setBG(image);
    };

    const create_look = () => {
        let userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "bundlename": lookname,
            "bundleamount": 0,
            "productdetails": selectedprodcts,
            "backgroundimage": bg,
            "layout": layout
        }
        dispatch(create_new_look(userpayload, (res) => {
            if (res.status === 200) {
                history('/stash');
            }
        }));
    }

    const edit_look = () => {
        let userpayload =
        {
            "bundleid": id,
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "bundlename": lookname,
            "bundleamount": 0,
            "productdetails": selectedprodcts,
            "backgroundimage": bg,
            "layout": layout
        }
        dispatch(edit_my_look(userpayload, (res) => {
            if (res.status === 200) {
                history('/stash');
            }
        }));
    }
    const get_wishlist_products = () => {
        let userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_wishlist(userpayload, (res) => {
            if (res.status === 200) {
                // setProductList(res.data);
                const groupedTech = res.data.reduce((acc, value) => {
                    if (!acc[value.category_name[0]]) {
                        acc[value.category_name[0]] = []
                    }
                    acc[value.category_name[0]].push(value)
                    return acc
                }, {})
                const resdata = Object.entries(groupedTech).map(([category, data]) => ({
                    category,
                    data
                }))
                setGroupList(resdata)
            }
        }));
    }
    const get_Look_background = () => {
        let userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
        }
        dispatch(get_look_background(userpayload, (res) => {
            if (res.status === 200) {
                setBackgroundList(res.data);
            }
        }));
    }
    const setSelectedProduct = (i, sequence) => {
        if (selectedprodcts.some(e => e.productid === i.item_id[0])) {
            const newList = selectedprodcts.filter((item) => item.productid !== i.item_id[0]);
            setSelectedProducts(newList);
        } else {
            let productpayload =
            {
                "productid": i.item_id[0],
                "productimage": i.other_image_url[0],
                "productname": i.item_name[0],
                "productprice": 0,
                "sequence": sequence
            }
            setSelectedProducts(current => [...current, productpayload]);
        }
    }
    // const setSelectedProductByImage = (i, sequence) => {
    //     if (selectedprodcts.some(e => e.productimage == i)) {
    //         const newList = selectedprodcts.filter((item) => item.productimage != i);
    //         setSelectedProducts(newList);
    //     } else {
    //         let productpayload =
    //         {
    //             "productid": "",
    //             "productimage": i,
    //             "productname": "",
    //             "productprice": 0,
    //             "sequence": sequence
    //         }
    //         setSelectedProducts(current => [...current, productpayload]);
    //     }
    // }
    // const delete_image = (e) => {
    //     let payload = {
    //         "email": state_account.profile.email,
    //         "image": e
    //     }
    //     delete_profile_look_image(payload, (res) => {
    //         if (res.success === true) {
    //             get_profile_details()
    //         }
    //     })
    // }
    // const get_profile_details = () => {
    //     let udata = [];
    //     let payload = {
    //         "token": state_account.profile.token,
    //         "email": state_account.profile.email,
    //     }
    //     dispatch(get_user_profile_details(payload, (res) => {

    //         if (res.status === 200) {
    //             udata = res.data;
    //             dispatch(user_signin(udata));
    //         }
    //     }));
    // }
    // const upload_image = (e) => {
    //     if (e) {
    //         const formData = new FormData();
    //         formData.append(
    //             "file",
    //             e.target.files[0]
    //         );
    //         let COO = state_account.profile.COO.length == 0 ? "USA" : state_account.profile.COO[0];
    //         let email = state_account.profile.email;
    //         jesssu_image_upload_by_email(formData, COO, email, (res) => {

    //             if (res.success === true) {
    //                 get_profile_details()
    //             }
    //         })
    //     }
    // }

    function changeLayout(e) {

        if (e === "fourfourlabel") {
            setLayout("4-4")
        }
        else if (e === "twotwoonelabel") {
            setLayout("2-2-1")
        }
        else if (e === "threetwolabel") {
            setLayout("3-2")
        }
    }

    return (
        <>
            <div className="thetop"></div>
            {/* <!-- Navigation Menu --> */}
            <Header />
            <Helmet>
                <title>Create Your Look : Customize & Share Your Unique Style</title>
                <meta name="description" content="Design your perfect outfit by combining shirts, pants, shoes, and more. Create a stylish collage, showcase your unique look, and share it with friends." />
                <meta name="keywords" content="Create your look, customize, unique" />
            </Helmet>
            {/* <!-- Navigation Menu closed Here -->
            <!-- Stash My Look Area Starts Here--> */}
            <hr></hr>
            {/* <div className="container img_upload_section">
                <div className="dotted-border col-md-5">
                    <i className="fa-solid fa-upload upload-icon"></i>
                    <p className="upload-text">Upload images from your device to create your look</p>
                    <div className="image-upload-box1">
                        <input type="file" id="img01" accept=".png, .jpg, .jpeg" onChange={(e) => { upload_image(e) }} />
                        <label htmlFor="img01" className="upload-imgbox1">
                            <button type="button" className="upload-own-image-button"
                            >Choose file</button>
                        </label>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="select_createnewlook_area">
                        {(state_account.profile.lookimages != undefined && state_account.profile.lookimages.length > 0) &&
                            state_account.profile.lookimages.map((i, key) =>
                            (<><div className="col-md-4" key={key}>
                                <div className="uploaded-image-box" >
                                    <a className="trash-image-icon" onClick={() => { delete_image(i) }}><i className="fa-solid fa-trash "></i></a>
                                    <LazyLoadImage className={`${selectedprodcts.some((e) => e.productimage == i) ? "uploaded-img-main selected_section" : "uploaded-img-main"}`} src={`${i}`} onClick={() => { setSelectedProductByImage(i, key) }} />
                                </div>
                            </div>
                            </>))}
                    </div>
                </div>
            </div> */}
            <div className="stash_my_look_area">
                <div className="container">
                    <div className="list_section your_wishlist_section">
                        <h3>Your Wishlist</h3>
                        <h5>Select images you want to create your look of</h5>
                        {grouplist.length === 0 ? <><LazyLoadImage src='images/wishlist default.svg' alt="Wishlist icon"/></> :
                            grouplist.map((data, ckey) => (
                                <div>
                                    <h4>{data.category}</h4>
                                    <div className="nopadding col-md-12">
                                        <div className="wishlist_product_row">
                                            {data.data.map((product, pkey) => (
                                                <div className="nopadding col-md-2">
                                                    <div className="wishlist_product shirts-img-selection" key={pkey} onClick={() => { setSelectedProduct(product, pkey) }}>
                                                        <div className={`${selectedprodcts.some((e) => e.productid === product.item_id[0]) ? "shirts-img-selection selected_section" : "shirts-img-selection"}`}>
                                                            <LazyLoadImage src={`${product.other_image_url[0]}`} alt="User-wishlisted product image"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="list_section select_background_section">
                        <h3>Select Background</h3>
                        <h5>Select a background image for your look</h5>
                        <div className="nopadding col-md-12">
                            <div className="select_background_row wishlist_product_row">
                                {backgroundlist.length === 0 ? <></> :
                                    backgroundlist.map((i, key) => (
                                        <div className="nopadding col-md-2">
                                            <div className="wishlist_product shirts-img-selection">
                                                <LazyLoadImage src={`${i.imageurl}`} onClick={() => { changeBG(i.imageurl) }} alt="My look background image" className={`${bg === i.imageurl ? "select_background_img selected_section" : "select_background_img"}`} key={key} />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>

                    <div className="list_section select_background_section">
                        <h3>Select Layout</h3>
                        <h5>Select a background image for your look</h5>
                        {selectedprodcts.length < 5 ? <><div><a href="#"> Select at least 5 product for layouts</a><LazyLoadImage src='images/layout default2.svg' alt="Look default layout icon" /></div></> :
                            <div className="nopadding col-md-12">
                                <div className="select_background_row wishlist_product_row">
                                    <div className="col-md-4">
                                        <div data-toggle="buttons" className="select_layout_box select_layout btn-group bizmoduleselect">
                                            {layout === "4-4" ? (<label className="layout_box btn btn-default brand_hobby1 selected_section" style={{ padding: "5px" }}>
                                                <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})` }}>
                                                    <div className="classic_img1 new-look-box-section" onClick={() => changeLayout("fourfourlabel")}>
                                                        <div className="classic_img1 new-look-row-for-selected-look">
                                                            <div className="select_layout_img1 classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[0].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img1 classic_img" />
                                                            </div>
                                                            <div className="select_layout_img1 classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[1].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img1 classic_img" />
                                                            </div>
                                                            <div className="select_layout_img1 classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[2].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img1 classic_img" />
                                                            </div>
                                                            <div className="select_layout_img1 classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[3].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img1 classic_img" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>) : (<label className="layout_box btn btn-default brand_hobby1" style={{ padding: "5px" }} >
                                                <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})` }}>
                                                    <div className="classic_img1 new-look-box-section" onClick={() => changeLayout("fourfourlabel")}>
                                                        <div className="classic_img1 new-look-row-for-selected-look">
                                                            <div className="select_layout_img1 classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[0].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img1 classic_img" />
                                                            </div>
                                                            <div className="select_layout_img1 classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[1].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img1 classic_img" />
                                                            </div>
                                                            <div className="select_layout_img1 classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[2].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img1 classic_img" />
                                                            </div>
                                                            <div className="select_layout_img1 classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[3].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img1 classic_img" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>)}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box-new-padding">
                                            <div data-toggle="buttons" className="select_layout_box select_layout btn-group bizmoduleselect">
                                                {layout === "2-2-1" ? (<label className="classic_img1 layout_box btn btn-default brand_hobby1 selected_section" style={{ padding: "5px" }}>
                                                    <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})` }}>
                                                        <div className="classic_img1 new-look-box-section" onClick={() => changeLayout("twotwoonelabel")}>
                                                            <div className="row_section_layout2 row-section">
                                                                <div className="classic_section_box2 section">
                                                                    <div className="classic_img_layout2 classic-img-area6">
                                                                        <LazyLoadImage src={`${selectedprodcts[0].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                    <div className="classic_img_layout2 classic-img-area6">
                                                                        <LazyLoadImage src={`${selectedprodcts[1].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                </div>
                                                                <div className="classic_section_box2 new-look-row-for-selected">
                                                                    <div className="classic_img_layout2 classic-img-area7">
                                                                        <LazyLoadImage src={`${selectedprodcts[2].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                    <div className="classic_img_layout2 classic-img-area7">
                                                                        <LazyLoadImage src={`${selectedprodcts[3].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                    <div className="classic_img_layout2 classic-img-area7">
                                                                        <LazyLoadImage src={`${selectedprodcts[4].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>) : (<label className="classic_img1 layout_box btn btn-default brand_hobby1" style={{ padding: "5px" }}>
                                                    <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})` }}>
                                                        <div className="classic_img1 new-look-box-section" onClick={() => changeLayout("twotwoonelabel")}>
                                                            <div className="row_section_layout2 row-section">
                                                                <div className="classic_section_box2 section">
                                                                    <div className="classic_img_layout2 classic-img-area6">
                                                                        <LazyLoadImage src={`${selectedprodcts[0].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                    <div className="classic_img_layout2 classic-img-area6">
                                                                        <LazyLoadImage src={`${selectedprodcts[1].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                </div>
                                                                <div className="classic_section_box2 new-look-row-for-selected">
                                                                    <div className="classic_img_layout2 classic-img-area7">
                                                                        <LazyLoadImage src={`${selectedprodcts[2].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                    <div className="classic_img_layout2 classic-img-area7">
                                                                        <LazyLoadImage src={`${selectedprodcts[3].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                    <div className="classic_img_layout2 classic-img-area7">
                                                                        <LazyLoadImage src={`${selectedprodcts[4].productimage}`} alt="User wishlisted product image"
                                                                            className="classic_img" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div data-toggle="buttons" className="select_layout_box select_layout btn-group bizmoduleselect">
                                            {layout === "3-2" ? (<label className="classic_img1 layout_box select_layout btn btn-default brand_hobby1 selected_section" style={{ padding: "5px" }}>
                                                <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})` }}>
                                                    <div className="classic_img1 new-look-box-section" onClick={() => changeLayout("threetwolabel")}>
                                                        <div className="row-section">
                                                            <div className="new-look-row-for-selected-look">
                                                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                                                    <LazyLoadImage src={`${selectedprodcts[0].productimage}`} alt="User wishlisted product image"
                                                                        className="classic_img" />
                                                                </div>
                                                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                                                    <LazyLoadImage src={`${selectedprodcts[1].productimage}`} alt="User wishlisted product image"
                                                                        className="classic_img" />
                                                                </div>
                                                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                                                    <LazyLoadImage src={`${selectedprodcts[2].productimage}`} alt="User wishlisted product image"
                                                                        className="classic_img" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="createlook_row">
                                                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[3].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img" />
                                                            </div>
                                                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[4].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>) : (<label className="classic_img1 layout_box btn btn-default brand_hobby1" style={{ padding: "5px" }}>
                                                <div className="classic_img1 select_layouts_bg itemcontent" style={{ background: `url(${bg})` }}>
                                                    <div className="classic_img1 new-look-box-section" onClick={() => changeLayout("threetwolabel")}>
                                                        <div className="row-section">
                                                            <div className="new-look-row-for-selected-look">
                                                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                                                    <LazyLoadImage src={`${selectedprodcts[0].productimage}`} alt="User wishlisted product image"
                                                                        className="classic_img" />
                                                                </div>
                                                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                                                    <LazyLoadImage src={`${selectedprodcts[1].productimage}`} alt="User wishlisted product image"
                                                                        className="classic_img" />
                                                                </div>
                                                                <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area7">
                                                                    <LazyLoadImage src={`${selectedprodcts[2].productimage}`} alt="User wishlisted product image"
                                                                        className="classic_img" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="createlook_row">
                                                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[3].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img" />
                                                            </div>
                                                            <div className="classic_img_layout3 classic-img-createnewlook-area classic-img-area6">
                                                                <LazyLoadImage src={`${selectedprodcts[4].productimage}`} alt="User wishlisted product image"
                                                                    className="classic_img" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="look_add_section list_section select_background_section">
                        <div className="look_name_section">
                            <input type="text" name="" placeholder="Look name" onChange={namevalueChange} value={lookname} className="look_input_section" />
                            <input type="button" className="share_look_btn btn btn-color" value="Save Look" onClick={() => { id !== undefined ? edit_look() : create_look() }} />
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default CreateLook;

