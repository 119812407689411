/* eslint-disable */
import { Header, ProfileSideBar } from 'components';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get_friend_list, un_friend, send_friend_request, get_sent_request, search_friends, ai_recomended_friend_list } from "../../store/friend/api"
import ReceiveRequestCard from 'components/ReceiveRequestCard';
import FriendRequestCard from 'components/FriendRequestCard';
import FriendCard from 'components/FriendCard';
import { useNavigate } from 'react-router-dom';
import { recomended_friendlist_success } from "../../store/friend/friendActions";
import { notification_message_status, notification_message_set } from "../../store/account/accountActions"
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
function Friends() {
	const history = useNavigate();
	const dispatch = useDispatch();
	// const [friendlist, setFriendList] = useState([]);
	const [searchfriendlist, setSearchFriendList] = useState([]);
	const state_account = useSelector(state => state.account);
	const state_friend = useSelector(state => state.friend);
	// const [friendreferance, setfriendreferance] = useState([]);
	const pageNo = 1
	let datacount = 20


	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getfriendlist()
			getrecomendedfriendlist()
		};
		return () => { isMounted = false };
	}, []);
	console.log(state_friend.recomendedfriendlist)

	const routetotimeline = (i) => {
		history('/timeline', { state: { friendemail: i } });
	}

	const searchfriend = (e) => {
		setSearchFriendList([])
		if (e.target.id === "search") {
			let searchpayload =
			{
				"email": state_account.profile.email,
				"token": state_account.profile.token,
				"search": e.target.value
			}
			dispatch(search_friends(searchpayload, (res) => {
				if (res.status === 200) {


					setSearchFriendList(res.data);
				}
			}));
		}
	}

	const getfriendlist = () => {
		let userpayload =
		{
			"useremail": state_account.profile.email,
			"token": state_account.profile.token
		}
		dispatch(get_friend_list(userpayload, (res) => { }));
	}

	const getrecomendedfriendlist = () => {
		let userpayload = state_account.profile.email

		dispatch(ai_recomended_friend_list(userpayload, pageNo, datacount))
	}

	const sendfriendrequest = (friendemail) => {
		
		let payload = {
			"useremail": state_account.profile.email,
			"token": state_account.profile.token,
			'friendemail': friendemail
		}
		dispatch(send_friend_request(payload, (res) => {
			if (res.status === 200) {
				getsentrequest()
				getfriendlist();
				dispatch(notification_message_status(true))
				dispatch(notification_message_set("Friend request sent successfully!"))
				const updatedData = state_friend.recomendedfriendlist.filter(item => item.friend_email !== friendemail)
				dispatch(recomended_friendlist_success(updatedData))
				setTimeout(function () {
					dispatch(notification_message_status(false))
				}, 5000);
			}
		}));
	}

	const unfriend = (i) => {
		let payload = {
			"useremail": state_account.profile.email,
			"token": state_account.profile.token,
			"friendemail": i
		}
		dispatch(un_friend(payload, (res) => {
			if (res.status === 200) {
				getfriendlist();
			}
		}));
	}

	const getsentrequest = () => {
		let userpayload =
		{
			"useremail": state_account.profile.email,
			"token": state_account.profile.token,
		}
		dispatch(get_sent_request(userpayload));
	}

	const closeNotification = () => {
		dispatch(notification_message_status(false))
	}

	const isFriendEmailExists = (email) => {
		return state_friend.sentrequestlist.some(friend => friend.friendemail[0] === email);
	};

	const cancelrequest = (friendemail) => {
		let payload = {
			"useremail": state_account.profile.email,
			"token": state_account.profile.token,
			'friendemail': friendemail
		}
		dispatch(un_friend(payload, (res) => {
			if (res.status === 200) {
				getsentrequest()
				getfriendlist();
				dispatch(notification_message_status(true))
				dispatch(notification_message_set("Friend request cancelled successfully!"))
				setTimeout(function () {
					dispatch(notification_message_status(false))
				}, 5000);
			}
		}));
	}

	return (
		<>
			<div className="thetop"></div>
			<Header />
			<Helmet>
                <title>Your Friends | Connect & Share with Your Network</title>
                <meta name="description" content="View and manage your friends on Jesssu. Connect, share, and stay updated with your network. Discover new connections and engage with your friends’ latest updates." />
                <meta name="keywords" content="friends, connect" />
            </Helmet>
			<div className="stash_my_look_area">
				<div className="container">
					<div className="row">
						<ProfileSideBar />
						<div className="col-md-6">
							<div className="profile_area">
								<div className="text-right">
									{/* <form method="post"> */}
										<div className="search_friends">
											<i className="fa-solid fa-magnifying-glass"></i>
											<input type="text" placeholder="Search friends.." id="search" onChange={(e) => { searchfriend(e) }} />
										</div>
									{/* </form> */}
								</div>
								{searchfriendlist.length > 0 ?
									searchfriendlist.map((search) =>
										(<FriendCard i={search} />)
									)
									:
									state_friend.friendlist.length === 0 ? <div>No more friends. Please search for friends.</div> :
										state_friend.friendlist.map((i, key) => (
											<div>
												<div className="friend_div display_flex space_between2 align_item_center" key={key}>
													<div className="display_flex align_item_center">
														<div className="name_img_area" onClick={() => { routetotimeline(i.friendemail[0]) }} >
															<LazyLoadImage src={`${i.profileimage[0] && (i?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(i.profileimage[0]): {})
																? i.profileimage[0]
																: i.gender === undefined ? "/images/onboarding/man.png" : i.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
																}`} alt="User profile image" className="name_img" />
															{/* <LazyLoadImage src={i.profileimage[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${i.profileimage[0]}`} alt="" className="name_img" /> */}
														</div>
														<div className="name_content">
															<p className="text_bold_600">{i.friendemail[0]}</p>
														</div>
													</div>
													<div className="remove_btn">
														<a href='#' data-toggle="modal" data-target={`#${key}`}>Remove</a>
													</div>
												</div>
												<div className="modal fade" id={`${key}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
													<div className="feed_details modal-dialog offer_dialog modal-md">
														<div className="modal-body">
															<div className="friends-timeline-pop-up">
																<div className="stash-pop-up-section">'
																	<div className="stash-pop-up-box">
																		<div className="wishlist-Just-checking-section">
																			<h6>UNFRIEND</h6>
																		</div>
																		<div className="delete-section">
																			<h6>Are you sure you want to unfriend?</h6>
																		</div>
																		<div className="checking-row">
																			<button className="checkingforyes-btn-default" onClick={() => { unfriend(i.friendemail[0]); }} data-dismiss="modal">YES</button>
																			<button className="checking-btn-default" data-dismiss="modal">NO</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										))
								}
								<div className="refer_earn">

									{state_friend.recomendedfriendlist == [] ?
										<LazyLoadImage src="/images/refer_and_earn.svg" alt="Refer and earn icon" className="img-responsive" />
										:
										state_friend.recomendedfriendlist.length === 0 ? <div></div> :
											<>
												<h5 className="text_bold_600">Suggested for you</h5>
												{state_friend.recomendedfriendlist.map((i, key) => (
													<div>
														{(!isFriendEmailExists(i.friend_email) && state_account.profile.email!==i.friend_email) && <div className="friend_div display_flex space_between2 align_item_center" key={`recomended${key}`}>
															<div className="display_flex align_item_center">
																<div className="name_img_area" onClick={() => { routetotimeline(i.friend_email) }} >
																	<LazyLoadImage src={`${i.profileImage && (i?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(i.profileimage[0]): {})
																		? i.profileImage
																		: i.gender === undefined ? "/images/onboarding/man.png" : i.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
																		}`} alt="User profile image" className="name_img" />
																	{/* <LazyLoadImage src={i.profileimage[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${i.profileimage[0]}`} alt="" className="name_img" /> */}
																</div>
																<div className="name_content">
																	<p className="text_bold_600">{i.friend_email}</p>
																</div>

															</div>
															{isFriendEmailExists(i.friend_email) ? <div className="remove_btn" onClick={() => { cancelrequest(i.friend_email) }}>
																<a href='#'>Cancel</a>
															</div> : <div className="remove_btn" onClick={() => { sendfriendrequest(i.friend_email) }}>
																<a href='#'>Send</a>
															</div>
															}

														</div>}
													</div>
												))}
											</>
									}



									<p><a>   </a></p>
									<p><Link to={`/profile/invite`} className="coin_btn">Refer New Friends</Link></p>
								</div>

							</div>
						</div>
						<div className="col-md-3">
							<div className="related_product">
								<p className="related_product_heading request_heading">Requests</p>
								<ReceiveRequestCard />
								<FriendRequestCard />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
			{
				state_account.showNotification ? (
					<div className="addwishlist-pop-up-box">
						<div className="add-wishlist-row-section">
							<LazyLoadImage src="/images/Icon metro-cancel.svg" alt="Cancel icon" onClick={closeNotification} />
							<h5>{state_account.showNotificationMessage}</h5>
						</div>
					</div>
				) : (
					<></>)
			}
		</>
	)
}

export default Friends;