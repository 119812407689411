import axios from "axios"
import { facebookApi, jesssuApi } from '../../config';
import {
  get_notification_count_action,
  user_signin,get_cart_value_success
} from "./accountActions";

export const jesssu_user_register =
  (payload, callback) => async (dispatch) => {
    axios.post(`${jesssuApi}/api/jesssu/register`, payload)
      .then(res => {
        dispatch(user_signin(payload))
        callback({ success: true, data: res.data, status: res.status });
      })
      .catch(err => {
        // dispatch(user_signin(payload))
        callback({ success: false, status: err.response?.status });
      })
  };

export const jesssu_google_login =
  (payload, callback) => async (dispatch) => {
    axios.post(`${jesssuApi}/api/jesssu/googlelogin`, payload)
      .then(res => {
        const value = res.data
        localStorage.setItem('user-info', JSON.stringify(res.data));
        dispatch(user_signin(value))
        callback({ success: true, data: res.data, status: res.status });
      })
      .catch(err => {
        console.log(err)
        // dispatch(user_signin(payload))
        callback({ success: false, status: err?.response?.status });
      })
  };

export const jesssu_facebook_login =
  (payload, callback) => async (dispatch) => {
    axios.post(`${jesssuApi}/api/jesssu/facebooklogin`, payload)
      .then(res => {
        localStorage.setItem('user-info', JSON.stringify(res.data));
        const value = res.data
        dispatch(user_signin(value))
        callback({ success: true, data: res.data, status: res.status });
      })
      .catch(err => {
        dispatch(user_signin(payload))
        callback({ success: false, status: err?.response?.status });
      })
  };

export const jesssu_user_signin =
  (payload, callback) => async (dispatch) => {
    axios.post(`${jesssuApi}/api/jesssu/login`, payload)
      .then(res => {
        localStorage.setItem('user-info', JSON.stringify(res.data));
        const value = res.data
        dispatch(user_signin(value))
        callback({ success: true, data: res.data, status: res.status });
      })
      .catch(err => {
        
        callback({ success: false, data: err.response, status: err.status });
        // console.log(err)
      })
  };

export const jesssu_user_referral_validate =
  (payload, callback) => async (dispatch) => {
    axios.post(`${jesssuApi}/api/jesssu/validate/code`, { "referalcode": payload.referalcode })
      .then(res => {
        // const value = res.data
        dispatch(user_signin(payload))
        callback({ success: true, status: res.status });
      })
      .catch(err => {
        callback({ success: false, status: err?.response?.status });
      })
  };

export const jesssu_user_profile_update =
  (payload, callback) => async (dispatch) => {
    axios.post(`${jesssuApi}/jesssu/users/update/:userId`, { "referalcode": payload.referalcode })
      .then(res => {
        const value = res.data
        // dispatch(user_signin(value))
        callback({ success: true, status: res.status });
      })
      .catch(err => {
        callback({ success: false, status: err?.response?.status });
      })
  };

export const jesssu_user_profileimage_upload = async (formData, callback) => {
  await axios.post(`${jesssuApi}/api/upload-usa-userprofilefiles`, formData)
    .then(res => {
      const value = res.data
      callback({ success: true, data: value });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}
export const jesssu_image_upload_by_email = async (formData, COO, email, callback) => {
  await axios.post(`${jesssuApi}/api/upload-usa-lookimagefiles/${COO}/${email}`, formData)
    .then(res => {
      const value = res.data
      callback({ success: true, data: value });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}

export const delete_profile_look_image = async (payload,  callback) => {
  await axios.post(`${jesssuApi}/api/delete/lookimagefiles`, payload)
    .then(res => {
      const value = res.data
      callback({ success: true, data: value });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}
export const jesssu_users_update_profile =
  (payload, callback) => async (dispatch) => {
    axios.patch(`${jesssuApi}/api/jesssu/users/update/profile`, payload)
      .then(res => {
        const value = res.data
        dispatch(user_signin(payload))
        localStorage.setItem('user-info', JSON.stringify(payload));
        callback({ success: true, data: value, status: res.status });
      })
      .catch(err => {
        callback({ success: false, status: err });
      })
  };

  export const jesssu_users_update_brand_category =
  (payload, callback) => async (dispatch) => {
    axios.patch(`${jesssuApi}/api/jesssu/users/update/brand-category`, payload)
      .then(res => {
        const value = res.data
        // dispatch(user_signin(payload))
        callback({ success: true, data: value, status: res.status });
      })
      .catch(err => {
        callback({ success: false, status: err?.response?.status });
      })
  };

/////FOR PROFILE PAGE
export const get_user_profile_details = (payload, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/api/jesssu/users/useremail`, payload)
    .then(res => {
      // localStorage.setItem('user-info', JSON.stringify(res.data));
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}

export const get_timeline_user_profile_details = (payload, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/api/jesssu/users/useremail`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}
export const get_notifications = (payload, offset, count, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/api/jesssu/notification/${offset}/${count}`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}


export const get_notification_count = (payload, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/api/jesssu/unreadnotificationcount`, payload)
    .then(res => {
      dispatch(get_notification_count_action(res.data));
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}
export const read_notification = (payload, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/api/jesssu/notificationseen`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}

export const get_jesssu_coin_tnx = (payload, offset, count, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/api/jesssu/jessucoindetails/${offset}/${count}`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}


export const refer_friend = (payload, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/api/jesssu/refer/email`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}

export const facebook_friend_list = (facebookuid, authtoken, callback) => async (dispatch) => {
  await axios.get(`${facebookApi}me?fields=friends.fields(username)&access_token=${authtoken}`)
    .then(res => {
      
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}


export const add_to_cart = (payload, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/cart/add`, payload)
    .then(res => {
      
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      callback({ success: false, status: err?.response?.status });
    })
}

export const get_cart_value = (payload,userid, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/cart/user/${userid}`, payload)
    .then(res => {
      dispatch(get_cart_value_success(res.data))
      callback({ success: true, data: res.data, status: res.status });
      
    })
    .catch(err => {
      callback({ success: false, status: err.status });
    })
}

export const add_transaction = (payload, callback) => async () => {
  await axios.post(`${jesssuApi}/api/jesssu/addjesssucoin`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status });
    })
    .catch(err => {
      console.log(err)
      // callback({ success: false, status: err.status });
    })
}


export const update_cart_product_value = (payload, callback) => async (dispatch) => {
  await axios.put(`${jesssuApi}/cart/update/quantity`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status })
    })
    .catch(err => {
      callback({ success: false, status: err.status });
    })
}

export const remove_cart_product = (payload,userId,id, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/cart/edit/${userId}/${id}`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status })
    })
    .catch(err => {
      callback({ success: false, status: err.status });
    })
}

export const get_banner = (payload, callback) => async (dispatch) => {
  await axios.post(`${jesssuApi}/api/getallbanners`, payload)
    .then(res => {
      callback({ success: true, data: res.data, status: res.status })
    })
    .catch(err => {
      callback({ success: false, status: err.status });
    })
}

export const googleRecaptchaVerify =
  (payload, callback) => async (dispatch) => {
    axios.post(`${jesssuApi}/api/jesssu/verify/googlerecaptcha`, payload)
      .then(res => {
        callback({ success: true, data: res.data, status: res.status });
      })
      .catch(err => {
        callback({ success: false, status: err.response?.status });
      })
  };


export const updateFirebaseTokenId = (payload, callback)=> async ()=>{
  axios.post(`${jesssuApi}/api/update/firebase/tokenid`, payload)
  .then(res=>{
    callback({success: true, data: res.data, status: res.status});
  })
  .catch(err=>{
    callback({success: false, status: err?.response?.status});
  })
}

export const updateLogoutFlag = (payload, callback)=> async ()=>{
  axios.post(`${jesssuApi}/api/update/logoutflag`, payload)
  .then(res=>{
    callback({success: true, data: res.data, status: res.status});
  })
  .catch(err=>{
    callback({success: false, status: err?.response?.status});
  })
}






