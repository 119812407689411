/* eslint-disable */
import { ProfileSideBar, FriendSideBar, ReceiveRequestCard, FriendRequestCard, Footer, CreatePost, Reshare, Header, Coindetails } from 'components';
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jesssu_get_all_shares, archived_a_share_by_user, jesssu_get_comments_for_single_post, jesssu_get_comments_for_sharefeed, report_abuse } from 'store/social/api';
import { jesssu_get_posted_shares } from 'store/social/api';
import { jesssu_get_received_shares } from 'store/social/api';
import { jesssu_get_shared_like } from 'store/social/api';
import { jesssu_get_shared_dislike } from 'store/social/api';
import { get_wishlist, get_mylook_list, add_delete_wishlist_product } from "../../store/stash/api"
import { get_wishlist_list_success, get_look_list_success, updateSocialCountforToogleBox } from "../../store/account/accountActions"
import { useNavigate } from 'react-router-dom';
import { get_product_details } from 'store/products/api';
// import { useLocation } from "react-router-dom";
import "./popup.css"
import ReactTooltip from "react-tooltip";
// import ScriptTag from 'react-script-tag'
import { Link } from "react-router-dom";
import { notification_message_status } from "../../store/account/accountActions"
// import { Hidden } from '@material-ui/core';
import $ from "jquery";
import { CardSwiper } from "react-card-rotate-swiper";
// import { get_all_social } from "../../store/social/socialActions"
import { useTimer } from "react-timer-hook";
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AIAgent } from 'genai-product-comparison-agent';
const Social = () => {
    const listInnerRef = useRef();
    // const swiper = useRef();
    const history = useNavigate();
    // const location = useLocation();
    const dispatch = useDispatch();
    const state_account = useSelector(state => state.account);
    // const state_social = useSelector(state => state.social);

    // const [state_account.userfriendlist, setFriendList] = useState(state_account.userfriendlist)
    const [isendlist, setIsEndList] = useState(false);
    // const [shareuserselection, setshareuserselection] = useState("Share With")
    // const [postmessage, setpostmessage] = useState("")
    const [coinview, setcoinview] = useState(false)
    const [offset, setoffset] = useState(0)
    // const [socialcount, setsocialcount] = useState(0)
    const count = 10;
    const [allSocial, setallSocial] = useState([]);
    const [postedSocial, setpostedSocial] = useState([]);
    const [receivedSocial, setreceivedSocial] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    // const [mylook, setMyLook] = useState([]);
    const [comment, setcomment] = useState("");
    const [postcomment, setpostcomment] = useState([]);
    const [singlepost, setsinglepost] = useState(typeof any)
    // const [publicshare, setpublicshare] = useState([]);
    // const [allfriendshare, setallfriendsshare] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const userfirstname = state_account.profile.firstname;
    const useremail = state_account.profile.email;
    const usertoken = state_account.profile.token;
    const [shareddata, setshareddata] = useState(typeof any)
    // const [sharetype, setsharetype] = useState("Post")
    const [reshare, setreshare] = useState(false)
    const [socialdata, setsocialdata] = useState(typeof any)
    const disable = false
    // const [ifabuse, setifabuse] = useState(true)
    const [abusetype, setabusetype] = useState('')
    const [abusetypemessage, setabusetypemessage] = useState('')
    // const [ok, setok] = useState(false)

    useEffect(() => {
        if (state_account.profile.email === "") {
            history('/login')
        }
        let isMounted = true;
        if (isMounted) {
            get_wishlists();
            get_look();
            getSocialdetails();

        }
        return () => { isMounted = false };
    }, []);

    function get_wishlists() {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }
        dispatch(get_wishlist(userpayload, (res) => {

            if (res.status === 200) {
                res.data.forEach((i) => {
                    setWishlist(current => [...current, i.item_id[0]])
                })
                dispatch(get_wishlist_list_success(res.data))

            }
        }));
    }

    async function addproductinwishlist(data, count, stage) {
        let payload = {
            "email": state_account.profile.email,
            "productid": data.productid,
            "url": data.postimage
        }

        dispatch(add_delete_wishlist_product(payload, (res) => {
            if (res.status === 200) {
                if (res.data === "Product added to wishist") {

                    if (stage === "Posted") {
                        const newstateforpostedsection = postedSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, wishlisted: true };
                            }
                            return i;
                        });
                        setpostedSocial(newstateforpostedsection);
                    }
                    else {
                        const newstateforreceivedsection = receivedSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, wishlisted: true };
                            }
                            return i;
                        });
                        setreceivedSocial(newstateforreceivedsection)
                    }



                    setWishlist(current => [...current, data.SKU])
                    setShowNotificationMessage("Product has been added successfuly to your wishlist")
                    setShowNotification(true);
                    closenotification()
                }
                else {
                    if (stage === "Posted") {
                        const newstateforpostedsection = postedSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, wishlisted: false };
                            }
                            return i;
                        });
                        setpostedSocial(newstateforpostedsection);
                    }
                    else {
                        const newstateforreceivedsection = receivedSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, wishlisted: false };
                            }
                            return i;
                        });
                        setreceivedSocial(newstateforreceivedsection)
                    }

                    setWishlist((prevState) =>
                        prevState.filter((prevItem) => prevItem !== data.SKU)
                    );
                    setShowNotificationMessage("Product has been removed successfuly from your wishlist")
                    setShowNotification(true);
                    closenotification()
                }
            }
        }));
    }


    function get_look() {
        var userpayload =
        {
            "email": state_account.profile.email,
            "token": state_account.profile.token
        }
        dispatch(get_mylook_list(userpayload, (res) => {

            if (res.status === 200) {
                // setMyLook(res.data);
                dispatch(get_look_list_success(res.data))
            }
        }));
    }

    function likeapost(count, shareid) {

        var payload =
        {
            'email': useremail,
            'id': shareid,
            'token': usertoken
        }
        dispatch(jesssu_get_shared_like(payload, (res => {
            if (res.status === 200) {
                if (allSocial[count].isliked === false) {
                    if (allSocial[count].isdisliked === true) {
                        const newStateforlike = allSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberoflikes: parseInt(`${allSocial[count].Numberoflikes}`) + 1, isliked: true, Numberofdislikes: parseInt(`${allSocial[count].Numberofdislikes}`) - 1, isdisliked: false };
                            }
                            return i;
                        });
                        setallSocial(newStateforlike);
                    }
                    else {
                        const newStateforlike = allSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberoflikes: parseInt(`${allSocial[count].Numberoflikes}`) + 1, isliked: true, };
                            }
                            return i;
                        });
                        setallSocial(newStateforlike);
                    }
                    setShowNotificationMessage("Like has been submitted successfully")
                    setShowNotification(true);
                    closenotification()
                } else {
                    const newStateforlike = allSocial.map((i = typeof any, index) => {
                        if (index === count) {
                            return { ...i, Numberoflikes: parseInt(`${allSocial[count].Numberoflikes}`) - 1, isliked: false };
                        }
                        return i;
                    });
                    setallSocial(newStateforlike);
                    setShowNotificationMessage("Like has been removed successfully")
                    setShowNotification(true);
                    closenotification()
                }

            }
        })));
    }
    function likeapostforpostedsection(count, sharedata) {
        setpostedstopdisplay(count + 1)
        if ((new Date() > new Date(sharedata.expiresAt))) {
            const archivedpayload = {
                'token': usertoken,
                'email': useremail,
                'shareid': sharedata._id
            }
            dispatch(archived_a_share_by_user(archivedpayload, (RESULT => {
                if (RESULT.status === 200) {
                    dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
                }
                // setsocialcount(socialcount - 1)

            })))
        }
        else {
            var payload =
            {
                'email': useremail,
                'id': sharedata._id,
                'token': usertoken
            }
            dispatch(jesssu_get_shared_like(payload, (res => {
                if (res.status === 200) {

                    setShowNotificationMessage("Like has been submitted successfully")
                    setShowNotification(true);
                    closenotification()

                    const archivedpayload = {
                        'token': usertoken,
                        'email': useremail,
                        'shareid': sharedata._id
                    }
                    dispatch(archived_a_share_by_user(archivedpayload, (RESULT => {
                        if (RESULT.status === 200) {
                            dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
                        }
                        // setsocialcount(socialcount - 1)

                    })))
                }
            })));
        }
    }
    function likeapostforreceivedsection(count, sharedata) {
        setreceivedstopdisplay(count + 1)
        if ((new Date() > new Date(sharedata.expiresAt))) {
            const archivedpayload = {
                'token': usertoken,
                'email': useremail,
                'shareid': sharedata._id
            }
            dispatch(archived_a_share_by_user(archivedpayload, (RESULT => {
                if (RESULT.status === 200) {
                    dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
                }
                // setsocialcount(socialcount - 1)

            })))
        }
        else {
            var payload =
            {
                'email': useremail,
                'id': sharedata._id,
                'token': usertoken
            }
            dispatch(jesssu_get_shared_like(payload, (res => {
                if (res.status === 200) {

                    setShowNotificationMessage("Like has been submitted successfully")
                    setShowNotification(true);
                    closenotification()
                    const archivedpayload = {
                        'token': usertoken,
                        'email': useremail,
                        'shareid': sharedata._id
                    }
                    dispatch(archived_a_share_by_user(archivedpayload, (RESULT => {
                        if (RESULT.status === 200) {
                            dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
                        }
                        // setsocialcount(socialcount - 1)

                    })))
                    // setsocialcount(socialcount - 1)
                    // dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount-1))

                }
            })));
        }
    }
    function getpostcomment(data) {
        // setpostmessage("")
        setsinglepost(data)
        var payload =
        {
            'email': useremail,
            "shareid": data._id,
            'token': usertoken
        }
        dispatch(jesssu_get_comments_for_single_post(payload, (res => {
            if (res.status === 200) {
                res.data.map((i) => {
                    setpostcomment(current => [...current, i])
                })
            }
        })));

    }

    function clearpost() {
        setpostcomment([])
        setsinglepost(typeof any)
    }
    function dislikeapost(count, shareid) {

        var payload =
        {
            'email': useremail,
            'id': shareid,
            'token': usertoken
        }
        dispatch(jesssu_get_shared_dislike(payload, (res => {
            if (res.status === 200) {
                if (allSocial[count].isdisliked === false) {
                    if (allSocial[count].isliked === true) {
                        const newStatefordislike = allSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberofdislikes: parseInt(`${allSocial[count].Numberofdislikes}`) + 1, isdisliked: true, Numberoflikes: parseInt(`${allSocial[count].Numberoflikes}`) - 1, isliked: false };
                            }
                            return i;
                        });
                        setallSocial(newStatefordislike);
                    } else {
                        const newStatefordislike = allSocial.map((i = typeof any, index) => {
                            if (index === count) {
                                return { ...i, Numberofdislikes: parseInt(`${allSocial[count].Numberofdislikes}`) + 1, isdisliked: true };
                            }
                            return i;
                        });
                        setallSocial(newStatefordislike);
                    }
                    setShowNotificationMessage("Dislike has been submitted successfully")
                    setShowNotification(true);
                    closenotification()

                } else {
                    const newStatefordislike = allSocial.map((i = typeof any, index) => {
                        if (index === count) {
                            return { ...i, Numberofdislikes: parseInt(`${allSocial[count].Numberofdislikes}`) - 1, isdisliked: false };
                        }
                        return i;
                    });
                    setallSocial(newStatefordislike);
                    setShowNotificationMessage("Dislike has been removed successfully")
                    setShowNotification(true);
                    closenotification()

                }
            }
        })));

    }
    function dislikeapostforpostedsection(count, sharedata) {
        setpostedstopdisplay(count + 1)
        if ((new Date() > new Date(sharedata.expiresAt))) {
            const archivedpayload = {
                'token': usertoken,
                'email': useremail,
                'shareid': sharedata._id
            }
            dispatch(archived_a_share_by_user(archivedpayload, (RESULT => {
                if (RESULT.status === 200) {
                    dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
                }
            })))
        }
        else {
            var payload =
            {
                'email': useremail,
                'id': sharedata._id,
                'token': usertoken
            }
            dispatch(jesssu_get_shared_dislike(payload, (res => {
                if (res.status === 200) {

                    setShowNotificationMessage("Dislike has been submitted successfully")
                    setShowNotification(true);
                    closenotification()
                    // setsocialcount(socialcount - 1)

                    const archivedpayload = {
                        'token': usertoken,
                        'email': useremail,
                        'shareid': sharedata._id
                    }
                    dispatch(archived_a_share_by_user(archivedpayload, (RESULT => {
                        if (RESULT.status === 200) {
                            dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
                        }
                    })))
                }
            })));
        }

    }
    function dislikeapostforreceivedsection(count, sharedata) {
        setreceivedstopdisplay(count + 1)
        if ((new Date() > new Date(sharedata.expiresAt))) {
            const archivedpayload = {
                'token': usertoken,
                'email': useremail,
                'shareid': sharedata._id
            }
            dispatch(archived_a_share_by_user(archivedpayload, (RESULT => {
                if (RESULT.status === 200) {
                    dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
                }
            })))
        }
        else {
            var payload =
            {
                'email': useremail,
                'id': sharedata._id,
                'token': usertoken
            }
            dispatch(jesssu_get_shared_dislike(payload, (res => {
                if (res.status === 200) {
                    setShowNotificationMessage("Dislike has been submitted successfully")
                    setShowNotification(true);
                    closenotification()
                    const archivedpayload = {
                        'token': usertoken,
                        'email': useremail,
                        'shareid': sharedata._id
                    }
                    dispatch(archived_a_share_by_user(archivedpayload, (RESULT => {
                        if (RESULT.status === 200) {
                            dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
                        }
                    })))
                    // setsocialcount(socialcount - 1)
                    // dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount-1))

                }
            })));
        }

    }
    const closeNotification = () => {
        setShowNotification(false);
        dispatch(notification_message_status(false))
    }
    function commentbox(shareid) {

        if (comment !== "") {
            var payload =
            {
                'email': useremail,
                'token': usertoken,
                'shareid': shareid,
                'comment': comment,
            }
            dispatch(jesssu_get_comments_for_sharefeed(payload, (res => {

                if (res.status === 200) {
                    const newState = allSocial.map((j, index) => {
                        if (j._id === shareid) {
                            let commentdata = {
                                "user": useremail,
                                "comment": comment
                            }
                            j.comments.push(commentdata)
                            let data = { ...j, Numberofcomment: parseInt(`${allSocial[index].Numberofcomment}`) + 1, };
                            getpostcomment(data)
                            return data;
                        }
                        return j;
                    });
                    setallSocial(newState);
                    setcomment("")
                    setShowNotificationMessage("Your comment has been posted successfully")
                    setShowNotification(true);
                }
            })));
        }



    }
    function getresharedata(data) {
        setshareddata(data)
        setreshare(true)
    }
    function getSocialdetails() {
        var payload =
        {
            'token': usertoken,
            'email': useremail
        }
        dispatch(jesssu_get_all_shares(payload, offset, count, (res => {
            if (res.status === 200) {
                const updatedAllSocial = res.data.map((i) => {
                    const likeSet = new Set(i.likeby[0] || []);
                    const dislikeSet = new Set(i.dislikeby[0] || []);
                    const sharedSet = new Set(i.sharedby || []);
        
                    return {
                        ...i,
                        isliked: likeSet.has(userfirstname),
                        isdisliked: dislikeSet.has(userfirstname),
                        isshared: sharedSet.has(userfirstname),
                    };
                });
        
                setallSocial((current) => [...current, ...updatedAllSocial]);
            }

        })));
        dispatch(jesssu_get_posted_shares(payload, offset, 100, (res) => {
            if (res.status === 200) {
                // Convert wishlist to a Set for faster lookups
                const wishlistSet = new Set(wishlist);
        
                // Process posted shares
                const updatedPostedSocial = res.data.map((i) => {
                    const likeSet = new Set(i.likeby[0] || []);
                    const dislikeSet = new Set(i.dislikeby[0] || []);
                    const sharedSet = new Set(i.sharedby || []);
        
                    return {
                        ...i,
                        isliked: likeSet.has(userfirstname),
                        isdisliked: dislikeSet.has(userfirstname),
                        isshared: sharedSet.has(userfirstname),
                        wishlisted: wishlistSet.has(i.productid),
                    };
                });
        
                setpostedSocial((current) => [...current, ...updatedPostedSocial]);
        
                // Fetch received shares
                dispatch(jesssu_get_received_shares(payload, offset, 100, (result) => {
                    if (result.status === 200) {
                        const totalSharesCount = result.data.length + res.data.length;
                        dispatch(updateSocialCountforToogleBox(totalSharesCount));
        
                        // Process received shares
                        const updatedReceivedSocial = result.data.map((i) => {
                            const likeSet = new Set(i.likeby[0] || []);
                            const dislikeSet = new Set(i.dislikeby[0] || []);
                            const sharedSet = new Set(i.sharedby || []);
        
                            return {
                                ...i,
                                isliked: likeSet.has(userfirstname),
                                isdisliked: dislikeSet.has(userfirstname),
                                isshared: sharedSet.has(userfirstname),
                                wishlisted: wishlistSet.has(i.productid),
                            };
                        });
        
                        setreceivedSocial((current) => [...current, ...updatedReceivedSocial]);
                    }
                }));
            }
        }));
    }
    function onScroll() {
        if (listInnerRef.current && isendlist === false) {

            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight > scrollHeight / 5) {
                $("#socialpageheight").removeClass('classic_height')
                $("#socialpageheight").addClass('increaseheight')
            }
            if (scrollTop + clientHeight > scrollHeight / 2) {

                let value = (offset + 1)
                setoffset(offset + 1)
                get_more_social(value);
            }
        }


    }

    function closenotification() {
        setTimeout(function () {
            setShowNotification(false);
            dispatch(notification_message_status(false))
        }, 5000);
    }

    function get_more_social(value) {

        var payload =
        {
            'token': usertoken,
            'email': useremail
        }
        dispatch(jesssu_get_all_shares(payload, value, count, (res) => {
            if (res.status === 200) {
                // Process data only if there's a valid response
                const updatedAllSocial = res.data.map((i) => {
                    const likeSet = new Set(i.likeby[0] || []);
                    const dislikeSet = new Set(i.dislikeby[0] || []);
                    const sharedSet = new Set(i.sharedby || []);
        
                    return {
                        ...i,
                        isliked: likeSet.has(userfirstname),
                        isdisliked: dislikeSet.has(userfirstname),
                        isshared: sharedSet.has(userfirstname),
                    };
                });
        
                setallSocial((current) => [...current, ...updatedAllSocial]);
        
                // Check if there are no more data items
                if (res.data.length === 0) {
                    setIsEndList(true);
                }
            }
        }));
    }

    function getproductdataforjesssucoin(data) {
        setsocialdata(data)
        setcoinview(true)

    }

    function routetotimeline(i) {
        history('/timeline', { state: { friendemail: i } });
    }
    const handleChangeComment = (e) => {
        setcomment(e.target.value)
    }
    const updatetoggleboxcount = () => {
        dispatch(updateSocialCountforToogleBox(state_account.toogleboxsocialcount - 1))
    }
    const [postedstopdisplay, setpostedstopdisplay] = useState(0)
    const [receivedstopdisplay, setreceivedstopdisplay] = useState(0)

    const receivedhandleSwipe = (d, index, data) => {

        if (d === 'left') {
            //  setreceivedstopdisplay(index + 1)
            likeapostforreceivedsection(index, data)

        } else if (d === 'right') {
            // setreceivedstopdisplay(index + 1)
            dislikeapostforreceivedsection(index, data)

        }
        else if (d === 'up') {
            // setreceivedstopdisplay(index + 1)
            likeapostforreceivedsection(index, data)
        }
        else if (d === 'down') {
            // setreceivedstopdisplay(index + 1)
            dislikeapostforreceivedsection(index, data)
        }

    };
    const postedhandleSwipe = (d, index, data) => {

        if (d === 'left') {
            // setpostedstopdisplay(index + 1)
            likeapostforpostedsection(index, data)
        } else if (d === 'right') {
            // setpostedstopdisplay(index + 1)
            dislikeapostforpostedsection(index, data)
        }
        else if (d === 'up') {
            // setpostedstopdisplay(index + 1)
            likeapostforpostedsection(index, data)
        }
        else if (d === 'down') {
            // setpostedstopdisplay(index + 1)
            dislikeapostforpostedsection(index, data)
        }
    };

    async function reportabuse(data) {

        console.log(data)
        var payload = {
            "useremail": state_account.profile.email,
            "targetuseremail": data.sharedbyId[0],
            "abusetype": abusetype,
            "message": abusetypemessage,
            "PostId": data._id,
            "token": state_account.profile.token
        }
        if (abusetype === "") {

        } else if (abusetypemessage === "") {

        } else {
            dispatch(report_abuse(payload, (res) => {
                if (res.status === 200) {
                    setallSocial(allSocial.filter(item => item._id !== data._id));
                    setShowNotificationMessage("Report submitted successfully")
                    setShowNotification(true);
                }
            }));
        }
    }

    const ListExpireData = ({ item, onExpirationChange }) => {
        const expiryTime = new Date(item.expiresAt);
        const { seconds, minutes } = useTimer({
            expiryTimestamp: expiryTime,
            onExpire: () => {
                onExpirationChange(item._id, true);
                // Handle timer expiration here (if needed)
            },
        });

        const padZero = (value) => value.toString().padStart(2, "0");
        const isExpired = new Date() > expiryTime;
        const mins = padZero(minutes);
        const secs = padZero(seconds);

        return (

            <div className="timeline_wrapp">

                {isExpired ? (
                    <h5>Post active time is over, you can’t like, dislike or comment </h5>
                ) : (
                    <h5>
                        You have left <span>{mins}:{secs}</span> minutes for like, dislike & comment
                    </h5>
                )}

            </div>
        );
    };

    const PostedExpireData = ({ item, onExpirationChange }) => {
        const expiryTime = new Date(item.expiresAt);
        const { seconds, minutes } = useTimer({
            expiryTimestamp: expiryTime,
            onExpire: () => {
                onExpirationChange(item._id, true);
                // Handle timer expiration here (if needed)
            },
            autoStart: false,
        });

        const padZero = (value) => value.toString().padStart(2, "0");
        const isExpired = new Date() > expiryTime;
        const mins = padZero(minutes);
        const secs = padZero(seconds);

        return (

            <div className="timeline_wrapp_box">
                {isExpired ? (
                    <h5>Expired</h5>
                ) : (
                    <span>{mins}:{secs}</span>
                )}

            </div>
        );
    };
    const ReceivedExpireData = ({ item, onExpirationChange }) => {
        const expiryTime = new Date(item.expiresAt);
        const [timerStarted, setTimerStarted] = useState(false);
        const { seconds, minutes } = useTimer({
            expiryTimestamp: expiryTime,
            onExpire: () => {
                onExpirationChange(item._id, true);
            },
            autoStart: false,
        });
        useEffect(() => {
            if (!timerStarted && new Date() > expiryTime) {
                // If the expiryTime is already in the past, call onExpirationChange immediately
                onExpirationChange(item._id, true);
            }
        }, [expiryTime, onExpirationChange, item._id, timerStarted]);
        const padZero = (value) => value.toString().padStart(2, "0");
        const isExpired = new Date() > expiryTime;
        const mins = padZero(minutes);
        const secs = padZero(seconds);
        useEffect(() => {
            if (!isExpired) {
                // Start the timer once if it's not expired
                setTimerStarted(true);
            }
        }, [isExpired]);
        return (
            <div className="timeline_wrapp_box">
                {isExpired ? (
                    <h5>Expired</h5>
                ) : (
                    <span>{mins}:{secs}</span>
                )}
            </div>
        );

    };

    const handleExpirationChangeallSocial = (itemId, isExpired) => {
        setallSocial((prevSocial) =>
            prevSocial.map((data) =>
                data._id === itemId ? { ...data, expiresStatus: isExpired } : data
            )
        );
    };
    const handleExpirationChangePosted = (itemId, isExpired) => {

        // setpostedSocial((prevSocial) =>
        //     prevSocial.map((data) =>
        //         data._id === itemId ? { ...data, expiresStatus:isExpired } : data
        //     )
        // );
    };
    const handleExpirationChangeReceived = (itemId, isExpired) => {

        // setreceivedSocial((prevSocial) =>
        //     prevSocial.map((data) =>
        //         data._id === itemId ? { ...data, expiresStatus: isExpired } : data
        //     )
        // );
    };
    async function redirectToProductPagefromSocial(id) {
        let payload = {
            'token': state_account.profile.token,
            'email': state_account.profile.email,
            "COO": state_account.profile.COO,
        };
        dispatch(get_product_details(payload, id, (res) => {
            if (res.data && res.data.length > 0) {
                window.open(res.data[0]?.channels[0].url, '_blank');
            }
        }))
    }
    return (
        <>
            <div className="thetop"></div>

            <Header />
            <Helmet>
                <title>Share, Discover, and Shop Products | Jesssu</title>
                <meta name="description" content="Showcase your product finds and experiences, and browse through unique user posts. Connect with a vibrant community, discover great deals, and make purchases seamlessly." />
                <meta name="keywords" content="Share, Products, Shop" />
            </Helmet>
            {/* <Header /> */}
            <div className="stash_my_look_area">
                <div className="container">
                    <div className="row">
                        <ProfileSideBar />
                        <div className="col-md-6">
                            <div className="swip_area collapse in" id="collapsepost">

                                <div className="row">
                                    <div className="col-md-6">
                                        <p className="related_product_heading request_heading text-center-important">Your Post</p>
                                        <div className="card-container" style={{ position: 'relative', height: '436px' }}>
                                            {(postedSocial || []).map((data, index) => (
                                                <CardSwiper
                                                    // onSwiping={handleSwipeProgress}
                                                    key={index}
                                                    rotateAngle={15}
                                                    onSwipe={(e) => postedhandleSwipe(e, index, data)}
                                                    className={"swiper"}
                                                    disabled={disable}

                                                    contents={
                                                        <>
                                                            <div className="swip_bg" id="container" style={{ "height": "435px", "display": (index !== postedstopdisplay) ? 'none' : 'block' }}>
                                                                <div className="swip_box buddy" style={{ "display": "block" }} >
                                                                    <input type="hidden" className="hiddenshareid" value={data._id} />
                                                                    <input type="hidden" className="hiddensharetype" value="Postedsocial" />
                                                                    <div className="name_area" style={{ "height": "410px" }}>
                                                                        <div className="display_flex post-space_between2">
                                                                            <div className="display_flex">
                                                                                <div className="name_img_area social_pag2_img_area" style={{ cursor: "pointer" }} onClick={() => { routetotimeline(data.sharedbyId[0]) }}>
                                                                                    <LazyLoadImage src={`${data.imageofsharedby[0] && (data?.providerofsharedby[0] === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.imageofsharedby[0]) : {})
                                                                                        ? data.imageofsharedby[0]
                                                                                        : data.genderofsharedby === undefined ? "images/onboarding/man.png" : data.genderofsharedby[0] === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                                        }`} alt="User profile image" className="name_img" />
                                                                                </div>
                                                                                <div className="name_content">
                                                                                    <p className="text_bold_600" style={{ cursor: "pointer" }} onClick={() => { routetotimeline(data.sharedbyId[0]) }}>{data.sharedby[0]}</p>
                                                                                    <p> {index + 1}/{postedSocial.length}</p>
                                                                                </div>
                                                                            </div>
                                                                            <ReceivedExpireData key={`receivedexpire${data._id}`} item={data} onExpirationChange={handleExpirationChangeReceived} />

                                                                        </div>
                                                                        {data.type !== 6 &&
                                                                            <div className="swip_box_img">
                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.productid)} src={data.postimage} alt="User-shared image"
                                                                                    className="img-responsive" /></Link>
                                                                            </div>}
                                                                        {data.type === 6 &&
                                                                            <div>
                                                                                {data.layout[0] === "2-2-1" ?
                                                                                    <div className="page2_right_area" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[0] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[0].productid)} src={data.bundleproduct[0].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[1] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[1].productid)} src={data.bundleproduct[1].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12 col-xs-12">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[2] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[2].productid)} src={data.bundleproduct[2].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[3] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[3].productid)} src={data.bundleproduct[3].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[4] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[4].productid)} src={data.bundleproduct[4].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    data.layout[0] === "3-2" ?
                                                                                        <div className="page2_right_area" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[0] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[0].productid)} src={data.bundleproduct[0].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[1] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[1].productid)} src={data.bundleproduct[1].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[2] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[2].productid)} src={data.bundleproduct[2].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[3] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[3].productid)} src={data.bundleproduct[3].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-12 col-xs-12">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[4] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[4].productid)} src={data.bundleproduct[4].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : data.layout[0] === "4-4" || data.layout[0] === "" ?
                                                                                            <div className="page2_right_area four_box" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area">
                                                                                                            {data.bundleproduct[0] !== null &&
                                                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[0].productid)} src={data.bundleproduct[0].productimage} alt="User-shared image" className="classic_img" /></Link>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {data.bundleproduct[1] !== null &&
                                                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[1].productid)} src={data.bundleproduct[1].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {data.bundleproduct[2] !== null &&
                                                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[2].productid)} src={data.bundleproduct[2].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {data.bundleproduct[3] !== null &&
                                                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[3].productid)} src={data.bundleproduct[3].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : <></>}

                                                                            </div>
                                                                        }
                                                                        <div className="swip_like" style={{ "margin": "0 0 100px" }}>
                                                                            <ul className={(new Date() > new Date(data.expiresAt)) ? "social_disable display_flex space_evenly" : "display_flex space_evenly"}>
                                                                                {data.isliked === true &&
                                                                                    <li className="active_color"><i className="fa-solid fa-thumbs-up" onClick={() => { likeapostforpostedsection(index, data) }}></i>
                                                                                        <div className="like-count">
                                                                                            <h6>{data.Numberoflikes} likes</h6>
                                                                                        </div></li>}
                                                                                {data.isliked === false &&
                                                                                    <li><i className="fa-solid fa-thumbs-up" onClick={() => { likeapostforpostedsection(index, data) }}></i>
                                                                                        <div className="like-count">
                                                                                            <h6>{data.Numberoflikes} likes</h6>
                                                                                        </div></li>}
                                                                                {/* <li><i className="fa-solid fa-heart"></i></li> */}
                                                                                {data.wishlisted === true &&
                                                                                    <li onClick={() => addproductinwishlist(data, index, "Posted")} className="active_color"><i className="fa-solid fa-heart"></i></li>}
                                                                                {data.wishlisted === false &&
                                                                                    <li onClick={() => addproductinwishlist(data, index, "Posted")} ><i className="fa-solid fa-heart" ></i></li>}
                                                                                {data.isdisliked === true &&
                                                                                    <li className="active_color"><i className="fa-solid fa-thumbs-down" onClick={() => { dislikeapostforpostedsection(index, data) }}></i>
                                                                                        <div className="like-count">
                                                                                            <h6>{data.Numberofdislikes} dislikes</h6>
                                                                                        </div></li>}
                                                                                {data.isdisliked === false &&
                                                                                    <li><i className="fa-solid fa-thumbs-down" onClick={() => { dislikeapostforpostedsection(index, data) }}></i>
                                                                                        <div className="like-count">
                                                                                            <h6>{data.Numberofdislikes} dislikes</h6>
                                                                                        </div></li>}

                                                                            </ul>
                                                                        </div>
                                                                        <div className="like-share-scroll-section">
                                                                            <div className="like-share-wrap">
                                                                                {(data.likebyuserdetails[0] || []).map((data, index) => (
                                                                                    <div className="like-profile-row" key={`LikeUser${index}`}>
                                                                                        <div className="social-like-section-row">
                                                                                            <div className="like-profile-img">
                                                                                                <LazyLoadImage src={`${data.profileimage && (data?.provider === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage) : {})
                                                                                                    ? data.profileimage
                                                                                                    : data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                                                                    }`} alt="User profile image" />
                                                                                                {/* <LazyLoadImage src={data.profileimage == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage}`} alt="" /> */}
                                                                                            </div>
                                                                                            <div className="like-profile-name">
                                                                                                <h6>{data.firstname} {data.lastname}</h6>
                                                                                            </div></div>
                                                                                        <div className="like-profile-thumbs">
                                                                                            <LazyLoadImage src="images/onboarding/thumbs-up.svg" alt="Like icon" />
                                                                                        </div>
                                                                                    </div>))}
                                                                                {(data.dislikebyuserdetails[0] || []).map((data, index) => (
                                                                                    <div className="like-profile-row" key={`DislikeUser${index}`}>
                                                                                        <div className="social-like-section-row">
                                                                                            <div className="like-profile-img">
                                                                                                <LazyLoadImage src={`${data.profileimage && (data?.provider === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage) : {})
                                                                                                    ? data.profileimage
                                                                                                    : data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                                                                    }`} alt="User profile image" />
                                                                                                {/* <LazyLoadImage src={data.profileimage == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage}`} alt="" /> */}
                                                                                            </div>
                                                                                            <div className="like-profile-name">
                                                                                                <h6>{data.firstname} {data.lastname}</h6>
                                                                                            </div></div>
                                                                                        <div className="like-profile-thumbs">
                                                                                            <LazyLoadImage src="images/onboarding/thumbs-down.svg" alt="Dislike icon" />
                                                                                        </div>
                                                                                    </div>))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                />))}

                                            {postedSocial.length <= postedstopdisplay && <>
                                                <div className="swip_bg" id="container" style={{ "height": "435px" }}>
                                                    <div className="swip_box buddy" key="123Posted2" style={{ "display": "block" }}>
                                                        <input type="hidden" className="hiddenshareid" value="0" />
                                                        <input type="hidden" className="hiddensharetype" value="Postedsocial" />
                                                        <div className="swip_box_img" style={{ "height": "370px" }}>
                                                            <LazyLoadImage src="/images/social-share/your-post.svg" alt="User posted section icon" className="img-responsive" style={{ "height": "245px" }} />
                                                            <span>
                                                                <h6 className="post-heading">You haven't post yet</h6>
                                                                <p className="post-body">
                                                                    To see your posts here,
                                                                    <br />
                                                                    please share your look or any product
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="related_product_heading request_heading text-center-important">Received Post
                                        </p>
                                        <div className="card-container" style={{ position: 'relative', height: '436px', overflow: 'hidden' }}>

                                            {(receivedSocial || []).map((data, index) => (
                                                <CardSwiper
                                                    key={index}
                                                    rotateAngle={15}
                                                    onSwipe={(e) => receivedhandleSwipe(e, index, data)}
                                                    className={"swiper"}
                                                    disabled={disable}
                                                    contents={
                                                        <>
                                                            <div className="swip_bg" id="container" style={{ "height": "435px" }}>
                                                                <div className="swip_box buddy" style={{ "display": "block" }} >
                                                                    <input type="hidden" className="hiddenshareid" value={data._id} />
                                                                    <input type="hidden" className="hiddensharetype" value="Receivedsocial" />
                                                                    <div className="name_area" style={{ "height": "410px" }}>
                                                                        <div className="display_flex post-space_between2">
                                                                            <div className="display_flex">
                                                                                <div className="name_img_area social_pag2_img_area" style={{ cursor: "pointer" }} onClick={() => { routetotimeline(data.sharedbyId[0]) }}>
                                                                                    <LazyLoadImage src={`${data.imageofsharedby[0] && (data?.providerofsharedby[0] === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.imageofsharedby[0]) : {})
                                                                                        ? data.imageofsharedby[0]
                                                                                        : data.genderofsharedby === undefined ? "/images/onboarding/man.png" : data.genderofsharedby[0] === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                                                        }`} alt="User profile image" className="name_img" />
                                                                                    {/* <LazyLoadImage src={data.imageofsharedby[0]} onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null; // prevents looping
                                                                                    currentTarget.src = `${data.genderofsharedby[0] == "" || undefined || null ? "images/onboarding/man.png" : "images/onboarding/woman.png"}`;
                                                                                }} alt="" title="" className="name_img" /> */}
                                                                                    {/* <LazyLoadImage src={data.imageofsharedby[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.imageofsharedby[0]}`} alt="" className="name_img" />  */}
                                                                                </div>
                                                                                <div className="name_content">
                                                                                    <p className="text_bold_600" style={{ cursor: "pointer" }} onClick={() => { routetotimeline(data.sharedbyId[0]) }}>{data.sharedby[0]}</p>
                                                                                    <p> {index + 1}/{receivedSocial.length}</p>
                                                                                </div>
                                                                            </div>
                                                                            <PostedExpireData key={`postedexpire${data._id}`} item={data} onExpirationChange={handleExpirationChangePosted} />
                                                                        </div>
                                                                        {data.type !== 6 &&
                                                                            <div className="swip_box_img">
                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.productid)} src={data.postimage} alt="User-shared image"
                                                                                    className="img-responsive" /></Link>
                                                                            </div>}
                                                                        {data.type === 6 &&
                                                                            <div>
                                                                                {data.layout[0] === "2-2-1" ?
                                                                                    <div className="page2_right_area" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[0] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[0].productid)} src={data.bundleproduct[0].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[1] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[1].productid)} src={data.bundleproduct[1].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12 col-xs-12">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[2] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[2].productid)} src={data.bundleproduct[2].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[3] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[3].productid)} src={data.bundleproduct[3].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic_img_area"> {data.bundleproduct[4] !== null &&
                                                                                                    <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[4].productid)} src={data.bundleproduct[4].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    :
                                                                                    data.layout[0] === "3-2" ?
                                                                                        <div className="page2_right_area" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[0] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[0].productid)} src={data.bundleproduct[0].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[1] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[1].productid)} src={data.bundleproduct[1].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[2] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[2].productid)} src={data.bundleproduct[2].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[3] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[3].productid)} src={data.bundleproduct[3].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-12 col-xs-12">
                                                                                                    <div className="classic_img_area"> {data.bundleproduct[4] !== null &&
                                                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[4].productid)} src={data.bundleproduct[4].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : data.layout[0] === "4-4" || data.layout[0] === "" ?
                                                                                            <div className="page2_right_area four_box" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area">
                                                                                                            {data.bundleproduct[0] !== null &&
                                                                                                                <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[0].productid)} src={data.bundleproduct[0].productimage} alt="User-shared image" className="classic_img" /></Link>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {data.bundleproduct[1] !== null &&
                                                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[1].productid)} src={data.bundleproduct[1].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {data.bundleproduct[2] !== null &&
                                                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[2].productid)} src={data.bundleproduct[2].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic_img_area"> {data.bundleproduct[3] !== null &&
                                                                                                            <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.bundleproduct[3].productid)} src={data.bundleproduct[3].productimage} alt="User-shared image" className="classic_img" /></Link>} </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : <></>}

                                                                            </div>}
                                                                        <div className="swip_like" style={{ "margin": "0 0 100px" }}>
                                                                            <ul className={(new Date() > new Date(data.expiresAt)) ? "social_disable display_flex space_evenly" : "display_flex space_evenly"}>
                                                                                {data.isdisliked === true &&
                                                                                    <li className="active_color"><i className="fa-solid fa-thumbs-down" onClick={() => { dislikeapostforreceivedsection(index, data) }}></i>
                                                                                        <div className="like-count">
                                                                                            <h6>{data.Numberofdislikes} dislikes</h6>
                                                                                        </div></li>}
                                                                                {data.isdisliked === false &&
                                                                                    <li><i className="fa-solid fa-thumbs-down" onClick={() => { dislikeapostforreceivedsection(index, data) }}></i>
                                                                                        <div className="like-count">
                                                                                            <h6>{data.Numberofdislikes} dislikes</h6>
                                                                                        </div></li>}


                                                                                {data.wishlisted === true &&
                                                                                    <li onClick={() => addproductinwishlist(data, index, "Posted")} className="active_color"><i className="fa-solid fa-heart"></i></li>}
                                                                                {data.wishlisted === false &&
                                                                                    <li onClick={() => addproductinwishlist(data, index, "Posted")} ><i className="fa-solid fa-heart" ></i></li>}


                                                                                {data.isliked === true &&
                                                                                    <li className="active_color"><i className="fa-solid fa-thumbs-up" onClick={() => { likeapostforreceivedsection(index, data) }}></i>
                                                                                        <div className="like-count">
                                                                                            <h6>{data.Numberoflikes} likes</h6>
                                                                                        </div></li>}
                                                                                {data.isliked === false &&
                                                                                    <li><i className="fa-solid fa-thumbs-up" onClick={() => { likeapostforreceivedsection(index, data) }}></i>
                                                                                        <div className="like-count">
                                                                                            <h6>{data.Numberoflikes} likes</h6>
                                                                                        </div></li>}
                                                                            </ul>
                                                                        </div>
                                                                        <div className="like-share-scroll-section">
                                                                            <div className="like-share-wrap">
                                                                                {(data.likebyuserdetails[0] || []).map((data, index) => (
                                                                                    <div className="like-profile-row" key={`Map3${index}`}>
                                                                                        <div className="social-like-section-row">
                                                                                            <div className="like-profile-img">
                                                                                                <LazyLoadImage src={`${data.profileimage && (data?.provider === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage) : {})
                                                                                                    ? data.profileimage
                                                                                                    : data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                                                                    }`} alt="User profile image" />
                                                                                                {/* <LazyLoadImage src={data.profileimage == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage}`} alt="" /> */}
                                                                                            </div>
                                                                                            <div className="like-profile-name">
                                                                                                <h6>{data.firstname} {data.lastname}</h6>
                                                                                            </div></div>
                                                                                        <div className="like-profile-thumbs">
                                                                                            <LazyLoadImage src="images/onboarding/thumbs-up.svg" alt="Like icon" />
                                                                                        </div>
                                                                                    </div>))}
                                                                                {(data.dislikebyuserdetails[0] || []).map((data, index) => (
                                                                                    <div className="like-profile-row" key={`Map4${index}`}>
                                                                                        <div className="social-like-section-row">
                                                                                            <div className="like-profile-img">
                                                                                                <LazyLoadImage src={`${data.profileimage && (data?.provider === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage) : {})
                                                                                                    ? data.profileimage
                                                                                                    : data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                                                                    }`} alt="User profile image" />
                                                                                                {/* <LazyLoadImage src={data.profileimage == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage}`} alt="" /> */}
                                                                                            </div>
                                                                                            <div className="like-profile-name">
                                                                                                <h6>{data.firstname} {data.lastname}</h6>
                                                                                            </div></div>
                                                                                        <div className="like-profile-thumbs">
                                                                                            <LazyLoadImage src="images/onboarding/thumbs-down.svg" alt="Dislike icon" />
                                                                                        </div>
                                                                                    </div>))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                />))}

                                            {receivedSocial.length <= receivedstopdisplay && <>
                                                <div className="swip_bg" id="container" style={{ "height": "435px" }}>
                                                    <div className="swip_box buddy" style={{ "display": "block" }} key="123Received2">
                                                        <input type="hidden" className="hiddenshareid" value="0" />
                                                        <input type="hidden" className="hiddensharetype" value="Receivedsocial" />
                                                        <div className="swip_box_img" style={{ "height": "370px" }}>
                                                            <LazyLoadImage src="/images/social-share/received-post.svg" alt="User received section image" className="img-responsive" style={{ "height": "245px" }} />
                                                            <span>
                                                                <h6 className="post-heading">You have no received posts</h6>
                                                                <p className="post-body">
                                                                    When someone share any posts
                                                                    <br />
                                                                    with you it'll be shown here
                                                                </p>
                                                            </span>
                                                        </div>
                                                    </div></div></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="hide_show_div" data-toggle="collapse" href="#collapsepost" id="closesocialsection">
                                <a className="badge_area" href="/notifications">
                                    <i className="fa-solid fa-angle-down">
                                    </i>
                                    <span className="nav_badge-for-count-section">{state_account.toogleboxsocialcount}</span>
                                </a>
                            </p>
                            <div className="wishlist_area">
                                <div className="social_page">
                                    <div className="classic_height" onScroll={() => onScroll()} ref={listInnerRef} id="socialpageheight">
                                        {allSocial.map((data, index) => (
                                            <div key={index}>
                                                <div className="classic_container">
                                                    <div className="classNameic_container">
                                                        <div className="social_box social_box_2">
                                                            <div className="social_box">
                                                                <div className="name_area">
                                                                    <div className="display_flex report-row" >
                                                                        <div className="display_flex">
                                                                            <div className="name_img_area" style={{ cursor: "pointer" }} onClick={() => { routetotimeline(data.sharedbyId[0]) }}>
                                                                                <LazyLoadImage src={`${data.imageofsharedby[0] && (data?.providerofsharedby[0] === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.imageofsharedby[0]) : {})
                                                                                    ? data.imageofsharedby[0]
                                                                                    : data.genderofsharedby === undefined ? "images/onboarding/man.png" : data.genderofsharedby[0] === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                                    }`} alt="User profile image" className="name_img" />
                                                                                {/* <LazyLoadImage src={data.imageofsharedby[0]} onError={({ currentTarget }) => {
                                                                                    currentTarget.onerror = null; // prevents looping
                                                                                    currentTarget.src = `${data.genderofsharedby[0] == "" || undefined || null ? "images/onboarding/man.png" : "images/onboarding/woman.png"}`;
                                                                                }} alt="" title="" className="name_img" /> */}
                                                                                {/* <LazyLoadImage src={data.imageofsharedby[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.imageofsharedby[0]}`} alt="" className="name_img" /> */}
                                                                            </div>
                                                                            <div className="name_content" data-tip data-for={`gettimeline${data.sharedbyId[0]}`}>
                                                                                <p className="name_content_heading" style={{ cursor: "pointer" }} onClick={() => { routetotimeline(data.sharedbyId[0]) }}>{data.sharedby[0]}</p>
                                                                                <p><i className="fa-solid fa-circle-dollar-to-slot" data-tip data-for={`getcoin${index}`} data-toggle="modal" data-target={`#coindetails${data._id}`} onClick={() => { getproductdataforjesssucoin(data) }}></i>
                                                                                    <ReactTooltip id={`getcoin${index}`} place="top" effect="solid">
                                                                                        Get Jesssu coin details
                                                                                    </ReactTooltip>
                                                                                    Shared on {new Intl.DateTimeFormat('en-GB', {
                                                                                        month: 'long',
                                                                                        day: '2-digit',
                                                                                        year: 'numeric',
                                                                                    }).format(new Date(data.createdAt))}</p>
                                                                            </div>
                                                                            <ReactTooltip id={`gettimeline${data.sharedbyId[0]}`} place="top" effect="solid">
                                                                                Check {data.sharedby[0]}'s post
                                                                            </ReactTooltip>
                                                                        </div>
                                                                        {/* <div> */}
                                                                        <p data-toggle="modal" data-target={`#abuse${index}`}><LazyLoadImage src="images/more.png" width="30px" height="30px" alt="Abuse section modal close icon" style={{ "cursor": "pointer" }} /></p>
                                                                        <div className="modal fade" id={`abuse${index}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                            <div className="feed_details modal-dialog offer_dialog modal-md">
                                                                                <div className="modal-body">
                                                                                    <div className="friends-timeline-pop-up">
                                                                                        <div className="stash-pop-up-section">
                                                                                            <div className="stash-pop-up-box">
                                                                                                <div className="abuse-report-section wishlist-Just-checking-section">
                                                                                                    <h6>Report Abuse </h6>
                                                                                                </div>
                                                                                                <div className="report-section">
                                                                                                    <h6>Abuse Type</h6>

                                                                                                    <select className="form-control drp-selection" onChange={(e) => setabusetype(e.target.value)}>
                                                                                                        <option className="dropdown" value="">-Select Abuse type-</option>
                                                                                                        <option className="dropdown" value="PHYSICAL ABUSE">PHYSICAL ABUSE</option>
                                                                                                        <option className="dropdown" value="SEXUAL ABUSE">SEXUAL ABUSE</option>
                                                                                                        <option className="dropdown" value="FINANCIAL ABUSE">FINANCIAL ABUSE</option>                                     </select>
                                                                                                    {/* <textarea type="text" onChange={(e) => setabusetype(e.target.value)} /> */}
                                                                                                </div>
                                                                                                <div className="report-section">
                                                                                                    <h6>Message</h6>
                                                                                                    <textarea className="form-control drp-selection" type="text" onChange={(e) => setabusetypemessage(e.target.value)} />
                                                                                                </div>
                                                                                                <div className="checking-row">
                                                                                                    <button className="checkingforyes-btn-default" data-dismiss="modal" onClick={() => reportabuse(data)}>YES</button>
                                                                                                    <button className="checking-btn-default" data-dismiss="modal" onClick={() => { setabusetypemessage(''); setabusetype('') }}>NO</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </div>

                                                                </div>
                                                                <Link to={`/post/${data._id}`}>
                                                                    <div className="social_box_desc" data-tip data-for={`gettimeline${data._id}`}>
                                                                        <p style={{ "color": "black" }}>{data.note[0].note}</p>
                                                                    </div></Link>
                                                                <ReactTooltip id={`gettimeline${data._id}`} place="top" effect="solid">
                                                                    Check full post
                                                                </ReactTooltip>
                                                                {data.type === 1 &&
                                                                    <div className="social_box_img">
                                                                        <Link to={`#`}><LazyLoadImage onClick={() => redirectToProductPagefromSocial(data.productid)} src={data.postimage} alt="User-shared image" className="img-responsive" />
                                                                        </Link>
                                                                    </div>}

                                                                {data.type === 3 &&
                                                                    <div className="social_box_img">
                                                                        <a>
                                                                            <LazyLoadImage src={data.postimage} alt="User-shared image" className="img-responsive" /></a>
                                                                    </div>}


                                                                {data.type === 6 &&

                                                                    <div>
                                                                        {
                                                                            data.layout[0] === "2-2-1" ?
                                                                                <div className="classic_formate" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>

                                                                                    <div className="classic_formate_scroll1 no_scroll">
                                                                                        <div className="row">
                                                                                            <div className="col-md-5 col-xs-5">
                                                                                                <div className="classic_img_area">
                                                                                                    {data.bundleproduct.length > 0 &&
                                                                                                        <LazyLoadImage src={`${data.bundleproduct[0].productimage}`} alt="User-shared image"
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="classic_img_area">
                                                                                                    {data.bundleproduct.length > 1 &&
                                                                                                        <LazyLoadImage src={`${data.bundleproduct[1].productimage}`} alt="User-shared image"
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-xs-3">
                                                                                                <div className="classic-img-area2">
                                                                                                    {data.bundleproduct.length > 2 &&
                                                                                                        <LazyLoadImage src={`${data.bundleproduct[2].productimage}`} alt="User-shared image"
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-xs-3">
                                                                                                <div className="classic-img-area2">
                                                                                                    {data.bundleproduct.length > 3 &&
                                                                                                        <LazyLoadImage src={`${data.bundleproduct[3].productimage}`} alt="User-shared image"
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-xs-6">
                                                                                                <div className="classic-img-area3">
                                                                                                    {data.bundleproduct.length > 4 &&
                                                                                                        <LazyLoadImage src={`${data.bundleproduct[4].productimage}`} alt="User-shared image"
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                data.layout[0] === "3-2" ?
                                                                                    <div className="classic_formate" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }}>
                                                                                        <div className="classic_formate_scroll1 no_scroll">
                                                                                            <div className="row">
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {data.bundleproduct.length > 0 &&
                                                                                                            <LazyLoadImage src={`${data.bundleproduct[0].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {data.bundleproduct.length > 1 &&
                                                                                                            <LazyLoadImage src={`${data.bundleproduct[1].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-4 col-xs-4">
                                                                                                    <div className="classic-img-area4">
                                                                                                        {data.bundleproduct.length > 2 &&
                                                                                                            <LazyLoadImage src={`${data.bundleproduct[2].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic-img-area5">
                                                                                                        {data.bundleproduct.length > 3 &&
                                                                                                            <LazyLoadImage src={`${data.bundleproduct[3].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic-img-area5">
                                                                                                        {data.bundleproduct.length > 4 &&
                                                                                                            <LazyLoadImage src={`${data.bundleproduct[4].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : data.layout[0] === "4-4" || data.layout[0] === "" ?
                                                                                        <div className="classic_formate" style={{ backgroundImage: data.backgroundimage === "" || data.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${data.backgroundimage})` }} >
                                                                                            <div className="classic_formate_scroll no_scroll">
                                                                                                <div className="row">
                                                                                                    {data.bundleproduct.length === 0
                                                                                                        ?
                                                                                                        <></>
                                                                                                        :
                                                                                                        data.bundleproduct.map((data, key) => (
                                                                                                            <div className="col-md-6 col-xs-6" key={`Map7${key}`}>
                                                                                                                <div className="classic_img_area">
                                                                                                                    <LazyLoadImage src={`${data.productimage}`} alt="User-shared image" className="classic_img" />
                                                                                                                </div>
                                                                                                            </div>)
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                        </div> : <></>
                                                                        }
                                                                    </div>
                                                                }

                                                                {/* if it disable 
                                                                <div className="social_disable">
                                                                    */}

                                                                <div className={data?.expiresStatus ? "social_disable" : "social_enable"}>

                                                                    <ul className="list-unstyled display_flex space_between2 social_page_like">
                                                                        {data.isdisliked === true &&
                                                                            <li><a className="active_color" style={{ cursor: "pointer" }}><i onClick={() => { dislikeapost(index, data._id) }} className="fa-solid fa-thumbs-down"></i></a>
                                                                                <span className="active_color" data-tip data-for={`disliketip${index}`}>{data.Numberofdislikes} dislikes</span></li>}
                                                                        {data.isdisliked === false &&
                                                                            <li><a style={{ cursor: "pointer" }}><i onClick={() => { dislikeapost(index, data._id) }} className="fa-solid fa-thumbs-down"></i></a>
                                                                                <span data-tip data-for={`disliketip${index}`}>{data.Numberofdislikes} dislikes</span></li>}

                                                                        <ReactTooltip id={`disliketip${index}`} place="top" effect="solid">
                                                                            {(data.dislikeby[0] || []).map((dislikedata, i) => ((i <= 5 && <div key={`Map8${i}`}>{dislikedata}</div>)))}
                                                                        </ReactTooltip>
                                                                        {data.isliked === true &&
                                                                            <li><a className="active_color" style={{ cursor: "pointer" }}><i onClick={() => { likeapost(index, data._id) }} className="fa-solid fa-thumbs-up"></i></a>
                                                                                <span className="active_color" data-tip data-for={`liketip${index}`}>{data.Numberoflikes} likes </span></li>}
                                                                        {data.isliked === false &&
                                                                            <li><a style={{ cursor: "pointer" }}><i onClick={() => { likeapost(index, data._id) }} className="fa-solid fa-thumbs-up"></i></a>
                                                                                <span data-tip data-for={`liketip${index}`}>{data.Numberoflikes} likes </span></li>}

                                                                        <ReactTooltip id={`liketip${index}`} place="top" effect="solid">
                                                                            {(data.likeby[0] || []).map((likedata, i) => ((i <= 5 && <div key={`Map9${i}`}>{likedata}</div>)))}
                                                                        </ReactTooltip>
                                                                        {data.isshared === false &&
                                                                            <><li>
                                                                                <a data-toggle="modal" data-target={`#create_post_alertreshare${data._id}`} onClick={() => getresharedata(data)} style={{ cursor: "pointer" }}>
                                                                                    <i className="fa-solid fa-share"></i></a>
                                                                                <span data-tip data-for={`sharetip${index}`}>{data.Numberofshares} shares </span></li></>
                                                                        }
                                                                        {data.isshared === true &&
                                                                            <><li>
                                                                                <a data-toggle="modal" data-target={`#create_post_alertreshare${data._id}`} className="active_color" onClick={() => getresharedata(data)} style={{ cursor: "pointer" }}>
                                                                                    <i className="fa-solid fa-share"></i></a>
                                                                                <span className="active_color" data-tip data-for={`sharetip${index}`}>{data.Numberofshares} shares </span></li></>}

                                                                        <ReactTooltip id={`sharetip${index}`} place="top" effect="solid">
                                                                            {(data.sharedby || []).map((sharedbydata, i) => ((i <= 5 && <div key={`Map10${i}`}>{sharedbydata}</div>)))}
                                                                        </ReactTooltip>
                                                                        {data.Numberofcomment > 0 &&
                                                                            <li><a className="active_color" style={{ cursor: "pointer" }}><i className="fa-solid fa-comment"></i> </a>
                                                                                <span className="active_color">{data.Numberofcomment} comments</span></li>}
                                                                        {data.Numberofcomment <= 0 &&
                                                                            <li><a style={{ cursor: "pointer" }}><i className="fa-solid fa-comment"></i> </a>
                                                                                <span>{data.Numberofcomment} comments</span></li>}
                                                                    </ul>
                                                                    <div className="social_page_comment">
                                                                        <p onClick={() => { getpostcomment(data) }}><a data-toggle="modal" data-target="#exampleModal2" style={{ cursor: "pointer" }}>View all {data.Numberofcomment} comments</a></p>

                                                                        {data.comments.length === 1 &&
                                                                            <div className="social_page_comment">
                                                                                <div>
                                                                                    <p>@{data.comments[data.comments.length - 1].user}</p>
                                                                                    <p>{data.comments[data.comments.length - 1].comment}</p>
                                                                                </div>
                                                                            </div>}
                                                                        {data.comments.length >= 2 &&
                                                                            <div className="social_page_comment">
                                                                                <div>
                                                                                    <p>@{data.comments[data.comments.length - 2].user}</p>
                                                                                    <p>{data.comments[data.comments.length - 2].comment}</p>
                                                                                </div>
                                                                                <div>
                                                                                    <p>@{data.comments[data.comments.length - 1].user}</p>
                                                                                    <p>{data.comments[data.comments.length - 1].comment}</p>
                                                                                </div>
                                                                            </div>}


                                                                        <form method="post">
                                                                            <div className="comment_text_area">
                                                                                <input className="social_page_comment_text" placeholder="Add a comment" value={comment} onChange={handleChangeComment} />
                                                                                <button type="button"><i className="fa-solid fa-paper-plane" onClick={() => { commentbox(data._id) }}></i></button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>


                                                                <ListExpireData key={`allsoicalexpire${data._id}`} item={data} onExpirationChange={handleExpirationChangeallSocial} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">
                            <CreatePost key="createpost" />
                            <div className="related_product">
                                <p>  </p>
                                {/* <p className="related_product_heading request_heading">Requests</p> */}
                                <ReceiveRequestCard />
                            </div>
                            <div className="related_product">
                                {/* <p className="related_product_heading request_heading">Sent Requests</p> */}
                                <FriendRequestCard />
                            </div>
                        </div>
                        <FriendSideBar />
                    </div>
                </div>
            </div>
            <Footer />

            <div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
            <div className="modal fade" id="exampleModal2" tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="feed_details modal-dialog offer_dialog modal-md">
                    <div className="modal-body">
                        <div className="friends-timeline-pop-up">
                            <div className="pop-up-section">'
                                <div className="pop-up-box-section pop-up-box">
                                    <button type="button" className="close modal_close" data-dismiss="modal" onClick={() => { clearpost() }}>&times;</button>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {(singlepost.type === 1) &&
                                                <div className="pop-up-img">
                                                    <LazyLoadImage src={singlepost.postimage}
                                                        alt="User-shared image" className="img-responsive" />
                                                </div>
                                            }
                                            {(singlepost.type === 3) &&
                                                <div className="pop-up-img">
                                                    <LazyLoadImage src={singlepost.postimage}
                                                        alt="User-shared image" className="img-responsive" />
                                                </div>
                                            }
                                            {(singlepost.type === 6) &&
                                                <div className="pop-up-img">
                                                    <div className="mylook_post">
                                                        <div data-toggle="buttons">
                                                            <label className="btn btn-default">
                                                                <input type="checkbox" name="var_id[]"
                                                                    autoComplete="off" value="" />
                                                                <div className="row">
                                                                    {singlepost.bundleproduct.length === 0 ? <></> :
                                                                        // singlepost.bundleproduct.map((data, index) => (
                                                                        //     <div className="col-md-6 col-xs-6" key={index}>
                                                                        //         <div className="post_select_for_img1">
                                                                        //             <LazyLoadImage src={`${data.productimage}`}
                                                                        //                 alt="" className="img-responsive" />
                                                                        //         </div>
                                                                        //     </div>))
                                                                        <div>
                                                                            {
                                                                                singlepost.layout[0] === "2-2-1" ?
                                                                                    <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }}>

                                                                                        <div className="classic_formate_scroll1 no_scroll">
                                                                                            <div className="row">
                                                                                                <div className="col-md-5 col-xs-5">
                                                                                                    <div className="classic_img_area">
                                                                                                        {singlepost.bundleproduct.length > 0 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[0].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="classic_img_area">
                                                                                                        {singlepost.bundleproduct.length > 1 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[1].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-3 col-xs-3">
                                                                                                    <div className="classic-img-area2">
                                                                                                        {singlepost.bundleproduct.length > 2 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[2].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-3 col-xs-3">
                                                                                                    <div className="classic-img-area2">
                                                                                                        {singlepost.bundleproduct.length > 3 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[3].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic-img-area3">
                                                                                                        {singlepost.bundleproduct.length > 4 &&
                                                                                                            <LazyLoadImage src={`${singlepost.bundleproduct[4].productimage}`} alt="User-shared image"
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    singlepost.layout[0] === "3-2" ?
                                                                                        <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }}>
                                                                                            <div className="classic_formate_scroll1 no_scroll">
                                                                                                <div className="row">
                                                                                                    <div className="col-md-4 col-xs-4">
                                                                                                        <div className="classic-img-area4">
                                                                                                            {singlepost.bundleproduct.length > 0 &&
                                                                                                                <LazyLoadImage src={`${singlepost.bundleproduct[0].productimage}`} alt="User-shared image"
                                                                                                                    className="classic_img" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-4 col-xs-4">
                                                                                                        <div className="classic-img-area4">
                                                                                                            {singlepost.bundleproduct.length > 1 &&
                                                                                                                <LazyLoadImage src={`${singlepost.bundleproduct[1].productimage}`} alt="User-shared image"
                                                                                                                    className="classic_img" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-4 col-xs-4">
                                                                                                        <div className="classic-img-area4">
                                                                                                            {singlepost.bundleproduct.length > 2 &&
                                                                                                                <LazyLoadImage src={`${singlepost.bundleproduct[2].productimage}`} alt="User-shared image"
                                                                                                                    className="classic_img" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic-img-area5">
                                                                                                            {singlepost.bundleproduct.length > 3 &&
                                                                                                                <LazyLoadImage src={`${singlepost.bundleproduct[3].productimage}`} alt="User-shared image"
                                                                                                                    className="classic_img" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-6 col-xs-6">
                                                                                                        <div className="classic-img-area5">
                                                                                                            {singlepost.bundleproduct.length > 4 &&
                                                                                                                <LazyLoadImage src={`${singlepost.bundleproduct[4].productimage}`} alt="User-shared image"
                                                                                                                    className="classic_img" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : singlepost.layout[0] === "4-4" || singlepost.layout[0] === "" ?

                                                                                            <div className="classic_formate" style={{ backgroundImage: singlepost.backgroundimage === "" || singlepost.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${singlepost.backgroundimage})` }} >
                                                                                                <div className="classic_formate_scroll no_scroll">
                                                                                                    <div className="row">
                                                                                                        {singlepost.bundleproduct.length === 0
                                                                                                            ?
                                                                                                            <></>
                                                                                                            :
                                                                                                            singlepost.bundleproduct.map((data, key) => (
                                                                                                                <div className="col-md-6 col-xs-6" key={`Map11${key}`}>
                                                                                                                    <div className="classic_img_area">
                                                                                                                        <LazyLoadImage src={`${data.productimage}`} alt="User-shared image" className="classic_img" />
                                                                                                                    </div>
                                                                                                                </div>)
                                                                                                            )
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>

                                                                                            </div> : <></>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="comments-section">
                                                <div className="profile-comment-img-row">
                                                    <p>{singlepost.postname}</p>
                                                </div>
                                                <div className="timeline-comments">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="friend-comments-section">
                                                                <p>{singlepost.Numberofcomment} comments</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="timeline-brand-content">
                                                    {postcomment.map((data, index) => (
                                                        <div className="comment-img-row" key={`Map12${index}`}>
                                                            <LazyLoadImage src={`${data.profileimage[0] && (data?.provider[0] === "Phonelogin" ? /\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0]) : {})
                                                                ? data.profileimage[0]
                                                                : data.gender === undefined ? "/images/onboarding/man.png" : data.gender === "Men" ? "/images/onboarding/man.png" : "/images/onboarding/woman.png"
                                                                }`} alt="User profile image" />
                                                            {/* <LazyLoadImage src={data.profileimage[0] === "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`} alt="" /> */}
                                                            <p>@{data.userid[0]}</p>
                                                            <p>{data.comment}</p>
                                                        </div>))}

                                                </div>
                                                <div className="timeline-comment-section">
                                                    <form method="post">
                                                        <div className="timeline-comment_text_area" >

                                                            <input className="timeline-social_page_comment_text" value={comment}
                                                                placeholder="write your comment..."
                                                                onChange={handleChangeComment} />
                                                            <a><i
                                                                className="fa-solid fa-paper-plane" onClick={() => { commentbox(singlepost._id) }} style={{ "cursor": "pointer" }}></i></a>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <AIAgent email={state_account.profile.email} token={state_account.profile.token}/>
            </div>
            {reshare === true ? (
                <Reshare shareddata={shareddata} pkey={"productdetails"} />
            ) : <></>}
            {/* <ScriptTag type="text/javascript"
                src=
                "/js/swip-jquery-1.11.1.min.js" />
            <ScriptTag type="text/javascript"
                src=
                "/js/swip-2.js" />

            <ScriptTag type="text/javascript"
                src=
                "/js/social_tiktok_slide.js" /> */}

            {state_account.showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <LazyLoadImage src="images/Icon metro-cancel.svg" alt="Cancel icon" onClick={closeNotification} />
                        <h5>{state_account.showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )}
            {coinview === true ? (
                <Coindetails productdata={socialdata} pkey={"productdetails"} />
            ) : <></>}
            {showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <LazyLoadImage src="images/Icon metro-cancel.svg" alt="Cancel icon" onClick={closeNotification} />
                        <h5>{showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <><div onClick={() => updatetoggleboxcount()} id="updatetoggleboxcount"></div></>

        </>
    )
}
export default Social;