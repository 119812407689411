/* eslint-disable */
import React from 'react'

function Viewcategory() {
	return (
		<>
			<div className="thetop"></div>

			<div className="navbar home_position navigation_menu navbar_area" role="navigation">
				<div className="container">
					<div className="navbar-header">
						<button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"> <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
						<a className="navbar-brand" ><img src="images/logo.png" alt="" className="navbar_brand_img" /></a>
					</div>
					<div className="navbar-collapse collapse">
						<ul className="nav navbar-nav">
							<li className="active"><a >Discover</a></li>
							<li className="active"><a href="#about">Activity</a></li>
							<li className="active"><a href="#portfolio">Social </a></li>
							<li className="active"><a href="#contact">Stash</a></li>
						</ul>
						<ul className="nav navbar-nav navbar-right mobile_flex">
							<li className="active"><a ><i className="fa-solid fa-magnifying-glass"></i></a></li>
							<li className="active"><a href="#about"><i className="fa-solid fa-cart-arrow-down"></i></a></li>
							<li className="active"><a href="#portfolio"><i className="fa-solid fa-user-large"></i></a></li>
							<li className="active"><a href="#contact"><i className="fa-solid fa-bell"></i></a></li>
						</ul>
					</div>
				</div>
			</div>

			<div className="container">

				<ol className="breadcrumb">
					<li className="breadcrumb-item"><i className="fa-solid fa-house-chimney"></i></li>
					<li className="breadcrumb-item">Jackets</li>
					<li className="breadcrumb-item active" aria-current="page">All products</li>
				</ol>
			</div>



			<div className="product_filter">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<form>
								<div className="a filter_left_area">
									<div className="panel-group">
										<div className="panel panel-default">
											<div className="panel-heading">
												<h4 className="panel-title">
													<a data-toggle="collapse" href="#collapse2">Subcategories </a>
												</h4> </div>
											<div id="collapse2" className="panel-collapse collapse in">
												<div className="panel-body">
													<div className="filter_wrap _2">
														<div className="control-pagination">
															<div className="form-group">
																<input type="checkbox" id="buynow" checked="checked" data-value="all" />
																<label for="buynow">T-shirt  <span>(180)</span></label>
															</div>
															<div className="form-group">
																<input type="checkbox" id="onauction" data-value=".software" />
																<label for="onauction">Sweatshirts  <span>(280)</span></label>
															</div>
															<div className="form-group">
																<input type="checkbox" id="new_p" data-value=".cat" />
																<label for="new_p">Tank tops  <span>(60)</span></label>
															</div>
															<div className="form-group">
																<input type="checkbox" id="onoffer" />
																<label for="onoffer">Dress Shirts <span>(170)</span></label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>


									<div className="panel-group">
										<div className="panel panel-default">
											<div className="panel-heading">
												<h4 className="panel-title">
													<a data-toggle="collapse" href="#collapse2">Brands </a>
												</h4> </div>
											<div id="collapse2" className="panel-collapse collapse in">
												<div className="panel-body">
													<div className="filter_wrap _2">
														<div className="control-pagination">
															<div className="form-group">
																<input type="checkbox" id="diamonds" checked="checked" data-value="all" />
																<label for="diamonds">7 Diamonds  <span>(180)</span></label>
															</div>
															<div className="form-group">
																<input type="checkbox" id="mc" data-value=".software" />
																<label for="mc">Alexander Mc Queen  <span>(280)</span></label>
															</div>
															<div className="form-group">
																<input type="checkbox" id="saints" data-value=".cat" />
																<label for="saints">All Saints <span>(60)</span></label>
															</div>
															<div className="form-group">
																<input type="checkbox" id="alternative" />
																<label for="alternative">Alternative <span>(170)</span></label>
															</div>
															<div className="form-group">
																<input type="checkbox" id="alton" />
																<label for="alton">Alton Lane <span>(170)</span></label>
															</div>
															<div className="form-group">
																<input type="checkbox" id="bos" />
																<label for="bos">Bos <span>(170)</span></label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="panel-group">
										<div className="panel panel-default">
											<div className="panel-heading">
												<h4 className="panel-title">
													<a data-toggle="collapse" href="#collapse3"> Price</a>
												</h4> </div>
											<div id="collapse3" className="panel-collapse collapse in">
												<div className="panel-body">
													<div className="filter_wrap _2">
														<p>Price Range</p>
														<div className="range-slider">
															<div className="display_flex space_between2 rangeValues"></div>
															<input value="1000" min="1000" max="50000" step="500" type="range" />
															<input value="50000" min="1000" max="50000" step="500" type="range" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</form>
						</div>
						<div className="col-md-8">
							<div className="menstop_heading">
								<div className="display_flex space_between2">
									<h3>Jackets <span>(133)</span></h3>
									<div className="display_flex sorting_product_filter">
										<p>Sort</p>
										<li className="dropdown">
											<a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Dropdown <span className="caret"></span></a>
											<ul className="dropdown-menu">
												<li><a className="dropdown-item" >Recent Add</a></li>
												<li><a className="dropdown-item" >Recent Sold</a></li>
												<li><a className="dropdown-item" >Price Low to High</a></li>
												<li><a className="dropdown-item" >Price High to Low</a></li>
											</ul>
										</li>

									</div>
								</div>
							</div>


							<div className="products_filter_all">
								<div className="row">
									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart"></i></a>
													<a ><i className="fa-solid fa-share"></i></a>
												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart active_bg"></i></a>

												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>

									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
												<div className="badge_pro">50%</div>
												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart"></i></a>
												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart"></i></a>
													<a ><i className="fa-solid fa-share"></i></a>
												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
												<div className="badge_pro">50%</div>
												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart active_bg"></i></a>

												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>

									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart"></i></a>
												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart"></i></a>
													<a ><i className="fa-solid fa-share"></i></a>
												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart active_bg"></i></a>

												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>

									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart"></i></a>
												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />
												<div className="badge_pro">50%</div>
												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart"></i></a>
													<a ><i className="fa-solid fa-share"></i></a>
												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart active_bg"></i></a>

												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>

									<div className="col-md-4">
										<div className="select_for-box home_page_dialog">
											<div className="select_for_img"> <img src="images/home/new-arrival-1.png" alt="" className="img-responsive" />

												<div className="like_share_product">
													<a ><i className="fa-regular fa-heart"></i></a>
												</div>
											</div>
											<div className="select_for_text">
												<p>T-Shirt Summber Vibes</p>
												<ul className="list-unstyled display_flex space_between2">
													<li><a className="real_price">$89.99</a></li>
													<li><span className="old_price">$111.99</span></li>
												</ul>
											</div>
										</div>
									</div>
								</div>

							</div>
							<nav className="text-center">
								<ul className="pagination pagination-lg" role="navigation">

									<li><a >Prev</a></li>
									<li className="active"><a >2</a></li>
									<li><a >3</a></li>
									<li><a >4</a></li>
									<li><a >Next</a></li>


								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>


			<div className="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="footer_first"> <img src="images/logo.png" alt="" className="img-responsive footer-logo" />
								<p className="footer_sub_heading">Shop Share & Self</p>
								<p>Follow us on:</p>
								<ul className="list-unstyled display_flex">
									<li><a ><i className="fa-brands fa-facebook-square"></i></a></li>
									<li><a ><i className="fa-brands fa-twitter-square"></i></a></li>
									<li><a ><i className="fa-brands fa-linkedin"></i></a></li>
									<li><a ><i className="fa-brands fa-instagram-square"></i></a></li>
								</ul>
							</div>
						</div>
						<div className="col-md-3"></div>
						<div className="col-md-3">
							<p className="footer_sub_heading padding-bottom-20">Contact us</p>
							<ul className="list-unstyled footer_link">
								<li><a >About us</a></li>
								<li><a >Mission</a></li>
								<li><a >Vision</a></li>
								<li><a >Products</a></li>
								<li><a >Services</a></li>
								<li><a >support@edgewrapper.com</a></li>
							</ul>
						</div>
						<div className="col-md-3">
							<p className="footer_sub_heading padding-bottom-20">Download the app on</p> <img src="images/home/Google_Play_Store_badge_EN.svg.png" alt="" className="footer_store" /> <img src="images/home/apple-app-store-logo-500x173 copy.png" alt="" className="footer_store" /> </div>
					</div>
					<div className="footer_copyright">
						<a href='https://www.edgewrapper.com/'>Powered by Edgewrapper Technology Private Limited©2020-2024.</a>
					</div>
				</div>
			</div>

			<div className="scroll icon scrolltop text-right"><i className="fa-solid fa-arrow-turn-up"></i></div>
			{/*<script src="js/jquery.min.js"></script>
		<script src="js/popper.min.js"></script>
		<script src="owlcarousel/owl.carousel.js"></script>
		<script src="js/bootstrap.min.js"></script>
		<script src="js/script.js"></script>*/}


		</>
	)
}

export default Viewcategory;