/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { share_a_new_product } from "../../store/social/api"
// import $ from "jquery";
// import ScriptTag from 'react-script-tag';
import { notification_message_set, notification_message_status } from "../../store/account/accountActions"
// import { jesssu_user_profileimage_upload } from "../../store/account/api"
// import { useNavigate } from 'react-router-dom';
const Productshare = ({ productdata, pkey }) => {

    const inputRef = useRef(null);

    // const history = useNavigate();
    const dispatch = useDispatch();
    // const [imageurl, setImageUrl] = useState("");
    const [note, setNote] = useState('');
    // const [selecteduser, setSelectedUser] = useState([]);
    const state_account = useSelector(state => state.account);
    // const [state_account.userfriendlist, setFriendList] = useState(state_account.userfriendlist);
    const [shareuserselection, setshareuserselection] = useState("Share With")
    // const [innermodal, setinnermodal] = useState(false)
    const sharetype ="Post"
    const [selectedfriendlist, setselectedfriendlist] = useState([])
    // const [hasError, setHasError] = useState(true);

    const timeOptions = [
        "5 mins", "10 mins", "20 mins", "30 mins"
    ]

    const [selectedOption, setSelectedOption] = useState('5 mins');
    const [showDropdown, setShowDropdown] = useState(false);

    // const timeOptions = ['Volvo', 'Saab', 'Mercedes', 'Audi'];

    localStorage.setItem('ShareProduct', JSON.stringify(productdata));


    // function openinnermodal() {
    //     // setinnermodal(true)
    // }
    function closeinnermodal() {
        // setinnermodal(false)
    }
    useEffect(() => {
        setshareuserselection("Share With")
        // inputRef.current.value = "5 mins";
    }, [productdata]);

    function userselectionupdate(data) {
        setshareuserselection(data)
    }
    function getselectedfriend(data, e) {
        if (e.target.checked) {
            setselectedfriendlist(current => [...current, data.friendemail[0]])
        }
        else {
            setselectedfriendlist((prevState) =>
                prevState.filter((prevItem) => prevItem !== data.friendemail[0])
            );
        }
    }


    const handleChange = (e) => {
        if (e.target.id === "note") {
            setNote(e.target.value)
        }
    };

    async function share() {
        let sharedtovalue = []
        if (shareuserselection === "Public") {
            sharedtovalue = []
            if (sharetype === "Post") {
                // setHasError(false);
                await shareaproduct(sharedtovalue, productdata)

            }
        } else if (shareuserselection === "Friends") {
            state_account.userfriendlist.map(i => {
                sharedtovalue.push(i.friendemail[0])
            })
            if (sharetype === "Post") {
                // setHasError(false);
                await shareaproduct(sharedtovalue, productdata)

            }
        } else if (shareuserselection === "Specific Friends") {
            sharedtovalue = selectedfriendlist
            if (sharetype === "Post") {
                // setHasError(false);
                await shareaproduct(sharedtovalue, productdata)

            }
        } else {
            // setHasError(true);
            dispatch(notification_message_status(true))
            dispatch(notification_message_set("Please select who can see your post."))              // setShowNotification(true);
            closenotification()
        }


    }
    async function convertToMilliseconds(str) {
        const regex = /\d+/g; // Regular expression to match numbers
        const numberArray = str.match(regex).map(Number); // Extract numbers and convert them to actual numbers

        if (numberArray.length > 0) {
            const firstNumber = numberArray[0];
            const milliseconds = firstNumber * 60000;
            return milliseconds;
        } else {
            return null; // Return null if no numbers are found in the string
        }
    }

    async function shareaproduct(sharedtovalue, product) {

        let time = await convertToMilliseconds(selectedOption)

        const payload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "notes": note,
            "sharedto": sharedtovalue,
            "productid": product.SKU,
            "likes": [],
            "dislikes": [],
            "timeLimit": time
        }
        dispatch(share_a_new_product(payload, (res) => {
            if (res.status === 200) {
                dispatch(notification_message_status(true))
                dispatch(notification_message_set("Your product has been shared successfully"))              // setShowNotification(true);
                closenotification()
                setshareuserselection("Share With")
                inputRef.current.click();
            } else {
                dispatch(notification_message_status(true))
                dispatch(notification_message_set("Share not successful."))              // setShowNotification(true);
                closenotification()
                setshareuserselection("Share With")
            }
        }))

    }

    function closenotification() {
        setTimeout(function () {
            dispatch(notification_message_status(false))
            dispatch(notification_message_set(""))
        }, 5000);
    }



    const handleOptionChange = (option) => {
        console.log(option)
        setSelectedOption(option);
        // setShowDropdown(false);
        setShowDropdown(!showDropdown)
    };

    const handleInputChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const closeFriendPopup = () => {
        $('#specific_post_alert').modal('hide'); // Using jQuery to hide the modal
    };

    return (<>
        {/* <!-- Slider Section Closed Here--> */}
        {/* <a  data-toggle="modal" data-target="#create_post_alert" className="btn btn-color" onClick={() => reset()}><i className="fa-solid fa-plus"></i> Create</a> */}

        {pkey != null ? <></> : <a href="#" data-toggle="modal" data-target={`#example-sharemodel${productdata.SKU}`}><i className="fa-solid fa-share"></i></a>}

        <div className="modal fade" id={`example-sharemodel${productdata.SKU}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="post_area modal-lg modal-dialog offer_dialog modal-md">
                <div className="modal-content feed_modal_content">
                    <button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
                    <div className="modal-body">
                        <form method="post">
                            <div className="row">
                                <div className="col-md-12">
                                    {sharetype === "Post" &&
                                        <h4 className="create_post_heading">Share This Product</h4>}
                                    {sharetype === "Product" &&
                                        <h4 className="create_post_heading">Share Wishlist Product</h4>}
                                    {sharetype === "Look" &&
                                        <h4 className="create_post_heading">Share Your Look</h4>}

                                    <div className="modal_row">
                                        <div>
                                            {/* <!-- Upload  --> */}
                                            {sharetype === "Post" &&
                                                <div className="post_nav_content" style={{ "height": "132px" }}>
                                                    <img src={productdata.ProductImage}
                                                        alt="" className="img-responsive" />
                                                </div>
                                            }
                                        </div>
                                        <div className="write_message uploader">
                                            <textarea rows="6" cols="10" className="form-control" id="note"
                                                placeholder="message here" onChange={(e) => { handleChange(e) }}></textarea>
                                        </div>
                                    </div>
                                    <div className="second_row margin-bottom-20">
                                        <p className="text-white">Who can see your post? </p>
                                        <div className="share_area">
                                            <ul className="share_list display_block_important share_list_box">
                                                <li>
                                                    <input type="radio" id="p-option" name="selector" />
                                                    <label htmlFor="p-option" className="who_share_icon share_list_box_ico" onClick={() => userselectionupdate("Public")}>
                                                        <i className="fa-solid fa-globe"></i> Public</label>
                                                    {shareuserselection !== "Public" &&
                                                        <div className="check">
                                                            <div className="inside"></div>
                                                        </div>}
                                                    {shareuserselection === "Public" &&
                                                        <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                            <div className="inside"></div>
                                                        </div>}
                                                </li>
                                                <li>
                                                    <input type="radio" id="f-option" name="selector" />
                                                    <label htmlFor="f-option" className="who_share_icon share_list_box_ico" onClick={() => userselectionupdate("Friends")}>
                                                        <i className="fa-solid fa-user"></i> Friends</label>
                                                    {shareuserselection !== "Friends" &&
                                                        <div className="check">
                                                            <div className="inside"></div>
                                                        </div>}
                                                    {shareuserselection === "Friends" &&
                                                        <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                            <div className="inside"></div>
                                                        </div>}
                                                </li>
                                                <li>
                                                    <input type="radio" id="s-option" name="selector" />
                                                    <label htmlFor="s-option" data-toggle="modal"
                                                        data-target="#specific_post_alert" className="who_share_icon share_list_box_ico" onClick={() => userselectionupdate("Specific Friends")}>
                                                        <i className="fa-solid fa-user-tag"></i> Specific
                                                        Friends</label>
                                                    {shareuserselection !== "Specific Friends" &&
                                                        <div className="check">
                                                            <div className="inside"></div>
                                                        </div>}
                                                    {shareuserselection === "Specific Friends" &&
                                                        <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                            <div className="inside"></div>
                                                        </div>}
                                                    <div className="modal fade" id="specific_post_alert" tabIndex="10"
                                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="post_area modal-sm modal-dialog offer_dialog">
                                                            <div className="sub_modal modal-content feed_modal_content">

                                                                <button type="button" className="close modal_close"
                                                                   onClick={closeFriendPopup}>&times;</button>
                                                                <div className="modal-body">
                                                                    <p>Select Your Friends !</p>
                                                                    <div className="specific_checkbox_fd">

                                                                        {(state_account.userfriendlist || []).map((data, index) => (
                                                                            <div className="form-area">
                                                                                <div>
                                                                                <img src={`${data.profileimage[0] && (data?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0]): {})
                                                                                                ? data.profileimage[0]
                                                                                                : data.gender === undefined ? "images/onboarding/man.png" : data.gender === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                                                }`} alt="" className="specific_user_icon" />
                                                                                    {/* <img src={data.profileimage[0] == "" ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : `${data.profileimage[0]}`}
                                                                                        alt=""
                                                                                        className="specific_user_icon" /> */}
                                                                                </div>
                                                                                <div><label htmlFor={index}>{data.friendfirstname[0]} {data.friendlastname[0]}</label></div>
                                                                                <div> <input type="checkbox" id={index} onChange={(e) => getselectedfriend(data, e)} />
                                                                                </div>
                                                                            </div>))}


                                                                        <div className="display_flex confirm_submit">
                                                                            <p><a href="#"
                                                                                className="grey_text" id="cancelfriendlist" onClick={closeFriendPopup}>Cancel</a></p>
                                                                            <p><a href="#"
                                                                                className="red_text" id="selectfriendlist" onClick={closeFriendPopup}>Confirm</a></p>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="time_activity">
                                                        <div className="time_activity">
                                                            <img src="/images/social-share/clock.svg" alt=""/>
                                                            <h5>
                                                                Set a active time for like, dislikes, and comments<br />
                                                                <span>
                                                                    When someone receives a post they can only like or dislike it within the given time
                                                                </span>
                                                            </h5>
                                                        </div>


                                                        <div className="input-box">
                                                            <input
                                                                type="text"
                                                                placeholder="Type a custom value or click for options..."
                                                                value={selectedOption}
                                                                onChange={handleInputChange}
                                                                onClick={() => setShowDropdown(!showDropdown)}
                                                            />
                                                            {showDropdown && (
                                                                <div className="dropdown-options">
                                                                    {timeOptions.map((option, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="dropdown-option"
                                                                            onClick={() => handleOptionChange(option)}
                                                                        >
                                                                            {option}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                </li>


                                            </ul>

                                        </div>
                                    </div>
                                    <div className="post_submit_btn_section">
                                        <button type="button" data-dismiss='modal' id='closesharemodal' style={{display:"none"}}  onClick={closeFriendPopup} ref={inputRef}></button>
                                        <button type="button" className="post_submit_btn" value="submit" onClick={() => { share() }}>Submit</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {/* <ScriptTag type="text/javascript"
            src=
            "/js/productshare.js" /> */}
    </>);
}

export default Productshare;

