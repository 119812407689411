/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { share_a_look } from "../../store/social/api"
import { notification_message_set, notification_message_status } from "../../store/account/accountActions"
// import ScriptTag from 'react-script-tag';
// import { Link } from "react-router-dom";
import { get_user_profile_details } from "../../store/account/api"
import { user_signin } from 'store/account/accountActions';

const MyLookShare = ({ lookdata, modalid }) => {
    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const [note, setNote] = useState('');
    const state_account = useSelector(state => state.account);
    const [shareuserselection, setshareuserselection] = useState("Share With")
    const [selectedfriendlist, setselectedfriendlist] = useState([])
    const [selectedOption, setSelectedOption] = useState('5 mins');
    const [showDropdown, setShowDropdown] = useState(false);
    const timeOptions = [
        "5 mins", "10 mins", "20 mins", "30 mins"
    ]
    // const [hasError, setHasError] = useState(true);


    const handleOptionChange = (option) => {
        console.log(option)
        setSelectedOption(option);
        // setShowDropdown(false);
        setShowDropdown(!showDropdown)
    };
    // function reset() {
    //     setNote("")
    //     setshareuserselection("Share With")
    //     setselectedfriendlist([])
    // }
    const handleInputChange = (e) => {
        setSelectedOption(e.target.value);
    };

    localStorage.setItem('Look', JSON.stringify(modalid));

    useEffect(() => {
        let isMounted = true;
		if (isMounted) get_profile_details();
    }, []);

	const get_profile_details = () => {
		let payload = {
			"token": state_account.profile.token,
			"email": state_account.profile.email,
		}

		dispatch(get_user_profile_details(payload, (res) => {
			if (res.status === 200) {
				// console.log(res.data)
				dispatch(user_signin(res.data));
			}
		}));
	}
    function userselectionupdate(data) {
        setshareuserselection(data)
    }
    function getselectedfriend(data, e) {
        if (e.target.checked) {
            setselectedfriendlist(current => [...current, data.friendemail[0]])
        }
        else {
            setselectedfriendlist((prevState) =>
                prevState.filter((prevItem) => prevItem !== data.friendemail[0])
            );
        }
    }


    const handleChange = (e) => {
        if (e.target.id === "note") {
            setNote(e.target.value)
        }
    };

    async function share() {
        let sharedtovalue = []
        if (shareuserselection === "Public") {
            sharedtovalue = []
            await sharealook(sharedtovalue)

        } else if (shareuserselection === "Friends") {
            // state_account.userfriendlist.map(i => {
            //     sharedtovalue.push(i.friendemail[0])
            // })
            for (let i = 0; i < state_account.userfriendlist.length; i++) {
                sharedtovalue.push(state_account.userfriendlist[i].friendemail[0]);
              }
            await sharealook(sharedtovalue)

        } else if (shareuserselection === "Specific Friends") {
            sharedtovalue = selectedfriendlist
            await sharealook(sharedtovalue)
        }else {
            // setHasError(true);
            dispatch(notification_message_status(true))
            dispatch(notification_message_set("Please select who can see your post."))              // setShowNotification(true);
            closenotification()
        }


    }
    async function convertToMilliseconds(str) {
        const regex = /\d+/g; // Regular expression to match numbers
        const numberArray = str.match(regex).map(Number); // Extract numbers and convert them to actual numbers

        if (numberArray.length > 0) {
            const firstNumber = numberArray[0];
            const milliseconds = firstNumber * 60000;
            return milliseconds;
        } else {
            return null; // Return null if no numbers are found in the string
        }
    }

    async function sharealook(sharedtovalue) {
        let time = await convertToMilliseconds(selectedOption)
        const payload = {
            "email": state_account.profile.email,
            "token": state_account.profile.token,
            "notes": note,
            "sharedto": sharedtovalue,
            "backgroundimage": lookdata.backgroundimage,
            "bundleproducts": lookdata.productdetails,
            "bundleid": lookdata.bundleid,
            "likes": [],
            "dislikes": [],
            "timeLimit": time
        }
        dispatch(share_a_look(payload, (res) => {
            if (res.status === 200) {
                dispatch(notification_message_status(true))
                dispatch(notification_message_set("Your look has been shared successfully"))
                closenotification()
                setshareuserselection("Share With")
                inputRef.current.click();

            }else {
                dispatch(notification_message_status(true))
                dispatch(notification_message_set("Share not successful."))              // setShowNotification(true);
                closenotification()
                setshareuserselection("Share With")
            }
        }))

    }
    function closenotification() {
        setTimeout(function () {
            dispatch(notification_message_status(false))
            dispatch(notification_message_set(""))
        }, 5000);
    }
    const closeFriendPopup = () => {
        $('#specific_post_alert_friends').modal('hide'); // Using jQuery to hide the modal
    };
    return (<>

        <div className="modal fade" id={`${modalid}`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="feed_details modal-dialog offer_dialog modal-md">
                <div className="modal-body">
                    <div className="friends-timeline-pop-up">
                        <div className="stash-pop-up-section">
                            <div className="stash-pop-up-box">
                                <div className="wishlist-Just-checking-section">
                                    <h6>DELETE </h6>
                                </div>
                                <div className="delete-section">
                                    <h6>5 Jesssu coin will be taken for share a look. </h6>
                                </div>
                                <div className="checking-row">
                                    
                                {state_account.profile.jesssucoin <=5 && <button className="checkingforyes-btn-default" data-dismiss="modal">YES</button>}
                                    
                                    {state_account.profile.jesssucoin>5 && <button className="checkingforyes-btn-default" data-toggle="modal" data-target={`#${modalid}popup`} data-dismiss="modal">YES</button>}
                                    
                                    <button className="checking-btn-default" data-dismiss="modal">NO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div style = {{"overflow":"scroll"}}className="modal fade" id={`${modalid}popup`} tabIndex="10" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="post_area modal-lg modal-dialog offer_dialog modal-md">
                <div className="modal-content feed_modal_content">
                    <button type="button" className="close modal_close" data-dismiss="modal">&times;</button>
                    <div className="modal-body">
                        <form method="post">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="create_post_heading">Share My Look</h4>
                                    <div className="modal_row">
                                        <div>
                                            <div className="post_nav_content" style={{ "height": "182px", "width": "255px" }}>
                                                <div className="mylook_post">
                                                    <div data-toggle="buttons">
                                                        <label className="btn btn-default">
                                                            <input type="checkbox" name="var_id[]"
                                                                autoComplete="off" value="" />
                                                            <div className="row">
                                                                {lookdata.productdetails.length === 0 ? <></> :
                                                                    <div>
                                                                        {
                                                                            lookdata.layout === "3-2" ?
                                                                                <div className="classic_formate" style={{ backgroundImage: lookdata.backgroundimage === "" || lookdata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${lookdata.backgroundimage})` }}>
                                                                                    <div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-5 col-xs-5">
                                                                                                <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                    {lookdata.productdetails.length > 0 &&
                                                                                                        <img src={`${lookdata.productdetails[0].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                    {lookdata.productdetails.length > 1 &&
                                                                                                        <img src={`${lookdata.productdetails[1].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-5 col-xs-5">
                                                                                                <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                    {lookdata.productdetails.length > 2 &&
                                                                                                        <img src={`${lookdata.productdetails[2].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-5 col-xs-5">
                                                                                                <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                    {lookdata.productdetails.length > 3 &&
                                                                                                        <img src={`${lookdata.productdetails[3].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-5 col-xs-5">
                                                                                                <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                    {lookdata.productdetails.length > 4 &&
                                                                                                        <img src={`${lookdata.productdetails[4].productimage}`} alt=""
                                                                                                            className="classic_img" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                lookdata.layout === "2-2-1" ?
                                                                                    <div className="classic_formate" style={{ backgroundImage: lookdata.backgroundimage === "" || lookdata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${lookdata.backgroundimage})` }}>
                                                                                        <div>
                                                                                            {/* <div className="page2_right_area"> */}
                                                                                            <div className="row">
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                        {lookdata.productdetails.length > 0 &&
                                                                                                            <img src={`${lookdata.productdetails[0].productimage}`} alt=""
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                        {lookdata.productdetails.length > 1 &&
                                                                                                            <img src={`${lookdata.productdetails[1].productimage}`} alt=""
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-12">
                                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                        {lookdata.productdetails.length > 2 &&
                                                                                                            <img src={`${lookdata.productdetails[2].productimage}`} alt="" height={"100%"}
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                        {lookdata.productdetails.length > 3 &&
                                                                                                            <img src={`${lookdata.productdetails[3].productimage}`} alt="" height={"100%"}
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-md-6 col-xs-6">
                                                                                                    <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                        {lookdata.productdetails.length > 4 &&
                                                                                                            <img src={`${lookdata.productdetails[4].productimage}`} alt="" height={"100%"}
                                                                                                                className="classic_img" />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    : lookdata.layout === "4-4" || lookdata.layout === "" ?
                                                                                        <div className="classic_formate" style={{ backgroundImage: lookdata.backgroundimage === "" || lookdata.backgroundimage === undefined ? "linear-gradient(179deg, #6398AF, #FEAB79)" : `url(${lookdata.backgroundimage})` }} >
                                                                                            <div>
                                                                                                <div className="row">
                                                                                                    {lookdata.productdetails.length === 0
                                                                                                        ?
                                                                                                        <></>
                                                                                                        :
                                                                                                        lookdata.productdetails.slice(0, 4).map((data, key) => (
                                                                                                            <div className="col-md-6 col-xs-6" key={key}>
                                                                                                                <div className="classic_img_area" style={{ "width": "74px", "height": "74px" }}>
                                                                                                                    <img src={`${data.productimage}`} alt="" className="classic_img" />
                                                                                                                </div>
                                                                                                            </div>)
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                        </div> : <></>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="write_message uploader">
                                            <textarea rows="6" cols="10" className="form-control" id="note"
                                                placeholder="message here" onChange={(e) => { handleChange(e) }}></textarea>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6"> */}
                                    <div className="second_row margin-bottom-20">
                                        <p className="text-white">Who can see your post? </p>
                                        <div className="share_area">
                                            <ul className="share_list">
                                                <li>
                                                    <input type="radio" id="p-option" name="selector" />
                                                    <label htmlFor="p-option" className="who_share_icon" onClick={() => userselectionupdate("Public")}>
                                                        <i className="fa-solid fa-globe"></i> Public</label>
                                                    {shareuserselection !== "Public" &&
                                                        <div className="check">
                                                            <div className="inside"></div>
                                                        </div>}
                                                    {shareuserselection === "Public" &&
                                                        <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                            <div className="inside"></div>
                                                        </div>}
                                                </li>
                                                <li>
                                                    <input type="radio" id="f-option" name="selector" />
                                                    <label htmlFor="f-option" className="who_share_icon" onClick={() => userselectionupdate("Friends")}>
                                                        <i className="fa-solid fa-user"></i> Friends</label>
                                                    {shareuserselection !== "Friends" &&
                                                        <div className="check">
                                                            <div className="inside"></div>
                                                        </div>}
                                                    {shareuserselection === "Friends" &&
                                                        <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                            <div className="inside"></div>
                                                        </div>}
                                                </li>
                                                <li>
                                                    <input type="radio" id="s-option" name="selector" />
                                                    <label htmlFor="s-option" data-toggle="modal"
                                                        data-target="#specific_post_alert_friends" className="who_share_icon" onClick={() => userselectionupdate("Specific Friends")}>
                                                        <i className="fa-solid fa-user-tag"></i> Specific
                                                        Friends</label>
                                                    {shareuserselection !== "Specific Friends" &&
                                                        <div className="check">
                                                            <div className="inside"></div>
                                                        </div>}
                                                    {shareuserselection === "Specific Friends" &&
                                                        <div className="check" style={{ "border": "5px solid #ffc332" }}>
                                                            <div className="inside"></div>
                                                        </div>}
                                                    <div className="modal fade" id="specific_post_alert_friends" tabIndex="10"
                                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="post_area modal-sm modal-dialog offer_dialog">
                                                            <div className="sub_modal modal-content feed_modal_content">
                                                                <button type="button" className="close modal_close" onClick={closeFriendPopup}
                                                                    id="modal2" >&times;</button>
                                                                <div className="modal-body">
                                                                    <p>Select Your Friends !</p>
                                                                    <div className="specific_checkbox_fd">
                                                                        {(state_account.userfriendlist || []).map((data, index) => (
                                                                            <div className="form-area" key={index}>
                                                                                <div><img src={`${data.profileimage[0] && (data?.provider[0] === "Phonelogin"?/\.(gif|jpg|jpeg|tiff|png|webp)(\?.*)?$/i.test(data.profileimage[0]): {})
                                                                                                ? data.profileimage[0]
                                                                                                : data.gender === undefined ? "images/onboarding/man.png" : data.gender === "Men" ? "images/onboarding/man.png" : "images/onboarding/woman.png"
                                                                                                }`} alt="" className="specific_user_icon" />
                                                                                </div>
                                                                                <div><label htmlFor={index}>{data.friendfirstname[0]} {data.friendlastname[0]}</label></div>
                                                                                <div> <input type="checkbox" id={index} onChange={(e) => getselectedfriend(data, e)} />
                                                                                </div>
                                                                            </div>))}
                                                                        <div className="display_flex confirm_submit">
                                                                            <p><a href="#"
                                                                                className="grey_text" id="cancelfriendlist" onClick={closeFriendPopup}>Cancel</a></p>
                                                                            <p><a href="#"
                                                                                className="red_text" id="selectfriendlist" onClick={closeFriendPopup}>Confirm</a></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="time_activity">
                                                        <div className="time_activity">
                                                            <img src="/images/social-share/clock.svg" alt=""/>
                                                            <h5>
                                                                Set a active time for like, dislikes, and comments<br />
                                                                <span>
                                                                    When someone receives a post they can only like or dislike it within the given time
                                                                </span>
                                                            </h5>
                                                        </div>


                                                        <div className="input-box">
                                                            <input
                                                                type="text"
                                                                placeholder="Type a custom value or click for options..."
                                                                value={selectedOption}
                                                                onChange={handleInputChange}
                                                                onClick={() => setShowDropdown(!showDropdown)}
                                                            />
                                                            {showDropdown && (
                                                                <div className="dropdown-options">
                                                                    {timeOptions.map((option, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="dropdown-option"
                                                                            onClick={() => handleOptionChange(option)}
                                                                        >
                                                                            {option}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    {/* </div> */}


                                </div>
                            </div>
                            <div className="post_submit_btn_section">
                            <button type="button" data-dismiss='modal' id='closesharemodal' style={{display:"none"}} ref={inputRef} onClick={closeFriendPopup}></button>
                                <button type="button" className="post_submit_btn" value="submit" id="closesharemodal" onClick={() => { share() }}>Submit</button>
                            </div>
                            {/* </div> */}
                        </form>
                    </div>
                </div>
            </div>
        </div>


        {/* <ScriptTag type="text/javascript"
            src=
            "/js/lookshare.js" /> */}

    </>);
}

export default MyLookShare;