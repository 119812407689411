/* eslint-disable */
import { Header } from "components";
import React from "react";
// import { useDispatch } from "react-redux";


const PrivacyPolicy = () => {
    // const dispatch = useDispatch();
    // const [issend, setIsSend] = useState(false);
    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [message, setMessage] = useState("");
    // const twitter = "https://twitter.com/Jesssushop";
    // const facebook = "https://www.facebook.com/Jesssushop";
    // const linkedin = "https://www.linkedin.com/showcase/jesssu";
    // const instagram = "https://www.instagram.com/jesssushop";

    return (
        <>
            <div className="thetop"></div>
            <Header />








            <div className="mission-box">
                <div className="contactus_area">
                    <div className="container">
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Jesssu Privacy Policy</h2>
                                    <p >This Privacy Policy describes how your personal information is collected, used, and shared when you visit Jesssu.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Collecting Personal Information</h2>
                                    <p >When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Device information</h2>
                                    <p >Examples of Personal Information collected: version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.
                                        Purpose of collection: to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.
                                        Source of collection: Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels.
                                        <br />
                                        Purpose of collection: to provide customer support.
                                        <br />
                                        Source of collection: collected from you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Minors</h2>
                                    <p>The Site is not intended for individuals under the age of 18. We do not intentionally collect Personal Information from children. If you are the parent or guardian and believe your child has provided us with Personal Information, please contact us at the address below to request deletion.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Sharing Personal Information</h2>
                                    <p>
                                        We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:
                                        We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: <a target="_blank" href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>.You can also opt-out of Google Analytics here:<a target="_blank" href="https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</a>.
                                        We share information about your use of the Site, your purchases, and your interaction with our ads on other websites with our advertising partners. We collect and share some of this information directly with our advertising partners, and in some cases through the use of cookies or other similar technologies (which you may consent to, depending on your location).
                                        For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at <a target="_blank" href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.
                                        You can opt out of targeted advertising by:
                                        FACEBOOK - <a href="https://www.facebook.com/settings/?tab=ads" target="_blank">https://www.facebook.com/settings/?tab=ads</a>  GOOGLE -<a target="_blank" href="https://www.google.com/settings/ads/anonymous">https://www.google.com/settings/ads/anonymous</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Cookies</h2>
                                    <p>
                                        A cookie is a small amount of information that’s downloaded to your computer or device when you visit our Site. We use a number of different cookies, including functional, performance, advertising, and social media or content cookies. Cookies make your browsing experience better by allowing the website to remember your actions and preferences (such as login and region selection). This means you don’t have to re-enter this information each time you return to the site or browse from one page to another. Cookies also provide information on how people use the website, for instance whether it’s their first time visiting or if they are a frequent visitor.

                                        We use the following cookies to optimize your experience on our Site and to provide our services.

                                        The length of time that a cookie remains on your computer or mobile device depends on whether it is a “persistent” or “session” cookie. Session cookies last until you stop browsing and persistent cookies last until they expire or are deleted. Most of the cookies we use are persistent and will expire between 30 minutes and two years from the date they are downloaded to your device.

                                        You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies can negatively impact your user experience and parts of our website may no longer be fully accessible.

                                        Most browsers automatically accept cookies, but you can choose whether or not to accept cookies through your browser controls, often found in your browser’s “Tools” or “Preferences” menu. For more information on how to modify your browser settings or how to block, manage or filter cookies can be found in your browser’s help file.

                                        Additionally, please note that blocking cookies may not completely prevent how we share information with third parties such as our advertising partners. To exercise your rights or opt-out of certain uses of your information by these parties, please follow the instructions in the “Behavioural Advertising” section above.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Using Personal Information</h2>
                                    <p>We use your personal Information to provide our services to you, which includes: offering products for sale, processing payments, shipping and fulfillment of your order, and keeping you up to date on new products, services, and offers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Do Not Track</h2>
                                    <p>Please note that because there is no consistent industry understanding of how to respond to “Do Not Track” signals, we do not alter our data collection and usage practices when we detect such a signal from your browser.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Changes</h2>
                                    <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row privacy-policy-row">
                            <div className="col-md-1">
                                <div className="mission-back">
                                </div>
                            </div>
                            <div className="col-md-11">
                                <div className="mission-section">
                                    <h2>Contact</h2>
                                    <p> For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <b>support@jesssu.com</b> or directly submit a ticket <a href="/contact-us" target="_blank" rel="noopener noreferrer">here</a>
                                        <br />Last updated: 27-Apr-2023
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>










        </>);
}
export default PrivacyPolicy;